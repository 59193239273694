import {useLocation, Navigate, Outlet} from "react-router-dom"
import useAuth from "./useAuth.js"

function RequireAuth({allowedRoles}) {
    const {auth} = useAuth()
    const location = useLocation()

    return (
        allowedRoles.includes(auth?.roles)
            ? <Outlet /> 
            : auth?.accessToken 
                ? <Navigate to="/" state={{from: location}} replace />
                : <Navigate to="/login" state={{from: location}} replace />
    )
}

export default RequireAuth