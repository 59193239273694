import axios from "./axios.js";
import SubstrateRowMech from "../Components/substrateRowMech.js";
import ProductRowMech from "../Components/productRowMech.js";

async function findMechanism({
  table,
  row,
  setIsLoading,
  count,
  pageCount,
  setDataCount,
  search,
  renderData,
  setPageCount,
  setButtonCount,
  name,
  terpType,
  kingdom,
  enzClass,
  uniprot,
  genbank,
  reactSmiles,
  reactChebi,
  reactName,
  prodChebi,
  prodName,
  prodSmiles,
  setSearchSubmit,
  evidence,
  mechanismUnclicked,
  setIntermediateSmiles,
  buttonCount,
  setEvidenceInputClicked,
  evidenceInputClicked,
  setEvidenceInputsUnclicked,
  showInput
}) {

  // async function findSmiles(smiles) {
  //   try {
  //     setQuickSearchValue(smiles);
  //     document.getElementById("overlap").style.display = "none";
  //     document.getElementById("mechDiv").style.display = "none";
  //     const elements = document.querySelectorAll("#body *");
  //     elements.forEach((element) => {
  //       element.style.filter = "unset";
  //     });
  //     if (pageCount === 1) {
  //       setFirstSearch(true);
  //       setButtonCount(1);
  //       setCount((count = 0));
  //       const result = await search(
  //         seeAllReactions,
  //         setIsLoading,
  //         count,
  //         onlyMechanism,
  //         smiles,
  //         pageCount,
  //         setDataCount
  //       );
  //       console.log(result)
  //       setTable(result?.table);
  //       renderData(result?.table);
  //     } else {
  //       setFirstSearch(true);
  //       setPageCount(1);
  //       setButtonCount(1);
  //       setCount((count = 0));
  //     }
  //   } catch (err) {
  //     throw err
  //   }
  // }

  async function findSmilesAdvanced(smiles) {
    try {
      setIntermediateSmiles(smiles);
      document.getElementById("overlap").style.display = "none";
      document.getElementById("mechDiv").style.display = "none";
      const elements = document.querySelectorAll("#body *");
      elements.forEach((element) => {
        element.style.filter = "unset";
      });
      if (evidenceInputClicked === false) {
        showInput(
          ["EvidenceRadioInput", "IntermediateSmilesInput"],
          "mechanismDD",
          evidenceInputClicked,
          setEvidenceInputClicked,
          "EvidenceInput",
          setEvidenceInputsUnclicked
        );
      }
      if (pageCount === 1) {
        setButtonCount(1);
        const result = await search(
          name,
          terpType,
          kingdom,
          enzClass,
          uniprot,
          genbank,
          reactSmiles,
          reactChebi,
          reactName,
          prodChebi,
          prodName,
          prodSmiles,
          pageCount,
          buttonCount,
          setIsLoading,
          setSearchSubmit,
          setDataCount,
          evidence,
          mechanismUnclicked,
          smiles
        );
        renderData(result?.table);
      } else {
        setPageCount(1);
        setButtonCount(1);
      }
    } catch (err) {
      throw err;
    }
  }

  

  let mechanismEvidence;
  let mechanismBody = [];

  try {
    const response = await axios.post("/mymechanism", {
      RE_pair: table[row].reaction_enzyme_pair_id,
    });

    const mechanismTable = response.data.mechanism;

    for (let row = 0; row < mechanismTable.length; row++) {
      mechanismBody.push([
        mechanismTable[row].substrate_image ? (
          <>
            <button
              style={{ border: "none", backgroundColor: "rgb(245,245,245)" }}
              disabled={mechanismUnclicked || count != undefined ? true : false}
            >
              <img
                src={mechanismTable[row].substrate_image}
                className="responsive"
                onClick={() => {findSmilesAdvanced(mechanismTable[row].substrate_smiles)
                }}
              />
            </button>
            <br />
            <SubstrateRowMech
              mechanismTable={mechanismTable}
              row={row}
            />
          </>
        ) : (
          <>
            {mechanismTable[row].substrate_smiles} <br />
            <SubstrateRowMech
              mechanismTable={mechanismTable}
              row={row}
            />
          </>
        ),
        mechanismTable[row].product_image ? (
          <>
            <button
              style={{ border: "none", backgroundColor: "rgb(245,245,245)" }}
              disabled={mechanismUnclicked || count != undefined ? true : false}
            >
              <img
                src={mechanismTable[row].product_image}
                className="responsive"
                onClick={() => {
                  findSmilesAdvanced(mechanismTable[row].product_smiles)
                }}
              />
            </button>
            <br />
            <ProductRowMech
              mechanismTable={mechanismTable}
              row={row}
            />
          </>
        ) : (
          <>
            {mechanismTable[row].product_smiles} <br />
            <ProductRowMech
              mechanismTable={mechanismTable}
              row={row}
            />
          </>
        ),
        mechanismTable[row].mechanism_type,
      ]);
    }
    mechanismEvidence = response.data.mechanism[0]?.mechanism_evidence;
    console.log(mechanismBody);
    return {
      mechanismBody: mechanismBody,
    };
  } catch (err) {
    console.error(err);
  }
}

export default findMechanism;
