function RadioTypeInput({
  idLabel,
  idInput,
  inputText,
  value,
  setType,
  diType,
  monoType,
  sesqType,
  triType,
  tetraType,
  sesterType,
  sesquarType,
  prenylType,
  hemiType,
  setTerpType,
}) {
  return (
    <label id={idLabel} for={idInput}>
      <input
        type="checkbox"
        id={idInput}
        value={value}
        onClick={async (e) => {
          if (e.target.checked) {
            setType(value);
            setTerpType({
              di: value === "di" ? "di" : diType,
              mono: value === "mono" ? "mono" : monoType,
              sesq: value === "sesq" ? "sesq" : sesqType,
              tri: value === "tri" ? "tri" : triType,
              tetra: value === "tetra" ? "tetra" : tetraType,
              sester: value === "sester" ? "sester" : sesterType,
              sesquar: value === "sesquar" ? "sesquar" : sesquarType,
              pt: value === "pt" ? "pt" : prenylType,
              hemi: value === "hemi" ? "hemi" : hemiType
            });
          } else {
            setType("");
            setTerpType({
              di: value === "di" ? "" : diType,
              mono: value === "mono" ? "" : monoType,
              sesq: value === "sesq" ? "" : sesqType,
              tri: value === "tri" ? "" : triType,
              tetra: value === "tetra" ? "" : tetraType,
              sester: value === "sester" ? "" : sesterType,
              sesquar: value === "sesquar" ? "" : sesquarType,
              pt: value === "pt" ? "" : prenylType,
              hemi: value === "hemi" ? "" : hemiType
            });
          }
        }}
      />
      {inputText}
    </label>
  );
}

export default RadioTypeInput;
