import {
  faCopy,
  faExternalLink,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function SubstrateRowMech({
  mechanismTable,
  row
}) {

  function copySmiles(id) {
    let smiles = document.getElementById(id).innerText;
    let elem = document.createElement("textarea");
    document.body.appendChild(elem);
    smiles = smiles.trim();
    elem.value = smiles;
    elem.select();
    document.execCommand("copy");
    document.body.removeChild(elem);
  }

  return (
    <ul
      style={{
        display: "flex",
        listStyle: "none",
        padding: 0,
        justifyContent: "space-between",
        margin: "0 auto",
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "center",
      }}
    >
      <li
        style={{ marginLeft: 2, position: "relative" }}
        className="copySmilesSubstrateMech"
        onMouseEnter={() => {
          document.getElementById(
            `substrateSmilesCopyMech${row}`
          ).style.zIndex = 1;
          document.getElementById(
            `substrateSmilesCopiedMech${row}`
          ).style.zIndex = 1;
          document.getElementById(
            `substrateSmilesCopiedMech${row}`
          ).style.display = "none";
          document.getElementById(
            `substrateSmilesCopyMech${row}`
          ).style.opacity = 1;
        }}
        onMouseLeave={() => {
          document.getElementById(
            `substrateSmilesCopiedMech${row}`
          ).style.opacity = 0;
          document.getElementById(
            `substrateSmilesCopyMech${row}`
          ).style.opacity = 0;
          document.getElementById(
            `substrateSmilesCopyMech${row}`
          ).style.zIndex = -1;
          document.getElementById(
            `substrateSmilesCopyMech${row}`
          ).style.display = "block";
          document.getElementById(
            `substrateSmilesCopiedMech${row}`
          ).style.zIndex = -1;
        }}
      >
        <button
          style={{
            border: "none",
            backgroundColor: "rgb(245,245,245)",
            padding: 0,
            margin: 0,
          }}
          onClick={() => {
            copySmiles(`substrateSmilesMech${row}`);
            document.getElementById(
              `substrateSmilesCopyMech${row}`
            ).style.display = "none";
            document.getElementById(
              `substrateSmilesCopiedMech${row}`
            ).style.display = "block";
            document.getElementById(
              `substrateSmilesCopiedMech${row}`
            ).style.opacity = 1;
          }}
        >
          <FontAwesomeIcon icon={faCopy} color="rgb(0,0,0,0.8)" />
        </button>
        <div
          id={`substrateSmilesCopyMech${row}`}
          className="substrateSmilesCopyMech"
          style={{ top: "1.75rem" }}
        >
          <nobr style={{ display: "inline-flex" }}>
            Copy SMILES:{" "}
            <p
              id={`substrateSmilesMech${row}`}
              style={{
                color: "black",
                backgroundColor: "white",
                minWidth: "fit-content",
                margin: "0 auto",
                marginRight: 2.5,
                marginLeft: 5,
                paddingRight: 5,
                paddingLeft: 5,
              }}
            >
              {mechanismTable[row].substrate_smiles}
            </p>
          </nobr>
        </div>
        <div
          id={`substrateSmilesCopiedMech${row}`}
          className="substrateSmilesCopiedMech"
          style={{ top: "1.75rem" }}
        >
          <nobr style={{ display: "inline-flex" }}>
            <p style={{ margin: "0 auto" }}>
              <FontAwesomeIcon icon={faCheck} color="white" /> Copied to
              clipboard
            </p>
          </nobr>
        </div>
      </li>
      <li style={{ wordWrap: "normal", maxWidth: "20ch" }}>
          {mechanismTable[row].substrate_name !== "unnamed_intermediate" ? (
            mechanismTable[row].substrate_name
          ) : (
            <></>
          )}
      </li>
      <li
        style={{ marginRight: 5, position: "relative" }}
        className="chebiLinkMech"
      >
        {mechanismTable[row].substrate_chebi ? (
          <>
            <a
              onMouseEnter={() => {
                document.getElementById(`goToChebiMech${row}`).style.zIndex = 1;
                document.getElementById(
                  `goToChebiMech${row}`
                ).style.opacity = 1;
              }}
              onMouseLeave={() => {
                document.getElementById(
                  `goToChebiMech${row}`
                ).style.opacity = 0;
                document.getElementById(`goToChebiMech${row}`).style.zIndex =
                  -1;
                document.getElementById(`goToChebiMech${row}`).style.display =
                  "block";
              }}
              href={`https://www.ebi.ac.uk/chebi/searchId.do?chebiId=CHEBI:${mechanismTable[row].substrate_chebi}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faExternalLink} color="rgb(0,0,0,0.8)" />
            </a>
            <div
              id={`goToChebiMech${row}`}
              className="goToChebiMech"
              style={{ top: "1.75rem" }}
            >
              <nobr style={{ display: "inline-flex" }}>
                Find in ChEBI: {mechanismTable[row].substrate_chebi}
              </nobr>
            </div>
          </>
        ) : (
          <></>
        )}
      </li>
    </ul>
  );
}

export default SubstrateRowMech;
