import { faExternalLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function UniprotLink({
  tableRow,
  seeAllReactions,
  setIsLoading,
  count,
  onlyMechanism,
  pageCount,
  setDataCount,
  setQuickSearchValue,
  search,
  renderData,
  setPageCount,
  setButtonCount,
  setCount,
  ///////////////////////////////////////////////////////////////FOR REACTION SEARCH//////////////////////////////////////////////////
  buttonCount,
  setUniprot,
  name,
  terpType,
  enzClass,
  uniprot,
  genbank,
  reactSmiles,
  reactChebi,
  reactName,
  prodChebi,
  prodName,
  prodSmiles,
  setSearchSubmit,
  showInput,
  enzymeInputClicked,
  setEnzymeInputClicked,
  setEnzymeInputUnclicked,
  setFirstSearch,
  kingdom,
  ///////////////////////////////////////////////////////////////FOR MECHANISM SEARCH//////////////////////////////////////////////////
  evidence,
  mechanismUnclicked,
  intermediateSmiles,
}) {
  ///////////////////////////////////////////////////////////////FIRST FUNCTION FOR QUICKSEARCH//////////////////////////////////////////////////
  const findUniprot = async function (uniprotID) {
    try {
      setFirstSearch(true);
      setQuickSearchValue(uniprotID);
      if (pageCount === 1) {
        setButtonCount(1);
        setCount((count = 0));
        const result = await search(
          seeAllReactions,
          setIsLoading,
          count,
          onlyMechanism,
          uniprotID,
          pageCount,
          setDataCount
        );
        renderData(result?.table);
      } else {
        setPageCount(1);
        setButtonCount(1);
        setCount((count = 0));
      }
    } catch (err) {
      throw err;
    }
  };
  ///////////////////////////////////////////////////////////////SECOND FUNCTION FOR ADVANCED SEARCH//////////////////////////////////////////////////
  async function findUniprotAdvanced(uniprot) {
    try {
      setFirstSearch(true);
      if (!enzymeInputClicked) {
        showInput(
          [
            "NameInput",
            "TypeInput",
            "ClassInput",
            "UniprotInput",
            "GenbankInput",
            "KingdomInput",
          ],
          "enzymeDD",
          enzymeInputClicked,
          setEnzymeInputClicked,
          "EnzymeInputs",
          setEnzymeInputUnclicked
        );
      }
      setUniprot(uniprot);
      if (pageCount === 1) {
        setFirstSearch(true);
        setButtonCount(1);
        const result = await search(
          name,
          terpType,
          kingdom,
          enzClass,
          uniprot,
          genbank,
          reactSmiles,
          reactChebi,
          reactName,
          prodChebi,
          prodName,
          prodSmiles,
          pageCount,
          buttonCount,
          setIsLoading,
          setSearchSubmit,
          setDataCount,
          evidence,
          mechanismUnclicked,
          intermediateSmiles
        );
        renderData(result);
        setPageCount(1);
      } else {
        setFirstSearch(true);
        setPageCount(1);
        setButtonCount(1);
      }
    } catch (err) {
      throw err;
    }
  }

  return (
    <>
      {tableRow.uniprot_id ? (
        <>
          <div>
            Uniprot ID: {" "}
            <a
              style={{ position: "relative" }}
              id="navigateUniprot"
              href={`https://www.uniprot.org/uniprotkb/${tableRow.uniprot_id}/entry`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faExternalLink} color="rgb(0,0,0,0.8)" />
              <div id="goToUniprot" style={{width:"max-content"}}>Find in Uniprot</div>
            </a>

            <button
              id="uniprotQuickSearch"
              style={{ backgroundColor: "rgb(245,245,245)", border: "none" }}
              onClick={() =>
                count != undefined
                  ? findUniprot(tableRow.uniprot_id)
                  : findUniprotAdvanced(tableRow.uniprot_id)
              }
            >
              {tableRow.uniprot_id}
            </button>{" "}
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default UniprotLink;
