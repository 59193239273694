import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

const Loading = () => (
    <div style={{textAlign:"center", backgroundSize:"cover", paddingTop:250}}>
  <Spin
    indicator={
      <LoadingOutlined
        style={{ fontSize: 150, color: "rgb(23, 55, 151)" }}
        spin
      />
    }
  />
  </div>
);

export default Loading;
