import React, { useState } from "react";
import "./SuggestReaction.css";
import Footer from "./Footer.js";
import {
  faExclamationCircle,
  faCheck,
  faDatabase,
  faAsterisk,
  faAngleDoubleDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useAxiosPrivate from "../hooks/useAxiosPrivate.js";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth.js";
import HomeNavbar from "../HomeNavbar.js";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import encoding from "encoding"

function SuggestReaction() {
  const [mechanismReference,setMechanismReference] = useState("")
  const [cosubstrateName, setCosubstrateName] = useState("");
  const [cosubstrateChebi, setCosubstrateChebi] = useState("");
  const [cosubstrateSmiles, setCosubstrateSmiles] = useState("");
  const [noteLength, setNoteLength] = useState(0);
  const [note, setNote] = useState("");
  const [pubFormClicked, setPubFormClicked] = useState(false);
  const [pubFormUnclicked, setPubFormUnclicked] = useState(true);
  const [fullFormClicked, setFullFormClicked] = useState(false);
  const [fullFormUnclicked, setFullFormUnclicked] = useState(true);
  const [enzymeName, setEnzymeName] = useState("");
  const [AASequence, setAASequence] = useState("");
  const [plantae, setPlantae] = useState("");
  const [animaliaCoral, setAnimaliaCoral] = useState("");
  const [bacteria, setBacteria] = useState("");
  const [fungi, setFungi] = useState("");
  const [animaliaMarineSponge, setAnimaliaMarineSponge] = useState("");
  const [animalia, setAnimalia] = useState("");
  const [archaea, setArchaea] = useState("");
  const [amoebozoa, setAmoebozoa] = useState("");
  const [viruses, setViruses] = useState("");
  const [animaliaInsecta, setAnimaliaInsecta] = useState("");
  const [animaliaHuman, setAnimaliaHuman] = useState("");
  const [cyanobacteria, setCyanobacteria] = useState("");
  const [redAlgae, setRedAlgae] = useState("");
  const [kingdomUnknown, setKingdomUnknown] = useState("");
  const [classOne, setClassOne] = useState("");
  const [classTwo, setClassTwo] = useState("");
  const [classUnknown, setClassUnknown] = useState("");
  const [diType, setDiType] = useState("");
  const [monoType, setMonoType] = useState("");
  const [sesqType, setSesqType] = useState("");
  const [diIntType, setDiIntType] = useState("");
  const [triType, setTriType] = useState("");
  const [tetraType, setTetraType] = useState("");
  const [sesterType, setSesterType] = useState("");
  const [triIntType, setTriIntType] = useState("");
  const [tetraIntType, setTetraIntType] = useState("");
  const [sesquarType, setSesquarType] = useState("");
  const [ggppsType, setGgppsType] = useState("");
  const [unknownType, setUnknownType] = useState("");
  const [species, setSpecies] = useState("");
  const [uniprotId, setUniprotId] = useState("");
  const [genbankId, setGenbankId] = useState("");
  const [link, setLink] = useState("");
  const [pubmedId, setPubmedId] = useState("");
  const [doi, setDoi] = useState("");
  const [reactantName, setReactantName] = useState("");
  const [reactantChebi, setReactantChebi] = useState("");
  const [reactantSmiles, setReactantSmiles] = useState("");
  const [productName, setProductName] = useState("");
  const [productChebi, setProductChebi] = useState("");
  const [productSmiles, setProductSmiles] = useState("");
  const [response, setResponse] = useState("");
  const [RE_pair, setRE_pair] = useState("");
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const { auth } = useAuth();


  function seeFullForm(event) {
    event.preventDefault();
    if (!fullFormClicked) {
      document.getElementById("suggestdetailbutton").style.backgroundColor =
        "gold";
      document.getElementById("overlapFullForm").style.display = "none";
      document.getElementById("overlapPubForm").style.display = "none";
      document.getElementById("confirmButtonFull").style.display = "block";
      const elementsPub = document.querySelectorAll("#PubForm > *");
      elementsPub.forEach((element) => {
        element.style.filter = "blur(0px)";
      });
      const elementsFull = document.querySelectorAll("#FullForm > *");
      elementsFull.forEach((element) => {
        element.style.filter = "blur(0px)";
      });
      setFullFormClicked(true);

      if (pubFormClicked) {
        document.getElementById("suggestpubbutton").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("confirmButtonPub").style.display = "none";
        setPubFormClicked(false);
        setPubFormUnclicked((current) => !current);
      }
    } else {
      document.getElementById("suggestdetailbutton").style.backgroundColor =
        "rgb(245,245,245)";
      document.getElementById("overlapPubForm").style.display = "block";
      document.getElementById("overlapFullForm").style.display = "block";
      document.getElementById("confirmButtonFull").style.display = "none";
      const elements = document.querySelectorAll("#PubForm > *");
      elements.forEach((element) => {
        element.style.filter = "blur(3px)";
      });
      const elementsFull = document.querySelectorAll("#FullForm > *");
      elementsFull.forEach((element) => {
        element.style.filter = "blur(3px)";
      });
      setFullFormClicked(false);
      setFullFormUnclicked((current) => !current);
    }
  }

  function seePubForm(event) {
    event.preventDefault();
    if (!pubFormClicked) {
      document.getElementById("suggestpubbutton").style.backgroundColor =
        "gold";
      document.getElementById("overlapPubForm").style.display = "none";
      document.getElementById("confirmButtonPub").style.display = "block";
      const elementsPub = document.querySelectorAll("#PubForm > *");
      elementsPub.forEach((element) => {
        element.style.filter = "blur(0px)";
      });
      setPubFormClicked(true);

      if (fullFormClicked) {
        document.getElementById("suggestdetailbutton").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("overlapFullForm").style.display = "block";
        document.getElementById("confirmButtonFull").style.display = "none";
        const elementsFull = document.querySelectorAll("#FullForm > *");
        elementsFull.forEach((element) => {
          element.style.filter = "blur(3px)";
        });
        setFullFormClicked(false);
        setFullFormUnclicked((current) => !current);
      }
    } else {
      document.getElementById("suggestpubbutton").style.backgroundColor =
        "rgb(245,245,245)";
      document.getElementById("overlapPubForm").style.display = "block";
      document.getElementById("confirmButtonPub").style.display = "none";
      const elements = document.querySelectorAll("#PubForm > *");
      elements.forEach((element) => {
        element.style.filter = "blur(3px)";
      });
      setPubFormClicked(false);
      setPubFormUnclicked((current) => !current);
    }
  }

  async function submitSuggestion(event) {
    event.preventDefault();
    const username = auth.username;
    try {
      await axiosPrivate.post("/suggestreaction", {
        link,
        pubmedId,
        doi,
        note,
        username,
        fullFormClicked,
        pubFormClicked,
      });

      const elements = document.querySelectorAll("#body > *:not(#overlap)");
      elements.forEach((element) => {
        element.style.filter = "blur(3px)";
      });
      document.getElementById("overlap").style.display = "block";
      document.getElementById("submitConfirmation").style.display = "block";
    } catch (err) {
      throw err;
    }
  }

  async function submitFullSuggestion(event) {
    event.preventDefault();
    const username = auth.username;
    try {
      await axiosPrivate.post("/suggestreaction", {
        enzymeName,
        AASequence,
        plantae,
        animaliaCoral,
        bacteria,
        fungi,
        animaliaMarineSponge,
        animalia,
        archaea,
        amoebozoa,
        viruses,
        animaliaInsecta,
        animaliaHuman,
        cyanobacteria,
        redAlgae,
        kingdomUnknown,
        classOne,
        classTwo,
        classUnknown,
        diType,
        monoType,
        sesqType,
        diIntType,
        triType,
        tetraType,
        sesterType,
        triIntType,
        tetraIntType,
        sesquarType,
        ggppsType,
        unknownType,
        species,
        uniprotId,
        genbankId,
        link,
        pubmedId,
        doi,
        note,
        reactantName,
        reactantChebi,
        reactantSmiles: encoding.convert(reactantSmiles,"Latin_1"),
        productName,
        productChebi,
        productSmiles: encoding.convert(productSmiles,"Latin_1"),
        cosubstrateName,
        cosubstrateChebi,
        cosubstrateSmiles: encoding.convert(cosubstrateSmiles,"Latin_1"),
        fullFormClicked,
        pubFormClicked,
        username,
      });

      const elements = document.querySelectorAll("#body > *:not(#overlap)");
      elements.forEach((element) => {
        element.style.filter = "blur(3px)";
      });
      document.getElementById("overlap").style.display = "block";
      document.getElementById("submitConfirmation").style.display = "block";
    } catch (err) {
      throw err;
    }
  }

  async function submitOK() {
    if (document.getElementById("submitConfirmation")) {
      document.getElementById("submitConfirmation").style.display = "none";
    }
    if (document.getElementById("duplication")) {
      document.getElementById("duplication").style.display = "none";
    }
    document.getElementById("addMechanism").style.display = "block";
  }

  async function noButton() {
    document.getElementById("overlap").style.display = "none";
    document.getElementById("addMechanism").style.display = "none";
    const elements = document.querySelectorAll("#body > *:not(#overlap)");
    elements.forEach((element) => {
      element.style.filter = "blur(0px)";
    });
    setPlantae("");
    setNote("");
    setAnimaliaCoral("");
    setBacteria("");
    setFungi("");
    setAnimaliaMarineSponge("");
    setAnimalia("");
    setArchaea("");
    setAmoebozoa("");
    setViruses("");
    setAnimaliaInsecta("");
    setAnimaliaHuman("");
    setCyanobacteria("");
    setRedAlgae("");
    setKingdomUnknown("");
    setClassOne("");
    setClassTwo("");
    setClassUnknown("");
    setSesqType("");
    setDiIntType("");
    setDiType("");
    setSesquarType("");
    setTriIntType("");
    setTetraIntType("");
    setTriType("");
    setGgppsType("");
    setSesterType("");
    setMonoType("");
    setTetraType("");
    setUnknownType("");
    setEnzymeName("");
    setAASequence("");
    setSpecies("");
    setGenbankId("");
    setUniprotId("");
    setDoi("");
    setPubmedId("");
    setLink("");
    setReactantChebi("");
    setProductChebi("");
    setReactantName("");
    setProductName("");
    setReactantSmiles("");
    setProductSmiles("");
    setCosubstrateChebi("");
    setCosubstrateName("");
    setCosubstrateSmiles("");
    const monoInput = await document.getElementById("mono");
    if (monoInput.checked == true) {
      monoInput.checked = false;
    }
    const sesqInput = await document.getElementById("sesq");
    if (sesqInput.checked == true) {
      sesqInput.checked = false;
    }
    const diintInput = await document.getElementById("di-int");
    if (diintInput.checked == true) {
      diintInput.checked = false;
    }
    const triInput = await document.getElementById("tri");
    if (triInput.checked == true) {
      triInput.checked = false;
    }
    const tetraInput = await document.getElementById("tetra");
    if (tetraInput.checked == true) {
      tetraInput.checked = false;
    }
    const sesterInput = await document.getElementById("sester");
    if (sesterInput.checked == true) {
      sesterInput.checked = false;
    }
    const triintInput = await document.getElementById("tri-int");
    if (triintInput.checked == true) {
      triintInput.checked = false;
    }
    const tetraintInput = await document.getElementById("tetra-int");
    if (tetraintInput.checked == true) {
      tetraintInput.checked = false;
    }
    const sesquarInput = await document.getElementById("sesquar");
    if (sesquarInput.checked == true) {
      sesquarInput.checked = false;
    }
    const ggppsInput = await document.getElementById("ggpps");
    if (ggppsInput.checked == true) {
      ggppsInput.checked = false;
    }
    const diInput = await document.getElementById("di");
    if (diInput.checked == true) {
      diInput.checked = false;
    }
    const classOne = await document.getElementById("ClassOne");
    if (classOne.checked == true) {
      classOne.checked = false;
    }
    const classTwo = await document.getElementById("ClassTwo");
    if (classTwo.checked == true) {
      classTwo.checked = false;
    }
    const plantae = await document.getElementById("Plantae");
    if (plantae.checked == true) {
      plantae.checked = false;
    }
    const animaliacoral = await document.getElementById("AnimaliaCoral");
    if (animaliacoral.checked == true) {
      animaliacoral.checked = false;
    }
    const bacteria = await document.getElementById("Bacteria");
    if (bacteria.checked == true) {
      bacteria.checked = false;
    }
    const fungi = await document.getElementById("Fungi");
    if (fungi.checked == true) {
      fungi.checked = false;
    }
    const animaliamarinesponge = await document.getElementById(
      "AnimaliaMarineSponge"
    );
    if (animaliamarinesponge.checked == true) {
      animaliamarinesponge.checked = false;
    }
    const animalia = await document.getElementById("Animalia");
    if (animalia.checked == true) {
      animalia.checked = false;
    }
    const archaea = await document.getElementById("Archaea");
    if (archaea.checked == true) {
      archaea.checked = false;
    }
    const amoebozoa = await document.getElementById("Amoebozoa");
    if (amoebozoa.checked == true) {
      amoebozoa.checked = false;
    }
    const viruses = await document.getElementById("Viruses");
    if (viruses.checked == true) {
      viruses.checked = false;
    }
    const animaliainsecta = await document.getElementById("AnimaliaInsecta");
    if (animaliainsecta.checked == true) {
      animaliainsecta.checked = false;
    }
    const animaliahuman = await document.getElementById("AnimaliaHuman");
    if (animaliahuman.checked == true) {
      animaliahuman.checked = false;
    }
    const cyanobacteria = await document.getElementById("Cyanobacteria");
    if (cyanobacteria.checked == true) {
      cyanobacteria.checked = false;
    }
    const redalgae = await document.getElementById("RedAlgae");
    if (redalgae.checked == true) {
      redalgae.checked = false;
    }
    const kingdomunknown = await document.getElementById("Unknown");
    if (kingdomunknown.checked == true) {
      kingdomunknown.checked = false;
    }
    const classUnknown = await document.getElementById("unknown");
    if (classUnknown.checked == true) {
      classUnknown.checked = false;
    }
  }

  async function yesButton() {
    document.getElementById("MechanismInput").style.display = "block";
  }

  async function submitMechanism() {
    const username = auth.username;
    try {
      await axiosPrivate.post("/mechanismlink", {
        mechanismReference,
        username,
      });
    } catch (err) {
      throw err;
    } finally {
      navigate("/mysuggestions");
    }
  }

  useEffect(()=>{
    const elementsFull = document.querySelectorAll("#FullForm > *");
    elementsFull.forEach((element) => {
      element.style.filter = "blur(3px)";
    });
    const elementsPub = document.querySelectorAll("#PubForm > *");
    elementsPub.forEach((element) => {
      element.style.filter = "blur(3px)";
    });
  },[])

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css"
          rel="stylesheet"
        />
      </head>
      <body id="body">
        <HomeNavbar />
        <div class="row" id="entrycontent">
          <div class="col-lg-12" align="center">
            <div class="row">
              <div class="col-lg-12">
                <form id="ReactionInputs" style={{ display: "block" }}>
                  <div id="fullForm">
                    <h3
                      align="left"
                      style={{
                        borderBottom: "solid 2px lightgrey",
                        paddingBottom: "5px",
                      }}
                    >
                      Suggestions. How does it work?
                    </h3>
                    <p align="left">
                      In order to protect this database from unverified users
                      and their untrustworthy entries, we decided to come up
                      with the concept of suggestions. Basically, a suggestion
                      is contentwise equivalent of a new entry in our database,
                      however protected by so called "editor layer". Suggestions
                      are stored and then reviewed by our editors –
                      self-appointed experts in the field of terpenesynthases to
                      thoroughly check the content of suggestion about to be
                      submitted.
                    </p>
                    <p
                      align="left"
                      style={{
                        borderBottom: "2px solid lightgrey",
                        paddingBottom: "5px",
                      }}
                    >
                      Keen on learning more? Read{" "}
                      <Link to="/userroles">here</Link>.
                    </p>
                    <ul
                      style={{
                        listStyle: "none",
                        padding: 0,
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <li>
                        <button
                          id="suggestpubbutton"
                          onClick={seePubForm}
                          key={pubFormUnclicked}
                        >
                          Suggest by posting a publication reference
                        </button>
                      </li>
                      <li>
                        <button
                          id="suggestdetailbutton"
                          onClick={seeFullForm}
                          key={fullFormUnclicked}
                        >
                          Suggest by typing the reaction and its details
                        </button>
                      </li>
                    </ul>
                    <ul
                      style={{
                        listStyle: "none",
                        padding: 0,
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <li>
                        <button
                          id="confirmButtonPub"
                          onClick={submitSuggestion}
                          style={{
                            display: "none",
                            opacity: link || doi || pubmedId ? 1 : 0.5,
                          }}
                          disabled={link || doi || pubmedId ? false : true}
                        >
                          Submit
                        </button>
                      </li>
                      <li>
                        <button
                          id="confirmButtonFull"
                          onClick={submitFullSuggestion}
                          disabled={
                            !AASequence ||
                            !(
                              plantae ||
                              animaliaCoral ||
                              bacteria ||
                              fungi ||
                              animaliaMarineSponge ||
                              animalia ||
                              archaea ||
                              amoebozoa ||
                              viruses ||
                              animaliaInsecta ||
                              animaliaHuman ||
                              cyanobacteria ||
                              redAlgae ||
                              kingdomUnknown
                            ) ||
                            !species ||
                            !(classOne || classTwo || classUnknown) ||
                            !link ||
                            !(
                              sesqType ||
                              diIntType ||
                              diType ||
                              sesquarType ||
                              triIntType ||
                              tetraIntType ||
                              triType ||
                              ggppsType ||
                              sesterType ||
                              monoType ||
                              tetraType ||
                              unknownType
                            ) ||
                            !reactantSmiles ||
                            !productSmiles
                              ? true
                              : false
                          }
                          style={{
                            display: "none",
                            opacity:
                              !AASequence ||
                              !(
                                plantae ||
                                animaliaCoral ||
                                bacteria ||
                                fungi ||
                                animaliaMarineSponge ||
                                animalia ||
                                archaea ||
                                amoebozoa ||
                                viruses ||
                                animaliaInsecta ||
                                animaliaHuman ||
                                cyanobacteria ||
                                redAlgae ||
                                kingdomUnknown
                              ) ||
                              !species ||
                              !(classOne || classTwo || classUnknown) ||
                              !link ||
                              !(
                                sesqType ||
                                diIntType ||
                                diType ||
                                sesquarType ||
                                triIntType ||
                                tetraIntType ||
                                triType ||
                                ggppsType ||
                                sesterType ||
                                monoType ||
                                tetraType ||
                                unknownType
                              ) ||
                              !reactantSmiles ||
                              !productSmiles
                                ? "0.5"
                                : "1",
                          }}
                        >
                          Submit
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div style={{ position: "relative" }}>
                    <div align="left" id="PubForm" style={{ zIndex: 3 }}>
                      <div id="Publication">
                        <h5>
                          <FontAwesomeIcon icon={faAsterisk} color="red" />{" "}
                          Publication:
                        </h5>
                      </div>
                      <label for="EnzymePublicationLink">
                        Publication link:
                      </label>
                      <input
                        id="EnzymePublicationLink"
                        onChange={async (event) => {
                          setLink(event.target.value);
                        }}
                        value={link}
                      />
                      <label for="EnzymePMID">Pub Med ID:</label>
                      <input
                        id="EnzymePMID"
                        onChange={async (event) => {
                          setPubmedId(event.target.value);
                        }}
                        value={pubmedId}
                      />
                      <label for="EnzymePublicationDOI">Publication DOI:</label>
                      <input
                        id="EnzymePublicationDOI"
                        onChange={async (event) => {
                          setDoi(event.target.value);
                        }}
                        value={doi}
                      />
                      <label for="generalnote">Note:</label>
                      <textarea
                        id="generalNote"
                        maxLength={200}
                        onChange={(event) => {
                          setNote(event.target.value);
                          setNoteLength(event.target.value.split("").length);
                        }}
                        value={note}
                      />
                      <p align="right" style={{ fontSize: 12.5 }}>
                        {noteLength}/200
                      </p>
                    </div>
                    <div
                      id="overlapPubForm"
                      style={{
                        position: "absolute",
                        zIndex: 3,
                        display: "block",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                      }}
                    ></div>
                  </div>
                  <div style={{ position: "relative" }}>
                    <div id="FullForm">
                      <div id="Enzyme" align="left">
                        <h5>Enzyme:</h5>
                      </div>
                      <div id="enzymeInputs" align="left">
                        <label for="EnzymeName">Name:</label>
                        <input
                          id="EnzymeName"
                          onChange={async (event) => {
                            setEnzymeName(event.target.value);
                          }}
                          value={enzymeName}
                        />
                        <label for="EnzymeAASequence">
                          {" "}
                          <FontAwesomeIcon
                            icon={faAsterisk}
                            color="red"
                            fontSize="12"
                          />{" "}
                          Aminoacid sequence:
                        </label>
                        <textarea
                          id="EnzymeAASequence"
                          onChange={async (event) => {
                            setAASequence(event.target.value);
                          }}
                          value={AASequence}
                        />
                        <label for="KingdomRadioInputs">
                          {" "}
                          <FontAwesomeIcon
                            icon={faAsterisk}
                            color="red"
                            fontSize="12"
                          />{" "}
                          Kingdom:
                        </label>
                        <div class="row" id="KingdomRadioInputs" align="right">
                          <div class="col-lg-4" align="left">
                            <input
                              type="radio"
                              id="Plantae"
                              name="kingdom"
                              onClick={async (e) => {
                                setPlantae("Plantae");
                                setAnimaliaCoral("");
                                setBacteria("");
                                setFungi("");
                                setAnimaliaMarineSponge("");
                                setAnimalia("");
                                setArchaea("");
                                setAmoebozoa("");
                                setViruses("");
                                setAnimaliaInsecta("");
                                setAnimaliaHuman("");
                                setCyanobacteria("");
                                setRedAlgae("");
                                setKingdomUnknown("");
                              }}
                            />
                            <label for="Plantae" value="Plantae">
                              Plantae
                            </label>
                            <br />
                            <input
                              type="radio"
                              id="AnimaliaCoral"
                              name="kingdom"
                              onClick={async (e) => {
                                setPlantae("");
                                setAnimaliaCoral("Animalia (Coral)");
                                setBacteria("");
                                setFungi("");
                                setAnimaliaMarineSponge("");
                                setAnimalia("");
                                setArchaea("");
                                setAmoebozoa("");
                                setViruses("");
                                setAnimaliaInsecta("");
                                setAnimaliaHuman("");
                                setCyanobacteria("");
                                setRedAlgae("");
                                setKingdomUnknown("");
                              }}
                            />
                            <label for="AnimaliaCoral" value="Animalia (Coral)">
                              Animalia (Coral)
                            </label>
                            <br />
                            <input
                              type="radio"
                              id="Bacteria"
                              name="kingdom"
                              onClick={async (e) => {
                                setPlantae("");
                                setAnimaliaCoral("");
                                setBacteria("Bacteria");
                                setFungi("");
                                setAnimaliaMarineSponge("");
                                setAnimalia("");
                                setArchaea("");
                                setAmoebozoa("");
                                setViruses("");
                                setAnimaliaInsecta("");
                                setAnimaliaHuman("");
                                setCyanobacteria("");
                                setRedAlgae("");
                                setKingdomUnknown("");
                              }}
                            />
                            <label for="Bacteria" value="Bacteria">
                              Bacteria
                            </label>
                            <br />
                            <input
                              type="radio"
                              id="Fungi"
                              name="kingdom"
                              onClick={async (e) => {
                                setPlantae("");
                                setAnimaliaCoral("");
                                setBacteria("");
                                setFungi("Fungi");
                                setAnimaliaMarineSponge("");
                                setAnimalia("");
                                setArchaea("");
                                setAmoebozoa("");
                                setViruses("");
                                setAnimaliaInsecta("");
                                setAnimaliaHuman("");
                                setCyanobacteria("");
                                setRedAlgae("");
                                setKingdomUnknown("");
                              }}
                            />
                            <label for="Fungi" value="Fungi">
                              Fungi
                            </label>
                            <br />
                            <input
                              type="radio"
                              id="AnimaliaMarineSponge"
                              name="kingdom"
                              onClick={async (e) => {
                                setPlantae("");
                                setAnimaliaCoral("");
                                setBacteria("");
                                setFungi("");
                                setAnimaliaMarineSponge(
                                  "Animalia (Marine Sponge)"
                                );
                                setAnimalia("");
                                setArchaea("");
                                setAmoebozoa("");
                                setViruses("");
                                setAnimaliaInsecta("");
                                setAnimaliaHuman("");
                                setCyanobacteria("");
                                setRedAlgae("");
                                setKingdomUnknown("");
                              }}
                            />
                            <label
                              for="AnimaliaMarineSponge"
                              value="Animalia (Marine Sponge)"
                            >
                              Animalia (Marine Sponge)
                            </label>
                            <br />
                          </div>
                          <div class="col-lg-4" align="left">
                            <input
                              type="radio"
                              id="Animalia"
                              name="kingdom"
                              onClick={async (e) => {
                                setPlantae("");
                                setAnimaliaCoral("");
                                setBacteria("");
                                setFungi("");
                                setAnimaliaMarineSponge("");
                                setAnimalia("Animalia");
                                setArchaea("");
                                setAmoebozoa("");
                                setViruses("");
                                setAnimaliaInsecta("");
                                setAnimaliaHuman("");
                                setCyanobacteria("");
                                setRedAlgae("");
                                setKingdomUnknown("");
                              }}
                            />
                            <label for="Animalia" value="Animalia">
                              Animalia
                            </label>
                            <br />
                            <input
                              type="radio"
                              id="Archaea"
                              name="kingdom"
                              onClick={async (e) => {
                                setPlantae("");
                                setAnimaliaCoral("");
                                setBacteria("");
                                setFungi("");
                                setAnimaliaMarineSponge("");
                                setAnimalia("");
                                setArchaea("Archaea");
                                setAmoebozoa("");
                                setViruses("");
                                setAnimaliaInsecta("");
                                setAnimaliaHuman("");
                                setCyanobacteria("");
                                setRedAlgae("");
                                setKingdomUnknown("");
                              }}
                            />
                            <label for="Archaea" value="Archaea">
                              Archaea
                            </label>
                            <br />
                            <input
                              type="radio"
                              id="Amoebozoa"
                              name="kingdom"
                              onClick={async (e) => {
                                setPlantae("");
                                setAnimaliaCoral("");
                                setBacteria("");
                                setFungi("");
                                setAnimaliaMarineSponge("");
                                setAnimalia("");
                                setArchaea("");
                                setAmoebozoa("Amoebozoa");
                                setViruses("");
                                setAnimaliaInsecta("");
                                setAnimaliaHuman("");
                                setCyanobacteria("");
                                setRedAlgae("");
                                setKingdomUnknown("");
                              }}
                            />
                            <label for="Amoebozoa" value="Amoebozoa">
                              Amoebozoa
                            </label>
                            <br />
                            <input
                              type="radio"
                              id="Viruses"
                              name="kingdom"
                              onClick={async (e) => {
                                setPlantae("");
                                setAnimaliaCoral("");
                                setBacteria("");
                                setFungi("");
                                setAnimaliaMarineSponge("");
                                setAnimalia("");
                                setArchaea("");
                                setAmoebozoa("");
                                setViruses("Viruses");
                                setAnimaliaInsecta("");
                                setAnimaliaHuman("");
                                setCyanobacteria("");
                                setRedAlgae("");
                                setKingdomUnknown("");
                              }}
                            />
                            <label for="Viruses" value="Viruses">
                              Viruses
                            </label>
                            <br />
                            <input
                              type="radio"
                              id="AnimaliaInsecta"
                              name="kingdom"
                              onClick={async (e) => {
                                setPlantae("");
                                setAnimaliaCoral("");
                                setBacteria("");
                                setFungi("");
                                setAnimaliaMarineSponge("");
                                setAnimalia("");
                                setArchaea("");
                                setAmoebozoa("");
                                setViruses("");
                                setAnimaliaInsecta("Animalia (Insecta)");
                                setAnimaliaHuman("");
                                setCyanobacteria("");
                                setRedAlgae("");
                                setKingdomUnknown("");
                              }}
                            />
                            <label
                              for="AnimaliaInsecta"
                              value="Animalia (Insecta)"
                            >
                              Animalia (Insecta)
                            </label>
                            <br />
                          </div>
                          <div class="col-lg-4" align="left">
                            <input
                              type="radio"
                              id="AnimaliaHuman"
                              name="kingdom"
                              onClick={async (e) => {
                                setPlantae("");
                                setAnimaliaCoral("");
                                setBacteria("");
                                setFungi("");
                                setAnimaliaMarineSponge("");
                                setAnimalia("");
                                setArchaea("");
                                setAmoebozoa("");
                                setViruses("");
                                setAnimaliaInsecta("");
                                setAnimaliaHuman("Animalia (Human)");
                                setCyanobacteria("");
                                setRedAlgae("");
                                setKingdomUnknown("");
                              }}
                            />
                            <label for="AnimaliaHuman" value="Animalia (Human)">
                              Animalia (Human)
                            </label>
                            <br />
                            <input
                              type="radio"
                              id="Cyanobacteria"
                              name="kingdom"
                              onClick={async (e) => {
                                setPlantae("");
                                setAnimaliaCoral("");
                                setBacteria("");
                                setFungi("");
                                setAnimaliaMarineSponge("");
                                setAnimalia("");
                                setArchaea("");
                                setAmoebozoa("");
                                setViruses("");
                                setAnimaliaInsecta("");
                                setAnimaliaHuman("");
                                setCyanobacteria("Cyanobacteria");
                                setRedAlgae("");
                                setKingdomUnknown("");
                              }}
                            />
                            <label for="Cyanobacteria" value="Cyanobacteria">
                              Cyanobacteria
                            </label>
                            <br />
                            <input
                              type="radio"
                              id="RedAlgae"
                              name="kingdom"
                              onClick={async (e) => {
                                setPlantae("");
                                setAnimaliaCoral("");
                                setBacteria("");
                                setFungi("");
                                setAnimaliaMarineSponge("");
                                setAnimalia("");
                                setArchaea("");
                                setAmoebozoa("");
                                setViruses("");
                                setAnimaliaInsecta("");
                                setAnimaliaHuman("");
                                setCyanobacteria("");
                                setRedAlgae("Red Algae");
                                setKingdomUnknown("");
                              }}
                            />
                            <label for="RedAlgae" value="Red Algae">
                              Red Algae
                            </label>
                            <br />
                            <input
                              type="radio"
                              id="Unknown"
                              name="kingdom"
                              onClick={async (e) => {
                                setPlantae("");
                                setAnimaliaCoral("");
                                setBacteria("");
                                setFungi("");
                                setAnimaliaMarineSponge("");
                                setAnimalia("");
                                setArchaea("");
                                setAmoebozoa("");
                                setViruses("");
                                setAnimaliaInsecta("");
                                setAnimaliaHuman("");
                                setCyanobacteria("");
                                setRedAlgae("");
                                setKingdomUnknown("Unknown");
                              }}
                            />
                            <label for="Unknown" value="Unknown">
                              Unknown
                            </label>
                            <br />
                          </div>
                        </div>
                        <label for="EnzymeSpecies">
                          <FontAwesomeIcon
                            icon={faAsterisk}
                            color="red"
                            fontSize="12"
                          />{" "}
                          Species:
                        </label>
                        <input
                          id="EnzymeSpecies"
                          onChange={async (event) => {
                            setSpecies(event.target.value);
                          }}
                          value={species}
                        />
                        <label for="ClassRadioInputs">
                          {" "}
                          <FontAwesomeIcon
                            icon={faAsterisk}
                            color="red"
                            fontSize="12"
                          />{" "}
                          Class:
                        </label>
                        <div class="row" id="ClassRadioInputs">
                          <div class="col-lg-4" align="left">
                            <input
                              type="radio"
                              id="ClassOne"
                              name="class"
                              onClick={async (e) => {
                                setClassOne("1");
                                setClassTwo("");
                                setClassUnknown("");
                              }}
                            />
                            <label for="ClassOne" value="1">
                              Class I
                            </label>
                          </div>
                          <div class="col-lg-4" align="left">
                            <input
                              type="radio"
                              id="ClassTwo"
                              name="class"
                              onClick={async (e) => {
                                setClassOne("");
                                setClassTwo("2");
                                setClassUnknown("");
                              }}
                            />
                            <label for="ClassTwo" value="2">
                              Class II
                            </label>
                          </div>
                          <div class="col-lg-4" align="left">
                            <input
                              type="radio"
                              id="unknown"
                              name="class"
                              onClick={async (e) => {
                                setClassOne("");
                                setClassTwo("");
                                setClassUnknown("Unknown");
                              }}
                            />
                            <label for="unknown" value="unknown">
                              Unknown
                            </label>
                          </div>
                        </div>
                        <label for="EnzymeUniprot">Uniprot ID:</label>
                        <input
                          id="EnzymeUniprot"
                          onChange={async (event) => {
                            setUniprotId(event.target.value);
                          }}
                          value={uniprotId}
                        />
                        <label for="EnzymeGenbank">Genbank ID:</label>
                        <input
                          id="EnzymeGenbank"
                          onChange={async (event) => {
                            setGenbankId(event.target.value);
                          }}
                          value={genbankId}
                        />

                        <label for="TypeRadioInputs">
                          {" "}
                          <FontAwesomeIcon
                            icon={faAsterisk}
                            color="red"
                            fontSize="12"
                          />{" "}
                          Type:
                        </label>
                        <div class="row" id="TypeRadioInputs">
                          <div class="col-lg-4" align="left">
                            <input
                              type="radio"
                              id="sesq"
                              name="type"
                              onClick={async (e) => {
                                setSesqType("sesq");
                                setDiIntType("");
                                setDiType("");
                                setSesquarType("");
                                setTriIntType("");
                                setTetraIntType("");
                                setTriType("");
                                setGgppsType("");
                                setSesterType("");
                                setMonoType("");
                                setTetraType("");
                                setUnknownType("");
                              }}
                            />
                            <label for="sesq" value="sesq">
                              Sesq terpenes
                            </label>
                            <br />
                            <input
                              type="radio"
                              id="di-int"
                              name="type"
                              onClick={async (e) => {
                                setSesqType("");
                                setDiIntType("di-int");
                                setDiType("");
                                setSesquarType("");
                                setTriIntType("");
                                setTetraIntType("");
                                setTriType("");
                                setGgppsType("");
                                setSesterType("");
                                setMonoType("");
                                setTetraType("");
                                setUnknownType("");
                              }}
                            />
                            <label for="di-int" value="di-int">
                              Di-int terpenes
                            </label>
                            <br />
                            <input
                              type="radio"
                              id="di"
                              name="type"
                              onClick={async (e) => {
                                setSesqType("");
                                setDiIntType("");
                                setDiType("di");
                                setSesquarType("");
                                setTriIntType("");
                                setTetraIntType("");
                                setTriType("");
                                setGgppsType("");
                                setSesterType("");
                                setMonoType("");
                                setTetraType("");
                                setUnknownType("");
                              }}
                            />
                            <label for="di" value="di">
                              Di terpenes
                            </label>
                            <br />
                            <input
                              type="radio"
                              id="mono"
                              name="type"
                              onClick={async (e) => {
                                setSesqType("");
                                setDiIntType("");
                                setDiType("");
                                setSesquarType("");
                                setTriIntType("");
                                setTetraIntType("");
                                setTriType("");
                                setGgppsType("");
                                setSesterType("");
                                setMonoType("mono");
                                setTetraType("");
                                setUnknownType("");
                              }}
                            />
                            <label for="mono" value="mono">
                              Mono terpenes
                            </label>
                            <br />
                          </div>
                          <div class="col-lg-4" align="left">
                            <input
                              type="radio"
                              id="tetra"
                              name="type"
                              onClick={async (e) => {
                                setSesqType("");
                                setDiIntType("");
                                setDiType("");
                                setSesquarType("");
                                setTriIntType("");
                                setTetraIntType("");
                                setTriType("");
                                setGgppsType("");
                                setSesterType("");
                                setMonoType("");
                                setTetraType("tetra");
                                setUnknownType("");
                              }}
                            />
                            <label for="tetra" value="tetra">
                              Tetra terpenes
                            </label>
                            <br />
                            <input
                              type="radio"
                              id="sester"
                              name="type"
                              onClick={async (e) => {
                                setSesqType("");
                                setDiIntType("");
                                setDiType("");
                                setSesquarType("");
                                setTriIntType("");
                                setTetraIntType("");
                                setTriType("");
                                setGgppsType("");
                                setSesterType("sester");
                                setMonoType("");
                                setTetraType("");
                                setUnknownType("");
                              }}
                            />
                            <label for="sester" value="sester">
                              Sester terpenes
                            </label>
                            <br />
                            <input
                              type="radio"
                              id="tri-int"
                              name="type"
                              onClick={async (e) => {
                                setSesqType("");
                                setDiIntType("");
                                setDiType("");
                                setSesquarType("");
                                setTriIntType("tri-int");
                                setTetraIntType("");
                                setTriType("");
                                setGgppsType("");
                                setSesterType("");
                                setMonoType("");
                                setTetraType("");
                                setUnknownType("");
                              }}
                            />
                            <label for="tri-int" value="tri-int">
                              Tri-int terpenes
                            </label>
                            <br />
                            <input
                              type="radio"
                              id="tetra-int"
                              name="type"
                              onClick={async (e) => {
                                setSesqType("");
                                setDiIntType("");
                                setDiType("");
                                setSesquarType("");
                                setTriIntType("");
                                setTetraIntType("tetra-int");
                                setTriType("");
                                setGgppsType("");
                                setSesterType("");
                                setMonoType("");
                                setTetraType("");
                                setUnknownType("");
                              }}
                            />
                            <label for="tetra-int" value="tetra-int">
                              Tetra-int terpenes
                            </label>
                            <br />
                          </div>
                          <div class="col-lg-4" align="left">
                            <input
                              type="radio"
                              id="tri"
                              name="type"
                              onClick={async (e) => {
                                setSesqType("");
                                setDiIntType("");
                                setDiType("");
                                setSesquarType("");
                                setTriIntType("");
                                setTetraIntType("");
                                setTriType("tri");
                                setGgppsType("");
                                setSesterType("");
                                setMonoType("");
                                setTetraType("");
                                setUnknownType("");
                              }}
                            />
                            <label for="tri" value="tri">
                              Tri terpenes
                            </label>
                            <br />
                            <input
                              type="radio"
                              id="sesquar"
                              name="type"
                              onClick={async (e) => {
                                setSesqType("");
                                setDiIntType("");
                                setDiType("");
                                setSesquarType("sesquar");
                                setTriIntType("");
                                setTetraIntType("");
                                setTriType("");
                                setGgppsType("");
                                setSesterType("");
                                setMonoType("");
                                setTetraType("");
                                setUnknownType("");
                              }}
                            />
                            <label for="sesquar" value="sesquar">
                              Sesquar terpenes
                            </label>
                            <br />
                            <input
                              type="radio"
                              id="ggpps"
                              name="type"
                              onClick={async (e) => {
                                setSesqType("");
                                setDiIntType("");
                                setDiType("");
                                setSesquarType("");
                                setTriIntType("");
                                setTetraIntType("");
                                setTriType("");
                                setGgppsType("ggpps");
                                setSesterType("");
                                setMonoType("");
                                setTetraType("");
                                setUnknownType("");
                              }}
                            />
                            <label for="ggpps" value="ggpps">
                              Ggpps terpenes
                            </label>
                            <br />
                            <input
                              type="radio"
                              id="nan"
                              name="type"
                              onClick={async (e) => {
                                setSesqType("");
                                setDiIntType("");
                                setDiType("");
                                setSesquarType("");
                                setTriIntType("");
                                setTetraIntType("");
                                setTriType("");
                                setGgppsType("");
                                setSesterType("");
                                setMonoType("");
                                setTetraType("");
                                setUnknownType("Unknown");
                              }}
                            />
                            <label for="nan" value="nan">
                              Unknown
                            </label>
                            <br />
                          </div>
                        </div>
                      </div>
                      <div id="Reactant" align="left">
                        <h5>Substrate:</h5>
                      </div>
                      <div id="reactantInputs" align="left">
                        <label for="ReactantName">Name:</label>
                        <input
                          id="ReactantName"
                          onChange={async (event) => {
                            setReactantName(event.target.value);
                          }}
                          value={reactantName}
                        />
                        <label for="ReactantChebi">ChEBI:</label>
                        <input
                          id="ReactantChebi"
                          onChange={async (event) => {
                            setReactantChebi(event.target.value);
                          }}
                          value={reactantChebi}
                        />
                        <label for="ReactantSMILES">
                          {" "}
                          <FontAwesomeIcon
                            icon={faAsterisk}
                            color="red"
                            fontSize="12"
                          />{" "}
                          SMILES:
                        </label>
                        <textarea
                          id="ReactantSMILES"
                          onChange={async (event) => {
                            setReactantSmiles(event.target.value);
                          }}
                          value={reactantSmiles}
                        />
                      </div>
                      <div id="Reactant" align="left">
                        <h5>Cosubstrate:</h5>
                      </div>
                      <div id="reactantInputs" align="left">
                        <label for="ReactantName">Name:</label>
                        <input
                          id="ReactantName"
                          onChange={async (event) => {
                            setCosubstrateName(event.target.value);
                          }}
                          value={cosubstrateName}
                        />
                        <label for="ReactantChebi">ChEBI:</label>
                        <input
                          id="ReactantChebi"
                          onChange={async (event) => {
                            setCosubstrateChebi(event.target.value);
                          }}
                          value={cosubstrateChebi}
                        />
                        <label for="ReactantSMILES">SMILES:</label>
                        <textarea
                          id="ReactantSMILES"
                          onChange={async (event) => {
                            setCosubstrateSmiles(event.target.value);
                          }}
                          value={cosubstrateSmiles}
                        />
                      </div>
                      <div id="Product" align="left">
                        <h5>Product:</h5>
                      </div>
                      <div id="productInputs" align="left">
                        <label for="ProductName">Name:</label>
                        <input
                          id="ProductName"
                          onChange={async (event) => {
                            setProductName(event.target.value);
                          }}
                          value={productName}
                        />
                        <label for="ProductChebi">ChEBI:</label>
                        <input
                          id="ProductChebi"
                          onChange={async (event) => {
                            setProductChebi(event.target.value);
                          }}
                          value={productChebi}
                        />
                        <label for="ProductSMILES">
                          {" "}
                          <FontAwesomeIcon
                            icon={faAsterisk}
                            color="red"
                            fontSize="12"
                          />{" "}
                          SMILES:
                        </label>
                        <textarea
                          id="ProductSMILES"
                          onChange={async (event) => {
                            setProductSmiles(event.target.value);
                          }}
                          value={productSmiles}
                        />
                      </div>
                    </div>
                    <div
                      id="overlapFullForm"
                      style={{
                        position: "absolute",
                        zIndex: 3,
                        display: "block",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                      }}
                    ></div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div id="entryfooter">
          <Footer />
        </div>
        <div id="overlap" style={{ display: "none" }}>
          <div id="submitConfirmation" align="left" style={{ display: "none" }}>
            <ul>
              <li>
                <FontAwesomeIcon icon={faCheck} style={{ color: "green" }} />{" "}
                Your suggestion has been successfully submitted and is waiting
                for a review.
              </li>
              <li>
                <button id="ok" onClick={submitOK}>
                  OK
                </button>
              </li>
            </ul>
          </div>
          <div id="addMechanism" align="left" style={{ display: "none" }}>
            <ul>
              <li>Do you want to suggest a mechanism as well?</li>
              <li>
                <button id="yes" onClick={yesButton}>
                  Yes
                </button>
                <button id="no" onClick={noButton}>
                  No
                </button>
              </li>
            </ul>
            <div id="MechanismInput" style={{ display: "none" }}>
              <ul>
                <li>
                  <label for="mechanisminput">
                    <>Suggest a mechanism: </>
                  </label>
                  <input
                    placeholder="Publication reference"
                    id="mechanisminput"
                    value={mechanismReference}
                    onChange={(event) => {
                      setMechanismReference(event.target.value);
                    }}
                  />
                </li>
                <li>
                  <button id="confirmButton" onClick={submitMechanism}>
                    Submit
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </body>
    </html>
  );
}

export default SuggestReaction;
