import axios from "axios"

const BASE_URL = "https://tpsdb.uochb.cas.cz"

export default axios.create({
    baseURL: BASE_URL,
    withCredentials:true,
    headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": true,
        // "Cache-Control": "no-cache, no-store, must-revalidate",
        // "Pragma": "no-cache",
        // "Expires": "0"
}
})

 export const axiosPrivate = axios.create({
     baseURL: BASE_URL,
     headers: {"Content-Type": "application/json"},
     withCredentials:true
 })