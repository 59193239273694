import {
  faCopy,
  faExternalLink,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function CosubstrateRow({
  table,
  row,
  seeAllReactions,
  setIsLoading,
  count,
  onlyMechanism,
  pageCount,
  setDataCount,
  setQuickSearchValue,
  search,
  renderData,
  setPageCount,
  setButtonCount,
  setCount,
  interactiveSearch,
  ///////////////////////////////////////////////////////////////FOR REACTION SEARCH//////////////////////////////////////////////////
  buttonCount,
  setReactName,
  name,
  terpType,
  enzClass,
  uniprot,
  genbank,
  reactSmiles,
  reactChebi,
  reactName,
  prodChebi,
  prodName,
  prodSmiles,
  setSearchSubmit,
  showInput,
  reactantInputClicked,
  setReactantInputClicked,
  setReactantInputUnclicked,
  setFirstSearch,
  kingdom,
  setReactSmiles,
  ///////////////////////////////////////////////////////////////FOR MECHANISM SEARCH//////////////////////////////////////////////////
  evidence,
  mechanismUnclicked,
  intermediateSmiles,
}) {
  ///////////////////////////////////////////////////////////////FIRST FUNCTION FOR QUICKSEARCH //////////////////////////////////////////////////
  const findName = async function (name) {
    try {
      setButtonCount(1);
      setCount((count = 0));
      setQuickSearchValue(name);
      if (pageCount === 1) {
        const result = await search(
          seeAllReactions,
          setIsLoading,
          count,
          onlyMechanism,
          name,
          pageCount,
          setDataCount
        );
        renderData(result?.table);
      } else {
        setPageCount(1);
        setButtonCount(1);
        setCount((count = 0));
      }
    } catch (err) {
      throw err;
    }
  };
  ///////////////////////////////////////////////////////////////SECOND FUNCTION FOR ADVANCED SEARCH//////////////////////////////////////////////////
  async function findNameAdvanced(substrateName) {
    try {
      setFirstSearch(true);
      if (!reactantInputClicked) {
        showInput(
          ["ReactantNameInput", "ReactantSMILESInput", "ReactantChebiInput"],
          "reactantDD",
          reactantInputClicked,
          setReactantInputClicked,
          "ReactantInputs",
          setReactantInputUnclicked
        );
      }
      setReactName(substrateName);
      if (pageCount === 1) {
        setFirstSearch(true);
        setButtonCount(1);
        const result = await search(
          name,
          terpType,
          kingdom,
          enzClass,
          uniprot,
          genbank,
          reactSmiles,
          reactChebi,
          substrateName,
          prodChebi,
          prodName,
          prodSmiles,
          pageCount,
          buttonCount,
          setIsLoading,
          setSearchSubmit,
          setDataCount,
          evidence,
          mechanismUnclicked,
          intermediateSmiles
        );
        renderData(result);
        setPageCount(1);
      } else {
        setFirstSearch(true);
        setPageCount(1);
        setButtonCount(1);
      }
    } catch (err) {
      throw err;
    }
  }

  function copySmiles(id) {
    let smiles = document.getElementById(id).innerText;
    let elem = document.createElement("textarea");
    document.body.appendChild(elem);
    smiles = smiles.trim();
    elem.value = smiles;
    elem.select();
    document.execCommand("copy");
    document.body.removeChild(elem);
  }

  return (
    <>
      <ul
        style={{
          listStyle: "none",
          display: "flex",
          justifyContent: "space-between",
          padding: 0,
          margin: "0 auto",
          gap: 0,
        }}
      >
        <li>
          <button
            style={{ border: "none", backgroundColor: "rgb(245,245,245)" }}
          >
            <img
              src={table[row].substrate_image}
              alt={table[row].substrate_smiles}
              className="responsiveCosubstrate"
              onClick={async () => {
                if (interactiveSearch) {
                  interactiveSearch(table[row].substrate_smiles);
                } else {
                  try {
                    if (!reactantInputClicked) {
                      showInput(
                        [
                          "ReactantSMILESInput",
                          "ReactantChebiInput",
                          "ReactantNameInput",
                        ],
                        "reactantDD",
                        reactantInputClicked,
                        setReactantInputClicked,
                        "ReactantInputs",
                        setReactantInputUnclicked
                      );
                    }
                    setReactSmiles(table[row].substrate_smiles);
                    if (pageCount === 1) {
                      setFirstSearch(true);
                      setButtonCount(1);
                      const result = await search(
                        name,
                        terpType,
                        kingdom,
                        enzClass,
                        uniprot,
                        genbank,
                        table[row].substrate_smiles,
                        reactChebi,
                        reactName,
                        prodChebi,
                        prodName,
                        prodSmiles,
                        pageCount,
                        buttonCount,
                        setIsLoading,
                        setSearchSubmit,
                        setDataCount,
                        evidence,
                        mechanismUnclicked,
                        intermediateSmiles
                      );
                      renderData(result);
                    } else {
                      setFirstSearch(true);
                      setPageCount(1);
                      setButtonCount(1);
                    }
                  } catch (err) {
                    throw err;
                  }
                }
              }}
            />
          </button>

          <li>
            <ul style={{ listStyle: "none", padding: 0, margin: "0 auto" }}>
              <li
                style={{ marginLeft: 0, position: "relative",display:"flex",justifyContent:"center" }}
                
             
              >
                <button
                className="copySmilesSubstrate"
                  style={{
                    border: "none",
                    backgroundColor: "rgb(245,245,245)",
                    padding: 0,
                    margin: 0,
                  }}
                  onMouseEnter={() => {
                    document.getElementById(
                      `cosubstrate1SmilesCopy${row}`
                    ).style.zIndex = 1;
                    document.getElementById(
                      `cosubstrate1SmilesCopied${row}`
                    ).style.zIndex = 1;
                    document.getElementById(
                      `cosubstrate1SmilesCopied${row}`
                    ).style.display = "none";
                    setTimeout(()=>{
                      document.getElementById(
                        `cosubstrate1SmilesCopy${row}`
                      ).style.opacity = 1;
                    },50)
                    
                  }}
                  onMouseLeave={() => {
                    document.getElementById(
                      `cosubstrate1SmilesCopied${row}`
                    ).style.opacity = 0;
                    document.getElementById(
                      `cosubstrate1SmilesCopy${row}`
                    ).style.opacity = 0;
                    setTimeout(()=>{
                      document.getElementById(
                        `cosubstrate1SmilesCopy${row}`
                      ).style.zIndex = -1;
                    },50)
                    setTimeout(()=>{
                      document.getElementById(
                        `cosubstrate1SmilesCopy${row}`
                      ).style.display = "block";
                    },50)
                    setTimeout(()=>{
                      document.getElementById(
                        `cosubstrate1SmilesCopied${row}`
                      ).style.zIndex = -1;
                    },50)
                  }}
                  onClick={() => {
                    copySmiles(`substrateSmiles${row}`);
                    document.getElementById(
                      `cosubstrate1SmilesCopy${row}`
                    ).style.display = "none";
                    document.getElementById(
                      `cosubstrate1SmilesCopied${row}`
                    ).style.display = "block";
                    document.getElementById(
                      `cosubstrate1SmilesCopied${row}`
                    ).style.opacity = 1;
                  }}
                >
                  <FontAwesomeIcon icon={faCopy} color="rgb(0,0,0,0.8)" />
                </button>
                <div
                  id={`cosubstrate1SmilesCopy${row}`}
                  className="cosubstrate1SmilesCopy"
                >
                  <div style={{ display: "inline-flex" }}>
                    Copy SMILES:{" "}
                    <p
                      id={`substrateSmiles${row}`}
                      style={{
                        color: "black",
                        backgroundColor: "white",
                        wordWrap:"break-word",
                        maxWidth: "30ch",
                        margin: "0 auto",
                        marginRight: 2.5,
                        marginLeft: 5,
                        paddingRight: 5,
                        paddingLeft: 5,
                      }}
                    >
                      {table[row].substrate_smiles}
                    </p>
                  </div>
                </div>
                <div
                  id={`cosubstrate1SmilesCopied${row}`}
                  className="cosubstrate1SmilesCopied"
                >
                  <nobr style={{ display: "inline-flex" }}>
                    <p style={{ margin: "0 auto" }}>
                      <FontAwesomeIcon icon={faCheck} color="white" /> Copied to
                      clipboard
                    </p>
                  </nobr>
                </div>
              </li>

              <li
                style={{ marginRight: 0, position: "relative" }}
                
              >
                {table[row].substrate_chebi ? (
                  <>
                    <a
                      onMouseEnter={() => {
                        document.getElementById(
                          `goToChebiSubstrate${row}`
                        ).style.zIndex = 1;
                        setTimeout(()=>{
                          document.getElementById(
                            `goToChebiSubstrate${row}`
                          ).style.opacity = 1;
                        },50)
                      }}
                      onMouseLeave={() => {
                        document.getElementById(
                          `goToChebiSubstrate${row}`
                        ).style.opacity = 0;
                        setTimeout(()=>{
                          document.getElementById(
                            `goToChebiSubstrate${row}`
                          ).style.zIndex = -1;
                        },50)
                        setTimeout(()=>{
                          document.getElementById(
                            `goToChebiSubstrate${row}`
                          ).style.display = "block";
                        },50)
                      }}
                      className="chebiLink"
                      style={{position:"relative"}}
                      href={`https://www.ebi.ac.uk/chebi/searchId.do?chebiId=CHEBI:${table[row].substrate_chebi}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon
                        icon={faExternalLink}
                        color="rgb(0,0,0,0.8)"
                      />
                      <div
                      id={`goToChebiSubstrate${row}`}
                      className="goToChebiSubstrate"
                    >
                      <nobr style={{ display: "inline-flex" }}>
                        Find in ChEBI: {table[row].substrate_chebi}
                      </nobr>
                    </div>
                    </a>
                    
                  </>
                ) : (
                  <></>
                )}
              </li>
            </ul>
          </li>
          <li
            style={{
              wordWrap: "normal",
              maxWidth: "15ch",
              listStyle: "none",
              padding: 0,
              margin: "0 auto",
              position: "relative",
              bottom: 0,
            }}
          >
            
            <button
              id="substrateNameQuickSearch"
              style={{
                border: "none",
                backgroundColor: "rgb(245,245,245)",
                padding: 0,
              }}
              onClick={() =>
                count != undefined
                  ? findName(table[row].substrate_name)
                  : findNameAdvanced(table[row].substrate_name)
              }
            >
              {table[row].substrate_name !== "unnamed_intermediate" ? (
                table[row].substrate_name
              ) : (
                <></>
              )}
            </button>
          </li>
        </li>
        <li>
          <li>
            <button
              style={{ border: "none", backgroundColor: "rgb(245,245,245)" }}
            >
              <img
                src={table[row].cosubstrates[0].image}
                alt={table[row].cosubstrates[0].smiles}
                className="responsiveCosubstrate"
                onClick={async () => {
                  if (interactiveSearch) {
                    interactiveSearch(table[row].cosubstrates[0].smiles);
                  } else {
                    try {
                      if (!reactantInputClicked) {
                        showInput(
                          [
                            "ReactantSMILESInput",
                            "ReactantChebiInput",
                            "ReactantNameInput",
                          ],
                          "reactantDD",
                          reactantInputClicked,
                          setReactantInputClicked,
                          "ReactantInputs",
                          setReactantInputUnclicked
                        );
                      }
                      setReactSmiles(table[row].cosubstrates[0].smiles);
                      if (pageCount === 1) {
                        setButtonCount(1);
                        setFirstSearch(true);
                        const result = await search(
                          name,
                          terpType,
                          kingdom,
                          enzClass,
                          uniprot,
                          genbank,
                          table[row].cosubstrates[0].smiles,
                          reactChebi,
                          reactName,
                          prodChebi,
                          prodName,
                          prodSmiles,
                          pageCount,
                          buttonCount,
                          setIsLoading,
                          setSearchSubmit,
                          setDataCount,
                          evidence,
                          mechanismUnclicked,
                          intermediateSmiles
                        );
                        renderData(result);
                      } else {
                        setPageCount(1);
                        setButtonCount(1);
                        setFirstSearch(true);
                      }
                    } catch (err) {
                      throw err;
                    }
                  }
                }}
              />
            </button>
          </li>
          <ul style={{ listStyle: "none", padding: 0, margin: "0 auto" }}>
            <li
              style={{ marginLeft: 0, position: "relative",display:"flex",justifyContent:"center" }} align="center"
              
            
            >
              <button
              className="copySmilesCosubstrate"
                style={{
                  border: "none",
                  backgroundColor: "rgb(245,245,245)",
                  padding: 0,
                  margin: 0,
                }}
                onMouseEnter={() => {
                  document.getElementById(
                    `cosubstrateSmilesCopy${row}`
                  ).style.zIndex = 1;
                  document.getElementById(
                    `cosubstrateSmilesCopied${row}`
                  ).style.zIndex = 1;
                  document.getElementById(
                    `cosubstrateSmilesCopied${row}`
                  ).style.display = "none";
                  setTimeout(()=>{
                    document.getElementById(
                      `cosubstrateSmilesCopy${row}`
                    ).style.opacity = 1;
                  },50)
                }}
                onMouseLeave={() => {
                  document.getElementById(
                    `cosubstrateSmilesCopied${row}`
                  ).style.opacity = 0;
                  document.getElementById(
                    `cosubstrateSmilesCopy${row}`
                  ).style.opacity = 0;
                  setTimeout(()=>{
                    document.getElementById(
                      `cosubstrateSmilesCopy${row}`
                    ).style.zIndex = -1;
                  },50)
                  setTimeout(()=>{
                    document.getElementById(
                      `cosubstrateSmilesCopy${row}`
                    ).style.display = "block";
                  },50)
                  setTimeout(()=>{
                    document.getElementById(
                      `cosubstrateSmilesCopied${row}`
                    ).style.zIndex = -1;
                  },50)
                }}
                onClick={() => {
                  copySmiles(`cosubstrateSmiles${row}`);
                  document.getElementById(
                    `cosubstrateSmilesCopy${row}`
                  ).style.display = "none";
                  document.getElementById(
                    `cosubstrateSmilesCopied${row}`
                  ).style.display = "block";
                  document.getElementById(
                    `cosubstrateSmilesCopied${row}`
                  ).style.opacity = 1;
                }}
              >
                <FontAwesomeIcon icon={faCopy} color="rgb(0,0,0,0.8)" />
              </button>
              <div
                id={`cosubstrateSmilesCopy${row}`}
                className="cosubstrateSmilesCopy"
              >
                <div style={{ display: "inline-flex" }}>
                  Copy SMILES:{" "}
                  <p
                    id={`cosubstrateSmiles${row}`}
                    style={{
                      color: "black",
                      backgroundColor: "white",
                      wordWrap:"break-word",
                      maxWidth: "30ch",
                      margin: "0 auto",
                      marginRight: 2.5,
                      marginLeft: 5,
                      paddingRight: 5,
                      paddingLeft: 5,
                    }}
                  >
                    {table[row].cosubstrates[0].smiles}
                  </p>
                </div>
              </div>
              <div
                id={`cosubstrateSmilesCopied${row}`}
                className="cosubstrateSmilesCopied"
                style={{ top: "1.75rem" }}
              >
                <nobr style={{ display: "inline-flex" }}>
                  <p style={{ margin: "0 auto" }}>
                    <FontAwesomeIcon icon={faCheck} color="white" /> Copied to
                    clipboard
                  </p>
                </nobr>
              </div>
            </li>

            <li
              style={{ marginRight: 0, position: "relative" }}
              
            >
              {table[row].cosubstrates[0].chebi ? (
                <>
                  <a
                    onMouseEnter={() => {
                      document.getElementById(
                        `goToChebiCosubstrate${row}`
                      ).style.zIndex = 1;
                      setTimeout(()=>{
                        document.getElementById(
                          `goToChebiCosubstrate${row}`
                        ).style.opacity = 1;
                      },50)
                    }}
                    onMouseLeave={() => {
                      document.getElementById(
                        `goToChebiCosubstrate${row}`
                      ).style.opacity = 0;
                      setTimeout(()=>{
                        document.getElementById(
                          `goToChebiCosubstrate${row}`
                        ).style.zIndex = -1;
                      },50)
                      setTimeout(()=>{
                        document.getElementById(
                          `goToChebiCosubstrate${row}`
                        ).style.display = "block";
                      },50)
                    }}
                    className="chebiLink"
                    style={{position:"relative"}}
                    href={`https://www.ebi.ac.uk/chebi/searchId.do?chebiId=CHEBI:${table[row].cosubstrates[0].chebi}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={faExternalLink}
                      color="rgb(0,0,0,0.8)"
                    />
                    <div
                    id={`goToChebiCosubstrate${row}`}
                    className="goToChebiCosubstrate"
                  >
                    <nobr style={{ display: "inline-flex" }}>
                      Find in ChEBI: {table[row].cosubstrates[0].chebi}
                    </nobr>
                  </div>
                  </a>
                </>
              ) : (
                <></>
              )}
              <br></br>

              <li
                style={{
                  wordWrap: "normal",
                  maxWidth: "15ch",
                  listStyle: "none",
                  padding: 0,
                  margin: "0 auto",
                  position: "relative",
                  left:0,
                  right:0,
                }}
              >
                <button
                  id="cosubstrateNameQuickSearch"
                  style={{
                    border: "none",
                    backgroundColor: "rgb(245,245,245)",
                    paddingLeft: 0,
                    margin: "0 auto",
                  }}
                  onClick={() =>
                    count != undefined
                      ? findName(table[row].cosubstrates[0].name)
                      : findNameAdvanced(table[row].cosubstrates[0].name)
                  }
                >
                  {table[row].cosubstrates[0].name !==
                  "unnamed_intermediate" ? (
                    table[row].cosubstrates[0].name
                  ) : (
                    <></>
                  )}
                </button>
              </li>
            </li>
          </ul>
        </li>
      </ul>

      <br />
      <ul
        style={{
          listStyle: "none",
          display: "flex",
          justifyContent: "center",
          padding: 0,
          margin: "0 auto",
          gap: "0rem",
        }}
      ></ul>
    </>
  );
}

export default CosubstrateRow;
