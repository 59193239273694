import {
  faCopy,
  faExternalLink,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function ProductRow({
  table,
  row,
  seeAllReactions,
  setIsLoading,
  count,
  onlyMechanism,
  pageCount,
  setDataCount,
  setQuickSearchValue,
  search,
  renderData,
  setPageCount,
  setButtonCount,
  setCount,
  ///////////////////////////////////////////////////////////////FOR REACTION SEARCH//////////////////////////////////////////////////
  buttonCount,
  setProdName,
  name,
  terpType,
  enzClass,
  uniprot,
  genbank,
  reactSmiles,
  reactChebi,
  reactName,
  prodChebi,
  prodName,
  prodSmiles,
  setSearchSubmit,
  showInput,
  productInputClicked,
  setProductInputClicked,
  setProductInputUnclicked,
  setFirstSearch,
  kingdom,
  ///////////////////////////////////////////////////////////////FOR MECHANISM SEARCH//////////////////////////////////////////////////
  evidence,
  mechanismUnclicked,
  intermediateSmiles
}) {
  ///////////////////////////////////////////////////////////////FIRST FUNCTION FOR QUICKSEARCH//////////////////////////////////////////////////
  const findName = async function (name) {
    try {
      setButtonCount(1);
      setCount((count = 0));
      setFirstSearch(true)
      setQuickSearchValue(name);
      if (pageCount === 1) {
        const result = await search(
          seeAllReactions,
          setIsLoading,
          count,
          onlyMechanism,
          name,
          pageCount,
          setDataCount
        );
        renderData(result?.table);
      } else {
        setPageCount(1);
        setButtonCount(1);
        setCount((count = 0));
      }
    } catch (err) {
      throw err;
    }
  };
///////////////////////////////////////////////////////////////SECOND FUNCTION FOR ADVANCED SEARCH//////////////////////////////////////////////////
  async function findNameAdvanced(productName) {
    try {
      setFirstSearch(true)
      if (!productInputClicked) {
        showInput(
          [
            "ProductNameInput",
            "ProductSMILESInput",
            "ProductChebiInput",
          ],
          "productDD",
          productInputClicked,
          setProductInputClicked,
          "ProductInputs",
          setProductInputUnclicked
        );
      }
      setProdName(productName);
      if (pageCount === 1) {
        setFirstSearch(true);
        setPageCount(1);
        setButtonCount(1);
        const result = await search(
          name,
          terpType,
          kingdom,
          enzClass,
          uniprot,
          genbank,
          reactSmiles,
          reactChebi,
          reactName,
          prodChebi,
          productName,
          prodSmiles,
          pageCount,
          buttonCount,
          setIsLoading,
          setSearchSubmit,
          setDataCount,
          evidence,
          mechanismUnclicked,
          intermediateSmiles
        );
        renderData(result);
      } else {
        setFirstSearch(true);
        setPageCount(1);
        setButtonCount(1);
      }
    } catch (err) {
      throw err;
    }
  }

  function copySmiles(id) {
    let smiles = document.getElementById(id).innerText;
    let elem = document.createElement("textarea");
    document.body.appendChild(elem);
    smiles = smiles.trim();
    elem.value = smiles;
    elem.select();
    document.execCommand("copy");
    document.body.removeChild(elem);
  }

  return (
  <>
  <li style={{ wordWrap: "normal", maxWidth: "20ch",listStyle:"none",padding:0,margin:"0 auto" }}>
        <button
          id="productNameQuickSearch"
          style={{
            border: "none",
            backgroundColor: "rgb(245,245,245)",
            padding: 0,
          }}
          onClick={() => count != undefined ? findName(table[row].product_name) : findNameAdvanced(table[row].product_name)}
        >
          {table[row].product_name !== "unnamed_intermediate" ? (
            table[row].product_name
          ) : (
            <></>
          )}
        </button>
      </li>

    <ul
      style={{
        display: "flex",
        listStyle: "none",
        padding: 0,
        justifyContent: "space-between",
        margin: "0 auto",
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "center",
      }}
    >
      <li
        style={{ marginLeft: 2, position: "relative" }}
        className="copySmilesProduct"

        onMouseEnter={() => {
          document.getElementById(
            `productSmilesCopy${row}`
          ).style.zIndex = 1;
          document.getElementById(
            `productSmilesCopied${row}`
          ).style.zIndex = 1;
          document.getElementById(
            `productSmilesCopied${row}`
          ).style.display = "none";
          setTimeout(() => {
            document.getElementById(
              `productSmilesCopy${row}`
            ).style.opacity = 1;
          }, 50);
        }}
        onMouseLeave={() => {
          document.getElementById(
            `productSmilesCopied${row}`
          ).style.opacity = 0;
          document.getElementById(
            `productSmilesCopy${row}`
          ).style.opacity = 0;
          setTimeout(() => {
            document.getElementById(
              `productSmilesCopy${row}`
            ).style.zIndex = -1;
          }, 50);
          setTimeout(() => {
            document.getElementById(
              `productSmilesCopy${row}`
            ).style.display = "block";
          }, 50);
          setTimeout(() => {
            document.getElementById(
              `productSmilesCopied${row}`
            ).style.zIndex = -1;
          }, 50);
        }}
      
      >
        <button
          style={{
            border: "none",
            backgroundColor: "rgb(245,245,245)",
            padding: 0,
            margin: 0,
          }}
          onClick={() => {
            copySmiles(`productSmiles${row}`);
            document.getElementById(`productSmilesCopy${row}`).style.display =
              "none";
            document.getElementById(`productSmilesCopied${row}`).style.display =
              "block";
            document.getElementById(
              `productSmilesCopied${row}`
            ).style.opacity = 1;
          }}
        >
          <FontAwesomeIcon icon={faCopy} color="rgb(0,0,0,0.8)" />
        </button>
        <div
          id={`productSmilesCopy${row}`}
          className="productSmilesCopy"
          style={{ top: "1.75rem" }}
        >
          <div style={{ display: "inline-flex" }}>
            Copy SMILES:{" "}
            <p
              id={`productSmiles${row}`}
              style={{
                color: "black",
                backgroundColor: "white",
                wordWrap:"break-word",
                maxWidth: "30ch",
                margin: "0 auto",
                marginRight: 2.5,
                marginLeft: 5,
                paddingRight: 5,
                paddingLeft: 5,
              }}
            >
              {table[row].product_smiles}
            </p>
          </div>
        </div>
        <div
          id={`productSmilesCopied${row}`}
          className="productSmilesCopied"
          style={{ top: "1.75rem" }}
        >
          <nobr style={{ display: "inline-flex" }}>
            <p style={{ margin: "0 auto" }}>
              <FontAwesomeIcon icon={faCheck} color="white" /> Copied to
              clipboard
            </p>
          </nobr>
        </div>
      </li>
      
      <li
        style={{ marginRight: 5, position: "relative" }}
        className="chebiLinkProduct"
      >
        {table[row].product_chebi ? (
          <>
            <a
              onMouseEnter={() => {
                document.getElementById(`goToChebiProduct${row}`).style.zIndex = 1;
                setTimeout(() => {
                  document.getElementById(
                    `goToChebiProduct${row}`
                  ).style.opacity = 1;
                }, 50);
              }}
              onMouseLeave={() => {
                document.getElementById(`goToChebiProduct${row}`).style.opacity = 0;
                setTimeout(() => {
                  document.getElementById(`goToChebiProduct${row}`).style.zIndex =
                    -1;
                }, 50);
                setTimeout(() => {
                  document.getElementById(`goToChebiProduct${row}`).style.display =
                    "block";
                }, 50);
              }}
              href={`https://www.ebi.ac.uk/chebi/searchId.do?chebiId=CHEBI:${table[row].product_chebi}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faExternalLink} color="rgb(0,0,0,0.8)" />
            </a>
            <div
              id={`goToChebiProduct${row}`}
              className="goToChebiProduct"
              style={{ top: "1.75rem" }}
            >
              <nobr style={{ display: "inline-flex" }}>
                Find in ChEBI: {table[row].product_chebi}
              </nobr>
            </div>
          </>
        ) : (
          <></>
        )}
      </li>
    </ul>
    </>
  );
}

export default ProductRow;
