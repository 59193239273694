import axios from "./axios.js";
import encoding from "encoding";
import { Buffer } from "buffer";

async function advancedSearch(
  name,
  terpType,
  kingdom,
  enzClass,
  uniprot,
  genbank,
  reactSmiles,
  reactChebi,
  reactName,
  prodChebi,
  prodName,
  prodSmiles,
  pageCount,
  buttonCount,
  setIsLoading,
  setSearchSubmit,
  setDataCount,
  evidence,
  mechanismUnclicked,
  intermediateSmiles
) {

  if (mechanismUnclicked) {
    setIsLoading(true);
    setSearchSubmit(false);
    document.getElementById("SearchSuccess").style.display = "block";
    try {
      const response = await axios.post("/searchreaction", {
        name,
        terpType,
        kingdom,
        enzClass,
        uniprot,
        genbank,
        reactSmiles: reactSmiles != "" ? Buffer.from(reactSmiles) : reactSmiles,
        reactChebi,
        reactName,
        prodChebi,
        prodName,
        prodSmiles: prodSmiles != "" ? Buffer.from(prodSmiles) : prodSmiles,
        pageCount,
        buttonCount,
      });
      const table = response.data;
      if (table.length > 0) {
        setDataCount(table[0].count);
      } else {
        setDataCount(0);
      }
      
      return table;
    } catch (err) {
      throw err;
    }
  } else {
    setIsLoading(true);
    setSearchSubmit(false);
    document.getElementById("SearchSuccess").style.display = "block";
    try {
      const response = await axios.post("/searchmechanism", {
          name,
          kingdom,
          terpType,
          enzClass,
          uniprot,
          genbank,
          reactSmiles:reactSmiles != "" ? Buffer.from(reactSmiles) : reactSmiles,
          reactChebi,
          reactName,
          prodChebi,
          prodName,
          prodSmiles: prodSmiles != "" ? Buffer.from(prodSmiles) : prodSmiles,
          pageCount,
          buttonCount,
          evidence,
          intermediateSmiles: intermediateSmiles != "" ? Buffer.from(intermediateSmiles) : intermediateSmiles,
      });
      const table = response.data;
      if (table.length > 0) {
        setDataCount(table[0].count);
      } else {
        setDataCount(0);
      }
      
      return table;
    } catch (err) {
      throw err;
    }
  }

}

export default advancedSearch