import axios from "../api/axios.js";
import React, { useState, useEffect } from "react";
import "./Login.css";
import useAuth from "../hooks/useAuth.js";
import { useNavigate, useLocation } from "react-router-dom";
import HomeNavbar from "../HomeNavbar.js";
import Home from "./Home.js";
import { Link } from "react-router-dom";

export default function LogIn() {
  const { setAuth } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {
    setErrMsg("err");
  }, [username, password]);

  async function onSubmit(event) {
    event.preventDefault();
    try {
      await axios
        .post(
          "/login",
          { username, password },
          {
            withCredentials: true,
          }
        )
        .then((res) => {
          if (res.status === 200) {
            setAuth({
              username: res.data.username,
              accessToken: res.data.token,
              roles:res.data.user_role
            });
            navigate(from, { replace: true });
          }
        });
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 400) {
        setErrMsg("Missing Username or Password");
      } else if (err.response?.status === 401) {
        setErrMsg("Invalid Username or Password");
      } else {
        setErrMsg("Login Failed");
      }
    }
  }

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css"
          rel="stylesheet"
        />
      </head>
      <body>
        <HomeNavbar/>
        <div className="LoginBackground" style={{position:"relative"}}>
          <div className="LoginAlignment">
            <div className="LoginBox">
              <div class="row">
                <div class="col-lg-12" align="center">
                  <p
                    id="errorMessage"
                    style={errMsg !== "err" ? { visibility: "visible" } : { visibility: "hidden" }}
                    
                  >
                    {errMsg}
                  </p>
                </div>
              </div>
              <h1>Log in</h1>
              <div className="LoginFormDiv">
                <form size="400px" className="LoginForm" onSubmit={onSubmit}>
                  <div class="row">
                    <div class="col-lg-12" align="center">
                      <label htmlFor="username" id="UserLabel">
                        Username:
                      </label>
                      <input
                        id="username"
                        type="text"
                        required
                        autoComplete="off"
                        onChange={async (event) => {
                          setUsername(event.target.value);
                        }}
                        value={username}
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12" align="center">
                      <label htmlFor="password" id="PasswdLabel">
                        Password:
                      </label>
                      <input
                        id="password"
                        type="password"
                        required
                        onChange={async (event) => {
                          setPassword(event.target.value);
                        }}
                        value={password}
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12" align="center">
                      <button
                        className="LoginButton"
                        disabled={!username || !password ? true : false}
                        style={{opacity: !username || !password ? "0.5" : "1"}}
                      >
                        Log in
                      </button>
                      <p style={{marginTop:10}}>
                        No account yet? Register <Link /*to="/signup"*/>here</Link>.
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </body>
    </html>
  );
}
