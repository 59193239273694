function RadioKingdomInput({
  idLabel,
  idInput,
  inputText,
  value,
  setSpecificKingdom,
  animaliaMammal,
  animaliaCoral,
  animaliaBird,
  animaliaMarineSponge,
  animaliaInsect,
  animaliaMammalHuman,
  fungi,
  viruses,
  bacteria,
  cyanobacteria,
  plantaeRedAlgae,
  amoebozoa,
  archaea,
  plantae,
  setKingdomObject,
}) {
  return (
    <label id={idLabel} for={idInput}>
      <input
        type="checkbox"
        id={idInput}
        value={value}
        onClick={async (e) => {
          if (e.target.checked) {
            setSpecificKingdom(value);
            setKingdomObject({
                animaliaMammal: value === "Animalia (Mammal)" ? "Animalia (Mammal)" : animaliaMammal,
                animaliaCoral: value === "Animalia (Coral)" ? "Animalia (Coral)" : animaliaCoral,
                animaliaBird: value === "Animalia (Bird)" ? "Animalia (Bird)" : animaliaBird,
                animaliaMarineSponge: value === "Animalia (Marine Sponge)" ? "Animalia (Marine Sponge)" : animaliaMarineSponge,
                animaliaInsect: value === "Animalia (Insect)" ? "Animalia (Insect)" : animaliaInsect,
                animaliaMammalHuman: value === "Animalia (Mammal, Human)" ? "Animalia (Mammal, Human)" : animaliaMammalHuman,
                fungi: value === "Fungi" ? "Fungi" : fungi,
                viruses: value === "Viruses" ? "Viruses" : viruses,
                bacteria: value === "Bacteria" ? "Bacteria" : bacteria,
                cyanobacteria: value === "Cyanobacteria" ? "Cyanobacteria" : cyanobacteria,
                plantaeRedAlgae: value === "Plantae (Red algae)" ? "Plantae (Red algae)" : plantaeRedAlgae,
                amoebozoa: value === "Amoebozoa" ? "Amoebozoa" : amoebozoa,
                archaea: value=== "Archaea" ? "Archaea" : archaea,
                plantae: value === "Plantae" ? "Plantae" : plantae,
            });
          } else {
            setSpecificKingdom("");
            setKingdomObject({
                animaliaMammal: value === "Animalia (Mammal)" ? "" : animaliaMammal,
                animaliaCoral: value === "Animalia (Coral)" ? "" : animaliaCoral,
                animaliaBird: value === "Animalia (Bird)" ? "" : animaliaBird,
                animaliaMarineSponge: value === "Animalia (Marine Sponge)" ? "" : animaliaMarineSponge,
                animaliaInsect: value === "Animalia (Insecta)" ? "" : animaliaInsect,
                animaliaMammalHuman: value === "Animalia (Mammal, Human)" ? "" : animaliaMammalHuman,
                fungi: value === "Fungi" ? "" : fungi,
                viruses: value === "Viruses" ? "" : viruses,
                bacteria: value === "Bacteria" ? "" : bacteria,
                cyanobacteria: value === "Cyanobacteria" ? "" : cyanobacteria,
                plantaeRedAlgae: value === "Plantae (Red algae)" ? "" : plantaeRedAlgae,
                amoebozoa: value === "Amoebozoa" ? "" : amoebozoa,
                archaea: value=== "Archaea" ? "" : archaea,
                plantae: value === "Plantae" ? "" : plantae,
            });
          }
        }}
      />
      {inputText}
    </label>
  );
}

export default RadioKingdomInput;
