function RadioEvidenceInput({
    idLabel,
    idInput,
    inputText,
    value,
    setSpecificEvidence,
    experimentEvidence,
    similarityEvidence,
    trivialEvidence,
    calculationEvidence,
    setEvidenceObject,
}) {
    return(
        <label id={idLabel} for={idInput}>
            <input
            type="checkbox"
            id={idInput}
            value={value}
            onClick={async (e) => {
                if (e.target.checked) {
                setSpecificEvidence(value);
                setEvidenceObject({
                    similarity: value === "similarity" ? "similarity" : similarityEvidence,
                    experiment: value === "experiment" ? "experiment" : experimentEvidence,
                    trivial: value === "trivial" ? "trivial" : trivialEvidence,
                    calculation: value === "calculation" ? "calculation" : calculationEvidence,
                });
                } else {
                setSpecificEvidence("");
                setEvidenceObject({
                    similarity: value === "similarity" ? "" : similarityEvidence,
                    experiment: value === "experiment" ? "" : experimentEvidence,
                    trivial: value === "trivial" ? "" : trivialEvidence,
                    calculation: value === "calculation" ? "" : calculationEvidence,
                });
                }
            }}
            />
            {inputText}
        </label>
    )
}

export default RadioEvidenceInput

