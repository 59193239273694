import "./SearchReaction.css";
import "./SearchMechanism.css";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Footer from "./Footer.js";
import ReactionTable from "../reactionTable.js";
import useLogout from "../hooks/useLogout.js";
import {
  faCheck,
  faTimes,
  faAngleDoubleDown,
  faAngleDoubleUp,
  faBook,
  faBrain,
  faFlask,
  faClone,
  faCalculator,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import HomeNavbar from "../HomeNavbar.js";
import PageCounter from "./pageCounter.js";
import useAuth from "../hooks/useAuth.js";
import useAxiosPrivate from "../hooks/useAxiosPrivate.js";
import findMechanism from "../api/findMechanism.js";
import MechanismTable from "../mechanismTable.js";
import RadioTypeInput from "../Components/radioTypeInput.js";
import RadioKingdomInput from "../Components/radioKingdomInput.js";
import advancedSearch from "../api/advancedSearch.js";
import TypeClass from "../Components/typeClass.js";
import GenbankLink from "../Components/genbankLink.js";
import UniprotLink from "../Components/uniprotLink.js";
import CopySequence from "../Components/copySequence.js";
import KingdomIcon from "../Components/kingdomIcon.js";
import ProductRow from "../Components/productRow.js";
import SubstrateRow from "../Components/substrateRow.js";
import CosubstrateRow from "../Components/cosubstrateRow.js";
import RadioEvidenceInput from "../Components/radioEvidenceInput.js";

function SearchReaction() {
  let [count,setCount] = useState(0)
  const [windowSize,setWindowSize] = useState(getWindowSize().innerWidth)
  const [table, setTable] = useState([]);
  const [filtersVisible, setFiltersVisible] = useState(true);
  const [intermediateSmiles, setIntermediateSmiles] = useState("");
  const [clearAll, setClearAll] = useState(false);
  const [mechanismUnclicked, setMechanismUnclicked] = useState(true);
  const [evidenceInputClicked, setEvidenceInputClicked] = useState(false);
  const [evidenceInputsUnclicked, setEvidenceInputsUnclicked] = useState(true);
  const [firstSearch, setFirstSearch] = useState(true);
  const [mechDetails, setMechDetails] = useState({});
  const [mechBody, setMechBody] = useState([]);
  const [mechEvidence, setMechEvidence] = useState("");
  const [dataCount, setDataCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [buttonCount, setButtonCount] = useState(1);
  const [newSearch, setNewSearch] = useState(false);
  const [searchSubmit, setSearchSubmit] = useState(false);
  const [enzymeInputClicked, setEnzymeInputClicked] = useState(false);
  const [reactantInputClicked, setReactantInputClicked] = useState(false);
  const [productInputClicked, setProductInputClicked] = useState(false);
  const [enzymeInputUnclicked, setEnzymeInputUnclicked] = useState(true);
  const [reactantInputUnclicked, setReactantInputUnclicked] = useState(true);
  const [productInputUnclicked, setProductInputUnclicked] = useState(true);
  const [searchResult, setSearchResult] = useState("");
  const [name, setName] = useState("");
  const [kingdom, setKingdom] = useState({
    animaliaMammal: "",
    animaliaCoral: "",
    animaliaBird: "",
    animaliaMarineSponge: "",
    animaliaInsect: "",
    animaliaMammalHuman: "",
    fungi: "",
    amoebozoa: "",
    plantae: "",
    bacteria: "",
    cyanobacteria: "",
    plantaeRedAlgae: "",
    viruses: "",
    archaea: "",
  });
  const [animaliaMammal, setAnimaliaMammal] = useState("");
  const [animaliaCoral, setAnimaliaCoral] = useState("");
  const [animaliaBird, setAnimaliaBird] = useState("");
  const [animaliaMarineSponge, setAnimaliaMarineSponge] = useState("");
  const [animaliaInsect, setAnimaliaInsect] = useState("");
  const [animaliaMammalHuman, setAnimaliaMammalHuman] = useState("");
  const [fungi, setFungi] = useState("");
  const [amoebozoa, setAmoebozoa] = useState("");
  const [plantae, setPlantae] = useState("");
  const [bacteria, setBacteria] = useState("");
  const [cyanobacteria, setCyanobacteria] = useState("");
  const [plantaeRedAlgae, setPlantaeRedAlgae] = useState("");
  const [viruses, setViruses] = useState("");
  const [archaea, setArchaea] = useState("");
  const [terpType, setTerpType] = useState({
    di: "",
    mono: "",
    sesq: "",
    tri: "",
    tetra: "",
    sester: "",
    sesquar: "",
    pt: "",
    hemi: "",
  });
  const [diType, setDiType] = useState("");
  const [monoType, setMonoType] = useState("");
  const [sesqType, setSesqType] = useState("");
  const [triType, setTriType] = useState("");
  const [tetraType, setTetraType] = useState("");
  const [sesterType, setSesterType] = useState("");
  const [sesquarType, setSesquarType] = useState("");
  const [prenylType, setPrenylType] = useState("");
  const [hemiType, setHemiType] = useState("");
  const [enzClass, setEnzClass] = useState({ one: "", two: "" });
  const [classOne, setClassOne] = useState("");
  const [classTwo, setClassTwo] = useState("");
  const [evidence, setEvidence] = useState({
    similarity: "",
    experiment: "",
    trivial: "",
    calculation: "",
  });
  const [similarityEvidence, setSimilarityEvidence] = useState("");
  const [experimentEvidence, setExperimentEvidence] = useState("");
  const [trivialEvidence, setTrivialEvidence] = useState("");
  const [calculationEvidence, setCalculationEvidence] = useState("");
  const [uniprot, setUniprot] = useState("");
  const [genbank, setGenbank] = useState("");
  const [reactSmiles, setReactSmiles] = useState("");
  const [prodSmiles, setProdSmiles] = useState("");
  const [reactName, setReactName] = useState("");
  const [prodName, setProdName] = useState("");
  const [reactChebi, setReactChebi] = useState("");
  const [prodChebi, setProdChebi] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const logout = useLogout();
  const location = useLocation();
  const { state } = useLocation();
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const seeAllReactions = true

  function getWindowSize() {
    const {innerWidth} = window
    return {innerWidth}
  }

  let foundMechanism = {};
  async function findingMechanism(table, row) {
    foundMechanism = await findMechanism({
      table,
      row,
      name,
      terpType,
      kingdom,
      enzClass,
      uniprot,
      genbank,
      reactSmiles,
      reactChebi,
      reactName,
      prodChebi,
      prodName,
      prodSmiles,
      setSearchSubmit,
      evidence,
      mechanismUnclicked,
      setIntermediateSmiles,
      buttonCount,
      setButtonCount,
      setPageCount,
      setEvidenceInputClicked,
      evidenceInputClicked,
      setEvidenceInputsUnclicked,
      showInput,
    });
    setMechBody(foundMechanism.mechanismBody);
    document.getElementById("overlap").style.display = "block";
    document.getElementById("mechDiv").style.display = "block";
    const elements = document.querySelectorAll("#body *");
    elements.forEach((element) => {
      element.style.filter = "blur(3px)";
    });
  }

  async function submitSuggestComm(row, col) {
    let radioValue;
    const idArray = [
      `enzymeGenbankComment${row}`,
      `enzymeUniprotComment${row}`,
      `enzymeKingdomComment${row}`,
      `enzymeSpeciesComment${row}`,
      `enzymeClassComment${row}`,
      `enzymeTypeComment${row}`,
      `enzymeNameComment${row}`,
      `enzymeAASequenceComment${row}`,
      `substrateNameComment${row}`,
      `substrateChebiComment${row}`,
      `substrateImageComment${row}`,
      `substrateSmilesComment${row}`,
      `productNameComment${row}`,
      `productChebiComment${row}`,
      `productImageComment${row}`,
      `productSmilesComment${row}`,
    ];

    for (let i = 0; i < idArray.length; i++) {
      if (document.getElementById(idArray[i]).checked) {
        radioValue = document.getElementById(idArray[i]).value;
      }
    }

    const inputValueComment = document.getElementById(
      `suggestInputComm${row}${col}`
    ).value;
    document.getElementById(
      `innerTextSubmittedComm${row}${col}`
    ).style.display = "block";
    document.getElementById(`innerTextSubmitComm${row}${col}`).style.display =
      "none";

    try {
      await axiosPrivate.post("/postcomment", {
        inputValueComment: inputValueComment,
        enzyme_id: table[row].enzyme_id,
        reaction_id: table[row].reaction_id,
        substrate_id: table[row].substrate,
        product_id: table[row].product,
        RE_pair: table[row].reaction_enzyme_pair_id,
        mechanism_id: table[row].mechanism_id,
        subject: radioValue,
        username: auth?.username,
      });
    } catch (err) {
      throw err;
    } finally {
      setTimeout(() => {
        document.getElementById(`suggestDivComm${row}${col}`).style.display =
          "none";
        document.getElementById(
          `innerTextSubmittedComm${row}${col}`
        ).style.display = "none";
        document.getElementById(
          `innerTextSubmitComm${row}${col}`
        ).style.display = "block";
      }, 1000);
    }
  }

  function showInput(inputId, buttonId, state, setState, divId, setUnclicked) {
    inputId.forEach((e) => {
      let showInput = document.getElementById(e);

      if (showInput.style.display === "none") {
        showInput.style.display = "block";
      } else {
        showInput.style.display = "none";
      }
    });

    const button = document.getElementById(buttonId);
    const div = document.getElementById(divId);

    if (state === false) {
      button.style.color = "gold";
      div.style.backgroundColor = "rgb(245,245,245)";
      div.style.borderTop = "2px solid lightgrey";
      setState(true);
    } else {
      button.style.color = "black";
      div.style.backgroundColor = "white";
      div.style.borderTop = "none";
      setState(false);
      setUnclicked((current) => !current);
    }
  }

  async function clearAllInputs(enzymes, substrates, products, mechanism) {
    if (substrates) {
      setReactChebi("");
      setReactName("");
      setReactSmiles("");
    }
    if (products) {
      setProdChebi("");
      setProdName("");
      setProdSmiles("");
    }
    if (enzymes) {
      setName("");
      setGenbank("");
      setUniprot("");
      setClassOne("");
      setClassTwo("");
      setDiType("");
      setMonoType("");
      setTriType("");
      setTetraType("");
      setPrenylType("");
      setSesqType("");
      setSesquarType("");
      setSesterType("");
      setAnimaliaMammal("");
      setAnimaliaCoral("");
      setAnimaliaBird("");
      setAnimaliaMammalHuman("");
      setAnimaliaInsect("");
      setArchaea("");
      setBacteria("");
      setCyanobacteria("");
      setPlantae("");
      setPlantaeRedAlgae("");
      setAmoebozoa("");
      setAnimaliaMarineSponge("");
      setFungi("");
      setViruses("");
      setKingdom({
        animaliaMammal: "",
        animaliaCoral: "",
        animaliaBird: "",
        animaliaMarineSponge: "",
        animaliaInsect: "",
        animaliaMammalHuman: "",
        fungi: "",
        amoebozoa: "",
        plantae: "",
        bacteria: "",
        cyanobacteria: "",
        plantaeRedAlgae: "",
        viruses: "",
        archaea: "",
      });
      setTerpType({
        di: "",
        mono: "",
        sesq: "",
        tri: "",
        tetra: "",
        sester: "",
        sesquar: "",
        pt: "",
        hemi: "",
      });
      setEnzClass({ one: "", two: "" });
      const idArray = [
        "MonoTerpInput",
        "SesqTerpInput",
        "TriTerpInput",
        "DiTerpInput",
        "SesterTerpInput",
        "SesquarTerpInput",
        "TetraTerpInput",
        "PrenylTerpInput",
        "HemiTerpInput",
        "ClassI",
        "ClassII",
        "AnimaliaMammalInput",
        "AnimaliaCoralInput",
        "AnimaliaBirdInput",
        "AnimaliaMarineSpongeInput",
        "AnimaliaMammalHumanInput",
        "AnimaliaInsectInput",
        "FungiInput",
        "BacteriaInput",
        "CyanobacteriaInput",
        "PlantaeRedAlgaeInput",
        "VirusesInput",
        "ArchaeaInput",
        "AmoebozoaInput",
        "PlantaeInput",
      ];
      for (let i = 0; i < idArray.length; i++) {
        let radioId = document.getElementById(idArray[i]);
        if (radioId.checked) {
          radioId.checked = false;
        }
      }
    }
    if (mechanism) {
      setIntermediateSmiles("");
      setEvidence({
        similarity: "",
        experiment: "",
        trivial: "",
        calculation: "",
      });
      setSimilarityEvidence("");
      setExperimentEvidence("");
      setTrivialEvidence("");
      setCalculationEvidence("");
      const idArray = [
        "SimilarityEvidence",
        "ExperimentEvidence",
        "CalculationEvidence",
        "TrivialEvidence",
      ];
      for (let i = 0; i < idArray.length; i++) {
        let radioId = document.getElementById(idArray[i]);
        if (radioId.checked) {
          radioId.checked = false;
        }
      }
    }
  }

  async function loadData() {
    const tableData = await advancedSearch(
      name,
      terpType,
      kingdom,
      enzClass,
      uniprot,
      genbank,
      reactSmiles,
      reactChebi,
      reactName,
      prodChebi,
      prodName,
      prodSmiles,
      pageCount,
      buttonCount,
      setIsLoading,
      setSearchSubmit,
      setDataCount,
      evidence,
      mechanismUnclicked,
      intermediateSmiles
    );
    setTable(tableData);
    renderData(tableData);
  }

  async function renderData(table) {
    let body = [];
    for (let row = 0; row < table?.length; row++) {
      body.push([
        table[row].name.split("_")[0] === "missing" || !table[row].name ? (
          <>
            <TypeClass
              table={table}
              row={row}
              setIsLoading={setIsLoading}
              pageCount={pageCount}
              setDataCount={setDataCount}
              search={advancedSearch}
              renderData={renderData}
              setPageCount={setPageCount}
              setButtonCount={setButtonCount}
              buttonCount={buttonCount}
              diType={diType}
              monoType={monoType}
              sesqType={sesqType}
              sesterType={sesterType}
              triType={triType}
              tetraType={tetraType}
              prenylType={prenylType}
              hemiType={hemiType}
              sesquarType={sesquarType}
              setDiType={setDiType}
              setMonoType={setMonoType}
              setSesqType={setSesqType}
              setSesterType={setSesterType}
              setTriType={setTriType}
              setTetraType={setTetraType}
              setPrenylType={setPrenylType}
              setHemiType={setHemiType}
              setSesquarType={setSesquarType}
              name={name}
              terpType={terpType}
              setTerpType={setTerpType}
              enzClass={enzClass}
              uniprot={uniprot}
              genbank={genbank}
              reactSmiles={reactSmiles}
              reactChebi={reactChebi}
              reactName={reactName}
              prodChebi={prodChebi}
              prodName={prodName}
              prodSmiles={prodSmiles}
              setSearchSubmit={setSearchSubmit}
              showInput={showInput}
              enzymeInputClicked={enzymeInputClicked}
              setEnzymeInputClicked={setEnzymeInputClicked}
              setEnzymeInputUnclicked={setEnzymeInputUnclicked}
              kingdom={kingdom}
              setFirstSearch={setFirstSearch}
              evidence={evidence}
              mechanismUnclicked={mechanismUnclicked}
              intermediateSmiles={intermediateSmiles}
            />
            <UniprotLink
              tableRow={table[row]}
              setIsLoading={setIsLoading}
              pageCount={pageCount}
              setDataCount={setDataCount}
              search={advancedSearch}
              renderData={renderData}
              setPageCount={setPageCount}
              setButtonCount={setButtonCount}
              buttonCount={buttonCount}
              setUniprot={setUniprot}
              name={name}
              terpType={terpType}
              enzClass={enzClass}
              uniprot={uniprot}
              genbank={genbank}
              reactSmiles={reactSmiles}
              reactChebi={reactChebi}
              reactName={reactName}
              prodChebi={prodChebi}
              prodName={prodName}
              prodSmiles={prodSmiles}
              setSearchSubmit={setSearchSubmit}
              showInput={showInput}
              enzymeInputClicked={enzymeInputClicked}
              setEnzymeInputClicked={setEnzymeInputClicked}
              setEnzymeInputUnclicked={setEnzymeInputUnclicked}
              kingdom={kingdom}
              setFirstSearch={setFirstSearch}
              evidence={evidence}
              mechanismUnclicked={mechanismUnclicked}
              intermediateSmiles={intermediateSmiles}
            />
            <GenbankLink
              tableRow={table[row]}
              setIsLoading={setIsLoading}
              pageCount={pageCount}
              setDataCount={setDataCount}
              search={advancedSearch}
              renderData={renderData}
              setPageCount={setPageCount}
              setButtonCount={setButtonCount}
              buttonCount={buttonCount}
              setGenbank={setGenbank}
              name={name}
              terpType={terpType}
              enzClass={enzClass}
              uniprot={uniprot}
              genbank={genbank}
              reactSmiles={reactSmiles}
              reactChebi={reactChebi}
              reactName={reactName}
              prodChebi={prodChebi}
              prodName={prodName}
              prodSmiles={prodSmiles}
              setSearchSubmit={setSearchSubmit}
              showInput={showInput}
              enzymeInputClicked={enzymeInputClicked}
              setEnzymeInputClicked={setEnzymeInputClicked}
              setEnzymeInputUnclicked={setEnzymeInputUnclicked}
              kingdom={kingdom}
              setFirstSearch={setFirstSearch}
              evidence={evidence}
              mechanismUnclicked={mechanismUnclicked}
              intermediateSmiles={intermediateSmiles}
            />

            <CopySequence table={table} row={row} />
            <KingdomIcon
              tableRow={table[row]}
              setIsLoading={setIsLoading}
              pageCount={pageCount}
              setDataCount={setDataCount}
              search={advancedSearch}
              renderData={renderData}
              setPageCount={setPageCount}
              setButtonCount={setButtonCount}
              buttonCount={buttonCount}
              animaliaBird={animaliaBird}
              animaliaCoral={animaliaCoral}
              animaliaInsect={animaliaInsect}
              animaliaMammal={animaliaMammal}
              animaliaMammalHuman={animaliaMammalHuman}
              animaliaMarineSponge={animaliaMarineSponge}
              fungi={fungi}
              bacteria={bacteria}
              archaea={archaea}
              amoebozoa={amoebozoa}
              plantae={plantae}
              plantaeRedAlgae={plantaeRedAlgae}
              cyanobacteria={cyanobacteria}
              viruses={viruses}
              setAnimaliaBird={setAnimaliaBird}
              setAnimaliaCoral={setAnimaliaCoral}
              setAnimaliaInsect={setAnimaliaInsect}
              setAnimaliaMammal={setAnimaliaMammal}
              setAnimaliaMammalHuman={setAnimaliaMammalHuman}
              setAnimaliaMarineSponge={setAnimaliaMarineSponge}
              setFungi={setFungi}
              setBacteria={setBacteria}
              setArchaea={setArchaea}
              setAmoebozoa={setAmoebozoa}
              setPlantae={setPlantae}
              setPlantaeRedAlgae={setPlantaeRedAlgae}
              setCyanobacteria={setCyanobacteria}
              setViruses={setViruses}
              name={name}
              terpType={terpType}
              setKingdom={setKingdom}
              enzClass={enzClass}
              uniprot={uniprot}
              genbank={genbank}
              reactSmiles={reactSmiles}
              reactChebi={reactChebi}
              reactName={reactName}
              prodChebi={prodChebi}
              prodName={prodName}
              prodSmiles={prodSmiles}
              setSearchSubmit={setSearchSubmit}
              showInput={showInput}
              enzymeInputClicked={enzymeInputClicked}
              setEnzymeInputClicked={setEnzymeInputClicked}
              setEnzymeInputUnclicked={setEnzymeInputUnclicked}
              setFirstSearch={setFirstSearch}
              evidence={evidence}
              mechanismUnclicked={mechanismUnclicked}
              intermediateSmiles={intermediateSmiles}
            />
          </>
        ) : (
          <>
            <TypeClass
              table={table}
              row={row}
              setIsLoading={setIsLoading}
              pageCount={pageCount}
              setDataCount={setDataCount}
              search={advancedSearch}
              renderData={renderData}
              setPageCount={setPageCount}
              setButtonCount={setButtonCount}
              buttonCount={buttonCount}
              diType={diType}
              monoType={monoType}
              sesqType={sesqType}
              sesterType={sesterType}
              triType={triType}
              tetraType={tetraType}
              prenylType={prenylType}
              hemiType={hemiType}
              sesquarType={sesquarType}
              setDiType={setDiType}
              setMonoType={setMonoType}
              setSesqType={setSesqType}
              setSesterType={setSesterType}
              setTriType={setTriType}
              setTetraType={setTetraType}
              setPrenylType={setPrenylType}
              setHemiType={setHemiType}
              setSesquarType={setSesquarType}
              name={name}
              terpType={terpType}
              setTerpType={setTerpType}
              enzClass={enzClass}
              uniprot={uniprot}
              genbank={genbank}
              reactSmiles={reactSmiles}
              reactChebi={reactChebi}
              reactName={reactName}
              prodChebi={prodChebi}
              prodName={prodName}
              prodSmiles={prodSmiles}
              setSearchSubmit={setSearchSubmit}
              showInput={showInput}
              enzymeInputClicked={enzymeInputClicked}
              setEnzymeInputClicked={setEnzymeInputClicked}
              setEnzymeInputUnclicked={setEnzymeInputUnclicked}
              kingdom={kingdom}
              setFirstSearch={setFirstSearch}
              evidence={evidence}
              mechanismUnclicked={mechanismUnclicked}
              intermediateSmiles={intermediateSmiles}
            />
            <button
              id="enzNameQuickSearch"
              style={{ border: "none", backgroundColor: "rgb(245,245,245)" }}
              onClick={async () => {
                try {
                  if (!enzymeInputClicked) {
                    showInput(
                      [
                        "NameInput",
                        "TypeInput",
                        "ClassInput",
                        "UniprotInput",
                        "GenbankInput",
                        "KingdomInput",
                      ],
                      "enzymeDD",
                      enzymeInputClicked,
                      setEnzymeInputClicked,
                      "EnzymeInputs",
                      setEnzymeInputUnclicked
                    );
                  }
                  setName(table[row].name);
                  if (pageCount === 1) {
                    setFirstSearch(true);
                    setButtonCount(1);
                    const result = await advancedSearch(
                      table[row].name,
                      terpType,
                      kingdom,
                      enzClass,
                      uniprot,
                      genbank,
                      reactSmiles,
                      reactChebi,
                      reactName,
                      prodChebi,
                      prodName,
                      prodSmiles,
                      pageCount,
                      buttonCount,
                      setIsLoading,
                      setSearchSubmit,
                      setDataCount,
                      evidence,
                      mechanismUnclicked,
                      intermediateSmiles
                    );
                    setTable(result);
                    renderData(result);
                  } else {
                    setFirstSearch(true);
                    setPageCount(1);
                    setButtonCount(1);
                  }
                } catch (err) {
                  throw err;
                }
              }}
            >
              {table[row].name}
            </button>
            <br />
            <UniprotLink
              tableRow={table[row]}
              setIsLoading={setIsLoading}
              pageCount={pageCount}
              setDataCount={setDataCount}
              search={advancedSearch}
              renderData={renderData}
              setPageCount={setPageCount}
              setButtonCount={setButtonCount}
              buttonCount={buttonCount}
              setUniprot={setUniprot}
              name={name}
              terpType={terpType}
              enzClass={enzClass}
              uniprot={uniprot}
              genbank={genbank}
              reactSmiles={reactSmiles}
              reactChebi={reactChebi}
              reactName={reactName}
              prodChebi={prodChebi}
              prodName={prodName}
              prodSmiles={prodSmiles}
              setSearchSubmit={setSearchSubmit}
              showInput={showInput}
              enzymeInputClicked={enzymeInputClicked}
              setEnzymeInputClicked={setEnzymeInputClicked}
              setEnzymeInputUnclicked={setEnzymeInputUnclicked}
              kingdom={kingdom}
              setFirstSearch={setFirstSearch}
              evidence={evidence}
              mechanismUnclicked={mechanismUnclicked}
              intermediateSmiles={intermediateSmiles}
            />
            <CopySequence table={table} row={row} />
            <KingdomIcon
              tableRow={table[row]}
              setIsLoading={setIsLoading}
              pageCount={pageCount}
              setDataCount={setDataCount}
              search={advancedSearch}
              renderData={renderData}
              setPageCount={setPageCount}
              setButtonCount={setButtonCount}
              buttonCount={buttonCount}
              animaliaBird={animaliaBird}
              animaliaCoral={animaliaCoral}
              animaliaInsect={animaliaInsect}
              animaliaMammal={animaliaMammal}
              animaliaMammalHuman={animaliaMammalHuman}
              animaliaMarineSponge={animaliaMarineSponge}
              fungi={fungi}
              bacteria={bacteria}
              archaea={archaea}
              amoebozoa={amoebozoa}
              plantae={plantae}
              plantaeRedAlgae={plantaeRedAlgae}
              cyanobacteria={cyanobacteria}
              viruses={viruses}
              setAnimaliaBird={setAnimaliaBird}
              setAnimaliaCoral={setAnimaliaCoral}
              setAnimaliaInsect={setAnimaliaInsect}
              setAnimaliaMammal={setAnimaliaMammal}
              setAnimaliaMammalHuman={setAnimaliaMammalHuman}
              setAnimaliaMarineSponge={setAnimaliaMarineSponge}
              setFungi={setFungi}
              setBacteria={setBacteria}
              setArchaea={setArchaea}
              setAmoebozoa={setAmoebozoa}
              setPlantae={setPlantae}
              setPlantaeRedAlgae={setPlantaeRedAlgae}
              setCyanobacteria={setCyanobacteria}
              setViruses={setViruses}
              name={name}
              terpType={terpType}
              setKingdom={setKingdom}
              enzClass={enzClass}
              uniprot={uniprot}
              genbank={genbank}
              reactSmiles={reactSmiles}
              reactChebi={reactChebi}
              reactName={reactName}
              prodChebi={prodChebi}
              prodName={prodName}
              prodSmiles={prodSmiles}
              setSearchSubmit={setSearchSubmit}
              showInput={showInput}
              enzymeInputClicked={enzymeInputClicked}
              setEnzymeInputClicked={setEnzymeInputClicked}
              setEnzymeInputUnclicked={setEnzymeInputUnclicked}
              setFirstSearch={setFirstSearch}
              mechanismUnclicked={mechanismUnclicked}
              evidence={evidence}
              intermediateSmiles={intermediateSmiles}
            />
          </>
        ),


        table[row].cosubstrates[0]?.smiles ? (
          table[row].substrate_image && table[row].cosubstrates[0].image ? (
                <CosubstrateRow
                table={table}
                row={row}
                setIsLoading={setIsLoading}
                pageCount={pageCount}
                setDataCount={setDataCount}
                search={advancedSearch}
                renderData={renderData}
                setPageCount={setPageCount}
                setButtonCount={setButtonCount}
                buttonCount={buttonCount}
                setReactName={setReactName}
                kingdom={kingdom}
                name={name}
                terpType={terpType}
                enzClass={enzClass}
                uniprot={uniprot}
                genbank={genbank}
                reactSmiles={reactSmiles}
                reactChebi={reactChebi}
                reactName={reactName}
                prodChebi={prodChebi}
                prodName={prodName}
                prodSmiles={prodSmiles}
                setSearchSubmit={setSearchSubmit}
                showInput={showInput}
                reactantInputClicked={reactantInputClicked}
                setReactantInputClicked={setReactantInputClicked}
                setReactantInputUnclicked={setReactantInputUnclicked}
                setFirstSearch={setFirstSearch}
                evidence={evidence}
                mechanismUnclicked={mechanismUnclicked}
                intermediateSmiles={intermediateSmiles}
                setReactSmiles={setReactSmiles}
              />
          ) : (
            <>
              <CosubstrateRow
                table={table}
                row={row}
                setIsLoading={setIsLoading}
                pageCount={pageCount}
                setDataCount={setDataCount}
                search={advancedSearch}
                renderData={renderData}
                setPageCount={setPageCount}
                setButtonCount={setButtonCount}
                buttonCount={buttonCount}
                setReactName={setReactName}
                kingdom={kingdom}
                name={name}
                terpType={terpType}
                enzClass={enzClass}
                uniprot={uniprot}
                genbank={genbank}
                reactSmiles={reactSmiles}
                reactChebi={reactChebi}
                reactName={reactName}
                prodChebi={prodChebi}
                prodName={prodName}
                prodSmiles={prodSmiles}
                setSearchSubmit={setSearchSubmit}
                showInput={showInput}
                reactantInputClicked={reactantInputClicked}
                setReactantInputClicked={setReactantInputClicked}
                setReactantInputUnclicked={setReactantInputUnclicked}
                setFirstSearch={setFirstSearch}
                evidence={evidence}
                mechanismUnclicked={mechanismUnclicked}
                intermediateSmiles={intermediateSmiles}
              />
            </>
          )
        ) : (
          table[row].substrate_image ? (
            <>
              <button
                style={{ border: "none", backgroundColor: "rgb(245,245,245)" }}
              >
                <img
                  src={table[row].substrate_image}
                  alt={table[row].substrate_smiles}
                  className="responsive"
                  onClick={async () => {
                    try {
                      if (!reactantInputClicked) {
                        showInput(
                          [
                            "ReactantSMILESInput",
                            "ReactantChebiInput",
                            "ReactantNameInput",
                          ],
                          "reactantDD",
                          reactantInputClicked,
                          setReactantInputClicked,
                          "ReactantInputs",
                          setReactantInputUnclicked
                        );
                      }
                      setReactSmiles(table[row].substrate_smiles);
                      if (pageCount === 1) {
                        setFirstSearch(true);
                        setButtonCount(1);
                        const result = await advancedSearch(
                          name,
                          terpType,
                          kingdom,
                          enzClass,
                          uniprot,
                          genbank,
                          table[row].substrate_smiles,
                          reactChebi,
                          reactName,
                          prodChebi,
                          prodName,
                          prodSmiles,
                          pageCount,
                          buttonCount,
                          setIsLoading,
                          setSearchSubmit,
                          setDataCount,
                          evidence,
                          mechanismUnclicked,
                          intermediateSmiles
                        );
                        setTable(result);
                        renderData(result);
                      } else {
                        setFirstSearch(true);
                        setPageCount(1);
                        setButtonCount(1);
                      }
                    } catch (err) {
                      throw err;
                    }
                  }}
                />
              </button>
              <br />
              <SubstrateRow
              table={table}
              row={row}
              setIsLoading={setIsLoading}
              pageCount={pageCount}
              setDataCount={setDataCount}
              search={advancedSearch}
              renderData={renderData}
              setPageCount={setPageCount}
              setButtonCount={setButtonCount}
              buttonCount={buttonCount}
              setReactName={setReactName}
              kingdom={kingdom}
              name={name}
              terpType={terpType}
              enzClass={enzClass}
              uniprot={uniprot}
              genbank={genbank}
              reactSmiles={reactSmiles}
              reactChebi={reactChebi}
              reactName={reactName}
              prodChebi={prodChebi}
              prodName={prodName}
              prodSmiles={prodSmiles}
              setSearchSubmit={setSearchSubmit}
              showInput={showInput}
              reactantInputClicked={reactantInputClicked}
              setReactantInputClicked={setReactantInputClicked}
              setReactantInputUnclicked={setReactantInputUnclicked}
              setFirstSearch={setFirstSearch}
              evidence={evidence}
              mechanismUnclicked={mechanismUnclicked}
              intermediateSmiles={intermediateSmiles}
            />
              
            </>
          ) : (
            <>
            {table[row].substrate_smiles}
            <SubstrateRow
              table={table}
              row={row}
              setIsLoading={setIsLoading}
              pageCount={pageCount}
              setDataCount={setDataCount}
              search={advancedSearch}
              renderData={renderData}
              setPageCount={setPageCount}
              setButtonCount={setButtonCount}
              buttonCount={buttonCount}
              setReactName={setReactName}
              kingdom={kingdom}
              name={name}
              terpType={terpType}
              enzClass={enzClass}
              uniprot={uniprot}
              genbank={genbank}
              reactSmiles={reactSmiles}
              reactChebi={reactChebi}
              reactName={reactName}
              prodChebi={prodChebi}
              prodName={prodName}
              prodSmiles={prodSmiles}
              setSearchSubmit={setSearchSubmit}
              showInput={showInput}
              reactantInputClicked={reactantInputClicked}
              setReactantInputClicked={setReactantInputClicked}
              setReactantInputUnclicked={setReactantInputUnclicked}
              setFirstSearch={setFirstSearch}
              evidence={evidence}
              mechanismUnclicked={mechanismUnclicked}
              intermediateSmiles={intermediateSmiles}
            />
              <br />
            </>
          )
        ),
        table[row].product_image ? (
          <>
            <button
              style={{ border: "none", backgroundColor: "rgb(245,245,245)" }}
            >
              <img
                src={table[row].product_image}
                className="responsive"
                onClick={async () => {
                  try {
                    if (!productInputClicked) {
                      showInput(
                        [
                          "ProductSMILESInput",
                          "ProductChebiInput",
                          "ProductNameInput",
                        ],
                        "productDD",
                        productInputClicked,
                        setProductInputClicked,
                        "ProductInputs",
                        setProductInputUnclicked
                      );
                    }
                    setProdSmiles(table[row].product_smiles);
                    if (pageCount === 1) {
                      setButtonCount(1);
                      setFirstSearch(true);
                      const result = await advancedSearch(
                        name,
                        terpType,
                        kingdom,
                        enzClass,
                        uniprot,
                        genbank,
                        reactSmiles,
                        reactChebi,
                        reactName,
                        prodChebi,
                        prodName,
                        table[row].product_smiles,
                        pageCount,
                        buttonCount,
                        setIsLoading,
                        setSearchSubmit,
                        setDataCount,
                        evidence,
                        mechanismUnclicked,
                        intermediateSmiles
                      );
                      setTable(result);
                      renderData(result);
                    } else {
                      setPageCount(1);
                      setButtonCount(1);
                      setFirstSearch(true);
                    }
                  } catch (err) {
                    throw err;
                  }
                }}
              />
            </button>
            <br />
            <ProductRow
              table={table}
              row={row}
              setIsLoading={setIsLoading}
              pageCount={pageCount}
              setDataCount={setDataCount}
              search={advancedSearch}
              renderData={renderData}
              setPageCount={setPageCount}
              setButtonCount={setButtonCount}
              buttonCount={buttonCount}
              setProdName={setProdName}
              name={name}
              terpType={terpType}
              enzClass={enzClass}
              uniprot={uniprot}
              genbank={genbank}
              reactSmiles={reactSmiles}
              reactChebi={reactChebi}
              reactName={reactName}
              prodChebi={prodChebi}
              prodName={prodName}
              prodSmiles={prodSmiles}
              setSearchSubmit={setSearchSubmit}
              showInput={showInput}
              productInputClicked={productInputClicked}
              setProductInputClicked={setProductInputClicked}
              setProductInputUnclicked={setProductInputUnclicked}
              kingdom={kingdom}
              setFirstSearch={setFirstSearch}
              evidence={evidence}
              mechanismUnclicked={mechanismUnclicked}
              intermediateSmiles={intermediateSmiles}
            />
          </>
        ) : (
          <>
            {table[row].product_smiles} <br />
            {table[row].product_name}
          </>
        ),

        <>
          <div
            style={{
              position: "relative",
              maxWidth: "fit-content",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            Reaction: <br />
            <a
              id="reactionPublication"
              style={{ position: "relative" }}
              href={`${table[row].reaction_link}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faBook} color="rgb(0,0,0,0.8)" />
              <div id="goToReactionPublication">
                <nobr>See publication</nobr>
              </div>
            </a>
          </div>
          <br />

          <div
            style={{
              position: "relative",
              maxWidth: "fit-content",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            {table[row].mechanism_id ? (
              <>
                Mechanism:
                <br />
                <nobr style={{ padding: 0 }}>
                  {(table[row].mechanism_evidence === "nan" ||
                    table[row].mechanism_evidence === "TRUE" ||
                    table[row].mechanism_evidence === "trivial") &&
                  table[row].mechanism_id ? (
                    <>
                      <FontAwesomeIcon
                        icon={faBrain}
                        color="rgb(0,0,0,0.8)"
                        id="seeEvizens"
                      />

                      <div
                        id={
                          seeAllReactions
                            ? "terezaEvizensTwoCol"
                            : "terezaEvizens"
                        }
                      >
                        Mechanism was based on other known <br /> mechanisms and
                        chemical logic.
                      </div>
                    </>
                  ) : table[row].mechanism_evidence === "similarity" ? (
                    <>
                      <FontAwesomeIcon
                        icon={faClone}
                        color="rgb(0,0,0,0.8)"
                        id="seeEvizens"
                      />

                      <div
                        id={
                          seeAllReactions
                            ? "terezaEvizensTwoCol"
                            : "terezaEvizens"
                        }
                      >
                        Mechanism was deduced using analogy <br /> and
                        similarity to other mechanisms.
                      </div>
                    </>
                  ) : table[row].mechanism_evidence === "calculation" ? (
                    <>
                      <FontAwesomeIcon
                        icon={faCalculator}
                        color="rgb(0,0,0,0.8)"
                        id="seeEvizens"
                      />
                      <div
                        id={
                          seeAllReactions
                            ? "terezaEvizensTwoCol"
                            : "terezaEvizens"
                        }
                      >
                        Mechanism was described <br /> using calculation
                        methods.
                      </div>
                    </>
                  ) : table[row].mechanism_evidence === "experiment" ? (
                    <>
                      <FontAwesomeIcon
                        icon={faFlask}
                        color="rgb(0,0,0,0.8)"
                        id="seeEvizens"
                      />
                      <div
                        id={
                          seeAllReactions
                            ? "terezaEvizensTwoCol"
                            : "terezaEvizens"
                        }
                      >
                        Mechanism was discovered by <br /> conducting chemical
                        experiments
                      </div>
                    </>
                  ) : (
                    <></>
                  )}{" "}
                  <button
                    id="entriesButton"
                    style={{ padding: 0, marginRight: 5 }}
                    onClick={() => findingMechanism(table, row)}
                  >
                    View
                  </button>
                  <a
                    id="mechanismPublication"
                    style={{ position: "relative" }}
                    href={`${table[row].mechanism_link}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faBook} color="rgb(0,0,0,0.8)" />
                    <div id="goToMechanismPublication">
                      <nobr>See publication</nobr>
                    </div>
                  </a>
                </nobr>
              </>
            ) : (
              <></>
            )}
          </div>
        </>,
      ]);
    }

    setSearchResult(body);
    setSearchSubmit(true);
    setIsLoading(false);
    setFirstSearch(false);
  }

  useEffect(() => {

    function handleWindowResize() {
      setWindowSize(getWindowSize().innerWidth);
    }

    window.addEventListener('resize', handleWindowResize);

    loadData();

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };

  }, [pageCount, clearAll]);

  const heading = ["Enzyme", "Substrate", "Product", "Mechanism", "Details"];

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css"
          rel="stylesheet"
        />
      </head>
      <body id="body">
        <HomeNavbar />
        <section
          id="sectionFilters"
        >
          <div style={{ paddingTop: 10}} id="content">
            <div id="filtersMargins">
              <form id="inputs" onSubmit={loadData}>
                <div id="filters">
                  <ul style={{ listStyle: "none" }}>
                    <li>
                      <ul
                        style={{
                          listStyle: "none",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          id="EnzymeInputs"
                          key={enzymeInputUnclicked}
                          onClick={() => {
                            showInput(
                              [
                                "NameInput",
                                "TypeInput",
                                "ClassInput",
                                "UniprotInput",
                                "GenbankInput",
                                "KingdomInput",
                              ],
                              "enzymeDD",
                              enzymeInputClicked,
                              setEnzymeInputClicked,
                              "EnzymeInputs",
                              setEnzymeInputUnclicked
                            );
                          }}
                        >
                          <li>
                            <h5>Enzyme filters:</h5>
                          </li>
                          <li>
                            <FontAwesomeIcon
                              id="enzymeDD"
                              icon={faAngleDoubleDown}
                            />
                          </li>
                        </div>
                        <button
                          id="clearEnzymeInputs"
                          style={{ marginTop: "10px", width: 200 }}
                          onClick={(event) => {
                            event.preventDefault();
                            clearAllInputs(true, false, false, false);
                          }}
                        >
                          Clear enzyme inputs
                        </button>
                      </ul>
                    </li>
                    <div id="allEnzymeInputs">
                      <input
                        id="NameInput"
                        placeholder="Enzyme name"
                        style={{ display: "none", marginBottom: 10 }}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                        value={name}
                      />

                      <div
                        id="KingdomInput"
                        style={{ display: "none", columnCount: 3 }}
                        class="row"
                      >
                        <RadioKingdomInput
                          idLabel={"animaliamammalinput"}
                          idInput={"AnimaliaMammalInput"}
                          inputText={"Animalia (Mammal)"}
                          value={"Animalia (Mammal)"}
                          setSpecificKingdom={setAnimaliaMammal}
                          animaliaMammal={animaliaMammal}
                          animaliaCoral={animaliaCoral}
                          animaliaBird={animaliaBird}
                          animaliaMammalHuman={animaliaMammalHuman}
                          animaliaInsect={animaliaInsect}
                          animaliaMarineSponge={animaliaMarineSponge}
                          plantae={plantae}
                          fungi={fungi}
                          bacteria={bacteria}
                          amoebozoa={amoebozoa}
                          archaea={archaea}
                          viruses={viruses}
                          cyanobacteria={cyanobacteria}
                          plantaeRedAlgae={plantaeRedAlgae}
                          setKingdomObject={setKingdom}
                        />
                        <RadioKingdomInput
                          idLabel={"animaliacoralinput"}
                          idInput={"AnimaliaCoralInput"}
                          inputText={"Animalia (Coral)"}
                          value={"Animalia (Coral)"}
                          setSpecificKingdom={setAnimaliaCoral}
                          animaliaMammal={animaliaMammal}
                          animaliaCoral={animaliaCoral}
                          animaliaBird={animaliaBird}
                          animaliaMammalHuman={animaliaMammalHuman}
                          animaliaInsect={animaliaInsect}
                          animaliaMarineSponge={animaliaMarineSponge}
                          plantae={plantae}
                          fungi={fungi}
                          bacteria={bacteria}
                          amoebozoa={amoebozoa}
                          archaea={archaea}
                          viruses={viruses}
                          cyanobacteria={cyanobacteria}
                          plantaeRedAlgae={plantaeRedAlgae}
                          setKingdomObject={setKingdom}
                        />
                        <RadioKingdomInput
                          idLabel={"animaliabirdinput"}
                          idInput={"AnimaliaBirdInput"}
                          inputText={"Animalia (Bird)"}
                          value={"Animalia (Bird)"}
                          setSpecificKingdom={setAnimaliaBird}
                          animaliaMammal={animaliaMammal}
                          animaliaCoral={animaliaCoral}
                          animaliaBird={animaliaBird}
                          animaliaMammalHuman={animaliaMammalHuman}
                          animaliaInsect={animaliaInsect}
                          animaliaMarineSponge={animaliaMarineSponge}
                          plantae={plantae}
                          fungi={fungi}
                          bacteria={bacteria}
                          amoebozoa={amoebozoa}
                          archaea={archaea}
                          viruses={viruses}
                          cyanobacteria={cyanobacteria}
                          plantaeRedAlgae={plantaeRedAlgae}
                          setKingdomObject={setKingdom}
                        />
                        <RadioKingdomInput
                          idLabel={"animaliamammalhumaninput"}
                          idInput={"AnimaliaMammalHumanInput"}
                          inputText={"Animalia (Mammal, Human)"}
                          value={"Animalia (Mammal, Human)"}
                          setSpecificKingdom={setAnimaliaMammalHuman}
                          animaliaMammal={animaliaMammal}
                          animaliaCoral={animaliaCoral}
                          animaliaBird={animaliaBird}
                          animaliaMammalHuman={animaliaMammalHuman}
                          animaliaInsect={animaliaInsect}
                          animaliaMarineSponge={animaliaMarineSponge}
                          plantae={plantae}
                          fungi={fungi}
                          bacteria={bacteria}
                          amoebozoa={amoebozoa}
                          archaea={archaea}
                          viruses={viruses}
                          cyanobacteria={cyanobacteria}
                          plantaeRedAlgae={plantaeRedAlgae}
                          setKingdomObject={setKingdom}
                        />
                        <RadioKingdomInput
                          idLabel={"animaliainsectinput"}
                          idInput={"AnimaliaInsectInput"}
                          inputText={"Animalia (Insect)"}
                          value={"Animalia (Insect)"}
                          setSpecificKingdom={setAnimaliaInsect}
                          animaliaMammal={animaliaMammal}
                          animaliaCoral={animaliaCoral}
                          animaliaBird={animaliaBird}
                          animaliaMammalHuman={animaliaMammalHuman}
                          animaliaInsect={animaliaInsect}
                          animaliaMarineSponge={animaliaMarineSponge}
                          plantae={plantae}
                          fungi={fungi}
                          bacteria={bacteria}
                          amoebozoa={amoebozoa}
                          archaea={archaea}
                          viruses={viruses}
                          cyanobacteria={cyanobacteria}
                          plantaeRedAlgae={plantaeRedAlgae}
                          setKingdomObject={setKingdom}
                        />
                        <RadioKingdomInput
                          idLabel={"animaliamarinespongeinput"}
                          idInput={"AnimaliaMarineSpongeInput"}
                          inputText={"Animalia (Marine Sponge)"}
                          value={"Animalia (Marine Sponge)"}
                          setSpecificKingdom={setAnimaliaMarineSponge}
                          animaliaMammal={animaliaMammal}
                          animaliaCoral={animaliaCoral}
                          animaliaBird={animaliaBird}
                          animaliaMammalHuman={animaliaMammalHuman}
                          animaliaInsect={animaliaInsect}
                          animaliaMarineSponge={animaliaMarineSponge}
                          plantae={plantae}
                          fungi={fungi}
                          bacteria={bacteria}
                          amoebozoa={amoebozoa}
                          archaea={archaea}
                          viruses={viruses}
                          cyanobacteria={cyanobacteria}
                          plantaeRedAlgae={plantaeRedAlgae}
                          setKingdomObject={setKingdom}
                        />
                        <RadioKingdomInput
                          idLabel={"plantaeinput"}
                          idInput={"PlantaeInput"}
                          inputText={"Plantae"}
                          value={"Plantae"}
                          setSpecificKingdom={setPlantae}
                          animaliaMammal={animaliaMammal}
                          animaliaCoral={animaliaCoral}
                          animaliaBird={animaliaBird}
                          animaliaMammalHuman={animaliaMammalHuman}
                          animaliaInsect={animaliaInsect}
                          animaliaMarineSponge={animaliaMarineSponge}
                          plantae={plantae}
                          fungi={fungi}
                          bacteria={bacteria}
                          amoebozoa={amoebozoa}
                          archaea={archaea}
                          viruses={viruses}
                          cyanobacteria={cyanobacteria}
                          plantaeRedAlgae={plantaeRedAlgae}
                          setKingdomObject={setKingdom}
                        />
                        <RadioKingdomInput
                          idLabel={"fungiinput"}
                          idInput={"FungiInput"}
                          inputText={"Fungi"}
                          value={"Fungi"}
                          setSpecificKingdom={setFungi}
                          animaliaMammal={animaliaMammal}
                          animaliaCoral={animaliaCoral}
                          animaliaBird={animaliaBird}
                          animaliaMammalHuman={animaliaMammalHuman}
                          animaliaInsect={animaliaInsect}
                          animaliaMarineSponge={animaliaMarineSponge}
                          plantae={plantae}
                          fungi={fungi}
                          bacteria={bacteria}
                          amoebozoa={amoebozoa}
                          archaea={archaea}
                          viruses={viruses}
                          cyanobacteria={cyanobacteria}
                          plantaeRedAlgae={plantaeRedAlgae}
                          setKingdomObject={setKingdom}
                        />
                        <RadioKingdomInput
                          idLabel={"bacteriainput"}
                          idInput={"BacteriaInput"}
                          inputText={"Bacteria"}
                          value={"Bacteria"}
                          setSpecificKingdom={setBacteria}
                          animaliaMammal={animaliaMammal}
                          animaliaCoral={animaliaCoral}
                          animaliaBird={animaliaBird}
                          animaliaMammalHuman={animaliaMammalHuman}
                          animaliaInsect={animaliaInsect}
                          animaliaMarineSponge={animaliaMarineSponge}
                          plantae={plantae}
                          fungi={fungi}
                          bacteria={bacteria}
                          amoebozoa={amoebozoa}
                          archaea={archaea}
                          viruses={viruses}
                          cyanobacteria={cyanobacteria}
                          plantaeRedAlgae={plantaeRedAlgae}
                          setKingdomObject={setKingdom}
                        />
                        <RadioKingdomInput
                          idLabel={"amoebozoainput"}
                          idInput={"AmoebozoaInput"}
                          inputText={"Amoebozoa"}
                          value={"Amoebozoa"}
                          setSpecificKingdom={setAmoebozoa}
                          animaliaMammal={animaliaMammal}
                          animaliaCoral={animaliaCoral}
                          animaliaBird={animaliaBird}
                          animaliaMammalHuman={animaliaMammalHuman}
                          animaliaInsect={animaliaInsect}
                          animaliaMarineSponge={animaliaMarineSponge}
                          plantae={plantae}
                          fungi={fungi}
                          bacteria={bacteria}
                          amoebozoa={amoebozoa}
                          archaea={archaea}
                          viruses={viruses}
                          cyanobacteria={cyanobacteria}
                          plantaeRedAlgae={plantaeRedAlgae}
                          setKingdomObject={setKingdom}
                        />
                        <RadioKingdomInput
                          idLabel={"archaeainput"}
                          idInput={"ArchaeaInput"}
                          inputText={"Archaea"}
                          value={"Archaea"}
                          setSpecificKingdom={setArchaea}
                          animaliaMammal={animaliaMammal}
                          animaliaCoral={animaliaCoral}
                          animaliaBird={animaliaBird}
                          animaliaMammalHuman={animaliaMammalHuman}
                          animaliaInsect={animaliaInsect}
                          animaliaMarineSponge={animaliaMarineSponge}
                          plantae={plantae}
                          fungi={fungi}
                          bacteria={bacteria}
                          amoebozoa={amoebozoa}
                          archaea={archaea}
                          viruses={viruses}
                          cyanobacteria={cyanobacteria}
                          plantaeRedAlgae={plantaeRedAlgae}
                          setKingdomObject={setKingdom}
                        />
                        <RadioKingdomInput
                          idLabel={"virusesinput"}
                          idInput={"VirusesInput"}
                          inputText={"Viruses"}
                          value={"Viruses"}
                          setSpecificKingdom={setViruses}
                          animaliaMammal={animaliaMammal}
                          animaliaCoral={animaliaCoral}
                          animaliaBird={animaliaBird}
                          animaliaMammalHuman={animaliaMammalHuman}
                          animaliaInsect={animaliaInsect}
                          animaliaMarineSponge={animaliaMarineSponge}
                          plantae={plantae}
                          fungi={fungi}
                          bacteria={bacteria}
                          amoebozoa={amoebozoa}
                          archaea={archaea}
                          viruses={viruses}
                          cyanobacteria={cyanobacteria}
                          plantaeRedAlgae={plantaeRedAlgae}
                          setKingdomObject={setKingdom}
                        />
                        <RadioKingdomInput
                          idLabel={"cyanobacteriainput"}
                          idInput={"CyanobacteriaInput"}
                          inputText={"Cyanobacteria"}
                          value={"Cyanobacteria"}
                          setSpecificKingdom={setCyanobacteria}
                          animaliaMammal={animaliaMammal}
                          animaliaCoral={animaliaCoral}
                          animaliaBird={animaliaBird}
                          animaliaMammalHuman={animaliaMammalHuman}
                          animaliaInsect={animaliaInsect}
                          animaliaMarineSponge={animaliaMarineSponge}
                          plantae={plantae}
                          fungi={fungi}
                          bacteria={bacteria}
                          amoebozoa={amoebozoa}
                          archaea={archaea}
                          viruses={viruses}
                          cyanobacteria={cyanobacteria}
                          plantaeRedAlgae={plantaeRedAlgae}
                          setKingdomObject={setKingdom}
                        />
                        <RadioKingdomInput
                          idLabel={"plantaeredalgaeinput"}
                          idInput={"PlantaeRedAlgaeInput"}
                          inputText={"Plantae (Red algae)"}
                          value={"Plantae (Red algae)"}
                          setSpecificKingdom={setPlantaeRedAlgae}
                          animaliaMammal={animaliaMammal}
                          animaliaCoral={animaliaCoral}
                          animaliaBird={animaliaBird}
                          animaliaMammalHuman={animaliaMammalHuman}
                          animaliaInsect={animaliaInsect}
                          animaliaMarineSponge={animaliaMarineSponge}
                          plantae={plantae}
                          fungi={fungi}
                          bacteria={bacteria}
                          amoebozoa={amoebozoa}
                          archaea={archaea}
                          viruses={viruses}
                          cyanobacteria={cyanobacteria}
                          plantaeRedAlgae={plantaeRedAlgae}
                          setKingdomObject={setKingdom}
                        />
                      </div>
                      <div
                        id="TypeInput"
                        style={{ display: "none", columnCount: 3 }}
                        class="row"
                      >
                        <RadioTypeInput
                          idLabel={"diterpinput"}
                          idInput={"DiTerpInput"}
                          inputText={"Diterpene synthases"}
                          value={"di"}
                          setType={setDiType}
                          diType={diType}
                          monoType={monoType}
                          sesqType={sesqType}
                          triType={triType}
                          tetraType={tetraType}
                          sesterType={sesterType}
                          sesquarType={sesquarType}
                          prenylType={prenylType}
                          hemiType={hemiType}
                          setTerpType={setTerpType}
                        />
                        <RadioTypeInput
                          idLabel={"monoterpinput"}
                          idInput={"MonoTerpInput"}
                          inputText={"Monoterpene synthases"}
                          value={"mono"}
                          setType={setMonoType}
                          diType={diType}
                          monoType={monoType}
                          sesqType={sesqType}
                          triType={triType}
                          tetraType={tetraType}
                          sesterType={sesterType}
                          sesquarType={sesquarType}
                          prenylType={prenylType}
                          hemiType={hemiType}
                          setTerpType={setTerpType}
                        />
                        <RadioTypeInput
                          idLabel={"sesqterpinput"}
                          idInput={"SesqTerpInput"}
                          inputText={"Sesquiterpene synthases"}
                          value={"sesq"}
                          setType={setSesqType}
                          diType={diType}
                          monoType={monoType}
                          sesqType={sesqType}
                          triType={triType}
                          tetraType={tetraType}
                          sesterType={sesterType}
                          sesquarType={sesquarType}
                          prenylType={prenylType}
                          hemiType={hemiType}
                          setTerpType={setTerpType}
                        />
                        <RadioTypeInput
                          idLabel={"triterpinput"}
                          idInput={"TriTerpInput"}
                          inputText={"Triterpene synthases"}
                          value={"tri"}
                          setType={setTriType}
                          diType={diType}
                          monoType={monoType}
                          sesqType={sesqType}
                          triType={triType}
                          tetraType={tetraType}
                          sesterType={sesterType}
                          sesquarType={sesquarType}
                          prenylType={prenylType}
                          hemiType={hemiType}
                          setTerpType={setTerpType}
                        />
                        <RadioTypeInput
                          idLabel={"tetraterpinput"}
                          idInput={"TetraTerpInput"}
                          inputText={"Tetraterpene synthases"}
                          value={"tetra"}
                          setType={setTetraType}
                          diType={diType}
                          monoType={monoType}
                          sesqType={sesqType}
                          triType={triType}
                          tetraType={tetraType}
                          sesterType={sesterType}
                          sesquarType={sesquarType}
                          prenylType={prenylType}
                          hemiType={hemiType}
                          setTerpType={setTerpType}
                        />
                        <RadioTypeInput
                          idLabel={"sesterterpinput"}
                          idInput={"SesterTerpInput"}
                          inputText={"Sesterterpene synthases"}
                          value={"sester"}
                          setType={setSesterType}
                          diType={diType}
                          monoType={monoType}
                          sesqType={sesqType}
                          triType={triType}
                          tetraType={tetraType}
                          sesterType={sesterType}
                          sesquarType={sesquarType}
                          prenylType={prenylType}
                          hemiType={hemiType}
                          setTerpType={setTerpType}
                        />
                        <RadioTypeInput
                          idLabel={"sesquarterpinput"}
                          idInput={"SesquarTerpInput"}
                          inputText={"Sesquarterpene synthases"}
                          value={"sesquar"}
                          setType={setSesquarType}
                          diType={diType}
                          monoType={monoType}
                          sesqType={sesqType}
                          triType={triType}
                          tetraType={tetraType}
                          sesterType={sesterType}
                          sesquarType={sesquarType}
                          prenylType={prenylType}
                          hemiType={hemiType}
                          setTerpType={setTerpType}
                        />
                        <RadioTypeInput
                          idLabel={"hemiterpinput"}
                          idInput={"HemiTerpInput"}
                          inputText={"Hemiterpene synthases"}
                          value={"hemi"}
                          setType={setHemiType}
                          diType={diType}
                          monoType={monoType}
                          sesqType={sesqType}
                          triType={triType}
                          tetraType={tetraType}
                          sesterType={sesterType}
                          sesquarType={sesquarType}
                          prenylType={prenylType}
                          hemiType={hemiType}
                          setTerpType={setTerpType}
                        />
                        <RadioTypeInput
                          idLabel={"prenylterpinput"}
                          idInput={"PrenylTerpInput"}
                          inputText={"Prenyl transferases"}
                          value={"pt"}
                          setType={setPrenylType}
                          diType={diType}
                          monoType={monoType}
                          sesqType={sesqType}
                          triType={triType}
                          tetraType={tetraType}
                          sesterType={sesterType}
                          sesquarType={sesquarType}
                          prenylType={prenylType}
                          hemiType={hemiType}
                          setTerpType={setTerpType}
                        />
                      </div>
                      <div
                        id="ClassInput"
                        style={{ display: "none" }}
                        class="row"
                      >
                        <div
                          class="col-xl-4"
                          align="left"
                          style={{ display: "inline-block" }}
                        >
                          <label id="classi" for="ClassI">
                            <input
                              type="checkbox"
                              id="ClassI"
                              value="1"
                              onClick={async (e) => {
                                if (e.target.checked) {
                                  setClassOne(1);
                                  setEnzClass({ one: 1, two: classTwo });
                                } else {
                                  setClassOne("");
                                  setEnzClass({ one: "", two: classTwo });
                                }
                              }}
                            />
                            Class I
                          </label>
                        </div>

                        <label id="clasii" for="ClassII">
                          <input
                            type="checkbox"
                            id="ClassII"
                            value="2"
                            onClick={async (e) => {
                              if (e.target.checked) {
                                setClassTwo(2);
                                setEnzClass({ one: classOne, two: 2 });
                              } else {
                                setClassTwo("");
                                setEnzClass({ one: classOne, two: "" });
                              }
                            }}
                          />
                          Class II
                        </label>
                      </div>
                      <input
                        id="UniprotInput"
                        placeholder="Uniprot ID"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          setUniprot(e.target.value);
                        }}
                        value={uniprot}
                      />
                      <input
                        id="GenbankInput"
                        placeholder="Genbank ID"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          setGenbank(e.target.value);
                        }}
                        value={genbank}
                      />
                    </div>
                    <li>
                      <ul
                        style={{
                          listStyle: "none",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          id="ReactantInputs"
                          key={reactantInputUnclicked}
                          onClick={() => {
                            showInput(
                              [
                                "ReactantSMILESInput",
                                "ReactantChebiInput",
                                "ReactantNameInput",
                              ],
                              "reactantDD",
                              reactantInputClicked,
                              setReactantInputClicked,
                              "ReactantInputs",
                              setReactantInputUnclicked
                            );
                          }}
                        >
                          <li>
                            <h5>Substrate filters:</h5>
                          </li>
                          <li>
                            <FontAwesomeIcon
                              id="reactantDD"
                              icon={faAngleDoubleDown}
                            />
                          </li>
                        </div>
                        <button
                          id="clearSubstrateInputs"
                          style={{ marginTop: "10px", width: 200 }}
                          onClick={(event) => {
                            event.preventDefault();
                            setPageCount(1);
                            setButtonCount(1);
                            clearAllInputs(false, true, false, false);
                            setClearAll((current) => !current);
                          }}
                        >
                          Clear substrate inputs
                        </button>
                      </ul>
                    </li>
                    <textarea
                      id="ReactantSMILESInput"
                      placeholder="Substrate SMILES"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        setReactSmiles(e.target.value);
                      }}
                      value={reactSmiles}
                    />
                    <input
                      id="ReactantNameInput"
                      placeholder="Substrate name"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        setReactName(e.target.value);
                      }}
                      value={reactName}
                    />
                    <input
                      id="ReactantChebiInput"
                      placeholder="Substrate ChEBI"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        setReactChebi(e.target.value);
                      }}
                      value={reactChebi}
                    />
                    <li>
                      <ul
                        style={{
                          display: "flex",
                          listStyle: "none",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          id="ProductInputs"
                          key={productInputUnclicked}
                          onClick={() => {
                            showInput(
                              [
                                "ProductSMILESInput",
                                "ProductChebiInput",
                                "ProductNameInput",
                              ],
                              "productDD",
                              productInputClicked,
                              setProductInputClicked,
                              "ProductInputs",
                              setProductInputUnclicked
                            );
                          }}
                        >
                          <li>
                            <h5>Product filters:</h5>
                          </li>
                          <li>
                            <FontAwesomeIcon
                              id="productDD"
                              icon={faAngleDoubleDown}
                            />
                          </li>
                        </div>
                        <button
                          id="clearProductInputs"
                          style={{ marginTop: "10px", width: 200 }}
                          onClick={(event) => {
                            event.preventDefault();
                            setPageCount(1);
                            setButtonCount(1);
                            clearAllInputs(false, false, true, false);
                            setClearAll((current) => !current);
                          }}
                        >
                          Clear product inputs
                        </button>
                      </ul>
                    </li>
                    <textarea
                      id="ProductSMILESInput"
                      placeholder="Product SMILES"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        setProdSmiles(e.target.value);
                      }}
                      value={prodSmiles}
                    />
                    <input
                      id="ProductNameInput"
                      placeholder="Product name"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        setProdName(e.target.value);
                      }}
                      value={prodName}
                    />
                    <input
                      id="ProductChebiInput"
                      placeholder="Product ChEBI"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        setProdChebi(e.target.value);
                      }}
                      value={prodChebi}
                    />

                    <li>
                      <ul
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          listStyle: "none",
                        }}
                      >
                        <div
                          id="EvidenceInput"
                          style={
                            mechanismUnclicked
                              ? { display: "none" }
                              : { display: "flex" }
                          }
                          key={evidenceInputsUnclicked}
                          onClick={() => {
                            showInput(
                              ["EvidenceRadioInput", "IntermediateSmilesInput"],
                              "mechanismDD",
                              evidenceInputClicked,
                              setEvidenceInputClicked,
                              "EvidenceInput",
                              setEvidenceInputsUnclicked
                            );
                          }}
                        >
                          <h5>Mechanism filters:</h5>

                          <li>
                            <FontAwesomeIcon
                              id="mechanismDD"
                              icon={faAngleDoubleDown}
                            />
                          </li>
                        </div>
                        <button
                          id="clearMechanismInputs"
                          style={
                            mechanismUnclicked
                              ? {
                                  display: "none",
                                  marginTop: "10px",
                                  width: 200,
                                }
                              : {
                                  display: "flex",
                                  marginTop: "10px",
                                  width: 200,
                                }
                          }
                          onClick={(event) => {
                            event.preventDefault();
                            setPageCount(1);
                            setButtonCount(1);
                            clearAllInputs(false, false, false, true);
                            setClearAll((current) => !current);
                          }}
                        >
                          Clear mechanism inputs
                        </button>
                      </ul>
                    </li>
                    <div
                      id="IntermediateSmilesInput"
                      style={
                        mechanismUnclicked
                          ? { display: "none" }
                          : !evidenceInputClicked
                          ? { display: "none" }
                          : { display: "flex" }
                      }
                    >
                      <textarea
                        placeholder="Intermediate SMILES"
                        id="IntermediateSMILESInput"
                        onChange={(e) => {
                          setIntermediateSmiles(e.target.value);
                        }}
                        value={intermediateSmiles}
                      />
                    </div>
                    <div
                      id="EvidenceRadioInput"
                      style={
                        mechanismUnclicked
                          ? { display: "none", columnCount: 3 }
                          : !evidenceInputClicked
                          ? { display: "none", columnCount: 3 }
                          : { display: "flex", columnCount: 3 }
                      }
                      class="row"
                    >
                      <RadioEvidenceInput
                        idLabel={"similarityevidence"}
                        idInput={"SimilarityEvidence"}
                        inputText={
                          <>
                            <FontAwesomeIcon icon={faClone} color="black" />{" "}
                            Similarity
                          </>
                        }
                        value={"similarity"}
                        setSpecificEvidence={setSimilarityEvidence}
                        experimentEvidence={experimentEvidence}
                        similarityEvidence={similarityEvidence}
                        trivialEvidence={trivialEvidence}
                        calculationEvidence={calculationEvidence}
                        setEvidenceObject={setEvidence}
                      />
                      <RadioEvidenceInput
                        idLabel={"experimentevidence"}
                        idInput={"ExperimentEvidence"}
                        inputText={
                          <>
                            <FontAwesomeIcon icon={faFlask} /> Experiment
                          </>
                        }
                        value={"experiment"}
                        setSpecificEvidence={setExperimentEvidence}
                        experimentEvidence={experimentEvidence}
                        similarityEvidence={similarityEvidence}
                        trivialEvidence={trivialEvidence}
                        calculationEvidence={calculationEvidence}
                        setEvidenceObject={setEvidence}
                      />
                      <RadioEvidenceInput
                        idLabel={"trivialevidence"}
                        idInput={"TrivialEvidence"}
                        inputText={
                          <>
                            <FontAwesomeIcon icon={faBrain} /> Trivial
                          </>
                        }
                        value={"trivial"}
                        setSpecificEvidence={setTrivialEvidence}
                        experimentEvidence={experimentEvidence}
                        similarityEvidence={similarityEvidence}
                        trivialEvidence={trivialEvidence}
                        calculationEvidence={calculationEvidence}
                        setEvidenceObject={setEvidence}
                      />
                      <RadioEvidenceInput
                        idLabel={"calculationevidence"}
                        idInput={"CalculationEvidence"}
                        inputText={
                          <>
                            <FontAwesomeIcon icon={faCalculator} /> Calculated
                          </>
                        }
                        value={"calculation"}
                        setSpecificEvidence={setCalculationEvidence}
                        experimentEvidence={experimentEvidence}
                        similarityEvidence={similarityEvidence}
                        trivialEvidence={trivialEvidence}
                        calculationEvidence={calculationEvidence}
                        setEvidenceObject={setEvidence}
                      />
                    </div>
                    <ul
                      style={{
                        listStyle: "none",
                        padding: 0,
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    > 
                    <li>
                    <button
                  id="MechanismSearch"
                  style={{ marginTop: "10px" }}
                  onClick={(event) => {
                    event.preventDefault();
                    let showInput = [
                      document.getElementById("EvidenceInput"),
                      document.getElementById("clearMechanismInputs"),
                    ];
                    showInput.forEach((element) => {
                      if (element.style.display === "none") {
                        element.style.display = "flex";
                        setMechanismUnclicked(false);
                      } else {
                        element.style.display = "none";
                        setMechanismUnclicked(true);
                      }
                    });
                    if (pageCount === 1) {
                      setClearAll((current) => !current);
                    } else {
                      setPageCount(1);
                      setButtonCount(1);
                    }
                  }}
                >
                  {mechanismUnclicked == false ? (
                    <>
                      <FontAwesomeIcon icon={faCheck} color="white" /> Search
                      mechanisms
                    </>
                  ) : (
                    <>Search mechanisms</>
                  )}
                </button>
                    </li>
                      
                      <li>
                      <button
                        id="quicksearchbutton"
                        style={{ marginTop: "10px",border:"1px solid white" }}
                        onClick={(event) => {
                          event.preventDefault();
                          if (pageCount === 1) {
                            loadData();
                          } else {
                            setPageCount(1);
                            setButtonCount(1);
                          }
                        }}
                      >
                        Search
                      </button>
                      <button
                        id="quicksearchbutton"
                        style={{ marginTop: "10px",border:"1px solid white" }}
                        onClick={(event) => {
                          event.preventDefault();
                          setPageCount(1);
                          setButtonCount(1);
                          clearAllInputs(true, true, true, true);
                          setClearAll((current) => !current);
                        }}
                      >
                        Clear all
                      </button>
                      </li>
                    </ul>
                  </ul>
                </div>
                <div id="SearchSuccess" align="left" style={{marginTop:10,marginBottom:10}}>
                  {!searchSubmit ? (
                    isLoading ? (
                      <>
                        <Spin
                          indicator={
                            <LoadingOutlined
                              style={{ color: "rgb(23, 55, 151)" }}
                              spin
                            />
                          }
                        />{" "}
                        Searching for results, please wait...
                      </>
                    ) : (
                      ""
                    )
                  ) : dataCount == 0 ? (
                    <>
                      <FontAwesomeIcon icon={faTimes} id="cross" /> No results
                    </>
                  ) : (
                    <>
                      {filtersVisible ? (
                        <>
                          <ul style={{ position: "relative" }}>
                            <li>
                              <FontAwesomeIcon icon={faCheck} id="tick" />{" "}
                              {dataCount + " results found"}
                            </li>
                            <li>
                              <button
                                type="button"
                                id="DoubleDown"
                                onClick={() => {
                                  setFiltersVisible(false);
                                  document.getElementById(
                                    "sectionFilters"
                                  ).style.display = "none";
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faAngleDoubleUp}
                                  id="arrow"
                                />
                              </button>
                              <p id="scrollDownNote">Hide filters</p>
                            </li>
                          </ul>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </div>
              </form>
            </div>
          </div>
          
        </section>
        {!filtersVisible ? (
          <div
            id="filtersMinimized"
          >
          <ul
            align="left"
            id="filtersMinimizedUl"
          >
            <li>
              <FontAwesomeIcon icon={faCheck} id="tick" /> {dataCount}
            </li>
            <li>
              <button
                type="button"
                id="DoubleDown"
                
                onClick={() => {
                  setFiltersVisible(true);
                  document.getElementById("sectionFilters").style.display =
                    "block";
                }}
              >
                <FontAwesomeIcon icon={faAngleDoubleDown} id="arrow" />
              </button>
              <p id="scrollDownNote">Show filters</p>
            </li>
          </ul>
        </div>) : (<></>)}
        
        <section id="tableSection">
          <div id="table" style={{ paddingTop: 50 }}>
            {!searchSubmit ? (
              ""
            ) : searchResult.length == 0 ? (
              ""
            ) : (
              <>

                <ReactionTable
                  body={searchResult}
                  display={"none"}
                  commentFunction={submitSuggestComm}
                  auth={auth}
                  seeAllReactions={seeAllReactions}
                  windowSize={windowSize}
                />

                <ul
                  style={{
                    listStyle: "none",
                    padding: 0,
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop:50
                  }}
                >
                  <li></li>
                  <li style={{fontSize:15}}>
                  <PageCounter
                  
                    dataCount={dataCount}
                    buttonCount={buttonCount}
                    setButtonCount={setButtonCount}
                    pageCount={pageCount}
                    setPageCount={setPageCount}
                    stateButtonCount={state?.buttonCount}
                    statePageCount={state?.pageCount}
                    setNewSearch={setNewSearch}
                    newSearch={newSearch}
                    setCount={setCount}
                  />
                  </li>
                  <li></li>
                </ul>
              </>
            )}
          </div>
        </section>
        <div id="footer">
          <Footer />
        </div>
      </body>
      <div
        id="overlap"
        style={{
          display: "none",
          position: "fixed",
          top: 0,
          left: 0,
          overflow: "scroll",
        }}
      >
        <div
          id="mechDiv"
          style={{ marginLeft: 150, marginRight: 165, display: "none" }}
        >
          <MechanismTable
            heading={[
              "Intermediate substrate",
              "Intermediate product",
              "Mechanism type",
            ]}
            body={mechBody}
          />
        </div>
      </div>
    </html>
  );
}

export default SearchReaction;
