import useAuth from "./useAuth.js";
import axios from "../api/axios.js";

function useRefreshToken() {
  const { setAuth } = useAuth();

  async function refresh() {
    const response = await axios.get("/refresh",{}, {
      withCredentials: true,
    });
    setAuth({
      username: response.data.username,
      accessToken: response.data.accessToken,
      roles: response.data.user_role
    });
    return response.data.accessToken
  }
  return refresh;
}

export default useRefreshToken;
