import React from "react";
import axios from "../api/axios.js";
import "./Home.css";
import Footer from "./Footer.js";
import HomeNavbar from "../HomeNavbar.js";
import { useState } from "react";
import { useEffect } from "react";
import ReactionTable from "../reactionTable.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PageCounter from "./pageCounter.js";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleDoubleUp,
  faAngleDoubleDown,
  faCheck,
  faExclamationCircle,
  faBook,
  faBrain,
  faClone,
  faCalculator,
  faFlask,
} from "@fortawesome/free-solid-svg-icons";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { useLocation } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate.js";
import useAuth from "../hooks/useAuth.js";
import MechanismTable from "../mechanismTable.js";
import quickSearch from "../api/quickSearch.js";
import UniprotLink from "../Components/uniprotLink.js";
import GenbankLink from "../Components/genbankLink.js";
import SubstrateRow from "../Components/substrateRow.js";
import ProductRow from "../Components/productRow.js";
import KingdomIcon from "../Components/kingdomIcon.js";
import CopySequence from "../Components/copySequence.js";
import TypeClass from "../Components/typeClass.js";
import findMechanism from "../api/findMechanism.js";
import { CountUp } from "use-count-up";
import CosubstrateRow from "../Components/cosubstrateRow.js";

function Home() {
  let [count, setCount] = useState(0);
  const [windowSize, setWindowSize] = useState(getWindowSize().innerWidth);
  const [distinctEnzymeCount, setDistinctEnzymeCount] = useState(1335);
  const [distinctReactionCount, setDistinctReactionCount] = useState(2580);
  const [distinctMechanismCount, setDistinctMechanismCount] = useState(1736);
  const [clearClicked, setClearClicked] = useState(false);
  const [firstSearch, setFirstSearch] = useState(true);
  const [mechBody, setMechBody] = useState([]);
  const [table, setTable] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [buttonCount, setButtonCount] = useState(1);
  const [dataCount, setDataCount] = useState(0);
  const [newSearch, setNewSearch] = useState(false);
  const [quickSearchValue, setQuickSearchValue] = useState("");
  const [seeAllReactions, setSeeAllReactions] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [onlyMechanism, setOnlyMechanism] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { state } = useLocation();
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();

  function getWindowSize() {
    const { innerWidth } = window;
    return { innerWidth };
  }

  async function interactiveSearch(value) {
    try {
      setQuickSearchValue(value);
      if (pageCount === 1) {
        setFirstSearch(true);
        setButtonCount(1);
        setCount((count = 0));
        const result = await quickSearch(
          seeAllReactions,
          setIsLoading,
          count,
          onlyMechanism,
          value,
          pageCount,
          setDataCount
        );
        setTable(result?.table);
        renderData(result?.table);
      } else {
        setFirstSearch(true);
        setPageCount(1);
        setButtonCount(1);
        setCount((count = 0));
      }
    } catch (err) {
      throw err;
    }
  }

  async function findingMechanism(table, row) {
    let foundMechanism = [];
    foundMechanism = await findMechanism({
      table,
      row,
      count,
    });
    setMechBody(foundMechanism.mechanismBody);
    document.getElementById("overlap").style.display = "block";
    document.getElementById("mechDiv").style.display = "block";
    const elements = document.querySelectorAll("#body *");
    elements.forEach((element) => {
      element.style.filter = "blur(3px)";
    });
  }

  async function loadData() {
    const result = await quickSearch(
      seeAllReactions,
      setIsLoading,
      count,
      onlyMechanism,
      quickSearchValue,
      pageCount,
      setDataCount
    );
    const table = result?.table;
    setTable(table);
    renderData(table);
  }

  async function renderData(table) {
    let body = [];
    for (let row = 0; row < table?.length; row++) {
      body.push([
        table[row].name.split("_")[0] === "missing" || !table[row].name ? (
          <>
            <TypeClass
              table={table}
              row={row}
              seeAllReactions={seeAllReactions}
              setIsLoading={setIsLoading}
              count={count}
              onlyMechanism={onlyMechanism}
              pageCount={pageCount}
              setDataCount={setDataCount}
              setQuickSearchValue={setQuickSearchValue}
              search={quickSearch}
              renderData={renderData}
              setPageCount={setPageCount}
              setButtonCount={setButtonCount}
              setCount={setCount}
              setFirstSearch={setFirstSearch}
            />
            <UniprotLink
              tableRow={table[row]}
              seeAllReactions={seeAllReactions}
              setIsLoading={setIsLoading}
              count={count}
              onlyMechanism={onlyMechanism}
              pageCount={pageCount}
              setDataCount={setDataCount}
              setQuickSearchValue={setQuickSearchValue}
              search={quickSearch}
              renderData={renderData}
              setPageCount={setPageCount}
              setButtonCount={setButtonCount}
              setCount={setCount}
              setFirstSearch={setFirstSearch}
            />
            <GenbankLink
              tableRow={table[row]}
              seeAllReactions={seeAllReactions}
              setIsLoading={setIsLoading}
              count={count}
              onlyMechanism={onlyMechanism}
              pageCount={pageCount}
              setDataCount={setDataCount}
              setQuickSearchValue={setQuickSearchValue}
              search={quickSearch}
              renderData={renderData}
              setPageCount={setPageCount}
              setButtonCount={setButtonCount}
              setCount={setCount}
              setFirstSearch={setFirstSearch}
            />

            <CopySequence table={table} row={row} />
            <KingdomIcon
              tableRow={table[row]}
              seeAllReactions={seeAllReactions}
              setIsLoading={setIsLoading}
              count={count}
              onlyMechanism={onlyMechanism}
              pageCount={pageCount}
              setDataCount={setDataCount}
              setQuickSearchValue={setQuickSearchValue}
              search={quickSearch}
              renderData={renderData}
              setPageCount={setPageCount}
              setButtonCount={setButtonCount}
              setCount={setCount}
              setFirstSearch={setFirstSearch}
            />
          </>
        ) : (
          <>
            <TypeClass
              table={table}
              row={row}
              seeAllReactions={seeAllReactions}
              setIsLoading={setIsLoading}
              count={count}
              onlyMechanism={onlyMechanism}
              pageCount={pageCount}
              setDataCount={setDataCount}
              setQuickSearchValue={setQuickSearchValue}
              search={quickSearch}
              renderData={renderData}
              setPageCount={setPageCount}
              setButtonCount={setButtonCount}
              setCount={setCount}
              setFirstSearch={setFirstSearch}
            />
            <button
              id="enzNameQuickSearch"
              style={{ border: "none", backgroundColor: "rgb(245,245,245)" }}
              onClick={() => {
                interactiveSearch(table[row].name);
              }}
            >
              {table[row].name}
            </button>
            <br />
            <UniprotLink
              tableRow={table[row]}
              seeAllReactions={seeAllReactions}
              setIsLoading={setIsLoading}
              count={count}
              onlyMechanism={onlyMechanism}
              pageCount={pageCount}
              setDataCount={setDataCount}
              setQuickSearchValue={setQuickSearchValue}
              search={quickSearch}
              renderData={renderData}
              setPageCount={setPageCount}
              setButtonCount={setButtonCount}
              setCount={setCount}
              setFirstSearch={setFirstSearch}
            />
            <GenbankLink
              tableRow={table[row]}
              seeAllReactions={seeAllReactions}
              setIsLoading={setIsLoading}
              count={count}
              onlyMechanism={onlyMechanism}
              pageCount={pageCount}
              setDataCount={setDataCount}
              setQuickSearchValue={setQuickSearchValue}
              search={quickSearch}
              renderData={renderData}
              setPageCount={setPageCount}
              setButtonCount={setButtonCount}
              setCount={setCount}
              setFirstSearch={setFirstSearch}
            />
            <CopySequence table={table} row={row} />
            <KingdomIcon
              tableRow={table[row]}
              seeAllReactions={seeAllReactions}
              setIsLoading={setIsLoading}
              count={count}
              onlyMechanism={onlyMechanism}
              pageCount={pageCount}
              setDataCount={setDataCount}
              setQuickSearchValue={setQuickSearchValue}
              search={quickSearch}
              renderData={renderData}
              setPageCount={setPageCount}
              setButtonCount={setButtonCount}
              setCount={setCount}
              setFirstSearch={setFirstSearch}
            />
          </>
        ),

        table[row].cosubstrates[0]?.smiles ? (
          <CosubstrateRow
            table={table}
            row={row}
            seeAllReactions={seeAllReactions}
            setIsLoading={setIsLoading}
            count={count}
            onlyMechanism={onlyMechanism}
            pageCount={pageCount}
            setDataCount={setDataCount}
            setQuickSearchValue={setQuickSearchValue}
            search={quickSearch}
            renderData={renderData}
            setPageCount={setPageCount}
            setButtonCount={setButtonCount}
            setCount={setCount}
            setFirstSearch={setFirstSearch}
            interactiveSearch={interactiveSearch}
          />
        ) : table[row].substrate_image ? (
          <>
            <button
              style={{ border: "none", backgroundColor: "rgb(245,245,245)" }}
            >
              <img
                src={table[row].substrate_image}
                alt={table[row].substrate_smiles}
                className="responsive"
                onClick={() => {
                  interactiveSearch(table[row].substrate_smiles);
                }}
              />
            </button>
            <br />
            <SubstrateRow
              table={table}
              row={row}
              seeAllReactions={seeAllReactions}
              setIsLoading={setIsLoading}
              count={count}
              onlyMechanism={onlyMechanism}
              pageCount={pageCount}
              setDataCount={setDataCount}
              setQuickSearchValue={setQuickSearchValue}
              search={quickSearch}
              renderData={renderData}
              setPageCount={setPageCount}
              setButtonCount={setButtonCount}
              setCount={setCount}
              setFirstSearch={setFirstSearch}
            />
          </>
        ) : (
          <>
            {table[row].substrate_smiles}
            <SubstrateRow
              table={table}
              row={row}
              seeAllReactions={seeAllReactions}
              setIsLoading={setIsLoading}
              count={count}
              onlyMechanism={onlyMechanism}
              pageCount={pageCount}
              setDataCount={setDataCount}
              setQuickSearchValue={setQuickSearchValue}
              search={quickSearch}
              renderData={renderData}
              setPageCount={setPageCount}
              setButtonCount={setButtonCount}
              setCount={setCount}
              setFirstSearch={setFirstSearch}
            />
            <br />
          </>
        ),
        table[row].product_image ? (
          <>
            <button
              style={{ border: "none", backgroundColor: "rgb(245,245,245)" }}
            >
              <img
                src={table[row].product_image}
                className="responsive"
                onClick={() => {
                  interactiveSearch(table[row].product_smiles);
                }}
              />
            </button>
            <br />
            <ProductRow
              table={table}
              row={row}
              seeAllReactions={seeAllReactions}
              setIsLoading={setIsLoading}
              count={count}
              onlyMechanism={onlyMechanism}
              pageCount={pageCount}
              setDataCount={setDataCount}
              setQuickSearchValue={setQuickSearchValue}
              search={quickSearch}
              renderData={renderData}
              setPageCount={setPageCount}
              setButtonCount={setButtonCount}
              setCount={setCount}
              setFirstSearch={setFirstSearch}
            />
          </>
        ) : (
          <>
            {table[row].product_smiles} <br />
            <ProductRow
              table={table}
              row={row}
              seeAllReactions={seeAllReactions}
              setIsLoading={setIsLoading}
              count={count}
              onlyMechanism={onlyMechanism}
              pageCount={pageCount}
              setDataCount={setDataCount}
              setQuickSearchValue={setQuickSearchValue}
              search={quickSearch}
              renderData={renderData}
              setPageCount={setPageCount}
              setButtonCount={setButtonCount}
              setCount={setCount}
              setFirstSearch={setFirstSearch}
            />
          </>
        ),

        <>
          <div
            style={{
              position: "relative",
              maxWidth: "fit-content",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            Reaction: <br />
            <a
              id="reactionPublication"
              style={{ position: "relative" }}
              href={`${table[row].reaction_link}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faBook} color="rgb(0,0,0,0.8)" />
              <div id="goToReactionPublication">
                <nobr>See publication</nobr>
              </div>
            </a>
          </div>
          <br />

          <div
            style={{
              position: "relative",
              maxWidth: "fit-content",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            {table[row].mechanism_id ? (
              <>
                Mechanism:
                <br />
                <nobr style={{ padding: 0 }}>
                  {(table[row].mechanism_evidence === "nan" ||
                    table[row].mechanism_evidence === "TRUE" ||
                    table[row].mechanism_evidence === "trivial") &&
                  table[row].mechanism_id ? (
                    <>
                      <FontAwesomeIcon
                        icon={faBrain}
                        color="rgb(0,0,0,0.8)"
                        id="seeEvizens"
                      />

                      <div
                        id={
                          seeAllReactions
                            ? "terezaEvizensTwoCol"
                            : "terezaEvizens"
                        }
                      >
                        Mechanism was based on other known <br /> mechanisms and
                        chemical logic.
                      </div>
                    </>
                  ) : table[row].mechanism_evidence === "similarity" ? (
                    <>
                      <FontAwesomeIcon
                        icon={faClone}
                        color="rgb(0,0,0,0.8)"
                        id="seeEvizens"
                      />

                      <div
                        id={
                          seeAllReactions
                            ? "terezaEvizensTwoCol"
                            : "terezaEvizens"
                        }
                      >
                        Mechanism was deduced using analogy <br /> and
                        similarity to other mechanisms.
                      </div>
                    </>
                  ) : table[row].mechanism_evidence === "calculation" ? (
                    <>
                      <FontAwesomeIcon
                        icon={faCalculator}
                        color="rgb(0,0,0,0.8)"
                        id="seeEvizens"
                      />
                      <div
                        id={
                          seeAllReactions
                            ? "terezaEvizensTwoCol"
                            : "terezaEvizens"
                        }
                      >
                        Mechanism was described <br /> using calculation
                        methods.
                      </div>
                    </>
                  ) : table[row].mechanism_evidence === "experiment" ? (
                    <>
                      <FontAwesomeIcon
                        icon={faFlask}
                        color="rgb(0,0,0,0.8)"
                        id="seeEvizens"
                      />
                      <div
                        id={
                          seeAllReactions
                            ? "terezaEvizensTwoCol"
                            : "terezaEvizens"
                        }
                      >
                        Mechanism was discovered by <br /> conducting chemical
                        experiments
                      </div>
                    </>
                  ) : (
                    <></>
                  )}{" "}
                  <button
                    id="entriesButton"
                    style={{ padding: 0, marginRight: 5 }}
                    onClick={() => findingMechanism(table, row)}
                  >
                    View
                  </button>
                  <a
                    id="mechanismPublication"
                    style={{ position: "relative" }}
                    href={`${table[row].mechanism_link}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faBook} color="rgb(0,0,0,0.8)" />
                    <div id="goToMechanismPublication">
                      <nobr>See publication</nobr>
                    </div>
                  </a>
                </nobr>
              </>
            ) : (
              <></>
            )}
          </div>
        </>,
      ]);
    }
    setSearchResult(body);
    setIsLoading(false);
    setFirstSearch(false);
  }

  async function submitSuggestComm(row, col) {
    let radioValue;
    const idArray = [
      `enzymeGenbankComment${row}`,
      `enzymeUniprotComment${row}`,
      `enzymeKingdomComment${row}`,
      `enzymeSpeciesComment${row}`,
      `enzymeClassComment${row}`,
      `enzymeTypeComment${row}`,
      `enzymeNameComment${row}`,
      `enzymeAASequenceComment${row}`,
      `substrateNameComment${row}`,
      `substrateChebiComment${row}`,
      `substrateImageComment${row}`,
      `substrateSmilesComment${row}`,
      `productNameComment${row}`,
      `productChebiComment${row}`,
      `productImageComment${row}`,
      `productSmilesComment${row}`,
    ];

    for (let i = 0; i < idArray.length; i++) {
      if (document.getElementById(idArray[i]).checked) {
        radioValue = document.getElementById(idArray[i]).value;
      }
    }

    const inputValueComment = document.getElementById(
      `suggestInputComm${row}${col}`
    ).value;
    document.getElementById(
      `innerTextSubmittedComm${row}${col}`
    ).style.display = "block";
    document.getElementById(`innerTextSubmitComm${row}${col}`).style.display =
      "none";

    try {
      await axiosPrivate.post("/postcomment", {
        inputValueComment: inputValueComment,
        enzyme_id: table[row].enzyme_id,
        reaction_id: table[row].reaction_id,
        substrate_id: table[row].substrate,
        product_id: table[row].product,
        RE_pair: table[row].reaction_enzyme_pair_id,
        mechanism_id: table[row].mechanism_id,
        subject: radioValue,
        username: auth?.username,
      });
    } catch (err) {
      throw err;
    } finally {
      setTimeout(() => {
        document.getElementById(`suggestDivComm${row}${col}`).style.display =
          "none";
        document.getElementById(
          `innerTextSubmittedComm${row}${col}`
        ).style.display = "none";
        document.getElementById(
          `innerTextSubmitComm${row}${col}`
        ).style.display = "block";
      }, 1000);
    }
  }

  async function OnlyMechanism() {
    document.getElementById("notonlymechanism").style.display = "none";
    document.getElementById("onlymechanism").style.display = "block";
    setOnlyMechanism(true);
    setPageCount(1);
    setButtonCount(1);
  }

  async function NotOnlyMechanism() {
    document.getElementById("notonlymechanism").style.display = "block";
    document.getElementById("onlymechanism").style.display = "none";
    setOnlyMechanism(false);
    setButtonCount(1);
    setPageCount(1);
  }

  async function seeAll() {
    document.getElementById("hideall").style.display = "block";
    document.getElementById("seeall").style.display = "none";
    // document.getElementById("scrollprevious").disabled = true;
    // document.getElementById("scrollnext").disabled = true;
    setSeeAllReactions(true);
  }

  async function hideAll() {
    count == 0
      ? (document.getElementById("scrollprevious").disabled = true)
      : (document.getElementById("scrollprevious").disabled = false);
    document.getElementById("scrollnext").disabled = false;
    document.getElementById("hideall").style.display = "none";
    document.getElementById("seeall").style.display = "block";
    setSeeAllReactions(false);
  }

  async function distinctEnzymesCount() {
    try {
      const response = await axios.post("/stats");
      setDistinctEnzymeCount(response.data.enzymeCount[0].enzymeCount);
      setDistinctMechanismCount(response.data.mechanismCount[0].mechanismCount);
      setDistinctReactionCount(response.data.reactionCount[0].reactionCount);
    } catch (err) {
      throw err;
    }
  }

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize().innerWidth);
    }

    window.addEventListener("resize", handleWindowResize);

    distinctEnzymesCount();
    loadData();

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [onlyMechanism, seeAllReactions, pageCount, clearClicked]);

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css"
          rel="stylesheet"
        />
      </head>
      <body id="body">
        <HomeNavbar
          setClearClicked={setClearClicked}
          setQuickSearchValue={setQuickSearchValue}
          setFirstSearch={setFirstSearch}
          setPageCount={setPageCount}
          setButtonCount={setButtonCount}
          pageCount={pageCount}
          setCount={setCount}
        />

        <div id="backgroundHome">
          <h6 id="databaseTitle" align="center">
            MARTS-DB is a freely available database of terpene synthases and their
            reaction mechanisms. <br />
            Currently, we store exactly{" "}
            <CountUp isCounting start={0} end={distinctEnzymeCount} /> unique
            enzymes which catalyze{" "}
            <CountUp isCounting start={0} end={distinctReactionCount} />{" "}
            reactions with{" "}
            <CountUp isCounting start={0} end={distinctMechanismCount} /> of
            them having their own mechanism.
          </h6>
          <h1 id="quickSearchTitle" align="center">
            Find your reaction
          </h1>
          <div id="quickSearchInput" align="left">
            <form>
              <ul
                style={{
                  listStyle: "none",
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "0rem",
                  padding: 0,
                  margin: "0 auto",
                }}
              >
                <li>
                  <button
                    type="button"
                    id="quicksearchbutton"
                    disabled={quickSearchValue?.length == 0 ? true : false}
                    style={{ position: "relative", marginTop: 10 }}
                    onClick={async () => {
                      setQuickSearchValue("");
                      setFirstSearch(true);
                      setCount((count = 0));
                      if (pageCount === 1) {
                        setClearClicked((current) => !current);
                      } else {
                        setPageCount(1);
                        setButtonCount(1);
                      }
                    }}
                  >
                    Clear
                  </button>
                </li>

                <input
                  id="quicksearch"
                  placeholder="Search"
                  style={{
                    border: "none",
                    backgroundColor: "rgb(245,245,245)",
                    outline: "none",
                  }}
                  onChange={(event) => {
                    setQuickSearchValue(event.target.value);
                    setFirstSearch(true);
                  }}
                  onFocus={() => {
                    document.getElementById("quicksearch").style.boxShadow =
                      "0 6px 20px 0 rgba(255, 255, 255, 0.25), 0 6px 20px 0 rgba(255, 255, 255, 0.25)";
                  }}
                  onBlur={() => {
                    document.getElementById("quicksearch").style.boxShadow =
                      "0 0px 0px 0 rgba(255, 255, 255, 0.0), 0 0px 0px 0 rgba(255, 255, 255, 0.0)";
                  }}
                  value={quickSearchValue}
                />

                <li>
                  <button
                    id="quicksearchbutton"
                    style={{ marginTop: 10 }}
                    onClick={(event) => {
                      loadData();
                      setPageCount(1);
                      setButtonCount(1);
                      setCount((count = 0));
                      event.preventDefault();
                    }}
                  >
                    Submit
                  </button>
                </li>
              </ul>
              <p id="quickSearchExamples">
                Examples:{" "}
                <button
                  style={{
                    border: "none",
                    backgroundColor: "rgb(0,0,0,0.0)",
                    color: "white",
                    fontWeight: "bold",
                    padding: 0,
                  }}
                  onClick={(event) => {
                    event.preventDefault();
                    interactiveSearch("FPP");
                  }}
                >
                  FPP
                </button>
                ,{" "}
                <button
                  style={{
                    border: "none",
                    backgroundColor: "rgb(0,0,0,0.0)",
                    color: "white",
                    fontWeight: "bold",
                    padding: 0,
                  }}
                  onClick={(event) => {
                    event.preventDefault();
                    interactiveSearch("Diterpene synthase");
                  }}
                >
                  Diterpene synthase
                </button>
                ,{" "}
                <button
                  style={{
                    border: "none",
                    backgroundColor: "rgb(0,0,0,0.0)",
                    color: "white",
                    fontWeight: "bold",
                    padding: 0,
                  }}
                  onClick={(event) => {
                    event.preventDefault();
                    interactiveSearch("coral");
                  }}
                >
                  coral
                </button>
                ,{" "}
                <button
                  style={{
                    border: "none",
                    backgroundColor: "rgb(0,0,0,0.0)",
                    color: "white",
                    fontWeight: "bold",
                    padding: 0,
                  }}
                  onClick={(event) => {
                    event.preventDefault();
                    interactiveSearch("W6HUT3");
                  }}
                >
                  W6HUT3
                </button>
                ,{" "}
                <button
                  style={{
                    border: "none",
                    backgroundColor: "rgb(0,0,0,0.0)",
                    color: "white",
                    fontWeight: "bold",
                    padding: 0,
                  }}
                  onClick={(event) => {
                    event.preventDefault();
                    interactiveSearch("germacrene");
                  }}
                >
                  germacrene
                </button>
                ,{" "}
                <button
                  style={{
                    border: "none",
                    backgroundColor: "rgb(0,0,0,0.0)",
                    color: "white",
                    fontWeight: "bold",
                    padding: 0,
                  }}
                  onClick={(event) => {
                    event.preventDefault();
                    interactiveSearch("CC1=CCC2(C(C)C)CC12");
                  }}
                >
                  CC1=CCC2(C(C)C)CC12
                </button>
              </p>
            </form>
          </div>
        </div>

        <section id="quickSearchResult">
          <ul id="nextResultsPrevious">
            <li>
              <nobr>
                <button
                  disabled={count == 0 ? true : false}
                  id="scrollprevious"
                  onClick={() => {
                    setCount((count = count - 1));
                    if (seeAllReactions === false) {
                      loadData();
                    } else {
                      setButtonCount(Number(buttonCount) - 1);
                    }
                  }}
                >
                  <FontAwesomeIcon icon={faAngleDoubleLeft} /> Previous
                </button>
              </nobr>
            </li>
            <li>
              {isLoading && firstSearch ? (
                <>
                  <Spin
                    indicator={
                      <LoadingOutlined
                        style={{ color: "rgb(23, 55, 151)" }}
                        spin
                      />
                    }
                  />{" "}
                  Searching for results, please wait...
                </>
              ) : dataCount === 0 ? (
                <></>
              ) : (
                <>
                  <FontAwesomeIcon icon={faCheck} color="green" /> {dataCount}{" "}
                  results found
                </>
              )}
            </li>
            <li>
              <nobr>
                <button
                  disabled={
                    count >= dataCount - 1 ||
                    (seeAllReactions && dataCount < 12)
                      ? true
                      : false
                  }
                  id="scrollnext"
                  onClick={() => {
                    setCount((count = count + 1));
                    if (seeAllReactions === false) {
                      loadData();
                    } else {
                      setPageCount(Number(pageCount) + 1);
                      setButtonCount(Number(buttonCount) + 1);
                    }
                  }}
                >
                  Next <FontAwesomeIcon icon={faAngleDoubleRight} />
                </button>
              </nobr>
            </li>
          </ul>
          <div id="hometable">
            {isLoading ? (
              <div
                style={{
                  textAlign: "center",
                  backgroundSize: "cover",
                  padding: 154,
                }}
              >
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{ fontSize: 50, color: "rgb(23, 55, 151)" }}
                      spin
                    />
                  }
                />
              </div>
            ) : searchResult?.length == 0 ? (
              <>
                <div id="noresults">
                  <h1>
                    <FontAwesomeIcon icon={faExclamationCircle} color="red" />
                  </h1>
                  <h1>No results found!</h1>
                  <p>
                    There are no reactions that match your current search. Try
                    updating it for better results.{" "}
                  </p>
                </div>
              </>
            ) : (
              <ReactionTable
                body={searchResult}
                display={"none"}
                commentFunction={submitSuggestComm}
                auth={auth}
                seeAllReactions={seeAllReactions}
                windowSize={windowSize}
              />
            )}
            <ul id="quickSearchDisplayAllResults">
              <li>
                <button
                  id="notonlymechanism"
                  onClick={() => {
                    OnlyMechanism();
                    setFirstSearch(true);
                  }}
                  disabled={searchResult?.length == 0 ? true : false}
                >
                  {" "}
                  Find reactions <br /> with mechanism
                </button>
                <button
                  id="onlymechanism"
                  disabled={searchResult?.length == 0 ? true : false}
                  style={{ display: "none" }}
                  onClick={() => {
                    NotOnlyMechanism();
                    setFirstSearch(true);
                  }}
                >
                  <FontAwesomeIcon icon={faCheck} color="green" /> Find
                  reactions <br /> with mechanism
                </button>
              </li>
              <li>
                <button
                  id="seeall"
                  onClick={seeAll}
                  disabled={searchResult?.length == 0 ? true : false}
                >
                  <FontAwesomeIcon icon={faAngleDoubleDown} /> Display more{" "}
                  <br />
                  reactions
                </button>
                <button
                  id="hideall"
                  disabled={searchResult?.length == 0 ? true : false}
                  style={{ display: "none" }}
                  onClick={hideAll}
                >
                  <FontAwesomeIcon icon={faAngleDoubleUp} /> Hide all <br />{" "}
                  reactions
                </button>
              </li>
            </ul>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {dataCount == 0 ? (
                ""
              ) : seeAllReactions ? (
                <PageCounter
                  dataCount={dataCount}
                  buttonCount={buttonCount}
                  setButtonCount={setButtonCount}
                  pageCount={pageCount}
                  setPageCount={setPageCount}
                  stateButtonCount={state?.buttonCount}
                  statePageCount={state?.pageCount}
                  setNewSearch={setNewSearch}
                  newSearch={newSearch}
                  setCount={setCount}
                  count={count}
                />
              ) : (
                <>
                  {count + 1} of {dataCount}
                </>
              )}
            </div>
          </div>
        </section>
        <div className="row">
          <div className="col-sm-12">
            <div id="Information">
              <h3>About this project</h3>
              <p>
                Database of Mechanisms And Reactions of Terpene Synthases (MARTS-DB) is a
                project founded at the Institute of Organic Chemistry and
                Biochemistry in Prague with the aim of cataloging all known
                terpene synthases, their reactions and reaction mechanisms.{" "}
              </p>
              <p>
                The project originaly started as an internal datataset built to
                train predictive machine learning models. Today the data
                gathered can be browsed and also expanded by a wide community.
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </body>
      <div
        id="overlap"
        style={{
          display: "none",
          position: "fixed",
          top: 0,
          left: 0,
          overflow: "scroll",
        }}
      >
        <div id="mechDiv" style={{ display: "none" }}>
          <MechanismTable
            heading={[
              "Intermediate substrate",
              "Intermediate product",
              "Mechanism type",
            ]}
            body={mechBody}
          />
        </div>
      </div>
    </html>
  );
}

export default Home;
