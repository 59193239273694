import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSeedling,
  faBacterium,
  faOtter,
  faVirus,
  faMale,
  faBug,
  faDisease,
} from "@fortawesome/free-solid-svg-icons";
import { GiSuperMushroom, GiCoral, GiAlgae } from "react-icons/gi";
import { LuBird } from "react-icons/lu";
import marineSpongeImg from "./icons/marine_sponge.svg";
import archaeaImg from "./icons/archaea.png";
import cyanobacteriaImg from "./icons/cyanobacteria.png";

function KingdomIcon({
  tableRow,
  seeAllReactions,
  setIsLoading,
  count,
  onlyMechanism,
  pageCount,
  setDataCount,
  setQuickSearchValue,
  search,
  renderData,
  setPageCount,
  setButtonCount,
  setCount,
  ///////////////////////////////////////////////////////////////FOR REACTION SEARCH//////////////////////////////////////////////////
  buttonCount,
  animaliaBird,
  animaliaCoral,
  animaliaInsect,
  animaliaMammal,
  animaliaMammalHuman,
  animaliaMarineSponge,
  fungi,
  bacteria,
  archaea,
  amoebozoa,
  plantae,
  plantaeRedAlgae,
  cyanobacteria,
  viruses,
  setAnimaliaBird,
  setAnimaliaCoral,
  setAnimaliaInsect,
  setAnimaliaMammal,
  setAnimaliaMammalHuman,
  setAnimaliaMarineSponge,
  setFungi,
  setBacteria,
  setArchaea,
  setAmoebozoa,
  setPlantae,
  setPlantaeRedAlgae,
  setCyanobacteria,
  setViruses,
  name,
  terpType,
  setKingdom,
  enzClass,
  uniprot,
  genbank,
  reactSmiles,
  reactChebi,
  reactName,
  prodChebi,
  prodName,
  prodSmiles,
  setSearchSubmit,
  showInput,
  enzymeInputClicked,
  setEnzymeInputClicked,
  setEnzymeInputUnclicked,
  setFirstSearch,
  ///////////////////////////////////////////////////////////////FOR MECHANISM SEARCH//////////////////////////////////////////////////
  evidence,
  mechanismUnclicked,
  intermediateSmiles
}) {


  //////////////////////////////////////////////////// FIRST FUNCTION ONLY FOR QUICKSEARCH ///////////////////////////////////////////
  const findKingdom = async (kingdom) => {
    try {
      setButtonCount(1)
      setCount((count = 0))
      setFirstSearch(true)
      setQuickSearchValue(kingdom);
      if (pageCount === 1) {
        const result = await search(
          seeAllReactions,
          setIsLoading,
          count,
          onlyMechanism,
          kingdom,
          pageCount,
          setDataCount,
        );
        renderData(result?.table);
      } else {
        setPageCount(1)
        setButtonCount(1)
        setCount((count = 0))
      }
    } catch (err) {
      throw err;
    }
  };


  //////////////////////////////////////////////////// SECOND FUNCTION ONLY FOR ADVANCED SEARCH ///////////////////////////////////////////
  const findKingdomAdvanced = async (kingdom,setSpecificKingdom,idInput) => {
    try {
      setButtonCount(1)
      setFirstSearch(true)
      if (!enzymeInputClicked) {
        showInput(
          [
            "NameInput",
            "TypeInput",
            "ClassInput",
            "UniprotInput",
            "GenbankInput",
            "KingdomInput"
          ],
          "enzymeDD",
          enzymeInputClicked,
          setEnzymeInputClicked,
          "EnzymeInputs",
          setEnzymeInputUnclicked
        );
      }
      document.getElementById(idInput).checked = true
      setSpecificKingdom(kingdom);
      setKingdom({
        animaliaMammal: kingdom === "Animalia (Mammal)" ? "Animalia (Mammal)" : animaliaMammal,
        animaliaCoral: kingdom === "Animalia (Coral)" ? "Animalia (Coral)" : animaliaCoral,
        animaliaBird: kingdom === "Animalia (Bird)" ? "Animalia (Bird)" : animaliaBird,
        animaliaMarineSponge: kingdom === "Animalia (Marine Sponge)" ? "Animalia (Marine Sponge)" : animaliaMarineSponge,
        animaliaInsect: kingdom === "Animalia (Insect)" ? "Animalia (Insect)" : animaliaInsect,
        animaliaMammalHuman: kingdom === "Animalia (Mammal, Human)" ? "Animalia (Mammal, Human)" : animaliaMammalHuman,
        fungi: kingdom === "Fungi" ? "Fungi" : fungi,
        viruses: kingdom === "Viruses" ? "Viruses" : viruses,
        bacteria: kingdom === "Bacteria" ? "Bacteria" : bacteria,
        cyanobacteria: kingdom === "Cyanobacteria" ? "Cyanobacteria" : cyanobacteria,
        plantaeRedAlgae: kingdom === "Plantae (Red algae)" ? "Plantae (Red algae)" : plantaeRedAlgae,
        amoebozoa: kingdom === "Amoebozoa" ? "Amoebozoa" : amoebozoa,
        archaea: kingdom=== "Archaea" ? "Archaea" : archaea,
        plantae: kingdom === "Plantae" ? "Plantae" : plantae,
    });
      if (pageCount === 1) {
        const result = await search(
          name,
          terpType,
          {
            animaliaMammal: kingdom === "Animalia (Mammal)" ? "Animalia (Mammal)" : animaliaMammal,
            animaliaCoral: kingdom === "Animalia (Coral)" ? "Animalia (Coral)" : animaliaCoral,
            animaliaBird: kingdom === "Animalia (Bird)" ? "Animalia (Bird)" : animaliaBird,
            animaliaMarineSponge: kingdom === "Animalia (Marine Sponge)" ? "Animalia (Marine Sponge)" : animaliaMarineSponge,
            animaliaInsect: kingdom === "Animalia (Insect)" ? "Animalia (Insect)" : animaliaInsect,
            animaliaMammalHuman: kingdom === "Animalia (Mammal, Human)" ? "Animalia (Mammal, Human)" : animaliaMammalHuman,
            fungi: kingdom === "Fungi" ? "Fungi" : fungi,
            viruses: kingdom === "Viruses" ? "Viruses" : viruses,
            bacteria: kingdom === "Bacteria" ? "Bacteria" : bacteria,
            cyanobacteria: kingdom === "Cyanobacteria" ? "Cyanobacteria" : cyanobacteria,
            plantaeRedAlgae: kingdom === "Plantae (Red algae)" ? "Plantae (Red algae)" : plantaeRedAlgae,
            amoebozoa: kingdom === "Amoebozoa" ? "Amoebozoa" : amoebozoa,
            archaea: kingdom=== "Archaea" ? "Archaea" : archaea,
            plantae: kingdom === "Plantae" ? "Plantae" : plantae,
        },
          enzClass,
          uniprot,
          genbank,
          reactSmiles,
          reactChebi,
          reactName,
          prodChebi,
          prodName,
          prodSmiles,
          pageCount,
          buttonCount,
          setIsLoading,
          setSearchSubmit,
          setDataCount,
          evidence,
          mechanismUnclicked,
          intermediateSmiles
        );
        renderData(result);
      } else {
        setPageCount(1)
        setButtonCount(1)
      }
    } catch (err) {
      throw err;
    }
  };

  return (
    <>
      {tableRow.kingdom === "Plantae" ? (
        <nobr style={{ position: "relative" }}>
          <button
            style={{ border: "none", backgroundColor: "rgb(245,245,245)" }}
            id="seeSpeciesDiv"
            onClick={() => count != undefined ? findKingdom(tableRow.kingdom) : findKingdomAdvanced(tableRow.kingdom,setPlantae,"PlantaeInput")}
          >
            <FontAwesomeIcon icon={faSeedling} color="rgb(0, 191, 0)" />
          </button>
          <div id="speciesDiv" style={{ left: 0, right: 0, top: "1.75rem" }}>
            {tableRow.kingdom}: {tableRow.species}
          </div>
        </nobr>
      ) : tableRow.kingdom === "Bacteria" ? (
        <nobr style={{ position: "relative" }}>
          <button
            style={{ border: "none", backgroundColor: "rgb(245,245,245)" }}
            id="seeSpeciesDiv"
            onClick={() => count != undefined ? findKingdom(tableRow.kingdom) : findKingdomAdvanced(tableRow.kingdom,setBacteria,"BacteriaInput")}
          >
            <FontAwesomeIcon icon={faBacterium} color="rgb(177, 200, 100)" />
          </button>
          <div id="speciesDiv" style={{ left: 0, right: 0, top: "1.75rem" }}>
            {tableRow.kingdom}: {tableRow.species}
          </div>
        </nobr>
      ) : tableRow.kingdom === "Fungi" ? (
        <nobr style={{ position: "relative" }}>
          <button
            style={{ border: "none", backgroundColor: "rgb(245,245,245)" }}
            id="seeSpeciesDiv"
            onClick={() => count != undefined ? findKingdom(tableRow.kingdom) : findKingdomAdvanced(tableRow.kingdom,setFungi,"FungiInput")}
          >
            <GiSuperMushroom color="red" />
          </button>
          <div id="speciesDiv" style={{ left: 0, right: 0, top: "1.75rem" }}>
            {tableRow.kingdom}: {tableRow.species}
          </div>
        </nobr>
      ) : tableRow.kingdom === "Animalia (Mammal)" ? (
        <nobr style={{ position: "relative" }}>
          <button
            style={{ border: "none", backgroundColor: "rgb(245,245,245)" }}
            id="seeSpeciesDiv"
            onClick={() => count != undefined ? findKingdom(tableRow.kingdom) : findKingdomAdvanced(tableRow.kingdom,setAnimaliaMammal,"AnimaliaMammalInput")}
          >
            <FontAwesomeIcon icon={faOtter} color="rgba(142, 74, 1, 0.764)" />
          </button>
          <div id="speciesDiv" style={{ left: 0, right: 0, top: "1.75rem" }}>
            {tableRow.kingdom}: {tableRow.species}
          </div>
        </nobr>
      ) : tableRow.kingdom === "Viruses" ? (
        <nobr style={{ position: "relative" }}>
          <button
            style={{ border: "none", backgroundColor: "rgb(245,245,245)" }}
            id="seeSpeciesDiv"
            onClick={() => count != undefined ? findKingdom(tableRow.kingdom) : findKingdomAdvanced(tableRow.kingdom,setViruses,"VirusesInput")}
          >
            <FontAwesomeIcon icon={faVirus} color="rgb(111, 205, 255)" />
          </button>
          <div id="speciesDiv" style={{ left: 0, right: 0, top: "1.75rem" }}>
            {tableRow.kingdom}: {tableRow.species}
          </div>
        </nobr>
      ) : tableRow.kingdom === "Animalia (Mammal, Human)" ? (
        <nobr style={{ position: "relative" }}>
          <button
            style={{ border: "none", backgroundColor: "rgb(245,245,245)" }}
            id="seeSpeciesDiv"
            onClick={() => count != undefined ? findKingdom(tableRow.kingdom) : findKingdomAdvanced(tableRow.kingdom,setAnimaliaMammalHuman,"AnimaliaMammalHumanInput")}
          >
            <FontAwesomeIcon icon={faMale} color="rgb(0, 0, 0)" />
          </button>
          <div id="speciesDiv" style={{ left: 0, right: 0, top: "1.75rem" }}>
            {tableRow.kingdom}: {tableRow.species}
          </div>
        </nobr>
      ) : tableRow.kingdom === "Animalia (Insect)" ? (
        <nobr style={{ position: "relative" }}>
          <button
            style={{ border: "none", backgroundColor: "rgb(245,245,245)" }}
            id="seeSpeciesDiv"
            onClick={() => count != undefined ? findKingdom(tableRow.kingdom) : findKingdomAdvanced(tableRow.kingdom,setAnimaliaInsect,"AnimaliaInsectInput")}
          >
            <FontAwesomeIcon icon={faBug} color="rgb(90, 7, 97)" />
          </button>
          <div id="speciesDiv" style={{ left: 0, right: 0, top: "1.75rem" }}>
            {tableRow.kingdom}: {tableRow.species}
          </div>
        </nobr>
      ) : tableRow.kingdom === "Amoebozoa" ? (
        <nobr style={{ position: "relative" }}>
          <button
            style={{ border: "none", backgroundColor: "rgb(245,245,245)" }}
            id="seeSpeciesDiv"
            onClick={() => count != undefined ? findKingdom(tableRow.kingdom) : findKingdomAdvanced(tableRow.kingdom,setAmoebozoa,"AmoebozoaInput")}
          >
            <FontAwesomeIcon icon={faDisease} color="rgb(104, 255, 182)" />
          </button>
          <div id="speciesDiv" style={{ left: 0, right: 0, top: "1.75rem" }}>
            {tableRow.kingdom}: {tableRow.species}
          </div>
        </nobr>
      ) : tableRow.kingdom === "Animalia (Coral)" ? (
        <nobr style={{ position: "relative" }}>
          <button
            style={{ border: "none", backgroundColor: "rgb(245,245,245)" }}
            id="seeSpeciesDiv"
            onClick={() => count != undefined ? findKingdom(tableRow.kingdom) : findKingdomAdvanced(tableRow.kingdom,setAnimaliaCoral,"AnimaliaCoralInput")}
          >
            <GiCoral color="rgb(193, 37, 117)" />
          </button>
          <div id="speciesDiv" style={{ left: 0, right: 0, top: "1.75rem" }}>
            {tableRow.kingdom}: {tableRow.species}
          </div>
        </nobr>
      ) : tableRow.kingdom === "Animalia (Bird)" ? (
        <nobr style={{ position: "relative" }}>
          <button
            style={{ border: "none", backgroundColor: "rgb(245,245,245)" }}
            id="seeSpeciesDiv"
            onClick={() => count != undefined ? findKingdom(tableRow.kingdom) : findKingdomAdvanced(tableRow.kingdom,setAnimaliaBird,"AnimaliaBirdInput")}
          >
            <LuBird/>
          </button>
          <div id="speciesDiv" style={{ left: 0, right: 0, top: "1.75rem" }}>
            {tableRow.kingdom}: {tableRow.species}
          </div>
        </nobr>
      ) : tableRow.kingdom === "Animalia (Marine Sponge)" ? (
        <nobr style={{ position: "relative" }}>
          <button
            style={{ border: "none", backgroundColor: "rgb(245,245,245)" }}
            id="seeSpeciesDiv"
            onClick={() => count != undefined ? findKingdom(tableRow.kingdom) : findKingdomAdvanced(tableRow.kingdom,setAnimaliaMarineSponge,"AnimaliaMarineSpongeInput")}
          >
            <img
              src={marineSpongeImg}
              alt="Animalia (Marine Sponge)"
              style={{
                width: 20,
                height: 20,
                backgroundColor: "rgb(245,245,245)",
              }}
            />
          </button>
          <div id="speciesDiv" style={{ left: 0, right: 0, top: "1.75rem" }}>
            {tableRow.kingdom}: {tableRow.species}
          </div>
        </nobr>
      ) : tableRow.kingdom === "Plantae (Red algae)" ? (
        <nobr style={{ position: "relative" }}>
          <button
            style={{ border: "none", backgroundColor: "rgb(245,245,245)" }}
            id="seeSpeciesDiv"
            onClick={() => count != undefined ? findKingdom(tableRow.kingdom) : findKingdomAdvanced(tableRow.kingdom,setPlantaeRedAlgae,"PlantaeRedAlgaeInput")}
          >
            <GiAlgae color="red" />
          </button>
          <div id="speciesDiv" style={{ left: 0, right: 0, top: "1.75rem" }}>
            {tableRow.kingdom}: {tableRow.species}
          </div>
        </nobr>
      ) : tableRow.kingdom === "Archaea" ? (
        <nobr style={{ position: "relative" }}>
          <button
            style={{ border: "none", backgroundColor: "rgb(245,245,245)" }}
            id="seeSpeciesDiv"
            onClick={() => count != undefined ? findKingdom(tableRow.kingdom) : findKingdomAdvanced(tableRow.kingdom,setArchaea,"ArchaeaInput")}
          >
            <img
              src={archaeaImg}
              alt="Archaea"
              style={{
                width: 20,
                height: 20,
                backgroundColor: "rgb(245,245,245)",
              }}
            />
          </button>
          <div id="speciesDiv" style={{ left: 0, right: 0, top: "1.75rem" }}>
            {tableRow.kingdom}: {tableRow.species}
          </div>
        </nobr>
      ) : tableRow.kingdom === "Cyanobacteria" ? (
        <nobr style={{ position: "relative" }}>
          <button
            style={{ border: "none", backgroundColor: "rgb(245,245,245)" }}
            id="seeSpeciesDiv"
            onClick={() => count != undefined ? findKingdom(tableRow.kingdom) : findKingdomAdvanced(tableRow.kingdom,setCyanobacteria,"CyanobacteriaInput")}
          >
            <img
              src={cyanobacteriaImg}
              alt="Cyanobacteria"
              style={{
                width: 20,
                height: 20,
                backgroundColor: "rgb(245,245,245)",
              }}
            />
          </button>
          <div id="speciesDiv" style={{ left: 0, right: 0, top: "1.75rem" }}>
            {tableRow.kingdom}: {tableRow.species}
          </div>
        </nobr>
      ) : (
        <></>
      )}
    </>
  );
}

export default KingdomIcon;
