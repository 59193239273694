import "./Footer.css";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css"
          rel="stylesheet"
        />
      </head>
      <body >
        <div className="row justify-content-left" id="BackgroundUpper" style={{boxShadow:
              "0 5px 20px 0 rgba(0, 0, 0, 0.25), 0 6px 20px 0 rgba(0, 0, 0, 0.2)",}}>
          <div className="col-sm-12" >
            <div id="Footer" >
              <ul id="ulFooter">
                <li id="listValue">
                  <Link id="FooterLink" to="/advancedsearch">
                    Advanced search
                  </Link>
                </li>
                <li id="listValue">
                  <Link id="FooterLink" to="/suggestreaction">
                    Reaction suggestion
                  </Link>
                </li>
                <li id="listValue">
                  <Link id="FooterLink" to="/suggestmechanism">
                    Mechanism suggestion
                  </Link>
                </li>
                <li id="listValue">
                  <Link id="FooterLink" to="/reactionentry">
                    Reaction entry
                  </Link>
                </li>
                <li id="listValue">
                  <Link id="FooterLink" to="/mechanismentry">
                    Mechanism entry
                  </Link>
                </li>
              </ul>
            </div>
            <div className="row justify-content-left" id="BackgroundLower">
            <div className="col-md-6">
              <div id="FooterPic"></div>
              </div>
            <div className="col-md-6" align="right" id="FooterIOCB">
            This project has been funded by Tomáš Pluskal group: <br/> <Link to="https://www.pluskal-lab.org" id="IOCBLink">Biochemistry of Plant Specialized Metabolites</Link>
            </div>

            </div>
          </div>
        </div>
      </body>
    </html>
  );
}

export default Footer;
