function TypeClass({
  table,
  row,
  seeAllReactions,
  setIsLoading,
  count,
  onlyMechanism,
  pageCount,
  setDataCount,
  setQuickSearchValue,
  search,
  renderData,
  setPageCount,
  setButtonCount,
  setCount,
  ///////////////////////////////////////////////////////////////FOR REACTION SEARCH//////////////////////////////////////////////////
  buttonCount,
  diType,
  monoType,
  triType,
  tetraType,
  sesterType,
  sesquarType,
  sesqType,
  prenylType,
  hemiType,
  setDiType,
  setMonoType,
  setTriType,
  setTetraType,
  setSesterType,
  setSesquarType,
  setSesqType,
  setPrenylType,
  setHemiType,
  name,
  terpType,
  setTerpType,
  enzClass,
  uniprot,
  genbank,
  reactSmiles,
  reactChebi,
  reactName,
  prodChebi,
  prodName,
  prodSmiles,
  setSearchSubmit,
  showInput,
  enzymeInputClicked,
  setEnzymeInputClicked,
  setEnzymeInputUnclicked,
  setFirstSearch,
  kingdom,
  ///////////////////////////////////////////////////////////////FOR MECHANISM SEARCH//////////////////////////////////////////////////
  evidence,
  mechanismUnclicked,
  intermediateSmiles
}) {
  ///////////////////////////////////////////////////////////////FIRST FUNCTION FOR QUICKSEARCH//////////////////////////////////////////////////
  const findType = async function (type) {
    try {
      setButtonCount(1);
      setCount((count = 0));
      setFirstSearch(true)
      setQuickSearchValue(type);
      if (pageCount === 1) {
        const result = await search(
          seeAllReactions,
          setIsLoading,
          count,
          onlyMechanism,
          type,
          pageCount,
          setDataCount
        );
        renderData(result?.table);
      } else {
        setPageCount(1);
        setButtonCount(1);
        setCount((count = 0));
      }
    } catch (err) {
      throw err;
    }
  };

///////////////////////////////////////////////////////////////SECOND FUNCTION FOR ADVANCED SEARCH//////////////////////////////////////////////////
  const findTypeAdvanced = async (type,setSpecificType,idInput) => {
    try {
      setFirstSearch(true)
      if (!enzymeInputClicked) {
        showInput(
          [
            "NameInput",
            "TypeInput",
            "ClassInput",
            "UniprotInput",
            "GenbankInput",
            "KingdomInput"
          ],
          "enzymeDD",
          enzymeInputClicked,
          setEnzymeInputClicked,
          "EnzymeInputs",
          setEnzymeInputUnclicked
        );
      }
      document.getElementById(idInput).checked = true
      setSpecificType(type);
      setTerpType({
        di: type === "di" ? "di" : diType,
        mono: type === "mono" ? "mono" : monoType,
        sesq: type === "sesq" ? "sesq" : sesqType,
        tri: type === "tri" ? "tri" : triType,
        tetra: type === "tetra" ? "tetra" : tetraType,
        sester: type === "sester" ? "sester" : sesterType,
        sesquar: type === "sesquar" ? "sesquar" : sesquarType,
        pt: type === "pt" ? "pt" : prenylType,
        hemi: type === "hemi" ? "hemi" : hemiType
    });
      if (pageCount === 1) {
        setButtonCount(1)
        const result = await search(
          name,
          {
            di: type === "di" ? "" : diType,
            mono: type === "mono" ? "" : monoType,
            sesq: type === "sesq" ? "" : sesqType,
            tri: type === "tri" ? "" : triType,
            tetra: type === "tetra" ? "" : tetraType,
            sester: type === "sester" ? "" : sesterType,
            sesquar: type === "sesquar" ? "" : sesquarType,
            pt: type === "pt" ? "" : prenylType,
            hemi: type === "hemi" ? "" : hemiType
        },
          kingdom,
          enzClass,
          uniprot,
          genbank,
          reactSmiles,
          reactChebi,
          reactName,
          prodChebi,
          prodName,
          prodSmiles,
          pageCount,
          buttonCount,
          setIsLoading,
          setSearchSubmit,
          setDataCount,
          evidence,
          mechanismUnclicked,
          intermediateSmiles
        );
        renderData(result);
      } else {
        setPageCount(1)
        setButtonCount(1)
      }
    } catch (err) {
      throw err;
    }
  };

  return (

      <div>
        {table[row].type === "di" ? (
          <button
            id="typeQuickSearch"
            style={{ border: "none", backgroundColor: "rgb(245,245,245)",padding:0 }}
            onClick={() => count != undefined ? findType("Diterpene synthases") : findTypeAdvanced("di",setDiType,"DiTerpInput")}
          >
            Diterpene synthases,
          </button>
        ) : table[row].type === "mono" ? (
          <button
            id="typeQuickSearch"
            style={{ border: "none", backgroundColor: "rgb(245,245,245)",padding:0 }}
            onClick={() => count != undefined ? findType("Monoterpene synthases") : findTypeAdvanced("mono",setMonoType,"MonoTerpInput")}
          >
            Monoterpene synthases,
          </button>
        ) : table[row].type === "sesq" ? (
          <button
          id="typeQuickSearch"
            style={{ border: "none", backgroundColor: "rgb(245,245,245)",padding:0 }}
            onClick={() => count != undefined ? findType("Sesquiterpene synthases") : findTypeAdvanced("sesq",setSesqType,"SesqTerpInput")}
          >
            Sesquiterpene synthases,
          </button>
        ) : table[row].type === "tetra" ? (
          <button
          id="typeQuickSearch"
            style={{ border: "none", backgroundColor: "rgb(245,245,245)",padding:0 }}
            onClick={() => count != undefined ? findType("Tetraterpene synthases") : findTypeAdvanced("tetra",setTetraType,"TetraTerpInput")}
          >
            Tetraterpene synthases,
          </button>
        ) : table[row].type === "sester" ? (
          <button
          id="typeQuickSearch"
            style={{ border: "none", backgroundColor: "rgb(245,245,245)",padding:0 }}
            onClick={() => count != undefined ? findType("Sesterterpene synthases") : findTypeAdvanced("sester",setSesterType,"SesterTerpInput")}
          >
            Sesterterpene synthases,
          </button>
        ) : table[row].type === "sesquar" ? (
          <button
          id="typeQuickSearch"
            style={{ border: "none", backgroundColor: "rgb(245,245,245)",padding:0 }}
            onClick={() => count != undefined ? findType("Sesquarterpene synthases") : findTypeAdvanced("sesquar",setSesquarType,"SesquarTerpInput")}
          >
            Sesquarterpene synthases,
          </button>
        ) : table[row].type === "tri" ? (
          <button
          id="typeQuickSearch"
            style={{ border: "none", backgroundColor: "rgb(245,245,245)",padding:0 }}
            onClick={() => count != undefined ? findType("Triterpene synthases") : findTypeAdvanced("tri",setTriType,"TriTerpInput")}
          >
            Triterpene synthases,
          </button>
        ) : table[row].type === "pt" ? (
          <button
          id="typeQuickSearch"
            style={{ border: "none", backgroundColor: "rgb(245,245,245)",padding:0 }}
            onClick={() => count != undefined ? findType("Prenyl transferases") : findTypeAdvanced("pt",setPrenylType,"PrenylTerpInput")}
          >
            Prenyl transferases
          </button>
        ) : (
          <button
          id="typeQuickSearch"
            style={{ border: "none", backgroundColor: "rgb(245,245,245)",padding:0 }}
            onClick={() => count != undefined ? findType("Hemiterpene synthases") : findTypeAdvanced("hemi",setHemiType,"HemiTerpInput")}
          >
            Hemiterpene synthases
          </button>
        )}
        {table[row].class && table[row].type !== "pt" ? <> class {table[row].class}</> : <></>}
      </div>
  );
}

export default TypeClass;
