import Footer from "./Footer.js";
import { useEffect, useState } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate.js";
import { useNavigate, useLocation } from "react-router-dom";
import ReactionTable from "../reactionTable.js";
import useLogout from "../hooks/useLogout.js";
import "./myEntries.css";
import useAuth from "../hooks/useAuth.js";
import Loading from "../Loading.js";
import HomeNavbar from "../HomeNavbar.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleDown } from "@fortawesome/free-solid-svg-icons";

function MyEntries() {
  const [myEntries, setMyEntries] = useState([]);
  const navigate = useNavigate();
  const { state } = useLocation();
  const logout = useLogout();
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [details, setDetails] = useState("");

  function scrollToView() {
    if (state?.from?.pathname == "/mechanismview") {
      console.log("scrolling");
      document.querySelector(`#${state.reactionClicked}`)?.scrollIntoView({
        behavior: "smooth",
      });
    }
  }

  useEffect(() => {
    let body = [];
    let reaction_details = {
      kingdom: [],
      species: [],
      aminoacid_sequence: [],
      class: [],
      type: [],
      ec_number: [],
      uniprot_id: [],
      genbank_id: [],
      substrate_smiles: [],
      substrate_chebi: [],
      product_smiles: [],
      product_chebi: [],
      link: [],
      doi: [],
      pmid: [],
      cosubstrate: [],
      cosubstrate_smiles: [],
      cosubstrate_name: [],
      cosubstrate_chebi: [],
    };

    const getMyEntries = async () => {
      try {
        const response = await axiosPrivate.post("/myentries", {
          username: auth.username,
        });
        const table = response.data;

        for (let row = 0; row < table.length; row++) {
          let DDid = `DD${row}`;
          let TRid = `TR${row}`;
          let DDwhitespace = `DDwhitespace${row}`;
          let RE_pair = table[row].reaction_enzyme_pair_id;
          let enzyme_name = table[row].name;
          let reaction_substrate = table[row].substrate;
          let reaction_product = table[row].product;
          let substrate_name = table[row].substrate_name;
          let product_name = table[row].product_name;

          reaction_details.kingdom.push(table[row].kingdom);
          reaction_details.species.push(table[row].species);
          reaction_details.aminoacid_sequence.push(
            table[row].aminoacid_sequence
          );
          reaction_details.class.push(table[row].class);
          reaction_details.type.push(table[row].type);
          reaction_details.ec_number.push(table[row].ec_number);
          reaction_details.uniprot_id.push(table[row].uniprot_id);
          reaction_details.genbank_id.push(table[row].genbank_id);
          reaction_details.substrate_chebi.push(table[row].substrate_chebi);
          reaction_details.product_chebi.push(table[row].product_chebi);
          reaction_details.link.push(table[row].publication_link);
          reaction_details.doi.push(table[row].publication_doi);
          reaction_details.pmid.push(table[row].publication_pmid);
          reaction_details.substrate_smiles.push(table[row].substrate_smiles);
          reaction_details.product_smiles.push(table[row].product_smiles);
          if (table[row].cosubstrate) {
            reaction_details.cosubstrate.push(table[row]?.cosubstrate);
            reaction_details.cosubstrate_smiles.push(
              table[row]?.cosubstrate_smiles
            );
            reaction_details.cosubstrate_name.push(
              table[row]?.cosubstrate_name
            );
            reaction_details.cosubstrate_chebi.push(
              table[row]?.cosubstrate_chebi
            );
          } else {
            reaction_details.cosubstrate.push(null);
            reaction_details.cosubstrate_smiles.push("None");
            reaction_details.cosubstrate_name.push("None");
            reaction_details.cosubstrate_chebi.push("None");
          }

          body.push([
            table[row].name === "missing_value" ? (
              table[row].uniprot_id ? (
                <>Uniprot ID: {table[row].uniprot_id}</>
              ) : (
                <>Genbank ID: {table[row].genbank_id}</>
              )
            ) : table[row].uniprot_id ? (
              <>
                {table[row].name}
                <br />
                Uniprot ID: {table[row].uniprot_id}
              </>
            ) : (
              <>
                {table[row].name}
                <br />
                Genbank ID: {table[row].genbank_id}
              </>
            ),
            !table[row].cosubstrate ? (
              table[row].substrate_image ? (
                <>
                  <img
                    src={table[row].substrate_image}
                    alt={table[row].substrate_smiles}
                    className="responsive"
                  />
                  <br />
                  {substrate_name}
                </>
              ) : (
                <>
                  {table[row].substrate_smiles} <br />
                  {table[row].substrate_name}
                  <br />
                </>
              )
            ) : table[row].substrate_image ? (
              <>
                <img
                  src={table[row].substrate_image}
                  alt={table[row].substrate_smiles}
                  className="responsive"
                />
                <br />
                {substrate_name}
                <br />
                +<br />
                <img
                  src={table[row].cosubstrate_image}
                  alt={table[row].cosubstrate_smiles}
                  className="responsive"
                />
                <br />
                {table[row].cosubstrate_name}
              </>
            ) : (
              <>
                {table[row].substrate_smiles} <br />
                {table[row].substrate_name}
                <br />
                +<br />
                {table[row].cosubstrate_smiles} <br />
                {table[row].cosubstrate_name}
              </>
            ),
            table[row].product_image ? (
              <>
                <img src={table[row].product_image} className="responsive" />
                <br />
                {product_name}
              </>
            ) : (
              <>
                {table[row].product_smiles} <br />
                {table[row].product_name}
              </>
            ),
            table[row].mechanism_id ? (
              <button
                id="entriesButton"
                onClick={() => {
                  navigate("/mechanismview", {
                    state: {
                      RE_pair: RE_pair,
                      enzyme_name: enzyme_name,
                      reaction_substrate: reaction_substrate,
                      reaction_product: reaction_product,
                      substrate_name: substrate_name,
                      product_name: product_name,
                      details: reaction_details,
                      row: row,
                      location: "/myentries",
                      reactionClicked: TRid,
                    },
                  });
                }}
              >
                View mechanism
              </button>
            ) : (
              ""
            ),
            <button
              id="entriesButton"
              onClick={() => {
                let dropdown = document.getElementById(DDid);
                let whitespace = document.getElementById(DDwhitespace);
                dropdown.style.display === "none"
                  ? (dropdown.style.display = "block")
                  : (dropdown.style.display = "none");

                let dropdownHeight = dropdown.clientHeight;
                dropdown.style.display === "none"
                  ? (whitespace.style.marginBottom = "0px")
                  : (whitespace.style.marginBottom = `${dropdownHeight}px`);
              }}
            >
              View reaction details
            </button>,
          ]);
        }
        setMyEntries(body);
        setDetails(reaction_details);
      } catch (err) {
        console.error(err);
        //logout();
        //navigate("/login", { state: { from: location }, replace: true });
      } finally {
        setIsLoading(false);
      }
    };

    getMyEntries();
  }, []);

  const heading = ["Enzyme", "Substrate", "Product", "Mechanism", "Details"];

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css"
          rel="stylesheet"
        />
      </head>
      {isLoading ? (
        <Loading />
      ) : (
        <body>
          <HomeNavbar />
          {myEntries?.length != 0 ? (
            <>
              <div id="myEntriesDiv" style={{ paddingTop: 100 }}>
                <ul
                  style={{
                    listStyle: "none",
                    display: "flex",
                    justifyContent: "space-between",
                    listStylePosition: "outside",
                    paddingTop: 25,
                    padding: 0,
                  }}
                >
                  <li>Total number of entries: {myEntries.length}</li>
                  <li>
                  <button
              type="button"
              id="DoubleDownHome"
              onClick={() => {
                if (state?.from?.pathname == "/mechanismview") {
                  document
                    .querySelector(`#${state.reactionClicked}`)
                    ?.scrollIntoView({
                      behavior: "smooth",
                    });
                } else {
                  document.querySelector("#table").scrollIntoView({
                    behavior: "smooth",
                  });
                }
              }}
            >
              <FontAwesomeIcon icon={faAngleDoubleDown} id="arrow"/>
            </button>
                  </li>
                </ul>
              </div>
              <div style={{ marginLeft: 150, marginRight: 150 }}>
                <ReactionTable
                  heading={heading}
                  body={myEntries}
                  details={details}
                  display={"none"}
                ></ReactionTable>
              </div>
            </>
          ) : (
            <div id="myEntriesDiv">No entries yet.</div>
          )}
          <Footer />
        </body>
      )}
    </html>
  );
}

export default MyEntries;
