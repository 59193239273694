import React, { Component } from "react";
import "./reactionTable.css";
import { Link } from "react-router-dom";
import { faCopy, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function copyAASequence(row) {
  let AASequence = document.getElementById(`AASequence${row}`).innerText;
  let elem = document.createElement("textarea");
  document.body.appendChild(elem);
  AASequence = AASequence.trim();
  elem.value = AASequence;
  elem.select();
  document.execCommand("copy");
  document.body.removeChild(elem);
}

function copyReactantSmiles(row) {
  let reactantSmiles = document.getElementById(
    `ReactantSmiles${row}`
  ).innerText;
  let elem = document.createElement("textarea");
  document.body.appendChild(elem);
  reactantSmiles = reactantSmiles.trim();
  elem.value = reactantSmiles;
  elem.select();
  document.execCommand("copy");
  document.body.removeChild(elem);
}

function copyProductSmiles(row) {
  let productSmiles = document.getElementById(`ProductSmiles${row}`).innerText;
  let elem = document.createElement("textarea");
  document.body.appendChild(elem);
  productSmiles = productSmiles.trim();
  elem.value = productSmiles;
  elem.select();
  document.execCommand("copy");
  document.body.removeChild(elem);
}

class Table extends Component {
  render() {
    let heading = this.props.heading;
    let body = this.props.body;

    return (
      <table id="table">
        <tbody>
          <tr id="headerRow">
            {heading.map((head, headID) => (
              <th id="headerCol">{head}</th>
            ))}
          </tr>
          {body.map((rowContent, rowID) => (
            <>
              <TableRow rowContent={rowContent} id={`TR${rowID}`} />
            </>
          ))}
        </tbody>
      </table>
    );
  }
}

class TableRow extends Component {
  render() {
    let row = this.props.rowContent;
    let TRid = this.props.id;
    return (
      <tr id={`${TRid}`} className="reactionContent">
        {row.map((val, rowID) => (
          <td key={rowID} id="dataCol">
            {val}
          </td>
        ))}
      </tr>
    );
  }
}

export default Table;
