import "./pageCounter.css";
import { useEffect } from "react";

function PageCounter({
  dataCount,
  pageCount,
  setPageCount,
  buttonCount,
  setButtonCount,
  setNewSearch,
  stateButtonCount,
  newSearch,
  setCount,
  count
}) {

  useEffect(() => {
    if (stateButtonCount && !newSearch) {
      goToPage(buttonCount, false);
    } else {
      if (buttonCount <= 4 && pageCount < Math.ceil(dataCount / 10) - 3) {
        goToPage(buttonCount, true);
      } else if (
        buttonCount >= 5 &&
        pageCount < Math.ceil(dataCount / 10) - 3
      ) {
        goToPage(buttonCount - 1, true);
      } else if (
        buttonCount <= 4 &&
        pageCount >= Math.ceil(dataCount / 10) - 3
      ) {
        goToPage(buttonCount, true);
      } else if (
        buttonCount > 4 &&
        pageCount >= Math.ceil(dataCount / 10) - 3
      ) {
        goToPage(buttonCount, true);
      } else {
        goToPage(1, true);
      }
    }
  }, [buttonCount]);

  async function goToPage(number, condition) {
    if (condition) {
      setNewSearch(true);
    }
    const array = [1, 2, 3, 4, 5, 6, 7];
    array.forEach((element) => {
      if (element != number) {
        const otherPage = document?.getElementById(`pages${element}`);
        if (otherPage != undefined) {
          otherPage.style.border = "solid 2px white";
          otherPage.style.borderRadius = "5px";
        }
      }
    });
    if (pageCount <= 4 && pageCount < Math.ceil(dataCount / 10) - 2) {
      const currentPage = document?.getElementById(`pages${number}`);
      if (number != 5 && number != 6) {
        const nextPage = document?.getElementById(`pages${number}`);
        if (nextPage != undefined) {
          nextPage.style.border = "solid 2px gold";
          nextPage.style.borderRadius = "5px";
        }
        if (currentPage != undefined) {
          setPageCount(currentPage.innerText);
          setCount(currentPage.innerText-1)
        }
        setButtonCount(number);
      } else {
        const nextPage = document?.getElementById(`pages4`);
        if (nextPage != undefined) {
          nextPage.style.border = "solid 2px gold";
          nextPage.style.borderRadius = "5px";
        }
        if (currentPage != undefined) {
          setPageCount(currentPage.innerText);
          setCount(currentPage.innerText-1)
        }
        setButtonCount(4);
      }
    } else if (pageCount <= 4 && pageCount >= Math.ceil(dataCount / 10) - 3) {
        const currentPage = document?.getElementById(`pages${number}`);
        const nextPage = document?.getElementById(`pages${number}`);
        if (nextPage != undefined) {
          nextPage.style.border = "solid 2px gold";
          nextPage.style.borderRadius = "5px";
        }
        if (currentPage != undefined) {
          setPageCount(currentPage.innerText);
          setCount(currentPage.innerText-1)
        }
        setButtonCount(number);
    } else if (pageCount >= Math.ceil(dataCount / 10) - 3) {
      const currentPage = document?.getElementById(`pages${number}`);
      if (number != 3 && number != 2) {
        const nextPage = document?.getElementById(`pages${number}`);
        if (nextPage != undefined) {
          nextPage.style.border = "solid 2px gold";
          nextPage.style.borderRadius = "5px";
        }
        if (currentPage != undefined) {
          setPageCount(currentPage.innerText);
          setCount(currentPage.innerText-1)
        }
        setButtonCount(number);
      } else {
        const nextPage = document?.getElementById(`pages4`);
        if (nextPage != undefined) {
          nextPage.style.border = "solid 2px gold";
          nextPage.style.borderRadius = "5px";
        }
        if (currentPage != undefined) {
          setPageCount(currentPage.innerText);
          setCount(currentPage.innerText-1)
        }
        setButtonCount(4);
      }
    } else {
      if (number == 1) {
        setPageCount(1);
        setCount(0)
        setButtonCount(number);
        const nextPage = document?.getElementById(`pages1`);
        if (nextPage != undefined) {
          nextPage.style.border = "solid 2px gold";
          nextPage.style.borderRadius = "5px";
        }
      } else if (number == 7) {
        setPageCount(Math.ceil(dataCount / 10));
        setCount(Math.ceil(dataCount / 10)-1)
        setButtonCount(number);
        const nextPage = document?.getElementById(`pages7`);
        if (nextPage != undefined) {
          nextPage.style.border = "solid 2px gold";
          nextPage.style.borderRadius = "5px";
        }
      } else {
        const currentPage = document?.getElementById(`pages${number}`);
        const nextPage = document?.getElementById(`pages4`);
        if (nextPage != undefined) {
          nextPage.style.border = "solid 2px gold";
          nextPage.style.borderRadius = "5px";
        }
        if (currentPage != undefined) {
          setPageCount(currentPage.innerText);
          setCount(currentPage.innerText-1);
        }
        setButtonCount(4);
      }
    }
  }

  return (
    <ul
      style={{
        listStyle: "none",
        display: "flex",
        justifyContent: "space-evenly",
        padding: 0,
        listStylePosition: "outside",
      }}
    >
      {" "}
      {Math.ceil(dataCount / 10) < 2 ? (
        <li>
          <button
            id="pages1"
            style={{ display: "none" }}
            onClick={() => {
              goToPage(1, true);
            }}
          >
            1
          </button>
        </li>
      ) : (
        <li>
          <button
            id="pages1"
            onClick={() => {
              goToPage(1, true);
            }}
          >
            1
          </button>
        </li>
      )}
      <li>
        {pageCount > 4 && Math.ceil(dataCount / 10 ) - 3 > 4? (
          <button id="dotbutton" disabled={true} style={{ color: "black" }}>
            ...
          </button>
        ) : (
          ""
        )}
      </li>
      <li>
        {Math.ceil(dataCount / 10) < 2 ? (
          ""
        ) : pageCount > 4 ? (
          pageCount > Math.ceil(dataCount / 10) - 3 && Math.ceil(dataCount / 10 ) - 3 <= 4 ? (
            <button
              onClick={() => {
                goToPage(2, true);
              }}
              id="pages2"
            >
              {Number(pageCount) - 3}
            </button>
          ) : (pageCount > Math.ceil(dataCount / 10) - 3 ? (<button
            onClick={() => {
              goToPage(2, true);
            }}
            id="pages2"
          >
            {Math.ceil(dataCount / 10) - 5}
          </button>) : (<button
              onClick={() => {
                goToPage(2, true);
              }}
              id="pages2"
            >
              {Number(pageCount) - 2}
            </button>)
            
          )
        ) : (
          <button
            onClick={() => {
              goToPage(2, true);
            }}
            id="pages2"
          >
            2
          </button>
        )}
      </li>
      <li>
        {Math.ceil(dataCount / 10) < 3 ? (
          ""
        ) : pageCount > 4 ? (
          pageCount > Math.ceil(dataCount / 10) - 3 && Math.ceil(dataCount / 10 ) - 3 <= 4 ? (
            <button
              onClick={() => {
                goToPage(3, true);
              }}
              id="pages3"
            >
              {Number(pageCount) - 2}
            </button>
          ) : (pageCount > Math.ceil(dataCount / 10) - 3 ? (<button
            onClick={() => {
              goToPage(3, true);
            }}
            id="pages3"
          >
            {Math.ceil(dataCount / 10) - 4}
          </button>) : (<button
              onClick={() => {
                goToPage(3, true);
              }}
              id="pages3"
            >
              {Number(pageCount) - 1}
            </button>)
            
          )
        ) : (
          <button
            onClick={() => {
              goToPage(3, true);
            }}
            id="pages3"
          >
            3
          </button>
        )}
      </li>
      <li>
        {Math.ceil(dataCount / 10) < 4 ? (
          ""
        ) : pageCount > 4 ? (
          pageCount > Math.ceil(dataCount / 10) - 3 && Math.ceil(dataCount / 10 ) - 3 <= 4 ? (
            <button
              onClick={() => {
                goToPage(4, true);
              }}
              id="pages4"
            >
              {Number(pageCount)-1}
            </button>
            
          ) : (pageCount > Math.ceil(dataCount / 10) - 3 ? (<button
            onClick={() => {
              goToPage(4, true);
            }}
            id="pages4"
          >
            {Math.ceil(dataCount / 10) - 3}
          </button>) : (<button
              onClick={() => {
                goToPage(4, true);
              }}
              id="pages4"
            >
              {Number(pageCount)}
            </button>)
            
          )
        ) : (
          <button
            onClick={() => {
              goToPage(4, true);
            }}
            id="pages4"
          >
            4
          </button>
        )}
      </li>
      <li>
        {Math.ceil(dataCount / 10) < 5 ? (
          ""
        ) : pageCount > 4 ? (
          pageCount > Math.ceil(dataCount / 10) - 3 && Math.ceil(dataCount / 10 ) - 3 <= 4 ? (
            <button
              onClick={() => {
                goToPage(5, true);
              }}
              id="pages5"
            >
              {Math.ceil(dataCount / 10)}
            </button>
          ) : ( pageCount > Math.ceil(dataCount / 10) - 3 ? (
            <button
            onClick={() => {
              goToPage(5, true);
            }}
            id="pages5"
          >
            {Math.ceil(dataCount / 10) - 2}
          </button>

          ) : (<button
            onClick={() => {
              goToPage(5, true);
            }}
            id="pages5"
          >
            {Number(pageCount) + 1}
          </button>)
            
          )
        ) : (
          <button
            onClick={() => {
              goToPage(5, true);
            }}
            id="pages5"
          >
            5
          </button>
        )}
      </li>
      <li>
        {Math.ceil(dataCount / 10) < 6 ? (
          ""
        ) : pageCount > 4 ? (
          pageCount > Math.ceil(dataCount / 10) - 3 ? (
            <button
              onClick={() => {
                goToPage(6, true);
              }}
              id="pages6"
            >
              {Math.ceil(dataCount / 10) - 1}
            </button>
          ) : (
            <button
              onClick={() => {
                goToPage(6, true);
              }}
              id="pages6"
            >
              {Number(pageCount) + 2}
            </button>
          )
        ) : (
          <button
            onClick={() => {
              goToPage(6, true);
            }}
            id="pages6"
          >
            6
          </button>
        )}
      </li>
      <li>
        {pageCount < Math.ceil(dataCount / 10) - 3 && Math.ceil(dataCount / 10) - 3 > 4 ? (
          <button id="dotbutton" disabled={true} style={{ color: "black" }}>
            ...
          </button>
        ) : (
          ""
        )}
      </li>
      {Math.ceil(dataCount / 10) < 7 ? (
        ""
      ) : (
        <>
          <li>
            <button
              onClick={() => {
                goToPage(7, true);
              }}
              id="pages7"
            >
              {Math.ceil(dataCount / 10)}
            </button>
          </li>
        </>
      )}
    </ul>
  );
}

export default PageCounter;
