import React, { useState } from "react";
import { useEffect } from "react";
import "./ReactionEntry.css";
import Footer from "./Footer.js";
import {
  faExclamationCircle,
  faCheck,
  faDatabase,
  faAsterisk,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useAxiosPrivate from "../hooks/useAxiosPrivate.js";
import { useLocation, useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth.js";
import HomeNavbar from "../HomeNavbar.js";
import encoding from "encoding"

function Reaction() {
  const {state} = useLocation()
  const [noteLength,setNoteLength] = useState(0)
  const [rejectNote,setRejectNote] = useState("");
  const [enzymeName, setEnzymeName] = useState(state?.enzyme_name ? (state.enzyme_name) : (""));
  const [AASequence, setAASequence] = useState(state?.aminoacid_sequence ? (state.aminoacid_sequence) : (""));
  const [plantae, setPlantae] = useState(state?.kingdom === "Plantae" ? (state.kingdom) : (""));
  const [animaliaCoral, setAnimaliaCoral] = useState(state?.kingdom === "Animalia (Coral)" ? (state.kingdom) : (""));
  const [bacteria, setBacteria] = useState(state?.kingdom === "Bacteria" ? (state.kingdom) : (""));
  const [fungi, setFungi] = useState(state?.kingdom === "Fungi" ? (state.kingdom) : (""));
  const [animaliaMarineSponge, setAnimaliaMarineSponge] = useState(state?.kingdom === "Animalia (Marine Sponge)" ? (state.kingdom) : (""));
  const [animalia, setAnimalia] = useState(state?.kingdom === "Animalia" ? (state.kingdom) : (""));
  const [archaea, setArchaea] = useState(state?.kingdom === "Archaea" ? (state.kingdom) : (""));
  const [amoebozoa, setAmoebozoa] = useState(state?.kingdom === "Amoebozoa" ? (state.kingdom) : (""));
  const [viruses, setViruses] = useState(state?.kingdom === "Viruses" ? (state.kingdom) : (""));
  const [animaliaInsecta, setAnimaliaInsecta] = useState(state?.kingdom === "Animalia (Insecta)" ? (state.kingdom) : (""));
  const [animaliaHuman, setAnimaliaHuman] = useState(state?.kingdom === "Animalia (Human)" ? (state.kingdom) : (""));
  const [cyanobacteria, setCyanobacteria] = useState(state?.kingdom === "Cyanobacteria" ? (state.kingdom) : (""));
  const [redAlgae, setRedAlgae] = useState(state?.kingdom === "Red algae" ? (state.kingdom) : (""));
  const [kingdomUnknown, setKingdomUnknown] = useState("");
  const [classOne, setClassOne] = useState(state?.class == 1 ? (state.class) : (""));
  const [classTwo, setClassTwo] = useState(state?.class == 2 ? (state.class) : (""));
  const [classUnknown, setClassUnknown] = useState("");
  const [diType, setDiType] = useState(state?.type === "di" ? (state.type) : (""));
  const [monoType, setMonoType] = useState(state?.type === "mono" ? (state.type) : (""));
  const [sesqType, setSesqType] = useState(state?.type === "sesq" ? (state.type) : (""));
  const [diIntType, setDiIntType] = useState(state?.type === "di-int" ? (state.type) : (""));
  const [triType, setTriType] = useState(state?.type === "tri" ? (state.type) : (""));
  const [tetraType, setTetraType] = useState(state?.type === "tetra" ? (state.type) : (""));
  const [sesterType, setSesterType] = useState(state?.type === "sester" ? (state.type) : (""));
  const [triIntType, setTriIntType] = useState(state?.type === "tri-int" ? (state.type) : (""));
  const [tetraIntType, setTetraIntType] = useState(state?.type === "tetra-int" ? (state.type) : (""));
  const [sesquarType, setSesquarType] = useState(state?.type === "sesquar" ? (state.type) : (""));
  const [ggppsType, setGgppsType] = useState(state?.type === "ggpps" ? (state.type) : (""));
  const [unknownType, setUnknownType] = useState("");
  const [species, setSpecies] = useState(state?.species ? (state.species) : (""));
  const [uniprotId, setUniprotId] = useState(state?.uniprot_id ? (state.uniprot_id) : (""));
  const [genbankId, setGenbankId] = useState(state?.genbank_id ? (state.genbank_id) : (""));
  const [link, setLink] = useState(state?.publication_link ? (state.publication_link) : (""));
  const [pubmedId, setPubmedId] = useState(state?.publication_pmid ? (state.publication_pmid) : (""));
  const [doi, setDoi] = useState(state?.publication_doi ? (state.publication_doi) : (""));
  const [reactantName, setReactantName] = useState(state?.substrate_name ? (state.substrate_name) : (""));
  const [reactantChebi, setReactantChebi] = useState(state?.substrate_chebi ? (state.substrate_chebi) : (""));
  const [reactantSmiles, setReactantSmiles] = useState(state?.substrate_smiles ? (state.substrate_smiles) : (""));
  const [cosubstrateName, setCosubstrateName] = useState(state?.cosubstrate_name ? (state.cosubstrate_name) : (""));
  const [cosubstrateChebi, setCosubstrateChebi] = useState(state?.cosubstrate_chebi ? (state.cosubstrate_chebi) : (""));
  const [cosubstrateSmiles, setCosubstrateSmiles] = useState(state?.cosubstrate_smiles ? (state.cosubstrate_smiles) : (""));
  const [productName, setProductName] = useState(state?.product_name ? (state.product_name) : (""));
  const [productChebi, setProductChebi] = useState(state?.product_chebi ? (state.product_chebi) : (""));
  const [productSmiles, setProductSmiles] = useState(state?.product_smiles ? (state.product_smiles) : (""));
  const [response, setResponse] = useState("")
  const [RE_pair, setRE_pair] = useState(state?.RE_pair ? (state.RE_pair) : (""))
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const { auth } = useAuth();


  useEffect(()=>{
    const monoInput = document.getElementById(
      "mono"
    );
    if (state?.type === "mono") {
      monoInput.checked = true;
    }
    const sesqInput = document.getElementById(
      "sesq"
    );
    if (state?.type === "sesq") {
      sesqInput.checked = true;
    }
    const diintInput = document.getElementById(
      "di-int"
    );
    if (state?.type === "di-int") {
      diintInput.checked = true;
    }
    const triInput = document.getElementById(
      "tri"
    );
    if (state?.type === "tri") {
      triInput.checked = true;
    }
    const tetraInput = document.getElementById(
      "tetra"
    );
    if (state?.type === "tetra") {
      tetraInput.checked = true;
    }
    const sesterInput = document.getElementById(
      "sester"
    );
    if (state?.type === "sester") {
      sesterInput.checked = true;
    }
    const triintInput = document.getElementById(
      "tri-int"
    );
    if (state?.type === "tri-int") {
      triintInput.checked = true;
    }
    const tetraintInput = document.getElementById(
      "tetra-int"
    );
    if (state?.type === "tetra") {
      tetraintInput.checked = true;
    }
    const sesquarInput = document.getElementById(
      "sesquar"
    );
    if (state?.type === "sesquar") {
      sesquarInput.checked = true;
    }
    const ggppsInput = document.getElementById(
      "ggpps"
    );
    if (state?.type === "ggpps") {
      ggppsInput.checked = true;
    }
    const diInput = document.getElementById(
      "di"
    );
    if (state?.type === "di") {
      diInput.checked = true;
    }
    const class1 = document.getElementById(
      "ClassOne"
    );
    if (state?.class == 1) {
      class1.checked = true;
    }
    const class2 = document.getElementById(
      "ClassTwo"
    );
    if (state?.class == 2) {
      class2.checked = true;
    }
    const Plantae = document.getElementById(
      "Plantae"
    );
    if (state?.kingdom === "Plantae") {
      Plantae.checked = true;
    }
    const animaliacoral = document.getElementById(
      "AnimaliaCoral"
    );
    if (state?.kingdom === "Animalia (Coral)") {
      animaliacoral.checked = true;
    }
    const Bacteria = document.getElementById(
      "Bacteria"
    );
    if (state?.kingdom === "Bacteria") {
      Bacteria.checked = true;
    }
    const Fungi = document.getElementById(
      "Fungi"
    );
    if (state?.kingdom === "Fungi") {
      Fungi.checked = true;
    }
    const animaliamarinesponge = document.getElementById(
      "AnimaliaMarineSponge"
    );
    if (state?.kingdom === "Animalia (Marine Sponge)") {
      animaliamarinesponge.checked = true;
    }
    const Animalia = document.getElementById(
      "Animalia"
    );
    if (state?.kingdom === "Animalia") {
      Animalia.checked = true;
    }
    const Archaea = document.getElementById(
      "Archaea"
    );
    if (state?.kingdom === "Archaea") {
      Archaea.checked = true;
    }
    const Amoebozoa = document.getElementById(
      "Amoebozoa"
    );
    if (state?.kingdom === "Amoebozoa") {
      Amoebozoa.checked = true;
    }
    const Viruses = document.getElementById(
      "Viruses"
    );
    if (state?.kingdom === "Viruses") {
      Viruses.checked = true;
    }
    const animaliainsecta = document.getElementById(
      "AnimaliaInsecta"
    );
    if (state?.kingdom === "Animalia (Insecta)") {
      animaliainsecta.checked = true;
    }
    const animaliahuman = document.getElementById(
      "AnimaliaHuman"
    );
    if (state?.kingdom === "Animalia (Human)") {
      animaliahuman.checked = true;
    }
    const Cyanobacteria = document.getElementById(
      "Cyanobacteria"
    );
    if (state?.kingdom === "Cyanobacteria") {
      Cyanobacteria.checked = true;
    }
    const redalgae = document.getElementById(
      "RedAlgae"
    );
    if (state?.kingdom === "Red algae") {
      redalgae.checked = true;
    }
    const typeunknown = document.getElementById(
      "nan"
    );
    if (!state?.type) {
      typeunknown.checked = false;
    }
    const kingdomunknown = document.getElementById(
      "Unknown"
    );
    if (!state?.kingdom) {
      kingdomunknown.checked = false;
    }
    const ClassUnknown = document.getElementById(
      "unknown"
    );
    if (!state?.class) {
      ClassUnknown.checked = false;
    }
  
  },[])

  async function rejectSuggestion(event) {
    event.preventDefault()
    try {
      await axiosPrivate.post("/rejectsuggestion",{
        suggestion_id:state.suggestion_id,
      })
    } catch (err) {
      throw err
    } finally {
      const elements = document.querySelectorAll("#body");
        elements.forEach((element) => {
          element.style.filter = "blur(3px)";
        });
      document.getElementById("submitConfirmation").style.display = "none";
      document.getElementById("overlap").style.display = "block";
      document.getElementById("rejectNote").style.display = "block"
    }
  }

  async function feedbackYes() {
      document.getElementById("rejectNote").style.display = "none"
      document.getElementById("RejectNote").style.display = "block"
  }

  async function feedbackNo() {
      document.getElementById("overlap").style.display = "none";
      document.getElementById("rejectNote").style.display = "none"
      navigate("/suggestions")
  }

  async function submitNote() {
    try {
      await axiosPrivate.post("/rejectsuggestion",{
        suggestion_id:state.suggestion_id,
        rejectNote:rejectNote
      })
    } catch (err) {
      throw err
    } finally {
      navigate("/suggestions")
    }
  }

  async function submitEntry(event) {
    event.preventDefault();
    const username = auth.username;
    try {
      const response = await axiosPrivate.post("/reactionentry", {
        enzymeName,
        AASequence,
        plantae,
        animaliaCoral,
        bacteria,
        fungi,
        animaliaMarineSponge,
        animalia,
        archaea,
        amoebozoa,
        viruses,
        animaliaInsecta,
        animaliaHuman,
        cyanobacteria,
        redAlgae,
        kingdomUnknown,
        classOne,
        classTwo,
        classUnknown,
        diType,
        monoType,
        sesqType,
        diIntType,
        triType,
        tetraType,
        sesterType,
        triIntType,
        tetraIntType,
        sesquarType,
        ggppsType,
        unknownType,
        species,
        uniprotId,
        genbankId,
        link,
        pubmedId,
        doi,
        reactantName,
        reactantChebi,
        reactantSmiles:encoding.convert(reactantSmiles,"Latin_1"),
        cosubstrateName,
        cosubstrateChebi,
        cosubstrateSmiles:encoding.convert(cosubstrateSmiles,"Latin_1"),
        productName,
        productChebi,
        productSmiles:encoding.convert(productSmiles,"Latin_1"),
        username,
        suggestion_id:state.suggestion_id
      });
      setRE_pair(response.data.RE_pair[0].RE_pair)
      setResponse(response.data.duplication)

      if (!response.data.duplication) {
        const elements = document.querySelectorAll("#body");
        elements.forEach((element) => {
          element.style.filter = "blur(3px)";
        });
        document.getElementById("overlap").style.display = "block";
        document.getElementById("submitConfirmation").style.display = "block";
      } else {
        const elements = document.querySelectorAll("#body");
        elements.forEach((element) => {
          element.style.filter = "blur(3px)";
        });
        document.getElementById("overlap").style.display = "block";
        document.getElementById("duplication").style.display = "block";
      }
    } catch (err) {
      throw err;
    }
  }

  async function submitOK() {
    if (document.getElementById("submitConfirmation")) {
      document.getElementById("submitConfirmation").style.display = "none";
    }
    if (document.getElementById("duplication")) {
      document.getElementById("duplication").style.display = "none";
    }
    document.getElementById("addMechanism").style.display = "block";
  }

  async function noButton() {
    document.getElementById("overlap").style.display = "none";
    document.getElementById("addMechanism").style.display = "none";
    const elements = document.querySelectorAll("#body");
    elements.forEach((element) => {
      element.style.filter = "blur(0px)";
    });
    setPlantae("");
    setAnimaliaCoral("");
    setBacteria("");
    setFungi("");
    setAnimaliaMarineSponge("");
    setAnimalia("");
    setArchaea("");
    setAmoebozoa("");
    setViruses("");
    setAnimaliaInsecta("");
    setAnimaliaHuman("");
    setCyanobacteria("");
    setRedAlgae("");
    setKingdomUnknown("");
    setClassOne("");
    setClassTwo("");
    setClassUnknown("");
    setSesqType("");
    setDiIntType("");
    setDiType("");
    setSesquarType("");
    setTriIntType("");
    setTetraIntType("");
    setTriType("");
    setGgppsType("");
    setSesterType("");
    setMonoType("");
    setTetraType("");
    setUnknownType("");
    setEnzymeName("")
    setAASequence("")
    setSpecies("")
    setGenbankId("")
    setUniprotId("")
    setDoi("")
    setPubmedId("")
    setLink("")
    setReactantChebi("")
    setCosubstrateChebi("")
    setProductChebi("")
    setReactantName("")
    setCosubstrateName("")
    setProductName("")
    setReactantSmiles("")
    setCosubstrateSmiles("")
    setProductSmiles("")
    const monoInput = await document.getElementById(
      "mono"
    );
    if (monoInput.checked == true) {
      monoInput.checked = false;
    }
    const sesqInput = await document.getElementById(
      "sesq"
    );
    if (sesqInput.checked == true) {
      sesqInput.checked = false;
    }
    const diintInput = await document.getElementById(
      "di-int"
    );
    if (diintInput.checked == true) {
      diintInput.checked = false;
    }
    const triInput = await document.getElementById(
      "tri"
    );
    if (triInput.checked == true) {
      triInput.checked = false;
    }
    const tetraInput = await document.getElementById(
      "tetra"
    );
    if (tetraInput.checked == true) {
      tetraInput.checked = false;
    }
    const sesterInput = await document.getElementById(
      "sester"
    );
    if (sesterInput.checked == true) {
      sesterInput.checked = false;
    }
    const triintInput = await document.getElementById(
      "tri-int"
    );
    if (triintInput.checked == true) {
      triintInput.checked = false;
    }
    const tetraintInput = await document.getElementById(
      "tetra-int"
    );
    if (tetraintInput.checked == true) {
      tetraintInput.checked = false;
    }
    const sesquarInput = await document.getElementById(
      "sesquar"
    );
    if (sesquarInput.checked == true) {
      sesquarInput.checked = false;
    }
    const ggppsInput = await document.getElementById(
      "ggpps"
    );
    if (ggppsInput.checked == true) {
      ggppsInput.checked = false;
    }
    const diInput = await document.getElementById(
      "di"
    );
    if (diInput.checked == true) {
      diInput.checked = false;
    }
    const classOne = await document.getElementById(
      "ClassOne"
    );
    if (classOne.checked == true) {
      classOne.checked = false;
    }
    const classTwo = await document.getElementById(
      "ClassTwo"
    );
    if (classTwo.checked == true) {
      classTwo.checked = false;
    }
    const plantae = await document.getElementById(
      "Plantae"
    );
    if (plantae.checked == true) {
      plantae.checked = false;
    }
    const animaliacoral = await document.getElementById(
      "AnimaliaCoral"
    );
    if (animaliacoral.checked == true) {
      animaliacoral.checked = false;
    }
    const bacteria = await document.getElementById(
      "Bacteria"
    );
    if (bacteria.checked == true) {
      bacteria.checked = false;
    }
    const fungi = await document.getElementById(
      "Fungi"
    );
    if (fungi.checked == true) {
      fungi.checked = false;
    }
    const animaliamarinesponge = await document.getElementById(
      "AnimaliaMarineSponge"
    );
    if (animaliamarinesponge.checked == true) {
      animaliamarinesponge.checked = false;
    }
    const animalia = await document.getElementById(
      "Animalia"
    );
    if (animalia.checked == true) {
      animalia.checked = false;
    }
    const archaea = await document.getElementById(
      "Archaea"
    );
    if (archaea.checked == true) {
      archaea.checked = false;
    }
    const amoebozoa = await document.getElementById(
      "Amoebozoa"
    );
    if (amoebozoa.checked == true) {
      amoebozoa.checked = false;
    }
    const viruses = await document.getElementById(
      "Viruses"
    );
    if (viruses.checked == true) {
      viruses.checked = false;
    }
    const animaliainsecta = await document.getElementById(
      "AnimaliaInsecta"
    );
    if (animaliainsecta.checked == true) {
      animaliainsecta.checked = false;
    }
    const animaliahuman = await document.getElementById(
      "AnimaliaHuman"
    );
    if (animaliahuman.checked == true) {
      animaliahuman.checked = false;
    }
    const cyanobacteria = await document.getElementById(
      "Cyanobacteria"
    );
    if (cyanobacteria.checked == true) {
      cyanobacteria.checked = false;
    }
    const redalgae = await document.getElementById(
      "RedAlgae"
    );
    if (redalgae.checked == true) {
      redalgae.checked = false;
    }
    const kingdomunknown = await document.getElementById(
      "Unknown"
    );
    if (kingdomunknown.checked == true) {
      kingdomunknown.checked = false;
    }
    const classUnknown = await document.getElementById(
      "unknown"
    );
    if (classUnknown.checked == true) {
      classUnknown.checked = false;
    }
  }

  async function yesButton() {
    document.getElementById("addMechanism").style.display = "none";
    navigate("/newmechanism",{
      state: {
        RE_pair: RE_pair,
        enzyme_name: enzymeName,
        substrate_smiles:[reactantSmiles],
        product_smiles:[productSmiles],
        substrate_name:reactantName,
        product_name:productName,
        product_chebi:[productChebi],
        row:0
      }
    });
  }

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css"
          rel="stylesheet"
        />
      </head>
      <body id="body">
        <HomeNavbar/>
        <div
          class="row"
          id="entrycontent"
          style={{ paddingTop: "100px" }}
        >
          <div class="col-lg-12" align="center">
            <div class="row">
              <div class="col-lg-12">
                <form id="ReactionInputs">
                  <div id="Enzyme" align="left">
                    <h5>Enzyme:</h5>
                  </div>
                  <div id="enzymeInputs" align="left">
                    <label for="EnzymeName">Name:</label>
                    <input
                      id="EnzymeName"
                      onChange={async (event) => {
                        setEnzymeName(event.target.value);
                      }}
                      value={enzymeName}
                    />
                    <label for="EnzymeAASequence">
                      {" "}
                      <FontAwesomeIcon
                        icon={faAsterisk}
                        color="red"
                        fontSize="12"
                      />{" "}
                      Aminoacid sequence:
                    </label>
                    <textarea
                      id="EnzymeAASequence"
                      onChange={async (event) => {
                        setAASequence(event.target.value);
                      }}
                      value={AASequence}
                    />
                    <label for="KingdomRadioInputs">
                      {" "}
                      <FontAwesomeIcon
                        icon={faAsterisk}
                        color="red"
                        fontSize="12"
                      />{" "}
                      Kingdom:
                    </label>
                    <div class="row" id="KingdomRadioInputs" align="right">
                      <div class="col-lg-4" align="left">
                        <input
                          type="radio"
                          id="Plantae"
                          name="kingdom"
                          onClick={async (e) => {
                            setPlantae("Plantae");
                            setAnimaliaCoral("");
                            setBacteria("");
                            setFungi("");
                            setAnimaliaMarineSponge("");
                            setAnimalia("");
                            setArchaea("");
                            setAmoebozoa("");
                            setViruses("");
                            setAnimaliaInsecta("");
                            setAnimaliaHuman("");
                            setCyanobacteria("");
                            setRedAlgae("");
                            setKingdomUnknown("");
                          }}
                        />
                        <label for="Plantae" value="Plantae">
                          Plantae
                        </label>
                        <br />
                        <input
                          type="radio"
                          id="AnimaliaCoral"
                          name="kingdom"
                          onClick={async (e) => {
                            setPlantae("");
                            setAnimaliaCoral("Animalia (Coral)");
                            setBacteria("");
                            setFungi("");
                            setAnimaliaMarineSponge("");
                            setAnimalia("");
                            setArchaea("");
                            setAmoebozoa("");
                            setViruses("");
                            setAnimaliaInsecta("");
                            setAnimaliaHuman("");
                            setCyanobacteria("");
                            setRedAlgae("");
                            setKingdomUnknown("");
                          }}
                        />
                        <label for="AnimaliaCoral" value="Animalia (Coral)">
                          Animalia (Coral)
                        </label>
                        <br />
                        <input
                          type="radio"
                          id="Bacteria"
                          name="kingdom"
                          onClick={async (e) => {
                            setPlantae("");
                            setAnimaliaCoral("");
                            setBacteria("Bacteria");
                            setFungi("");
                            setAnimaliaMarineSponge("");
                            setAnimalia("");
                            setArchaea("");
                            setAmoebozoa("");
                            setViruses("");
                            setAnimaliaInsecta("");
                            setAnimaliaHuman("");
                            setCyanobacteria("");
                            setRedAlgae("");
                            setKingdomUnknown("");
                          }}
                        />
                        <label for="Bacteria" value="Bacteria">
                          Bacteria
                        </label>
                        <br />
                        <input
                          type="radio"
                          id="Fungi"
                          name="kingdom"
                          onClick={async (e) => {
                            setPlantae("");
                            setAnimaliaCoral("");
                            setBacteria("");
                            setFungi("Fungi");
                            setAnimaliaMarineSponge("");
                            setAnimalia("");
                            setArchaea("");
                            setAmoebozoa("");
                            setViruses("");
                            setAnimaliaInsecta("");
                            setAnimaliaHuman("");
                            setCyanobacteria("");
                            setRedAlgae("");
                            setKingdomUnknown("");
                          }}
                        />
                        <label for="Fungi" value="Fungi">
                          Fungi
                        </label>
                        <br />
                        <input
                          type="radio"
                          id="AnimaliaMarineSponge"
                          name="kingdom"
                          onClick={async (e) => {
                            setPlantae("");
                            setAnimaliaCoral("");
                            setBacteria("");
                            setFungi("");
                            setAnimaliaMarineSponge("Animalia (Marine Sponge)");
                            setAnimalia("");
                            setArchaea("");
                            setAmoebozoa("");
                            setViruses("");
                            setAnimaliaInsecta("");
                            setAnimaliaHuman("");
                            setCyanobacteria("");
                            setRedAlgae("");
                            setKingdomUnknown("");
                          }}
                        />
                        <label
                          for="AnimaliaMarineSponge"
                          value="Animalia (Marine Sponge)"
                        >
                          Animalia (Marine Sponge)
                        </label>
                        <br />
                      </div>
                      <div class="col-lg-4" align="left">
                        <input
                          type="radio"
                          id="Animalia"
                          name="kingdom"
                          onClick={async (e) => {
                            setPlantae("");
                            setAnimaliaCoral("");
                            setBacteria("");
                            setFungi("");
                            setAnimaliaMarineSponge("");
                            setAnimalia("Animalia");
                            setArchaea("");
                            setAmoebozoa("");
                            setViruses("");
                            setAnimaliaInsecta("");
                            setAnimaliaHuman("");
                            setCyanobacteria("");
                            setRedAlgae("");
                            setKingdomUnknown("");
                          }}
                        />
                        <label for="Animalia" value="Animalia">
                          Animalia
                        </label>
                        <br />
                        <input
                          type="radio"
                          id="Archaea"
                          name="kingdom"
                          onClick={async (e) => {
                            setPlantae("");
                            setAnimaliaCoral("");
                            setBacteria("");
                            setFungi("");
                            setAnimaliaMarineSponge("");
                            setAnimalia("");
                            setArchaea("Archaea");
                            setAmoebozoa("");
                            setViruses("");
                            setAnimaliaInsecta("");
                            setAnimaliaHuman("");
                            setCyanobacteria("");
                            setRedAlgae("");
                            setKingdomUnknown("");
                          }}
                        />
                        <label for="Archaea" value="Archaea">
                          Archaea
                        </label>
                        <br />
                        <input
                          type="radio"
                          id="Amoebozoa"
                          name="kingdom"
                          onClick={async (e) => {
                            setPlantae("");
                            setAnimaliaCoral("");
                            setBacteria("");
                            setFungi("");
                            setAnimaliaMarineSponge("");
                            setAnimalia("");
                            setArchaea("");
                            setAmoebozoa("Amoebozoa");
                            setViruses("");
                            setAnimaliaInsecta("");
                            setAnimaliaHuman("");
                            setCyanobacteria("");
                            setRedAlgae("");
                            setKingdomUnknown("");
                          }}
                        />
                        <label for="Amoebozoa" value="Amoebozoa">
                          Amoebozoa
                        </label>
                        <br />
                        <input
                          type="radio"
                          id="Viruses"
                          name="kingdom"
                          onClick={async (e) => {
                            setPlantae("");
                            setAnimaliaCoral("");
                            setBacteria("");
                            setFungi("");
                            setAnimaliaMarineSponge("");
                            setAnimalia("");
                            setArchaea("");
                            setAmoebozoa("");
                            setViruses("Viruses");
                            setAnimaliaInsecta("");
                            setAnimaliaHuman("");
                            setCyanobacteria("");
                            setRedAlgae("");
                            setKingdomUnknown("");
                          }}
                        />
                        <label for="Viruses" value="Viruses">
                          Viruses
                        </label>
                        <br />
                        <input
                          type="radio"
                          id="AnimaliaInsecta"
                          name="kingdom"
                          onClick={async (e) => {
                            setPlantae("");
                            setAnimaliaCoral("");
                            setBacteria("");
                            setFungi("");
                            setAnimaliaMarineSponge("");
                            setAnimalia("");
                            setArchaea("");
                            setAmoebozoa("");
                            setViruses("");
                            setAnimaliaInsecta("Animalia (Insecta)");
                            setAnimaliaHuman("");
                            setCyanobacteria("");
                            setRedAlgae("");
                            setKingdomUnknown("");
                          }}
                        />
                        <label for="AnimaliaInsecta" value="Animalia (Insecta)">
                          Animalia (Insecta)
                        </label>
                        <br />
                      </div>
                      <div class="col-lg-4" align="left">
                        <input
                          type="radio"
                          id="AnimaliaHuman"
                          name="kingdom"
                          onClick={async (e) => {
                            setPlantae("");
                            setAnimaliaCoral("");
                            setBacteria("");
                            setFungi("");
                            setAnimaliaMarineSponge("");
                            setAnimalia("");
                            setArchaea("");
                            setAmoebozoa("");
                            setViruses("");
                            setAnimaliaInsecta("");
                            setAnimaliaHuman("Animalia (Human)");
                            setCyanobacteria("");
                            setRedAlgae("");
                            setKingdomUnknown("");
                          }}
                        />
                        <label for="AnimaliaHuman" value="Animalia (Human)">
                          Animalia (Human)
                        </label>
                        <br />
                        <input
                          type="radio"
                          id="Cyanobacteria"
                          name="kingdom"
                          onClick={async (e) => {
                            setPlantae("");
                            setAnimaliaCoral("");
                            setBacteria("");
                            setFungi("");
                            setAnimaliaMarineSponge("");
                            setAnimalia("");
                            setArchaea("");
                            setAmoebozoa("");
                            setViruses("");
                            setAnimaliaInsecta("");
                            setAnimaliaHuman("");
                            setCyanobacteria("Cyanobacteria");
                            setRedAlgae("");
                            setKingdomUnknown("");
                          }}
                        />
                        <label for="Cyanobacteria" value="Cyanobacteria">
                          Cyanobacteria
                        </label>
                        <br />
                        <input
                          type="radio"
                          id="RedAlgae"
                          name="kingdom"
                          onClick={async (e) => {
                            setPlantae("");
                            setAnimaliaCoral("");
                            setBacteria("");
                            setFungi("");
                            setAnimaliaMarineSponge("");
                            setAnimalia("");
                            setArchaea("");
                            setAmoebozoa("");
                            setViruses("");
                            setAnimaliaInsecta("");
                            setAnimaliaHuman("");
                            setCyanobacteria("");
                            setRedAlgae("Red Algae");
                            setKingdomUnknown("");
                          }}
                        />
                        <label for="RedAlgae" value="Red Algae">
                          Red Algae
                        </label>
                        <br />
                        <input
                          type="radio"
                          id="Unknown"
                          name="kingdom"
                          onClick={async (e) => {
                            setPlantae("");
                            setAnimaliaCoral("");
                            setBacteria("");
                            setFungi("");
                            setAnimaliaMarineSponge("");
                            setAnimalia("");
                            setArchaea("");
                            setAmoebozoa("");
                            setViruses("");
                            setAnimaliaInsecta("");
                            setAnimaliaHuman("");
                            setCyanobacteria("");
                            setRedAlgae("");
                            setKingdomUnknown("Unknown");
                          }}
                        />
                        <label for="Unknown" value="Unknown">
                          Unknown
                        </label>
                        <br />
                      </div>
                    </div>
                    <label for="EnzymeSpecies">
                      <FontAwesomeIcon
                        icon={faAsterisk}
                        color="red"
                        fontSize="12"
                      />{" "}
                      Species:
                    </label>
                    <input
                      id="EnzymeSpecies"
                      onChange={async (event) => {
                        setSpecies(event.target.value);
                      }}
                      value={species}
                    />
                    <label for="ClassRadioInputs">
                      {" "}
                      <FontAwesomeIcon
                        icon={faAsterisk}
                        color="red"
                        fontSize="12"
                      />{" "}
                      Class:
                    </label>
                    <div class="row" id="ClassRadioInputs">
                      <div class="col-lg-4" align="left">
                        <input
                          type="radio"
                          id="ClassOne"
                          name="class"
                          onClick={async (e) => {
                            setClassOne("1");
                            setClassTwo("");
                            setClassUnknown("");
                          }}
                        />
                        <label for="ClassOne" value="1">
                          Class I
                        </label>
                      </div>
                      <div class="col-lg-4" align="left">
                        <input
                          type="radio"
                          id="ClassTwo"
                          name="class"
                          onClick={async (e) => {
                            setClassOne("");
                            setClassTwo("2");
                            setClassUnknown("");
                          }}
                        />
                        <label for="ClassTwo" value="2">
                          Class II
                        </label>
                      </div>
                      <div class="col-lg-4" align="left">
                        <input
                          type="radio"
                          id="unknown"
                          name="class"
                          onClick={async (e) => {
                            setClassOne("");
                            setClassTwo("");
                            setClassUnknown("Unknown");
                          }}
                        />
                        <label for="unknown" value="unknown">
                          Unknown
                        </label>
                      </div>
                    </div>
                    <label for="EnzymeUniprot">Uniprot ID:</label>
                    <input
                      id="EnzymeUniprot"
                      onChange={async (event) => {
                        setUniprotId(event.target.value);
                      }}
                      value={uniprotId}
                    />
                    <label for="EnzymeGenbank">Genbank ID:</label>
                    <input
                      id="EnzymeGenbank"
                      onChange={async (event) => {
                        setGenbankId(event.target.value);
                      }}
                      value={genbankId}
                    />
                    <label for="EnzymePublicationLink">
                      {" "}
                      <FontAwesomeIcon
                        icon={faAsterisk}
                        color="red"
                        fontSize="12"
                      />{" "}
                      Publication link:
                    </label>
                    <input
                      id="EnzymePublicationLink"
                      onChange={async (event) => {
                        setLink(event.target.value);
                      }}
                      value={link}
                    />
                    <label for="EnzymePMID">Pub Med ID:</label>
                    <input
                      id="EnzymePMID"
                      onChange={async (event) => {
                        setPubmedId(event.target.value);
                      }}
                      value={pubmedId}
                    />
                    <label for="EnzymePublicationDOI">Publication DOI:</label>
                    <input
                      id="EnzymePublicationDOI"
                      onChange={async (event) => {
                        setDoi(event.target.value);
                      }}
                      value={doi}
                    />
                    <label for="TypeRadioInputs">
                      {" "}
                      <FontAwesomeIcon
                        icon={faAsterisk}
                        color="red"
                        fontSize="12"
                      />{" "}
                      Type:
                    </label>
                    <div class="row" id="TypeRadioInputs">
                      <div class="col-lg-4" align="left">
                        <input
                          type="radio"
                          id="sesq"
                          name="type"
                          onClick={async (e) => {
                            setSesqType("sesq");
                            setDiIntType("");
                            setDiType("");
                            setSesquarType("");
                            setTriIntType("");
                            setTetraIntType("");
                            setTriType("");
                            setGgppsType("");
                            setSesterType("");
                            setMonoType("");
                            setTetraType("");
                            setUnknownType("");
                          }}
                        />
                        <label for="sesq" value="sesq">
                          Sesq terpenes
                        </label>
                        <br />
                        <input
                          type="radio"
                          id="di-int"
                          name="type"
                          onClick={async (e) => {
                            setSesqType("");
                            setDiIntType("di-int");
                            setDiType("");
                            setSesquarType("");
                            setTriIntType("");
                            setTetraIntType("");
                            setTriType("");
                            setGgppsType("");
                            setSesterType("");
                            setMonoType("");
                            setTetraType("");
                            setUnknownType("");
                          }}
                        />
                        <label for="di-int" value="di-int">
                          Di-int terpenes
                        </label>
                        <br />
                        <input
                          type="radio"
                          id="di"
                          name="type"
                          onClick={async (e) => {
                            setSesqType("");
                            setDiIntType("");
                            setDiType("di");
                            setSesquarType("");
                            setTriIntType("");
                            setTetraIntType("");
                            setTriType("");
                            setGgppsType("");
                            setSesterType("");
                            setMonoType("");
                            setTetraType("");
                            setUnknownType("");
                          }}
                        />
                        <label for="di" value="di">
                          Di terpenes
                        </label>
                        <br />
                        <input
                          type="radio"
                          id="mono"
                          name="type"
                          onClick={async (e) => {
                            setSesqType("");
                            setDiIntType("");
                            setDiType("");
                            setSesquarType("");
                            setTriIntType("");
                            setTetraIntType("");
                            setTriType("");
                            setGgppsType("");
                            setSesterType("");
                            setMonoType("mono");
                            setTetraType("");
                            setUnknownType("");
                          }}
                        />
                        <label for="mono" value="mono">
                          Mono terpenes
                        </label>
                        <br />
                      </div>
                      <div class="col-lg-4" align="left">
                        <input
                          type="radio"
                          id="tetra"
                          name="type"
                          onClick={async (e) => {
                            setSesqType("");
                            setDiIntType("");
                            setDiType("");
                            setSesquarType("");
                            setTriIntType("");
                            setTetraIntType("");
                            setTriType("");
                            setGgppsType("");
                            setSesterType("");
                            setMonoType("");
                            setTetraType("tetra");
                            setUnknownType("");
                          }}
                        />
                        <label for="tetra" value="tetra">
                          Tetra terpenes
                        </label>
                        <br />
                        <input
                          type="radio"
                          id="sester"
                          name="type"
                          onClick={async (e) => {
                            setSesqType("");
                            setDiIntType("");
                            setDiType("");
                            setSesquarType("");
                            setTriIntType("");
                            setTetraIntType("");
                            setTriType("");
                            setGgppsType("");
                            setSesterType("sester");
                            setMonoType("");
                            setTetraType("");
                            setUnknownType("");
                          }}
                        />
                        <label for="sester" value="sester">
                          Sester terpenes
                        </label>
                        <br />
                        <input
                          type="radio"
                          id="tri-int"
                          name="type"
                          onClick={async (e) => {
                            setSesqType("");
                            setDiIntType("");
                            setDiType("");
                            setSesquarType("");
                            setTriIntType("tri-int");
                            setTetraIntType("");
                            setTriType("");
                            setGgppsType("");
                            setSesterType("");
                            setMonoType("");
                            setTetraType("");
                            setUnknownType("");
                          }}
                        />
                        <label for="tri-int" value="tri-int">
                          Tri-int terpenes
                        </label>
                        <br />
                        <input
                          type="radio"
                          id="tetra-int"
                          name="type"
                          onClick={async (e) => {
                            setSesqType("");
                            setDiIntType("");
                            setDiType("");
                            setSesquarType("");
                            setTriIntType("");
                            setTetraIntType("tetra-int");
                            setTriType("");
                            setGgppsType("");
                            setSesterType("");
                            setMonoType("");
                            setTetraType("");
                            setUnknownType("");
                          }}
                        />
                        <label for="tetra-int" value="tetra-int">
                          Tetra-int terpenes
                        </label>
                        <br />
                      </div>
                      <div class="col-lg-4" align="left">
                        <input
                          type="radio"
                          id="tri"
                          name="type"
                          onClick={async (e) => {
                            setSesqType("");
                            setDiIntType("");
                            setDiType("");
                            setSesquarType("");
                            setTriIntType("");
                            setTetraIntType("");
                            setTriType("tri");
                            setGgppsType("");
                            setSesterType("");
                            setMonoType("");
                            setTetraType("");
                            setUnknownType("");
                          }}
                        />
                        <label for="tri" value="tri">
                          Tri terpenes
                        </label>
                        <br />
                        <input
                          type="radio"
                          id="sesquar"
                          name="type"
                          onClick={async (e) => {
                            setSesqType("");
                            setDiIntType("");
                            setDiType("");
                            setSesquarType("sesquar");
                            setTriIntType("");
                            setTetraIntType("");
                            setTriType("");
                            setGgppsType("");
                            setSesterType("");
                            setMonoType("");
                            setTetraType("");
                            setUnknownType("");
                          }}
                        />
                        <label for="sesquar" value="sesquar">
                          Sesquar terpenes
                        </label>
                        <br />
                        <input
                          type="radio"
                          id="ggpps"
                          name="type"
                          onClick={async (e) => {
                            setSesqType("");
                            setDiIntType("");
                            setDiType("");
                            setSesquarType("");
                            setTriIntType("");
                            setTetraIntType("");
                            setTriType("");
                            setGgppsType("ggpps");
                            setSesterType("");
                            setMonoType("");
                            setTetraType("");
                            setUnknownType("");
                          }}
                        />
                        <label for="ggpps" value="ggpps">
                          Ggpps terpenes
                        </label>
                        <br />
                        <input
                          type="radio"
                          id="nan"
                          name="type"
                          onClick={async (e) => {
                            setSesqType("");
                            setDiIntType("");
                            setDiType("");
                            setSesquarType("");
                            setTriIntType("");
                            setTetraIntType("");
                            setTriType("");
                            setGgppsType("");
                            setSesterType("");
                            setMonoType("");
                            setTetraType("");
                            setUnknownType("Unknown");
                          }}
                        />
                        <label for="nan" value="nan">
                          Unknown
                        </label>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div id="Reactant" align="left">
                    <h5>Substrate:</h5>
                  </div>
                  <div id="reactantInputs" align="left">
                    <label for="ReactantName">Name:</label>
                    <input
                      id="ReactantName"
                      onChange={async (event) => {
                        setReactantName(event.target.value);
                      }}
                      value={reactantName}
                    />
                    <label for="ReactantChebi">ChEBI:</label>
                    <input
                      id="ReactantChebi"
                      onChange={async (event) => {
                        setReactantChebi(event.target.value);
                      }}
                      value={reactantChebi}
                    />
                    <label for="ReactantSMILES">
                      {" "}
                      <FontAwesomeIcon
                        icon={faAsterisk}
                        color="red"
                        fontSize="12"
                      />{" "}
                      SMILES:
                    </label>
                    <textarea
                      id="ReactantSMILES"
                      onChange={async (event) => {
                        setReactantSmiles(event.target.value);
                      }}
                      value={reactantSmiles}
                    />
                  </div>
                  <div id="Cosubstrate" align="left">
                    <h5>Cosubstrate:</h5>
                  </div>
                  <div id="cosubstrateInputs" align="left">
                    <label for="CosubstrateName">Name:</label>
                    <input
                      id="CosubstrateName"
                      onChange={async (event) => {
                        setCosubstrateName(event.target.value);
                      }}
                      value={cosubstrateName}
                    />
                    <label for="CosubstrateChebi">ChEBI:</label>
                    <input
                      id="CosubstrateChebi"
                      onChange={async (event) => {
                        setCosubstrateChebi(event.target.value);
                      }}
                      value={cosubstrateChebi}
                    />
                    <label for="CosubstrateSMILES">

                      SMILES:
                    </label>
                    <textarea
                      id="CosubstrateSMILES"
                      onChange={async (event) => {
                        setCosubstrateSmiles(event.target.value);
                      }}
                      value={cosubstrateSmiles}
                    />
                  </div>
                  <div id="Product" align="left">
                    <h5>Product:</h5>
                  </div>
                  <div id="productInputs" align="left">
                    <label for="ProductName">Name:</label>
                    <input
                      id="ProductName"
                      onChange={async (event) => {
                        setProductName(event.target.value);
                      }}
                      value={productName}
                    />
                    <label for="ProductChebi">ChEBI:</label>
                    <input
                      id="ProductChebi"
                      onChange={async (event) => {
                        setProductChebi(event.target.value);
                      }}
                      value={productChebi}
                    />
                    <label for="ProductSMILES">
                      {" "}
                      <FontAwesomeIcon
                        icon={faAsterisk}
                        color="red"
                        fontSize="12"
                      />{" "}
                      SMILES:
                    </label>
                    <textarea
                      id="ProductSMILES"
                      onChange={async (event) => {
                        setProductSmiles(event.target.value);
                      }}
                      value={productSmiles}
                    />
                  </div>
                  <div style={{ marginTop: 10, marginBottom: 10 }}>
                    <ul style={{listStyle:"none",padding:0,display:"flex",justifyContent:"space-between"}}>
                      <li>
                    <button
                      id="confirmButton"
                      onClick={submitEntry}
                      disabled={
                        !AASequence ||
                        !(
                          plantae ||
                          animaliaCoral ||
                          bacteria ||
                          fungi ||
                          animaliaMarineSponge ||
                          animalia ||
                          archaea ||
                          amoebozoa ||
                          viruses ||
                          animaliaInsecta ||
                          animaliaHuman ||
                          cyanobacteria ||
                          redAlgae ||
                          kingdomUnknown
                        ) ||
                        !species ||
                        !(classOne || classTwo || classUnknown) ||
                        !link ||
                        !(
                          sesqType ||
                          diIntType ||
                          diType ||
                          sesquarType ||
                          triIntType ||
                          tetraIntType ||
                          triType ||
                          ggppsType ||
                          sesterType ||
                          monoType ||
                          tetraType ||
                          unknownType
                        ) ||
                        !reactantSmiles ||
                        !productSmiles
                          ? true
                          : false
                      }
                      style={{
                        opacity:
                          !AASequence ||
                          !(
                            plantae ||
                            animaliaCoral ||
                            bacteria ||
                            fungi ||
                            animaliaMarineSponge ||
                            animalia ||
                            archaea ||
                            amoebozoa ||
                            viruses ||
                            animaliaInsecta ||
                            animaliaHuman ||
                            cyanobacteria ||
                            redAlgae ||
                            kingdomUnknown
                          ) ||
                          !species ||
                          !(classOne || classTwo || classUnknown) ||
                          !link ||
                          !(
                            sesqType ||
                            diIntType ||
                            diType ||
                            sesquarType ||
                            triIntType ||
                            tetraIntType ||
                            triType ||
                            ggppsType ||
                            sesterType ||
                            monoType ||
                            tetraType ||
                            unknownType
                          ) ||
                          !reactantSmiles ||
                          !productSmiles
                            ? "0.5"
                            : "1",
                      }}
                    >
                      Submit
                    </button>
                    </li>
                    <li style={state ? {display:"block"} : {display:"none"}}>
                      <button id="confirmButton" onClick={rejectSuggestion}>
                        Reject
                      </button>
                    </li>
                    </ul>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div id="entryfooter">
          <Footer />
        </div>
        
      </body>
      <div id="overlap" style={{ display: "none" }}>

        <div id="rejectNote" align="left" style={{display:"none",width:"50%", zIndex:5}}>
            <ul>
              <li>
                <FontAwesomeIcon icon={faCheck} style={{ color: "green" }} />{" "}
                Suggestion has been successfully rejected. Would you like to provide a feedback?
              </li>
              <li>
                <button id="yes" onClick={feedbackYes}>
                  Yes
                </button>
              </li>
              <li>
                <button id="no" onClick={feedbackNo}>
                  No
                </button>
              </li>
            </ul>
          </div>

          <div id="RejectNote" style={{ display: "none" }}>
              <ul>
                <li>
                  <label for="mechanisminput">
                    <>Post a feedback: </>
                  </label>
                  <textarea
                    placeholder="Feedback note"
                    id="mechanisminput"
                    value={rejectNote}
                    maxLength={500}
                    onChange={(event) => {
                      setRejectNote(event.target.value);
                      setNoteLength(event.target.value.split("").length)
                    }}
                  />
                  <p style={{fontSize:14}} align="left">{noteLength}/500</p>
                </li>
                <li>
                  <button id="confirmButton" onClick={submitNote}>
                    Submit
                  </button>
                </li>
              </ul>
            </div>

            <div id="submitConfirmation" align="left" style={{display:"none"}}>
            <ul>
              <li>
                <FontAwesomeIcon icon={faCheck} style={{ color: "green" }} />{" "}
                Your entry has been successfully submitted to the database.
              </li>
              <li>
                <button id="ok" onClick={submitOK}>
                  OK
                </button>
              </li>
            </ul>
          </div>
            <div id="duplication" align="left" style={{display:"none"}}>
            <ul>
              <li>
                <FontAwesomeIcon
                  icon={faExclamationCircle}
                  style={{ color: "red" }}
                />{" "}
                Your entry contained one or more duplicate values.<br></br>
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ color: "green" }}
                />{" "}
                Duplicates were not inserted but your entry remains valid.
              </li>
              <li>
                <button id="ok" onClick={submitOK}>
                  OK
                </button>
              </li>
            </ul>
          </div>
          <div id="addMechanism" align="left" style={{ display: "none" }}>
            <ul>
              <li>Do you want to enter a mechanism for this reaction?</li>
              <li>
                <button id="yes" onClick={yesButton}>
                  Yes
                </button>
                <button id="no" onClick={noButton}>
                  No
                </button>
              </li>
            </ul>
          </div>
        </div>
    </html>
  );
}

export default Reaction;
