import { faExternalLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function GenbankLink({
  tableRow,
  seeAllReactions,
  setIsLoading,
  count,
  onlyMechanism,
  pageCount,
  setDataCount,
  setQuickSearchValue,
  search,
  renderData,
  setPageCount,
  setButtonCount,
  setCount,
  ///////////////////////////////////////////////////////////////FOR REACTION SEARCH//////////////////////////////////////////////////
  buttonCount,
  setGenbank,
  name,
  terpType,
  enzClass,
  uniprot,
  genbank,
  reactSmiles,
  reactChebi,
  reactName,
  prodChebi,
  prodName,
  prodSmiles,
  setSearchSubmit,
  showInput,
  enzymeInputClicked,
  setEnzymeInputClicked,
  setEnzymeInputUnclicked,
  setFirstSearch,
  kingdom,
  ///////////////////////////////////////////////////////////////FOR MECHANISM SEARCH//////////////////////////////////////////////////
  evidence,
  mechanismUnclicked,
  intermediateSmiles,
}) {
  ///////////////////////////////////////////////////////////////FIRST FUNCTION FOR QUICKSEARCH//////////////////////////////////////////////////
  const findGenbank = async function (genbankID) {
    try {
      setFirstSearch(true);
      setButtonCount(1);
      setCount((count = 0));
      setQuickSearchValue(genbankID);
      if (pageCount === 1) {
        const result = await search(
          seeAllReactions,
          setIsLoading,
          count,
          onlyMechanism,
          genbankID,
          pageCount,
          setDataCount
        );
        renderData(result?.table);
      } else {
        setPageCount(1);
        setButtonCount(1);
        setCount((count = 0));
      }
    } catch (err) {
      throw err;
    }
  };
  ///////////////////////////////////////////////////////////////SECOND FUNCTION FOR ADVANCED SEARCH//////////////////////////////////////////////////
  async function findGenbankAdvanced(genbank) {
    try {
      setFirstSearch(true);
      if (!enzymeInputClicked) {
        showInput(
          [
            "NameInput",
            "TypeInput",
            "ClassInput",
            "UniprotInput",
            "GenbankInput",
            "KingdomInput",
          ],
          "enzymeDD",
          enzymeInputClicked,
          setEnzymeInputClicked,
          "EnzymeInputs",
          setEnzymeInputUnclicked
        );
      }
      setGenbank(genbank);
      if (pageCount === 1) {
        setFirstSearch(true);
        setButtonCount(1);
        const result = await search(
          name,
          terpType,
          kingdom,
          enzClass,
          uniprot,
          genbank,
          reactSmiles,
          reactChebi,
          reactName,
          prodChebi,
          prodName,
          prodSmiles,
          pageCount,
          buttonCount,
          setIsLoading,
          setSearchSubmit,
          setDataCount,
          evidence,
          mechanismUnclicked,
          intermediateSmiles
        );
        renderData(result);
      } else {
        setFirstSearch(true);
        setPageCount(1);
        setButtonCount(1);
      }
    } catch (err) {
      throw err;
    }
  }

  return (
    <>
      {tableRow.genbank_id ? (
        <>
          <div style={{ position: "relative" }}>
            Genbank ID:{" "}
            <a
              style={{ position: "relative" }}
              id="navigateGenbank"
              href={`https://www.ncbi.nlm.nih.gov/protein/${tableRow.genbank_id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faExternalLink} color="rgb(0,0,0,0.8)" />
              <div id="goToGenbank" style={{ width: "max-content" }}>
                Find in NCBI
              </div>
            </a>
            <button
              id="genbankQuickSearch"
              style={{ backgroundColor: "rgb(245,245,245)", border: "none" }}
              onClick={() =>
                count != undefined
                  ? findGenbank(tableRow.genbank_id)
                  : findGenbankAdvanced(tableRow.genbank_id)
              }
            >
              {tableRow.genbank_id}
            </button>{" "}
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default GenbankLink;
