import Footer from "./Footer.js";
import HomeNavbar from "../HomeNavbar.js";
import {
  faExclamationCircle,
  faCheck,
  faTimes,
  faInfoCircle
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import SuggestionTable from "../suggestionTable.js";
import { Link } from "react-router-dom";
import ReactionTable from "../reactionTable.js";
import { useState } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate.js";
import useAuth from "../hooks/useAuth.js";
import { useNavigate } from "react-router-dom";

function AllSuggestions() {
  const [reactionDetails, setReactionDetails] = useState({});
  const [reactionBody, setReactionBody] = useState([]);
  const [suggestUnclicked, setSuggestUnlicked] = useState(false);
  const [result, setResult] = useState([]);
  const [suggestionCount, setSuggestionCount] = useState(1);
  const [table, setTable] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const navigate = useNavigate();

  let body = [];
  let reaction_body = [];

  function review(row) {
    if (
      (table[row].enzyme_name ||
        table[row].aminoacid_sequence ||
        table[row].kingdom ||
        table[row].species ||
        table[row].class ||
        table[row].type ||
        table[row].uniprot_id ||
        table[row].genbank_id ||
        table[row].substrate_smiles ||
        table[row].substrate_chebi ||
        table[row].substrate_name ||
        table[row].cosubstrate_smiles ||
        table[row].cosubstrate_chebi ||
        table[row].cosubstrate_name ||
        table[row].product_smiles ||
        table[row].product_name ||
        table[row].product_chebi) &&
      !table[row].re_pair
    ) {
      navigate("/reactionentry", {
        state: {
          enzyme_name: table[row].enzyme_name,
          aminoacid_sequence: table[row].aminoacid_sequence,
          kingdom: table[row].kingdom,
          species: table[row].species,
          class: table[row].class,
          type: table[row].type,
          uniprot_id: table[row].uniprot_id,
          genbank_id: table[row].genbank_id,
          substrate_smiles: table[row].substrate_smiles,
          substrate_chebi: table[row].substrate_chebi,
          substrate_name: table[row].substrate_name,
          cosubstrate_smiles: table[row].cosubstrate_smiles,
          cosubstrate_chebi: table[row].cosubstrate_chebi,
          cosubstrate_name: table[row].cosubstrate_name,
          product_smiles: table[row].product_smiles,
          product_name: table[row].product_name,
          product_chebi: table[row].product_chebi,
          suggestion_id: table[row].suggestion_id,
          publication_link: table[row].publication_link,
          publication_pmid: table[row].publication_pmid,
          publication_doi: table[row].publication_doi,
        },
      });
    } else if (table[row].re_pair) {
      navigate("/newmechanism", {
        state: {
          product_name: table[row].product_name,
          product_chebi: [table[row].product_chebi],
          product_smiles: [table[row].product_smiles],
          substrate_smiles: [table[row].substrate_smiles],
          RE_pair: [table[row].re_pair],
          suggestion_id:table[row].suggestion_id,
          row: 0,
        },
      });
    } else {
      navigate("/reactionentry");
    }
  }

  async function suggest(row) {
    if (document.getElementById(`suggestDiv${row}`).style.display === "none") {
      document.getElementById(`suggestDiv${row}`).style.display = "block";
      document.getElementById(`suggestInput${row}`).value = "";
      for (let i = 0; i < suggestionCount; i++) {
        if (i != row) {
          if (document.getElementById(`suggestDiv${i}`)) {
            document.getElementById(`suggestDiv${i}`).style.display = "none";
            document.getElementById(`suggestInput${i}`).value = "";
          }
        }
      }
    } else {
      document.getElementById(`suggestDiv${row}`).style.display = "none";
      document.getElementById(`suggestInput${row}`).value = "";
      setSuggestUnlicked((current) => !current);
      for (let i = 0; i < suggestionCount; i++) {
        if (i != row) {
          if (document.getElementById(`suggestDiv${i}`)) {
            document.getElementById(`suggestDiv${i}`).style.display = "none";
            document.getElementById(`suggestInput${i}`).value = "";
          }
        }
      }
    }
  }

  async function suggestNote(row) {
    if (
      document.getElementById(`suggestDivNote${row}`).style.display === "none"
    ) {
      document.getElementById(`suggestDivNote${row}`).style.display = "block";
      document.getElementById(`suggestInputNote${row}`).value = "";
      for (let i = 0; i < suggestionCount; i++) {
        if (i != row) {
          if (document.getElementById(`suggestDivNote${i}`)) {
            document.getElementById(`suggestDivNote${i}`).style.display =
              "none";
            document.getElementById(`suggestInputNote${i}`).value = "";
          }
        }
      }
    } else {
      document.getElementById(`suggestDivNote${row}`).style.display = "none";
      document.getElementById(`suggestInputNote${row}`).value = "";
      setSuggestUnlicked((current) => !current);
      for (let i = 0; i < suggestionCount; i++) {
        if (i != row) {
          if (document.getElementById(`suggestDivNote${i}`)) {
            document.getElementById(`suggestDivNote${i}`).style.display =
              "none";
            document.getElementById(`suggestInputNote${i}`).value = "";
          }
        }
      }
    }
  }

  async function submitSuggest(row) {
    const inputValue = document.getElementById(`suggestInput${row}`).value;
    document.getElementById(`innerTextSubmitted${row}`).style.display = "block";
    document.getElementById(`innerTextSubmit${row}`).style.display = "none";
    const suggestionId = table[row].suggestion_id;
    try {
      await axiosPrivate.post("/mechanismlink", {
        inputValue,
        suggestionId,
      });
    } catch (err) {
      throw err;
    } finally {
      setTimeout(() => {
        document.getElementById(`suggestDiv${row}`).style.display = "none";
        document.getElementById(`innerTextSubmitted${row}`).style.display =
          "none";
        document.getElementById(`innerTextSubmit${row}`).style.display =
          "block";
      }, 1000);
    }
  }

  async function submitSuggestNote(row) {
    const inputValueNote = document.getElementById(
      `suggestInputNote${row}`
    ).value;
    document.getElementById(`innerTextSubmittedNote${row}`).style.display =
      "block";
    document.getElementById(`innerTextSubmitNote${row}`).style.display = "none";
    const suggestionId = table[row].suggestion_id;
    try {
      await axiosPrivate.post("/mechanismlink", {
        inputValueNote,
        suggestionId,
      });
    } catch (err) {
      throw err;
    } finally {
      setTimeout(() => {
        document.getElementById(`suggestDivNote${row}`).style.display = "none";
        document.getElementById(`innerTextSubmittedNote${row}`).style.display =
          "none";
        document.getElementById(`innerTextSubmitNote${row}`).style.display =
          "block";
      }, 1000);
    }
  }

  async function displaySuggestions() {
    try {
      const response = await axiosPrivate.post("/allsuggestions");
      const table = response.data;
      setTable(table);
      setSuggestionCount(table.length);

      for (let row = 0; row < table.length; row++) {
        body.push([
          table[row].username,
          table[row].re_pair ? (
            <>Reaction already exists</>
          ) : table[row].publication_link ? (
            <>
              <Link to={table[row].publication_link}>
                {table[row].publication_link}
              </Link>
            </>
          ) : table[row].publication_doi ? (
            <>DOI: {table[row].publication_doi}</>
          ) : table[row].publication_pmid ? (
            <>PubMedID: {table[row].publication_pmid}</>
          ) : (
            <></>
          ),
          <Link to={table[row].mechanism_link}>{table[row].mechanism_link}</Link>
          ,
          table[row].note,
          table[row].enzyme_name &&
          table[row].substrate_smiles &&
          table[row].re_pair ? (
            <button
              align="center"
              className="seeReaction"
              onClick={() => {
                seeOneReaction(row);
              }}
              style={{ border: "none", backgroundColor: "rgb(245,245,245)" }}
            >
              See existing reaction
            </button>
          ) : table[row].product_smiles ? (
            <button
              align="center"
              className="seeReaction"
              onClick={() => {
                seeOneReaction(row);
              }}
              style={{ border: "none", backgroundColor: "rgb(245,245,245)" }}
            >
              See suggested reaction
            </button>
          ) : (
            ""
          ),
          table[row].review === 0 ? (
            <button
              onClick={() => review(row)}
              style={{ border: "none", backgroundColor: "rgb(245,245,245)" }}
            >
              Review now!
            </button>
          ) : table[row].review === 1 ? (
            <>
              <FontAwesomeIcon icon={faCheck} color="green" /> Reviewed!
            </>
          ) : (
            <div style={{ position: "relative" }} className="infoCircle">
            <nobr>
            <FontAwesomeIcon
              icon={faInfoCircle}
              color="black"
            />{" "}
            Rejected
            </nobr>
            <div
              id={`displayComment${row}`}
              className="displayComment"
              style={{
                position: "absolute",
                padding: 5,
                backgroundColor: "rgb(23, 55, 151)",
              }}
            >
              {table[row].comment ? (table[row].comment) : (<>No comment to display</>)}
            </div>
          </div>
          ),
        ]);
      }

      setResult(body);
    } catch (err) {
      console.error(err);
    }
  }

  async function seeOneReaction(row) {
    reaction_body = [];
    let reaction_details = {
      kingdom: [],
      species: [],
      aminoacid_sequence: [],
      class: [],
      type: [],
      ec_number: [],
      uniprot_id: [],
      genbank_id: [],
      substrate_smiles: [],
      substrate_chebi: [],
      product_smiles: [],
      product_chebi: [],
      link: [],
      doi: [],
      pmid: [],
    };
    setReactionBody([]);
    setReactionDetails({});
    document.getElementById("overlap").style.display = "block";
    document.getElementById("reactionDiv").style.display = "block";
    const elements = document.querySelectorAll("#body");
    elements.forEach((element) => {
      element.style.filter = "blur(3px)";
    });

    try {
      const response = await axiosPrivate.post("/mysuggestions");
      const table = response.data;

      reaction_details.kingdom.push(table[row].kingdom);
      reaction_details.species.push(table[row].species);
      reaction_details.aminoacid_sequence.push(table[row].aminoacid_sequence);
      reaction_details.class.push(table[row].class);
      reaction_details.type.push(table[row].type);
      reaction_details.uniprot_id.push(table[row].uniprot_id);
      reaction_details.genbank_id.push(table[row].genbank_id);
      reaction_details.substrate_chebi.push(table[row].substrate_chebi);
      reaction_details.product_chebi.push(table[row].product_chebi);
      reaction_details.substrate_smiles.push(table[row].substrate_smiles);
      reaction_details.product_smiles.push(table[row].product_smiles);
      reaction_details.link.push(table[row].publication_link);
      reaction_details.doi.push(table[row].publication_doi);
      reaction_details.pmid.push(table[row].publication_pmid);

      reaction_body.push([
        table[row].enzyme_name === "missing_value" ? (
          table[row].uniprot_id ? (
            <>Uniprot ID: {table[row].uniprot_id}</>
          ) : (
            <>Genbank ID: {table[row].genbank_id}</>
          )
        ) : table[row].uniprot_id ? (
          <>
            {table[row].enzyme_name}
            <br />
            Uniprot ID: {table[row].uniprot_id}
          </>
        ) : (
          <>
            {table[row].enzyme_name}
            <br />
            Genbank ID: {table[row].genbank_id}
          </>
        ),
        table[row].substrate_image ? (
          <>
            <img
              src={table[row].substrate_image}
              alt={table[row].substrate_smiles}
              className="responsive"
            />
            <br />
            {table[row].substrate_name}
          </>
        ) : (
          <>
            {table[row].substrate_smiles} <br />
            {table[row].substrate_name}
            <br />
          </>
        ),
        table[row].product_image ? (
          <>
            <img src={table[row].product_image} className="responsive" />
            <br />
            {table[row].product_name}
          </>
        ) : (
          <>
            {table[row].product_smiles} <br />
            {table[row].product_name}
          </>
        ),
      ]);

      setReactionBody(reaction_body);
      setReactionDetails(reaction_details);
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    displaySuggestions();
  }, [suggestionCount]);

  const heading = [
    "User",
    <>
      Raction <br /> reference
    </>,
    <>
      Mechanism <br /> reference
    </>,
    "Note",
    "Reaction",
    "Status",
  ];

  const reactionHeading = ["Enzyme", "Substrate", "Product"];

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css"
          rel="stylesheet"
        />
      </head>
      <body id="body">
        <HomeNavbar style={{ zIndex: 3 }} id="HomeNavbar" />

        <div
          style={{
            marginRight: 150,
            marginLeft: 150,
            paddingTop: 120,
            paddingBottom: 120,
          }}
        >
          {suggestionCount > 0 ? (
            <SuggestionTable body={result} heading={heading} />
          ) : (
            <>
              <div align="center">
                <h1>
                  <FontAwesomeIcon icon={faExclamationCircle} color="red" />
                </h1>
                <h1>No suggestions found!</h1>
                <p>
                  You have not posted any suggestions yet. Start by submitting
                  your first suggestion <Link to="/suggestreaction">here</Link>.{" "}
                </p>
              </div>
            </>
          )}
        </div>
        <Footer />
      </body>
      <div
        id="overlap"
        style={{
          display: "none",
          position: "fixed",
          top: 0,
          left: 0,
          overflow: "auto",
        }}
      >
        <div
          id="reactionDiv"
          style={{ marginLeft: 150, marginRight: 165, display: "none" }}
        >
          <ReactionTable
            heading={reactionHeading}
            body={reactionBody}
            details={reactionDetails}
            display={"block"}
          />
        </div>
      </div>
    </html>
  );
}

export default AllSuggestions;
