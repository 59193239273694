import {Outlet} from "react-router-dom"
import {useState,useEffect} from "react"
import useRefreshToken from "./hooks/useRefreshToken.js"
import useAuth from "./hooks/useAuth.js"
import Loading from "./Loading.js"


 function PersistLogin() {
     const [isLoading,setIsLoading] = useState(true)
     const refresh = useRefreshToken()
     const {auth} = useAuth()

     useEffect(() => {
         async function verifyRefreshToken() {
             try {
                 await refresh()
             } catch (err) {
                 console.error(err)
             } finally {
                 setIsLoading(false)
             }
         }
         !auth?.accessToken ? verifyRefreshToken() : setIsLoading(false)
     },[])


     return (
         <>
             {isLoading
                 ? <Loading/>
                 : <Outlet/>
             }
         </>



    )
}

export default PersistLogin