import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faCheck } from "@fortawesome/free-solid-svg-icons";

function CopySequence({ table, row }) {
  function copySequence(id) {
    let sequence = document.getElementById(id).innerText;
    let elem = document.createElement("textarea");
    document.body.appendChild(elem);
    sequence = sequence.trim();
    elem.value = sequence;
    elem.select();
    document.execCommand("copy");
    document.body.removeChild(elem);
  }

  return (
    <ul
      style={{
        display: "flex",
        listStyle: "none",
        padding: 0,
        justifyContent: "center",
        margin: "0 auto",
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "center",
      }}
    >
      <li style={{ position: "relative" }} >
        Sequence:{" "}
        <button
        id="sequence"
          style={{ border: "none", backgroundColor: "rgb(245,245,245)" }}
          onClick={() => {
            copySequence(`sequence${row}`);
            document.getElementById(`copySequence${row}`).style.display =
              "none";
            document.getElementById(`copiedSequence${row}`).style.display =
              "block";
            document.getElementById(`copiedSequence${row}`).style.opacity = 1;
          }}
          onMouseEnter={() => {
            document.getElementById(`copySequence${row}`).style.zIndex = 1;
            document.getElementById(`copiedSequence${row}`).style.zIndex = 1;
            document.getElementById(`copiedSequence${row}`).style.display =
              "none";
            document.getElementById(`copySequence${row}`).style.opacity = 1;
          }}
          onMouseLeave={() => {
            document.getElementById(`copiedSequence${row}`).style.opacity = 0;
            document.getElementById(`copySequence${row}`).style.opacity = 0;
            document.getElementById(`copySequence${row}`).style.zIndex = -1;
            document.getElementById(`copySequence${row}`).style.display =
              "block";
            document.getElementById(`copiedSequence${row}`).style.zIndex = -1;
          }}
        >
          <FontAwesomeIcon icon={faCopy} color="rgb(0,0,0,0.8)" />
        </button>
        <div id={`copySequence${row}`} className="copySequence" style={{right:0,top:"-2rem"}}>
          <nobr style={{ display: "inline-flex"}}>Copy sequence</nobr>
        </div>
        <div id={`copiedSequence${row}`} className="copiedSequence" style={{right:0,top:"-2rem"}}>
          <nobr style={{ display: "inline-flex" }}>
            <p style={{margin:"0 auto"}}>
            <FontAwesomeIcon icon={faCheck} color="white" /> Copied
            </p>
          </nobr>
        </div>
        <p id={`sequence${row}`} style={{ display: "none" }}>
          {table[row].aminoacid_sequence}
        </p>
      </li>
    </ul>
  );
}

export default CopySequence;
