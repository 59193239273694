import HomeNavbar from "../HomeNavbar.js";
import Footer from "./Footer.js";
import { useEffect } from "react";
import SuggestionTable from "../suggestionTable.js";
import { useState } from "react";
import { Link } from "react-router-dom";
import {
  faTimes,
  faCheck,
  faExclamationCircle,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useAxiosPrivate from "../hooks/useAxiosPrivate.js";
import useAuth from "../hooks/useAuth.js";
import "./MySuggestions.css";
import ReactionTable from "../reactionTable.js";
import { useNavigate } from "react-router-dom";

function MyComments() {
  const [reactionDetails, setReactionDetails] = useState({});
  const [reactionBody, setReactionBody] = useState([]);
  const [suggestUnclicked, setSuggestUnlicked] = useState(false);
  const [result, setResult] = useState([]);
  const [commentsCount, setCommentsCount] = useState(1);
  const [finishedMapping, setFinishedMapping] = useState(false);
  const [table, setTable] = useState([]);
  const [comments, setComments] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const navigate = useNavigate();

  let body = [];
  let reaction_body = [];
  let reaction_details = {
    kingdom: [],
    species: [],
    aminoacid_sequence: [],
    class: [],
    type: [],
    ec_number: [],
    uniprot_id: [],
    genbank_id: [],
    substrate_smiles: [],
    substrate_chebi: [],
    product_smiles: [],
    product_chebi: [],
    link: [],
    doi: [],
    pmid: [],
  };

  //   async function suggest(row) {
  //     if (document.getElementById(`suggestDiv${row}`).style.display === "none") {
  //       document.getElementById(`suggestDiv${row}`).style.display = "block";
  //       document.getElementById(`suggestInput${row}`).value = "";
  //       for (let i = 0; i < suggestionCount; i++) {
  //         if (i != row) {
  //           if (document.getElementById(`suggestDiv${i}`)) {
  //             document.getElementById(`suggestDiv${i}`).style.display = "none";
  //             document.getElementById(`suggestInput${i}`).value = "";
  //           }
  //         }
  //       }
  //     } else {
  //       document.getElementById(`suggestDiv${row}`).style.display = "none";
  //       document.getElementById(`suggestInput${row}`).value = "";
  //       setSuggestUnlicked((current) => !current);
  //       for (let i = 0; i < suggestionCount; i++) {
  //         if (i != row) {
  //           if (document.getElementById(`suggestDiv${i}`)) {
  //             document.getElementById(`suggestDiv${i}`).style.display = "none";
  //             document.getElementById(`suggestInput${i}`).value = "";
  //           }
  //         }
  //       }
  //     }
  //   }

  //   async function suggestNote(row) {
  //     if (
  //       document.getElementById(`suggestDivNote${row}`).style.display === "none"
  //     ) {
  //       document.getElementById(`suggestDivNote${row}`).style.display = "block";
  //       document.getElementById(`suggestInputNote${row}`).value = "";
  //       for (let i = 0; i < suggestionCount; i++) {
  //         if (i != row) {
  //           if (document.getElementById(`suggestDivNote${i}`)) {
  //             document.getElementById(`suggestDivNote${i}`).style.display =
  //               "none";
  //             document.getElementById(`suggestInputNote${i}`).value = "";
  //           }
  //         }
  //       }
  //     } else {
  //       document.getElementById(`suggestDivNote${row}`).style.display = "none";
  //       document.getElementById(`suggestInputNote${row}`).value = "";
  //       setSuggestUnlicked((current) => !current);
  //       for (let i = 0; i < suggestionCount; i++) {
  //         if (i != row) {
  //           if (document.getElementById(`suggestDivNote${i}`)) {
  //             document.getElementById(`suggestDivNote${i}`).style.display =
  //               "none";
  //             document.getElementById(`suggestInputNote${i}`).value = "";
  //           }
  //         }
  //       }
  //     }
  //   }

  //   async function submitSuggest(row) {
  //     const inputValue = document.getElementById(`suggestInput${row}`).value;
  //     document.getElementById(`innerTextSubmitted${row}`).style.display = "block";
  //     document.getElementById(`innerTextSubmit${row}`).style.display = "none";
  //     const suggestionId = table[row].suggestion_id;
  //     try {
  //       await axiosPrivate.post("/mechanismlink", {
  //         inputValue,
  //         suggestionId,
  //       });
  //     } catch (err) {
  //       throw err;
  //     } finally {
  //       setTimeout(() => {
  //         document.getElementById(`suggestDiv${row}`).style.display = "none";
  //         document.getElementById(`innerTextSubmitted${row}`).style.display =
  //           "none";
  //         document.getElementById(`innerTextSubmit${row}`).style.display =
  //           "block";
  //       }, 1000);
  //     }
  //   }

  //   async function submitSuggestNote(row) {
  //     const inputValueNote = document.getElementById(
  //       `suggestInputNote${row}`
  //     ).value;
  //     document.getElementById(`innerTextSubmittedNote${row}`).style.display =
  //       "block";
  //     document.getElementById(`innerTextSubmitNote${row}`).style.display = "none";
  //     const suggestionId = table[row].suggestion_id;
  //     try {
  //       await axiosPrivate.post("/mechanismlink", {
  //         inputValueNote,
  //         suggestionId,
  //       });
  //     } catch (err) {
  //       throw err;
  //     } finally {
  //       setTimeout(() => {
  //         document.getElementById(`suggestDivNote${row}`).style.display = "none";
  //         document.getElementById(`innerTextSubmittedNote${row}`).style.display =
  //           "none";
  //         document.getElementById(`innerTextSubmitNote${row}`).style.display =
  //           "block";
  //       }, 1000);
  //     }
  //   }

//   async function seeComments(row, subject) {
//     try {
//       const response = await axiosPrivate.post("/seecomments", {
//         username: auth.username,
//         enzyme_id: table[row]?.enzyme_id,
//         reaction_id: table[row]?.reaction_id,
//         substrate_id: table[row]?.substrate,
//         product_id: table[row]?.product,
//         RE_pair: table[row]?.reaction_enzyme_pair_id,
//         mechanism_id: table[row]?.mechanism_id,
//         subject: subject,
//       });

//       const result = response.data;
//       setComments(result);
//     } catch (err) {
//       throw err;
//     }
//   }

  async function displayComments() {
    let reaction_body = [];
    let reaction_details = {
      kingdom: [],
      species: [],
      aminoacid_sequence: [],
      class: [],
      type: [],
      ec_number: [],
      uniprot_id: [],
      genbank_id: [],
      substrate_smiles: [],
      substrate_chebi: [],
      product_smiles: [],
      product_chebi: [],
      link: [],
      doi: [],
      pmid: [],
    };
    try {
      const response = await axiosPrivate.post("/mycomments", {
        username: auth.username,
      });
      const table = response.data;
      setTable(table);


      for (let row = 0; row < table.length; row++) {


        const responseComm = await axiosPrivate.post("/seecomments", {
            username: auth.username,
            enzyme_id: table[row]?.enzyme_id,
            reaction_id: table[row]?.reaction_id,
            substrate_id: table[row]?.substrate,
            product_id: table[row]?.product,
            RE_pair: table[row]?.reaction_enzyme_pair_id,
            mechanism_id: table[row]?.mechanism_id,
            subject: "Type",
          });
    
          const tableComm = responseComm.data;


        let DDid = `DD${row}`;
        let TRid = `TR${row}`;
        let DDwhitespace = `DDwhitespace${row}`;
        let RE_pair = table[row].reaction_enzyme_pair_id;
        let enzyme_name = table[row].name;
        let reaction_substrate = table[row].substrate;
        let reaction_product = table[row].product;
        let substrate_name = table[row].substrate_name;
        let product_name = table[row].product_name;
        reaction_details.kingdom.push(table[row].kingdom);
        reaction_details.species.push(table[row].species);
        reaction_details.aminoacid_sequence.push(table[row].aminoacid_sequence);
        reaction_details.class.push(table[row].class);
        reaction_details.type.push(
          <>
            {table[row].type}
            {table[row].re_pair_subject === "Type" ? (
              <>
                <button onClick={() => {
                    if (document.getElementById("commentType").style.display == "none") {
                        document.getElementById("commentType").style.display = "block"
                    } else {
                        document.getElementById("commentType").style.display = "none"
                    }
                }}>
                  <FontAwesomeIcon icon={faExclamationCircle} color="red" />
                </button>
                <div id="commentType" style={{display:"none"}}>
                    
                  {tableComm.map((element) => { 
                    return (
                        <p key={tableComm.indexOf(element)}>{element.comment}</p>
                    );
                  })}

                </div>
              </>
            ) : (
              <></>
            )}
          </>
        );
        reaction_details.ec_number.push(table[row].ec_number);
        reaction_details.uniprot_id.push(table[row].uniprot_id);
        reaction_details.genbank_id.push(table[row].genbank_id);
        reaction_details.substrate_chebi.push(table[row].substrate_chebi);
        reaction_details.product_chebi.push(table[row].product_chebi);
        reaction_details.substrate_smiles.push(table[row].substrate_smiles);
        reaction_details.product_smiles.push(table[row].product_smiles);
        reaction_details.link.push(table[row].publication_link);
        reaction_details.doi.push(table[row].publication_doi);
        reaction_details.pmid.push(table[row].publication_pmid);

        reaction_body.push([
          table[row].name === "missing_value" ? (
            table[row].uniprot_id ? (
              <>Uniprot ID: {table[row].uniprot_id}</>
            ) : (
              <>Genbank ID: {table[row].genbank_id}</>
            )
          ) : table[row].uniprot_id ? (
            <>
              {table[row].name}
              <br />
              Uniprot ID: {table[row].uniprot_id}
            </>
          ) : (
            <>
              {table[row].name}
              <br />
              Genbank ID: {table[row].genbank_id}
            </>
          ),

          table[row].substrate_image ? (
            <>
              <img
                src={table[row].substrate_image}
                alt={table[row].substrate_smiles}
                className="responsive"
              />
              <br />
              {substrate_name}
            </>
          ) : (
            <>
              {table[row].substrate_smiles} <br />
              {table[row].substrate_name}
              <br />
            </>
          ),
          table[row].product_image ? (
            <>
              <img src={table[row].product_image} className="responsive" />
              <br />
              {product_name}
            </>
          ) : (
            <>
              {table[row].product_smiles} <br />
              {table[row].product_name}
            </>
          ),
          table[row].mechanism_id ? (
            <button
              id="entriesButton"
              onClick={() => {
                navigate("/mechanismview", {
                  state: {
                    RE_pair: RE_pair,
                    enzyme_name: enzyme_name,
                    reaction_substrate: reaction_substrate,
                    reaction_product: reaction_product,
                    details: reaction_details,
                    substrate_name: substrate_name,
                    product_name: product_name,
                    row: row,
                    location: "/reactionsearch",
                  },
                });
              }}
            >
              View mechanism
            </button>
          ) : (
            ""
          ),
          <button
            id="entriesButton"
            onClick={() => {
              let dropdown = document.getElementById(DDid);
              let whitespace = document.getElementById(DDwhitespace);
              dropdown.style.display === "none"
                ? (dropdown.style.display = "block")
                : (dropdown.style.display = "none");

              let dropdownHeight = dropdown.clientHeight;
              dropdown.style.display === "none"
                ? (whitespace.style.marginBottom = "0px")
                : (whitespace.style.marginBottom = `${dropdownHeight}px`);
            }}
          >
            View reaction details
          </button>,
        ]);
      }

      setReactionBody(reaction_body);
      setReactionDetails(reaction_details);
    } catch (err) {
      throw err;
    }
  }

  useEffect(() => {
    displayComments();
    console.log("triggered useEffect")
  }, [finishedMapping]);

  const reactionHeading = [
    "Enzyme",
    "Substrate",
    "Product",
    "Mechanism",
    "Details",
  ];

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css"
          rel="stylesheet"
        />
      </head>
      <body id="body">
        <HomeNavbar style={{ zIndex: 3 }} id="HomeNavbar" />

        <div
          style={{
            marginRight: 150,
            marginLeft: 150,
            paddingTop: 120,
            paddingBottom: 100,
          }}
        >
          <ReactionTable
            heading={reactionHeading}
            body={reactionBody}
            details={reactionDetails}
            display={"none"}
          />
        </div>
        <Footer />
      </body>
      <div
        id="overlap"
        style={{
          display: "none",
          position: "fixed",
          top: 0,
          left: 0,
          overflow: "auto",
        }}
      >
        <div
          id="reactionDiv"
          style={{ marginLeft: 150, marginRight: 165, display: "none" }}
        ></div>
      </div>
    </html>
  );
}

export default MyComments;
