import React, { Component } from "react";
import "./mechanismTable.css";
import { Link } from "react-router-dom";
import { faCopy, faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


class Table extends Component {
  render() {
    let heading = this.props.heading;
    let body = this.props.body;

    return (
      <table id="tableMech" align="center">
        <tbody style={{ position: "relative" }}>
          <tr id="headerRow">
            {heading.map((head, headID) => (
              <th id="headerCol">{head}
              {headID === (2) ? (<button
                  id="closeView"
                  style={{
                    border: "none",
                    backgroundColor: "rgb(235, 235, 235)",
                    position: "absolute",
                    top: "5px",
                    right: "5px",
                  }}
                  onClick={() => {
                    document.getElementById("overlap").style.display =
                      "none";
                    document.getElementById("mechDiv").style.display =
                      "none";
                    const elements = document.querySelectorAll("#body *");
                    elements.forEach((element) => {
                      element.style.filter = "unset";
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faTimes} color="rgb(150,150,150)" style={{fontWeight:"lighter",margin:0,padding:0}} />
                </button>) : (<></>)}
              </th>

            ))}
          </tr>

          {body.map((rowContent, rowID) => (
            <>
              <TableRow rowContent={rowContent} />
            </>
          ))}
        </tbody>
      </table>
    );
  }
}

class TableRow extends Component {
  render() {
    let row = this.props.rowContent;
    return (
      <tr>
        {row.map((val, rowID) => (
          <td key={rowID} id="dataColMech">
            {val}
          </td>
        ))}
      </tr>
    );
  }
}

export default Table;
