import "./SearchReaction.css";
import "./SuggestMechanism.css";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import Footer from "./Footer.js";
import ReactionTable from "../reactionTable.js";
import useLogout from "../hooks/useLogout.js";
import {
  faCheck,
  faTimes,
  faAngleDoubleDown,
  faDatabase,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import HomeNavbar from "../HomeNavbar.js";
import useAxiosPrivate from "../hooks/useAxiosPrivate.js";
import PageCounter from "./pageCounter.js";
import { useEffect } from "react";
import useAuth from "../hooks/useAuth.js";
import encoding from "encoding"

function SuggestMechanism() {
  const [fireUseEffect, setFireUseEffect] = useState(true);
  const [table, setTable] = useState([]);
  const [suggestionCount, setSuggestionCount] = useState(0);
  const [suggestUnclicked, setSuggestUnlicked] = useState(true);
  const [newSearch, setNewSearch] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [buttonCount, setButtonCount] = useState(1);
  const [dataCount, setDataCount] = useState(0);
  const [enzymeInputClicked, setEnzymeInputClicked] = useState(false);
  const [reactantInputClicked, setReactantInputClicked] = useState(false);
  const [productInputClicked, setProductInputClicked] = useState(false);
  const [enzymeInputUnclicked, setEnzymeInputUnclicked] = useState(true);
  const [reactantInputUnclicked, setReactantInputUnclicked] = useState(true);
  const [productInputUnclicked, setProductInputUnclicked] = useState(true);
  const [searchSubmit, setSearchSubmit] = useState(false);
  const [searchResult, setSearchResult] = useState("");
  const [details, setDetails] = useState("");
  const [name, setName] = useState("");
  const [terpType, setTerpType] = useState({
    di: "",
    mono: "",
    sesq: "",
    diint: "",
    tri: "",
    tetra: "",
    sester: "",
    triint: "",
    tetraint: "",
    sesquar: "",
    ggpps: "",
  });
  const [diType, setDiType] = useState("");
  const [monoType, setMonoType] = useState("");
  const [sesqType, setSesqType] = useState("");
  const [diIntType, setDiIntType] = useState("");
  const [triType, setTriType] = useState("");
  const [tetraType, setTetraType] = useState("");
  const [sesterType, setSesterType] = useState("");
  const [triIntType, setTriIntType] = useState("");
  const [tetraIntType, setTetraIntType] = useState("");
  const [sesquarType, setSesquarType] = useState("");
  const [ggppsType, setGgppsType] = useState("");
  const [enzClass, setEnzClass] = useState({ one: "", two: "" });
  const [classOne, setClassOne] = useState("");
  const [classTwo, setClassTwo] = useState("");
  const [uniprot, setUniprot] = useState("");
  const [genbank, setGenbank] = useState("");
  const [reactSmiles, setReactSmiles] = useState("");
  const [prodSmiles, setProdSmiles] = useState("");
  const [reactName, setReactName] = useState("");
  const [prodName, setProdName] = useState("");
  const [reactChebi, setReactChebi] = useState("");
  const [prodChebi, setProdChebi] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const logout = useLogout();
  const location = useLocation();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const {auth} = useAuth()

  async function suggest(row) {
    if (document.getElementById(`suggestDiv${row}`).style.display === "none") {
      document.getElementById(`suggestDiv${row}`).style.display = "block";
      document.getElementById(`suggestInput${row}`).value = "";
      for (let i = 0; i < suggestionCount; i++) {
        if (i != row) {
          if (document.getElementById(`suggestDiv${i}`)) {
            document.getElementById(`suggestDiv${i}`).style.display = "none";
            document.getElementById(`suggestInput${i}`).value = "";
          }
        }
      }
    } else {
      document.getElementById(`suggestDiv${row}`).style.display = "none";
      document.getElementById(`suggestInput${row}`).value = "";
      setSuggestUnlicked((current) => !current);
      for (let i = 0; i < suggestionCount; i++) {
        if (i != row) {
          if (document.getElementById(`suggestDiv${i}`)) {
            document.getElementById(`suggestDiv${i}`).style.display = "none";
            document.getElementById(`suggestInput${i}`).value = "";
          }
        }
      }
    }
  }

  function showInput(inputId, buttonId, state, setState, divId, setUnclicked) {
    inputId.forEach((e) => {
      let showInput = document.getElementById(e);

      if (showInput.style.display === "none") {
        showInput.style.display = "block";
      } else {
        showInput.style.display = "none";
      }
    });

    const button = document.getElementById(buttonId);
    const div = document.getElementById(divId);

    if (state === false) {
      button.style.color = "gold";
      div.style.backgroundColor = "rgb(245,245,245)";
      div.style.borderTop = "2px solid lightgrey";
      setState(true);
    } else {
      button.style.color = "black";
      div.style.backgroundColor = "white";
      div.style.borderTop = "none";
      setState(false);
      setUnclicked((current) => !current);
    }
  }

  async function submitSuggest(row) {
    const inputValue = document.getElementById(`suggestInput${row}`).value;
    document.getElementById(`innerTextSubmitted${row}`).style.display = "block";
    document.getElementById(`innerTextSubmit${row}`).style.display = "none";
    try {
      await axiosPrivate.post("/mechanismlink", {
        inputValue: inputValue,
        reaction: table[row],
        username:auth?.username
      });
    } catch (err) {
      throw err;
    } finally {
      setTimeout(() => {
        document.getElementById(`suggestDiv${row}`).style.display = "none";
        document.getElementById(`innerTextSubmitted${row}`).style.display =
          "none";
        document.getElementById(`innerTextSubmit${row}`).style.display =
          "block";
      }, 1000);
    }
  }

  async function search(event) {
    event?.preventDefault();
    setNewSearch(true);
    setIsSearching(true);
    setSearchSubmit(false);
    let state = undefined;
    document.getElementById("SearchSuccess").style.display = "block";
    let body = [];
    let reaction_details = {
      kingdom: [],
      species: [],
      aminoacid_sequence: [],
      class: [],
      type: [],
      ec_number: [],
      uniprot_id: [],
      genbank_id: [],
      substrate_smiles: [],
      substrate_chebi: [],
      product_smiles: [],
      product_chebi: [],
      link: [],
      doi: [],
      pmid: [],
    };
    try {
      const response = await axiosPrivate.post("/searchnomechanism", {
        name: name,
        terpType,
        enzClass,
        uniprot,
        genbank,
        reactSmiles: encoding.convert(reactSmiles,"Latin_1"),
        reactChebi,
        reactName,
        prodChebi,
        prodName,
        prodSmiles: encoding.convert(prodSmiles,"Latin_1"),
        pageCount,
        buttonCount,
      });
      const table = response.data;
      setTable(table);

      if (table.length > 0) {
        setDataCount(table[0].count);
        setSuggestionCount(table[0].count);
      } else {
        setDataCount(0);
        setSuggestionCount(0);
      }

      for (let row = 0; row < table.length; row++) {
        let DDid = `DD${row}`;
        let DDwhitespace = `DDwhitespace${row}`;
        let substrate_name = table[row].substrate_name;
        let product_name = table[row].product_name;
        reaction_details.kingdom.push(table[row].kingdom);
        reaction_details.species.push(table[row].species);
        reaction_details.aminoacid_sequence.push(table[row].aminoacid_sequence);
        reaction_details.class.push(table[row].class);
        reaction_details.type.push(table[row].type);
        reaction_details.ec_number.push(table[row].ec_number);
        reaction_details.uniprot_id.push(table[row].uniprot_id);
        reaction_details.genbank_id.push(table[row].genbank_id);
        reaction_details.substrate_chebi.push(table[row].substrate_chebi);
        reaction_details.product_chebi.push(table[row].product_chebi);
        reaction_details.substrate_smiles.push(table[row].substrate_smiles);
        reaction_details.product_smiles.push(table[row].product_smiles);
        reaction_details.link.push(table[row].publication_link);
        reaction_details.doi.push(table[row].publication_doi);
        reaction_details.pmid.push(table[row].publication_pmid);

        body.push([
          table[row].name === "missing_value" ? (
            table[row].uniprot_id ? (
              <>Uniprot ID: {table[row].uniprot_id}</>
            ) : (
              <>Genbank ID: {table[row].genbank_id}</>
            )
          ) : table[row].uniprot_id ? (
            <>
              {table[row].name}
              <br />
              Uniprot ID: {table[row].uniprot_id}
            </>
          ) : (
            <>
              {table[row].name}
              <br />
              Genbank ID: {table[row].genbank_id}
            </>
          ),

          table[row].substrate_image ? (
            <>
              <img
                src={table[row].substrate_image}
                alt={table[row].substrate_smiles}
                className="responsive"
              />
              <br />
              {substrate_name}
            </>
          ) : (
            <>
              {table[row].substrate_smiles} <br />
              {table[row].substrate_name}
              <br />
            </>
          ),
          table[row].product_image ? (
            <>
              <img src={table[row].product_image} className="responsive" />
              <br />
              {product_name}
            </>
          ) : (
            <>
              {table[row].product_smiles} <br />
              {table[row].product_name}
            </>
          ),
          table[row].mechanism_id ? (
            <button id="entriesButton">View mechanism</button>
          ) : (
            <div style={{ position: "relative" }}>
              <button
                key={suggestUnclicked}
                id={`suggestMechanism${row}`}
                className="suggestMechanism"
                align="center"
                onClick={() => suggest(row)}
                style={{ border: "none", backgroundColor: "rgb(245,245,245)" }}
              >
                <nobr>Suggest a mechanism</nobr>
              </button>
              <div
                id={`suggestDiv${row}`}
                className="suggestDivMech"
                style={{
                  display: "none",
                  position: "absolute",
                  padding: 5,
                  backgroundColor: "rgb(23, 55, 151)",
                }}
              >
                <nobr>
                  <input
                    id={`suggestInput${row}`}
                    className="suggestInput"
                    placeholder="Publication reference"
                  />
                  <button
                    id={`confirmButton${row}`}
                    className="confirmButton"
                    onClick={() => {
                      submitSuggest(row);
                    }}
                  >
                    <div
                      id={`innerTextSubmitted${row}`}
                      style={{ display: "none" }}
                    >
                      <FontAwesomeIcon icon={faCheck} color="green" /> Submitted
                    </div>{" "}
                    <div
                      id={`innerTextSubmit${row}`}
                      style={{ display: "block" }}
                    >
                      Submit
                    </div>
                  </button>
                </nobr>
              </div>
            </div>
          ),
          <button
            id="entriesButton"
            onClick={() => {
              let dropdown = document.getElementById(DDid);
              let whitespace = document.getElementById(DDwhitespace);
              dropdown.style.display === "none"
                ? (dropdown.style.display = "block")
                : (dropdown.style.display = "none");

              let dropdownHeight = dropdown.clientHeight;
              dropdown.style.display === "none"
                ? (whitespace.style.marginBottom = "0px")
                : (whitespace.style.marginBottom = `${dropdownHeight}px`);
            }}
          >
            View reaction details
          </button>,
        ]);
      }

      setSearchResult(body);
      setDetails(reaction_details);
    } catch (err) {
      console.error(err);
      //logout();
      //navigate("/login", { state: { from: location }, replace: true });
    } finally {
      setSearchSubmit(true);
      setIsSearching(false);
    }
  }


  useEffect(() => {
    search();
  }, [suggestionCount, pageCount]);

  const heading = ["Enzyme", "Substrate", "Product", "Mechanism", "Details"];

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css"
          rel="stylesheet"
        />
      </head>
      <body>
        <HomeNavbar />
        <div style={{ paddingTop: 50 }} id="content">
          <div style={{ marginLeft: 100 }}>
            <form id="inputs" onSubmit={search}>
              <div id="fullFormMech">
                <h3
                  align="left"
                  style={{
                    borderBottom: "solid 2px lightgrey",
                    paddingBottom: "5px",
                  }}
                >
                  Suggestions. How does it work?
                </h3>
                <p align="left">
                  In order to protect this database from unverified users and
                  their untrustworthy entries, we decided to come up with the
                  concept of suggestions. Basically, a suggestion is contentwise
                  equivalent of a new entry in our database, however protected
                  by so called "editor layer". Suggestions are stored and then
                  reviewed by our editors – self-appointed experts in the field
                  of terpenesynthases to thoroughly check the content of
                  suggestion about to be submitted.
                </p>
                <p
                  align="left"
                  style={{
                    borderBottom: "2px solid lightgrey",
                    paddingBottom: "5px",
                  }}
                >
                  Keen on learning more? Read <Link to="/userroles">here</Link>.
                </p>
              </div>
              <div id="SearchSuccess" align="left">
                {!searchSubmit ? (
                  isSearching ? (
                    <>
                      <Spin
                        indicator={
                          <LoadingOutlined
                            style={{ color: "rgb(23, 55, 151)" }}
                            spin
                          />
                        }
                      />{" "}
                      Searching for results, please wait...
                    </>
                  ) : (
                    ""
                  )
                ) : searchResult.length == 0 ? (
                  <>
                    <FontAwesomeIcon icon={faTimes} id="cross" /> No results
                  </>
                ) : (
                  <>
                    {dataCount == 1 ? (
                      <>
                        <ul>
                          <li>
                            <FontAwesomeIcon icon={faCheck} id="tick" />{" "}
                            {dataCount + " result found"}
                          </li>
                          <li>
                            <button
                              type="button"
                              id="DoubleDown"
                              onClick={() => {
                                document
                                  .querySelector(".reactionContent")
                                  .scrollIntoView({
                                    behavior: "smooth",
                                  });
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faAngleDoubleDown}
                                id="arrow"
                              />
                            </button>
                          </li>
                        </ul>
                      </>
                    ) : (
                      <>
                        <ul>
                          <li>
                            <FontAwesomeIcon icon={faCheck} id="tick" />{" "}
                            {dataCount + " results found"}
                          </li>
                          <li>
                            <button
                              type="button"
                              id="DoubleDown"
                              onClick={() => {
                                document
                                  .querySelector(".reactionContent")
                                  .scrollIntoView({
                                    behavior: "smooth",
                                  });
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faAngleDoubleDown}
                                id="arrow"
                              />
                            </button>
                          </li>
                        </ul>
                      </>
                    )}
                  </>
                )}
              </div>
              <div id="filters">
                <ul style={{ listStyle: "none" }}>
                  <li>
                    <div
                      id="EnzymeInputs"
                      key={enzymeInputUnclicked}
                      onClick={async () => {
                        showInput(
                          [
                            "NameInput",
                            "TypeInput",
                            "ClassInput",
                            "UniprotInput",
                            "GenbankInput",
                          ],
                          "enzymeDD",
                          enzymeInputClicked,
                          setEnzymeInputClicked,
                          "EnzymeInputs",
                          setEnzymeInputUnclicked
                        );
                        setName("");
                        setGenbank("");
                        setUniprot("");
                        setClassOne("");
                        setClassTwo("");
                        setDiType("");
                        setMonoType("");
                        setTriType("");
                        setTetraType("");
                        setDiIntType("");
                        setTriIntType("");
                        setTetraIntType("");
                        setGgppsType("");
                        setSesqType("");
                        setSesquarType("");
                        setSesterType("");
                        setTerpType({
                          di: "",
                          mono: "",
                          sesq: "",
                          diint: "",
                          tri: "",
                          tetra: "",
                          sester: "",
                          triint: "",
                          tetraint: "",
                          sesquar: "",
                          ggpps: "",
                        });
                        const monoInput = await document.getElementById(
                          "MonoTerpInput"
                        );
                        if (monoInput.checked == true) {
                          monoInput.checked = false;
                        }
                        const sesqInput = await document.getElementById(
                          "SesqTerpInput"
                        );
                        if (sesqInput.checked == true) {
                          sesqInput.checked = false;
                        }
                        const diintInput = await document.getElementById(
                          "DiIntTerpInput"
                        );
                        if (diintInput.checked == true) {
                          diintInput.checked = false;
                        }
                        const triInput = await document.getElementById(
                          "TriTerpInput"
                        );
                        if (triInput.checked == true) {
                          triInput.checked = false;
                        }
                        const tetraInput = await document.getElementById(
                          "TetraTerpInput"
                        );
                        if (tetraInput.checked == true) {
                          tetraInput.checked = false;
                        }
                        const sesterInput = await document.getElementById(
                          "SesterTerpInput"
                        );
                        if (sesterInput.checked == true) {
                          sesterInput.checked = false;
                        }
                        const triintInput = await document.getElementById(
                          "TriIntTerpInput"
                        );
                        if (triintInput.checked == true) {
                          triintInput.checked = false;
                        }
                        const tetraintInput = await document.getElementById(
                          "TetraIntTerpInput"
                        );
                        if (tetraintInput.checked == true) {
                          tetraintInput.checked = false;
                        }
                        const sesquarInput = await document.getElementById(
                          "SesquarTerpInput"
                        );
                        if (sesquarInput.checked == true) {
                          sesquarInput.checked = false;
                        }
                        const ggppsInput = await document.getElementById(
                          "GgppsTerpInput"
                        );
                        if (ggppsInput.checked == true) {
                          ggppsInput.checked = false;
                        }
                        const diInput = await document.getElementById(
                          "DiTerpInput"
                        );
                        if (diInput.checked == true) {
                          diInput.checked = false;
                        }
                        setEnzClass({ one: "", two: "" });
                        const oneInput = await document.getElementById(
                          "ClassI"
                        );
                        if (oneInput.checked == true) {
                          oneInput.checked = false;
                        }
                        const twoInput = await document.getElementById(
                          "ClassII"
                        );
                        if (twoInput.checked == true) {
                          twoInput.checked = false;
                        }
                      }}
                    >
                      <li>
                        <h5>Enzyme filters:</h5>
                      </li>
                      <li>
                        <FontAwesomeIcon
                          id="enzymeDD"
                          icon={faAngleDoubleDown}
                        />
                      </li>
                    </div>
                  </li>
                  <input
                    id="NameInput"
                    placeholder="Enzyme name"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    value={name}
                  />
                  <div id="TypeInput" style={{ display: "none" }} class="row">
                    <div
                      class="col-xl-4"
                      align="left"
                      style={{ display: "inline-block" }}
                    >
                      <label id="diterpinput" for="DiTerpInput">
                        <input
                          type="checkbox"
                          id="DiTerpInput"
                          value="di"
                          onClick={async (e) => {
                            if (e.target.checked) {
                              setDiType("di");
                              setTerpType({
                                di: "di",
                                mono: monoType,
                                sesq: sesqType,
                                diint: diIntType,
                                tri: triType,
                                tetra: tetraType,
                                sester: sesterType,
                                triint: triIntType,
                                tetraint: tetraIntType,
                                sesquar: sesquarType,
                                ggpps: ggppsType,
                              });
                            } else {
                              setDiType("");
                              setTerpType({
                                di: "",
                                mono: monoType,
                                sesq: sesqType,
                                diint: diIntType,
                                tri: triType,
                                tetra: tetraType,
                                sester: sesterType,
                                triint: triIntType,
                                tetraint: tetraIntType,
                                sesquar: sesquarType,
                                ggpps: ggppsType,
                              });
                            }
                          }}
                        />
                        diterpenes
                      </label>
                      <label id="monoterpinput" for="MonoTerpInput">
                        <input
                          type="checkbox"
                          id="MonoTerpInput"
                          value="mono"
                          onClick={async (e) => {
                            if (e.target.checked) {
                              setMonoType("mono");
                              setTerpType({
                                di: diType,
                                mono: "mono",
                                sesq: sesqType,
                                diint: diIntType,
                                tri: triType,
                                tetra: tetraType,
                                sester: sesterType,
                                triint: triIntType,
                                tetraint: tetraIntType,
                                sesquar: sesquarType,
                                ggpps: ggppsType,
                              });
                            } else {
                              setMonoType("");
                              setTerpType({
                                di: diType,
                                mono: "",
                                sesq: sesqType,
                                diint: diIntType,
                                tri: triType,
                                tetra: tetraType,
                                sester: sesterType,
                                triint: triIntType,
                                tetraint: tetraIntType,
                                sesquar: sesquarType,
                                ggpps: ggppsType,
                              });
                            }
                          }}
                        />
                        monoterpenes
                      </label>
                      <label id="sesqterpinput" for="SesqTerpInput">
                        <input
                          type="checkbox"
                          id="SesqTerpInput"
                          value="sesq"
                          onClick={async (e) => {
                            if (e.target.checked) {
                              setSesqType("sesq");
                              setTerpType({
                                di: diType,
                                mono: monoType,
                                sesq: "sesq",
                                diint: diIntType,
                                tri: triType,
                                tetra: tetraType,
                                sester: sesterType,
                                triint: triIntType,
                                tetraint: tetraIntType,
                                sesquar: sesquarType,
                                ggpps: ggppsType,
                              });
                            } else {
                              setSesqType("");
                              setTerpType({
                                di: diType,
                                mono: monoType,
                                sesq: "",
                                diint: diIntType,
                                tri: triType,
                                tetra: tetraType,
                                sester: sesterType,
                                triint: triIntType,
                                tetraint: tetraIntType,
                                sesquar: sesquarType,
                                ggpps: ggppsType,
                              });
                            }
                          }}
                        />
                        sesqiterpenes
                      </label>
                      <label id="diintterpinput" for="DiIntTerpInput">
                        <input
                          type="checkbox"
                          id="DiIntTerpInput"
                          value="di-int"
                          onClick={async (e) => {
                            if (e.target.checked) {
                              setDiIntType("di-int");
                              setTerpType({
                                di: diType,
                                mono: monoType,
                                sesq: sesqType,
                                diint: "di-int",
                                tri: triType,
                                tetra: tetraType,
                                sester: sesterType,
                                triint: triIntType,
                                tetraint: tetraIntType,
                                sesquar: sesquarType,
                                ggpps: ggppsType,
                              });
                            } else {
                              setDiIntType("");
                              setTerpType({
                                di: diType,
                                mono: monoType,
                                sesq: sesqType,
                                diint: "",
                                tri: triType,
                                tetra: tetraType,
                                sester: sesterType,
                                triint: triIntType,
                                tetraint: tetraIntType,
                                sesquar: sesquarType,
                                ggpps: ggppsType,
                              });
                            }
                          }}
                        />
                        di-int terpenes
                      </label>
                    </div>
                    <div
                      class="col-xl-4"
                      align="left"
                      style={{ display: "inline-block" }}
                    >
                      <label id="triterpinput" for="TriTerpInput">
                        <input
                          type="checkbox"
                          id="TriTerpInput"
                          value="tri"
                          onClick={async (e) => {
                            if (e.target.checked) {
                              setTriType("tri");
                              setTerpType({
                                di: diType,
                                mono: monoType,
                                sesq: sesqType,
                                diint: diIntType,
                                tri: "tri",
                                tetra: tetraType,
                                sester: sesterType,
                                triint: triIntType,
                                tetraint: tetraIntType,
                                sesquar: sesquarType,
                                ggpps: ggppsType,
                              });
                            } else {
                              setTriType("");
                              setTerpType({
                                di: diType,
                                mono: monoType,
                                sesq: sesqType,
                                diint: diIntType,
                                tri: "",
                                tetra: tetraType,
                                sester: sesterType,
                                triint: triIntType,
                                tetraint: tetraIntType,
                                sesquar: sesquarType,
                                ggpps: ggppsType,
                              });
                            }
                          }}
                        />
                        triterpenes
                      </label>

                      <label id="tetraterpinput" for="TetraTerpInput">
                        <input
                          type="checkbox"
                          id="TetraTerpInput"
                          value="tetra"
                          onClick={async (e) => {
                            if (e.target.checked) {
                              setTetraType("tetra");
                              setTerpType({
                                di: diType,
                                mono: monoType,
                                sesq: sesqType,
                                diint: diIntType,
                                tri: triType,
                                tetra: "tetra",
                                sester: sesterType,
                                triint: triIntType,
                                tetraint: tetraIntType,
                                sesquar: sesquarType,
                                ggpps: ggppsType,
                              });
                            } else {
                              setTetraType("");
                              setTerpType({
                                di: diType,
                                mono: monoType,
                                sesq: sesqType,
                                diint: diIntType,
                                tri: triType,
                                tetra: "",
                                sester: sesterType,
                                triint: triIntType,
                                tetraint: tetraIntType,
                                sesquar: sesquarType,
                                ggpps: ggppsType,
                              });
                            }
                          }}
                        />
                        tetraterpenes
                      </label>
                      <label id="sesterterpinput" for="SesterTerpInput">
                        <input
                          type="checkbox"
                          id="SesterTerpInput"
                          value="sester"
                          onClick={async (e) => {
                            if (e.target.checked) {
                              setSesterType("sester");
                              setTerpType({
                                di: diType,
                                mono: monoType,
                                sesq: sesqType,
                                diint: diIntType,
                                tri: triType,
                                tetra: tetraType,
                                sester: "sester",
                                triint: triIntType,
                                tetraint: tetraIntType,
                                sesquar: sesquarType,
                                ggpps: ggppsType,
                              });
                            } else {
                              setSesterType("");
                              setTerpType({
                                di: diType,
                                mono: monoType,
                                sesq: sesqType,
                                diint: diIntType,
                                tri: triType,
                                tetra: tetraType,
                                sester: "",
                                triint: triIntType,
                                tetraint: tetraIntType,
                                sesquar: sesquarType,
                                ggpps: ggppsType,
                              });
                            }
                          }}
                        />
                        sesterterpenes
                      </label>
                      <label id="triintterpinput" for="TriIntTerpInput">
                        <input
                          type="checkbox"
                          id="TriIntTerpInput"
                          value="tri-int"
                          onClick={async (e) => {
                            if (e.target.checked) {
                              setTriIntType("tri-int");
                              setTerpType({
                                di: diType,
                                mono: monoType,
                                sesq: sesqType,
                                diint: diIntType,
                                tri: triType,
                                tetra: tetraType,
                                sester: sesterType,
                                triint: "tri-int",
                                tetraint: tetraIntType,
                                sesquar: sesquarType,
                                ggpps: ggppsType,
                              });
                            } else {
                              setTriIntType("");
                              setTerpType({
                                di: diType,
                                mono: monoType,
                                sesq: sesqType,
                                diint: diIntType,
                                tri: triType,
                                tetra: tetraType,
                                sester: sesterType,
                                triint: "",
                                tetraint: tetraIntType,
                                sesquar: sesquarType,
                                ggpps: ggppsType,
                              });
                            }
                          }}
                        />
                        tri-int terpenes
                      </label>
                    </div>
                    <div
                      class="col-xl-4"
                      align="left"
                      style={{ display: "inline-block" }}
                    >
                      <label id="tetraintterpinput" for="TetraIntTerpInput">
                        <input
                          type="checkbox"
                          id="TetraIntTerpInput"
                          value="tetra-int"
                          onClick={async (e) => {
                            if (e.target.checked) {
                              setTetraIntType("tetra-int");
                              setTerpType({
                                di: diType,
                                mono: monoType,
                                sesq: sesqType,
                                diint: diIntType,
                                tri: triType,
                                tetra: tetraType,
                                sester: sesterType,
                                triint: triIntType,
                                tetraint: "tetra-int",
                                sesquar: sesquarType,
                                ggpps: ggppsType,
                              });
                            } else {
                              setTetraIntType("");
                              setTerpType({
                                di: diType,
                                mono: monoType,
                                sesq: sesqType,
                                diint: diIntType,
                                tri: triType,
                                tetra: tetraType,
                                sester: sesterType,
                                triint: triIntType,
                                tetraint: "",
                                sesquar: sesquarType,
                                ggpps: ggppsType,
                              });
                            }
                          }}
                        />
                        tetra-int terpenes
                      </label>
                      <label id="sesquarterpinput" for="SesquarTerpInput">
                        <input
                          type="checkbox"
                          id="SesquarTerpInput"
                          value="sesquar"
                          onClick={async (e) => {
                            if (e.target.checked) {
                              setSesquarType("sesquar");
                              setTerpType({
                                di: diType,
                                mono: monoType,
                                sesq: sesqType,
                                diint: diIntType,
                                tri: triType,
                                tetra: tetraType,
                                sester: sesterType,
                                triint: triIntType,
                                tetraint: tetraIntType,
                                sesquar: "sesquar",
                                ggpps: ggppsType,
                              });
                            } else {
                              setSesquarType("");
                              setTerpType({
                                di: diType,
                                mono: monoType,
                                sesq: sesqType,
                                diint: diIntType,
                                tri: triType,
                                tetra: tetraType,
                                sester: sesterType,
                                triint: triIntType,
                                tetraint: tetraIntType,
                                sesquar: "",
                                ggpps: ggppsType,
                              });
                            }
                          }}
                        />
                        sesquarterpenes
                      </label>
                      <label id="ggppsterpinput" for="GgppsTerpInput">
                        <input
                          type="checkbox"
                          id="GgppsTerpInput"
                          value="ggpps"
                          onClick={async (e) => {
                            if (e.target.checked) {
                              setGgppsType("ggpps");
                              setTerpType({
                                di: diType,
                                mono: monoType,
                                sesq: sesqType,
                                diint: diIntType,
                                tri: triType,
                                tetra: tetraType,
                                sester: sesterType,
                                triint: triIntType,
                                tetraint: tetraIntType,
                                sesquar: sesquarType,
                                ggpps: "ggpps",
                              });
                            } else {
                              setGgppsType("");
                              setTerpType({
                                di: diType,
                                mono: monoType,
                                sesq: sesqType,
                                diint: diIntType,
                                tri: triType,
                                tetra: tetraType,
                                sester: sesterType,
                                triint: triIntType,
                                tetraint: tetraIntType,
                                sesquar: sesquarType,
                                ggpps: "",
                              });
                            }
                          }}
                        />
                        ggpps terpenes
                      </label>
                    </div>
                  </div>
                  <div id="ClassInput" style={{ display: "none" }} class="row">
                    <div
                      class="col-xl-4"
                      align="left"
                      style={{ display: "inline-block" }}
                    >
                      <label id="classi" for="ClassI">
                        <input
                          type="checkbox"
                          id="ClassI"
                          value="1"
                          onClick={async (e) => {
                            if (e.target.checked) {
                              setClassOne(1);
                              setEnzClass({ one: 1, two: classTwo });
                            } else {
                              setClassOne("");
                              setEnzClass({ one: "", two: classTwo });
                            }
                          }}
                        />
                        Class I
                      </label>
                    </div>

                    <label id="clasii" for="ClassII">
                      <input
                        type="checkbox"
                        id="ClassII"
                        value="2"
                        onClick={async (e) => {
                          if (e.target.checked) {
                            setClassTwo(2);
                            setEnzClass({ one: classOne, two: 2 });
                          } else {
                            setClassTwo("");
                            setEnzClass({ one: classOne, two: "" });
                          }
                        }}
                      />
                      Class II
                    </label>
                  </div>
                  <input
                    id="UniprotInput"
                    placeholder="Uniprot ID"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      setUniprot(e.target.value);
                    }}
                    value={uniprot}
                  />
                  <input
                    id="GenbankInput"
                    placeholder="Genbank ID"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      setGenbank(e.target.value);
                    }}
                    value={genbank}
                  />
                  <li>
                    <div
                      id="ReactantInputs"
                      key={reactantInputUnclicked}
                      onClick={() => {
                        showInput(
                          [
                            "ReactantSMILESInput",
                            "ReactantChebiInput",
                            "ReactantNameInput",
                          ],
                          "reactantDD",
                          reactantInputClicked,
                          setReactantInputClicked,
                          "ReactantInputs",
                          setReactantInputUnclicked
                        );
                        setReactName("");
                        setReactSmiles("");
                        setReactChebi("");
                      }}
                    >
                      <li>
                        <h5>Reactant filters:</h5>
                      </li>
                      <li>
                        <FontAwesomeIcon
                          id="reactantDD"
                          icon={faAngleDoubleDown}
                        />
                      </li>
                    </div>
                  </li>
                  <textarea
                    id="ReactantSMILESInput"
                    placeholder="Reactant SMILES"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      setReactSmiles(e.target.value);
                    }}
                    value={reactSmiles}
                  />
                  <input
                    id="ReactantNameInput"
                    placeholder="Reactant name"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      setReactName(e.target.value);
                    }}
                    value={reactName}
                  />
                  <input
                    id="ReactantChebiInput"
                    placeholder="Reactant ChEBI"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      setReactChebi(e.target.value);
                    }}
                    value={reactChebi}
                  />
                  <li>
                    <div
                      id="ProductInputs"
                      key={productInputUnclicked}
                      onClick={() => {
                        showInput(
                          [
                            "ProductSMILESInput",
                            "ProductChebiInput",
                            "ProductNameInput",
                          ],
                          "productDD",
                          productInputClicked,
                          setProductInputClicked,
                          "ProductInputs",
                          setProductInputUnclicked
                        );
                        setProdName("");
                        setProdSmiles("");
                        setProdChebi("");
                      }}
                    >
                      <li>
                        <h5>Product filters:</h5>
                      </li>
                      <li>
                        <FontAwesomeIcon
                          id="productDD"
                          icon={faAngleDoubleDown}
                        />
                      </li>
                    </div>
                  </li>
                  <textarea
                    id="ProductSMILESInput"
                    placeholder="Product SMILES"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      setProdSmiles(e.target.value);
                    }}
                    value={prodSmiles}
                  />
                  <input
                    id="ProductNameInput"
                    placeholder="Product name"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      setProdName(e.target.value);
                    }}
                    value={prodName}
                  />
                  <input
                    id="ProductChebiInput"
                    placeholder="Product ChEBI"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      setProdChebi(e.target.value);
                    }}
                    value={prodChebi}
                  />
                  <button id="confirmButton" style={{ marginTop: "10px" }}>
                    Search
                  </button>
                </ul>
              </div>
            </form>
          </div>
        </div>
        <section style={{ marginRight: 150, marginLeft: 150 }}>
          <div id="table">
            {!searchSubmit ? (
              ""
            ) : searchResult.length == 0 ? (
              ""
            ) : (
              <>
                <ReactionTable
                  heading={heading}
                  body={searchResult}
                  details={details}
                  display={"none"}
                />
                <ul
                  style={{
                    listStyle: "none",
                    padding: 0,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <li></li>

                  <PageCounter
                    dataCount={dataCount}
                    buttonCount={buttonCount}
                    setButtonCount={setButtonCount}
                    pageCount={pageCount}
                    setPageCount={setPageCount}
                    setNewSearch={setNewSearch}
                    newSearch={newSearch}
                  />
                  <li></li>
                </ul>
              </>
            )}
          </div>
        </section>
        <div id="footer">
          <Footer />
        </div>
      </body>
    </html>
  );
}

export default SuggestMechanism;
