import Footer from "./Footer.js";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./newMechanism.css";
import { faPlus, faMinus, faDatabase, faCheck, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useAxiosPrivate from "../hooks/useAxiosPrivate.js";
import useAuth from "../hooks/useAuth.js";
import HomeNavbar from "../HomeNavbar.js";
import encoding from "encoding"

function NewMechanism() {
  const location = useLocation();
  const { state } = location;
  const { auth } = useAuth()
  const navigate = useNavigate();
  const [evidence, setEvidence] = useState("");
  const [link, setLink] = useState("");
  const [doi, setDoi] = useState("");
  const [pmid, setPmid] = useState("");
  let [inputCount, setInputCount] = useState(2);
  const [name1, setName1] = useState("");
  const [name2, setName2] = useState(state.product_name);
  const [name3, setName3] = useState("");
  const [name4, setName4] = useState("");
  const [name5, setName5] = useState("");
  const [name6, setName6] = useState("");
  const [name7, setName7] = useState("");
  const [name8, setName8] = useState("");
  const [name9, setName9] = useState("");
  const [name10, setName10] = useState("");
  const [name11, setName11] = useState("");
  const [name12, setName12] = useState("");
  const [name13, setName13] = useState("");
  const [name14, setName14] = useState("");
  const [name15, setName15] = useState("");
  const [name16, setName16] = useState("");
  const [name17, setName17] = useState("");
  const [name18, setName18] = useState("");
  const [name19, setName19] = useState("");
  const [name20, setName20] = useState("");
  const [name21, setName21] = useState("");
  const [name22, setName22] = useState("");
  const [name23, setName23] = useState("");
  const [name24, setName24] = useState("");
  const [name25, setName25] = useState("");
  const [name26, setName26] = useState("");
  const [name27, setName27] = useState("");
  const [name28, setName28] = useState("");
  const [name29, setName29] = useState("");
  const [name30, setName30] = useState("");
  const [name31, setName31] = useState("");
  const [name32, setName32] = useState("");
  const [name33, setName33] = useState("");
  const [name34, setName34] = useState("");
  const [name35, setName35] = useState("");
  const [name36, setName36] = useState("");
  const [name37, setName37] = useState("");
  const [name38, setName38] = useState("");
  const [name39, setName39] = useState("");
  const [name40, setName40] = useState("");
  const [name41, setName41] = useState("");
  const [name42, setName42] = useState("");
  const [name43, setName43] = useState("");
  const [name44, setName44] = useState("");
  const [name45, setName45] = useState("");
  const [name46, setName46] = useState("");
  const [name47, setName47] = useState("");
  const [name48, setName48] = useState("");
  const [name49, setName49] = useState("");
  const [name50, setName50] = useState("");

  const [chebi1, setChebi1] = useState("");
  const [chebi2, setChebi2] = useState(state.product_chebi[state.row]);
  const [chebi3, setChebi3] = useState("");
  const [chebi4, setChebi4] = useState("");
  const [chebi5, setChebi5] = useState("");
  const [chebi6, setChebi6] = useState("");
  const [chebi7, setChebi7] = useState("");
  const [chebi8, setChebi8] = useState("");
  const [chebi9, setChebi9] = useState("");
  const [chebi10, setChebi10] = useState("");
  const [chebi11, setChebi11] = useState("");
  const [chebi12, setChebi12] = useState("");
  const [chebi13, setChebi13] = useState("");
  const [chebi14, setChebi14] = useState("");
  const [chebi15, setChebi15] = useState("");
  const [chebi16, setChebi16] = useState("");
  const [chebi17, setChebi17] = useState("");
  const [chebi18, setChebi18] = useState("");
  const [chebi19, setChebi19] = useState("");
  const [chebi20, setChebi20] = useState("");
  const [chebi21, setChebi21] = useState("");
  const [chebi22, setChebi22] = useState("");
  const [chebi23, setChebi23] = useState("");
  const [chebi24, setChebi24] = useState("");
  const [chebi25, setChebi25] = useState("");
  const [chebi26, setChebi26] = useState("");
  const [chebi27, setChebi27] = useState("");
  const [chebi28, setChebi28] = useState("");
  const [chebi29, setChebi29] = useState("");
  const [chebi30, setChebi30] = useState("");
  const [chebi31, setChebi31] = useState("");
  const [chebi32, setChebi32] = useState("");
  const [chebi33, setChebi33] = useState("");
  const [chebi34, setChebi34] = useState("");
  const [chebi35, setChebi35] = useState("");
  const [chebi36, setChebi36] = useState("");
  const [chebi37, setChebi37] = useState("");
  const [chebi38, setChebi38] = useState("");
  const [chebi39, setChebi39] = useState("");
  const [chebi40, setChebi40] = useState("");
  const [chebi41, setChebi41] = useState("");
  const [chebi42, setChebi42] = useState("");
  const [chebi43, setChebi43] = useState("");
  const [chebi44, setChebi44] = useState("");
  const [chebi45, setChebi45] = useState("");
  const [chebi46, setChebi46] = useState("");
  const [chebi47, setChebi47] = useState("");
  const [chebi48, setChebi48] = useState("");
  const [chebi49, setChebi49] = useState("");
  const [chebi50, setChebi50] = useState("");

  const [substrateIntermediate1, setSubstrateIntermediate1] = useState(
    state.substrate_smiles[state.row]
  );
  const [productIntermediate1, setProductIntermediate1] = useState("");
  const [type1, setType1] = useState("");
  const [substrateIntermediate2, setSubstrateIntermediate2] = useState("");
  const [productIntermediate2, setProductIntermediate2] = useState(
    state.product_smiles[state.row]
  );
  const [type2, setType2] = useState("");
  const [substrateIntermediate3, setSubstrateIntermediate3] = useState("");
  const [productIntermediate3, setProductIntermediate3] = useState("");
  const [type3, setType3] = useState("");

  const [substrateIntermediate4, setSubstrateIntermediate4] = useState("");
  const [productIntermediate4, setProductIntermediate4] = useState("");
  const [type4, setType4] = useState("");

  const [substrateIntermediate5, setSubstrateIntermediate5] = useState("");
  const [productIntermediate5, setProductIntermediate5] = useState("");
  const [type5, setType5] = useState("");

  const [substrateIntermediate6, setSubstrateIntermediate6] = useState("");
  const [productIntermediate6, setProductIntermediate6] = useState("");
  const [type6, setType6] = useState("");

  const [substrateIntermediate7, setSubstrateIntermediate7] = useState("");
  const [productIntermediate7, setProductIntermediate7] = useState("");
  const [type7, setType7] = useState("");

  const [substrateIntermediate8, setSubstrateIntermediate8] = useState("");
  const [productIntermediate8, setProductIntermediate8] = useState("");
  const [type8, setType8] = useState("");

  const [substrateIntermediate9, setSubstrateIntermediate9] = useState("");
  const [productIntermediate9, setProductIntermediate9] = useState("");
  const [type9, setType9] = useState("");

  const [substrateIntermediate10, setSubstrateIntermediate10] = useState("");
  const [productIntermediate10, setProductIntermediate10] = useState("");
  const [type10, setType10] = useState("");

  const [substrateIntermediate11, setSubstrateIntermediate11] = useState("");
  const [productIntermediate11, setProductIntermediate11] = useState("");
  const [type11, setType11] = useState("");

  const [substrateIntermediate12, setSubstrateIntermediate12] = useState("");
  const [productIntermediate12, setProductIntermediate12] = useState("");
  const [type12, setType12] = useState("");

  const [substrateIntermediate13, setSubstrateIntermediate13] = useState("");
  const [productIntermediate13, setProductIntermediate13] = useState("");
  const [type13, setType13] = useState("");

  const [substrateIntermediate14, setSubstrateIntermediate14] = useState("");
  const [productIntermediate14, setProductIntermediate14] = useState("");
  const [type14, setType14] = useState("");

  const [substrateIntermediate15, setSubstrateIntermediate15] = useState("");
  const [productIntermediate15, setProductIntermediate15] = useState("");
  const [type15, setType15] = useState("");

  const [substrateIntermediate16, setSubstrateIntermediate16] = useState("");
  const [productIntermediate16, setProductIntermediate16] = useState("");
  const [type16, setType16] = useState("");

  const [substrateIntermediate17, setSubstrateIntermediate17] = useState("");
  const [productIntermediate17, setProductIntermediate17] = useState("");
  const [type17, setType17] = useState("");

  const [substrateIntermediate18, setSubstrateIntermediate18] = useState("");
  const [productIntermediate18, setProductIntermediate18] = useState("");
  const [type18, setType18] = useState("");

  const [substrateIntermediate19, setSubstrateIntermediate19] = useState("");
  const [productIntermediate19, setProductIntermediate19] = useState("");
  const [type19, setType19] = useState("");

  const [substrateIntermediate20, setSubstrateIntermediate20] = useState("");
  const [productIntermediate20, setProductIntermediate20] = useState("");
  const [type20, setType20] = useState("");

  const [substrateIntermediate21, setSubstrateIntermediate21] = useState("");
  const [productIntermediate21, setProductIntermediate21] = useState("");
  const [type21, setType21] = useState("");

  const [substrateIntermediate22, setSubstrateIntermediate22] = useState("");
  const [productIntermediate22, setProductIntermediate22] = useState("");
  const [type22, setType22] = useState("");

  const [substrateIntermediate23, setSubstrateIntermediate23] = useState("");
  const [productIntermediate23, setProductIntermediate23] = useState("");
  const [type23, setType23] = useState("");

  const [substrateIntermediate24, setSubstrateIntermediate24] = useState("");
  const [productIntermediate24, setProductIntermediate24] = useState("");
  const [type24, setType24] = useState("");

  const [substrateIntermediate25, setSubstrateIntermediate25] = useState("");
  const [productIntermediate25, setProductIntermediate25] = useState("");
  const [type25, setType25] = useState("");

  const [substrateIntermediate26, setSubstrateIntermediate26] = useState("");
  const [productIntermediate26, setProductIntermediate26] = useState("");
  const [type26, setType26] = useState("");

  const [substrateIntermediate27, setSubstrateIntermediate27] = useState("");
  const [productIntermediate27, setProductIntermediate27] = useState("");
  const [type27, setType27] = useState("");

  const [substrateIntermediate28, setSubstrateIntermediate28] = useState("");
  const [productIntermediate28, setProductIntermediate28] = useState("");
  const [type28, setType28] = useState("");

  const [substrateIntermediate29, setSubstrateIntermediate29] = useState("");
  const [productIntermediate29, setProductIntermediate29] = useState("");
  const [type29, setType29] = useState("");

  const [substrateIntermediate30, setSubstrateIntermediate30] = useState("");
  const [productIntermediate30, setProductIntermediate30] = useState("");
  const [type30, setType30] = useState("");

  const [substrateIntermediate31, setSubstrateIntermediate31] = useState("");
  const [productIntermediate31, setProductIntermediate31] = useState("");
  const [type31, setType31] = useState("");

  const [substrateIntermediate32, setSubstrateIntermediate32] = useState("");
  const [productIntermediate32, setProductIntermediate32] = useState("");
  const [type32, setType32] = useState("");

  const [substrateIntermediate33, setSubstrateIntermediate33] = useState("");
  const [productIntermediate33, setProductIntermediate33] = useState("");
  const [type33, setType33] = useState("");

  const [substrateIntermediate34, setSubstrateIntermediate34] = useState("");
  const [productIntermediate34, setProductIntermediate34] = useState("");
  const [type34, setType34] = useState("");

  const [substrateIntermediate35, setSubstrateIntermediate35] = useState("");
  const [productIntermediate35, setProductIntermediate35] = useState("");
  const [type35, setType35] = useState("");

  const [substrateIntermediate36, setSubstrateIntermediate36] = useState("");
  const [productIntermediate36, setProductIntermediate36] = useState("");
  const [type36, setType36] = useState("");

  const [substrateIntermediate37, setSubstrateIntermediate37] = useState("");
  const [productIntermediate37, setProductIntermediate37] = useState("");
  const [type37, setType37] = useState("");

  const [substrateIntermediate38, setSubstrateIntermediate38] = useState("");
  const [productIntermediate38, setProductIntermediate38] = useState("");
  const [type38, setType38] = useState("");

  const [substrateIntermediate39, setSubstrateIntermediate39] = useState("");
  const [productIntermediate39, setProductIntermediate39] = useState("");
  const [type39, setType39] = useState("");

  const [substrateIntermediate40, setSubstrateIntermediate40] = useState("");
  const [productIntermediate40, setProductIntermediate40] = useState("");
  const [type40, setType40] = useState("");

  const [substrateIntermediate41, setSubstrateIntermediate41] = useState("");
  const [productIntermediate41, setProductIntermediate41] = useState("");
  const [type41, setType41] = useState("");

  const [substrateIntermediate42, setSubstrateIntermediate42] = useState("");
  const [productIntermediate42, setProductIntermediate42] = useState("");
  const [type42, setType42] = useState("");

  const [substrateIntermediate43, setSubstrateIntermediate43] = useState("");
  const [productIntermediate43, setProductIntermediate43] = useState("");
  const [type43, setType43] = useState("");

  const [substrateIntermediate44, setSubstrateIntermediate44] = useState("");
  const [productIntermediate44, setProductIntermediate44] = useState("");
  const [type44, setType44] = useState("");

  const [substrateIntermediate45, setSubstrateIntermediate45] = useState("");
  const [productIntermediate45, setProductIntermediate45] = useState("");
  const [type45, setType45] = useState("");

  const [substrateIntermediate46, setSubstrateIntermediate46] = useState("");
  const [productIntermediate46, setProductIntermediate46] = useState("");
  const [type46, setType46] = useState("");

  const [substrateIntermediate47, setSubstrateIntermediate47] = useState("");
  const [productIntermediate47, setProductIntermediate47] = useState("");
  const [type47, setType47] = useState("");

  const [substrateIntermediate48, setSubstrateIntermediate48] = useState("");
  const [productIntermediate48, setProductIntermediate48] = useState("");
  const [type48, setType48] = useState("");

  const [substrateIntermediate49, setSubstrateIntermediate49] = useState("");
  const [productIntermediate49, setProductIntermediate49] = useState("");
  const [type49, setType49] = useState("");

  const [substrateIntermediate50, setSubstrateIntermediate50] = useState("");
  const [productIntermediate50, setProductIntermediate50] = useState("");
  const [type50, setType50] = useState("");
  const axiosPrivate = useAxiosPrivate();

  async function submitOK() {
    document.getElementById("submitConfirmation").style.display = "none";
    document.getElementById("duplication").style.display = "none";
    document.getElementById("overlap").style.display = "none";
    const elements = document.querySelectorAll("#body > *:not(#overlap)");
    elements.forEach((element) => {
      element.style.filter = "blur(0px)";
    });
  }

  function addInput(event) {
    if (inputCount < 50) {
      event.preventDefault();
      setInputCount((inputCount = inputCount + 1));
      const heading = document.getElementById(`heading${inputCount}`);
      const substrateInput = document.getElementById(
        `newinputsubstrate${inputCount}`
      );
      const productInput = document.getElementById(
        `newinputproduct${inputCount}`
      );
      const name = document.getElementById(`newinputname${inputCount}`);
      const chebi = document.getElementById(`newinputchebi${inputCount}`);
      const typeInput = document.getElementById(`newinputtype${inputCount}`);
      const labelSubstrate = document.getElementById(
        `labelsubstrate${inputCount}`
      );
      const labelProduct = document.getElementById(`labelproduct${inputCount}`);
      const labelType = document.getElementById(`labeltype${inputCount}`);
      const labelName = document.getElementById(`labelname${inputCount}`);
      const labelChebi = document.getElementById(`labelchebi${inputCount}`);

      heading.style.display = "block";
      substrateInput.style.display = "block";
      productInput.style.display = "block";
      name.style.display = "block";
      chebi.style.display = "block";
      typeInput.style.display = "block";
      labelSubstrate.style.display = "block";
      labelProduct.style.display = "block";
      labelType.style.display = "block";
      labelName.style.display = "block";
      labelChebi.style.display = "block";

      if (inputCount === 3) {
        setProductIntermediate2("");
        setProductIntermediate3(state.product_smiles[state.row]);
        setName2("");
        setName3(state.product_name);
        setChebi2("");
        setChebi3(state.product_chebi[state.row]);
        document.getElementById("newinputchebi2").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi2").disabled = false;
        document.getElementById("newinputchebi3").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi3").disabled = true;
        document.getElementById("newinputname2").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname2").disabled = false;
        document.getElementById("newinputname3").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname3").disabled = true;
        document.getElementById("newinputproduct2").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct2").disabled = false;
        document.getElementById("newinputproduct3").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct3").disabled = true;
      } else if (inputCount === 4) {
        setProductIntermediate3("");
        setProductIntermediate4(state.product_smiles[state.row]);
        setName3("");
        setName4(state.product_name);
        setChebi3("");
        setChebi4(state.product_chebi[state.row]);
        document.getElementById("newinputchebi3").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi3").disabled = false;
        document.getElementById("newinputchebi4").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi4").disabled = true;
        document.getElementById("newinputname3").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname3").disabled = false;
        document.getElementById("newinputname4").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname4").disabled = true;
        document.getElementById("newinputproduct3").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct3").disabled = false;
        document.getElementById("newinputproduct4").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct4").disabled = true;
      } else if (inputCount === 5) {
        setProductIntermediate4("");
        setProductIntermediate5(state.product_smiles[state.row]);
        setName4("");
        setName5(state.product_name);
        setChebi4("");
        setChebi5(state.product_chebi[state.row]);
        document.getElementById("newinputchebi4").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi4").disabled = false;
        document.getElementById("newinputchebi5").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi5").disabled = true;
        document.getElementById("newinputname4").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname4").disabled = false;
        document.getElementById("newinputname5").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname5").disabled = true;
        document.getElementById("newinputproduct4").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct4").disabled = false;
        document.getElementById("newinputproduct5").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct5").disabled = true;
      } else if (inputCount === 6) {
        setProductIntermediate5("");
        setProductIntermediate6(state.product_smiles[state.row]);
        setName5("");
        setName6(state.product_name);
        setChebi5("");
        setChebi6(state.product_chebi[state.row]);
        document.getElementById("newinputchebi5").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi5").disabled = false;
        document.getElementById("newinputchebi6").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi6").disabled = true;
        document.getElementById("newinputname5").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname5").disabled = false;
        document.getElementById("newinputname6").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname6").disabled = true;
        document.getElementById("newinputproduct5").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct5").disabled = false;
        document.getElementById("newinputproduct6").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct6").disabled = true;
      } else if (inputCount === 7) {
        setProductIntermediate6("");
        setProductIntermediate7(state.product_smiles[state.row]);
        setName6("");
        setName7(state.product_name);
        setChebi6("");
        setChebi7(state.product_chebi[state.row]);
        document.getElementById("newinputchebi6").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi6").disabled = false;
        document.getElementById("newinputchebi7").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi7").disabled = true;
        document.getElementById("newinputname6").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname6").disabled = false;
        document.getElementById("newinputname7").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname7").disabled = true;
        document.getElementById("newinputproduct6").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct6").disabled = false;
        document.getElementById("newinputproduct7").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct7").disabled = true;
      } else if (inputCount === 8) {
        setProductIntermediate7("");
        setProductIntermediate8(state.product_smiles[state.row]);
        setName7("");
        setName8(state.product_name);
        setChebi7("");
        setChebi8(state.product_chebi[state.row]);
        document.getElementById("newinputchebi7").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi7").disabled = false;
        document.getElementById("newinputchebi8").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi8").disabled = true;
        document.getElementById("newinputname7").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname7").disabled = false;
        document.getElementById("newinputname8").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname8").disabled = true;
        document.getElementById("newinputproduct7").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct7").disabled = false;
        document.getElementById("newinputproduct8").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct8").disabled = true;
      } else if (inputCount === 9) {
        setProductIntermediate8("");
        setProductIntermediate9(state.product_smiles[state.row]);
        setName8("");
        setName9(state.product_name);
        setChebi8("");
        setChebi9(state.product_chebi[state.row]);
        document.getElementById("newinputchebi8").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi8").disabled = false;
        document.getElementById("newinputchebi9").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi9").disabled = true;
        document.getElementById("newinputname8").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname8").disabled = false;
        document.getElementById("newinputname9").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname9").disabled = true;
        document.getElementById("newinputproduct8").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct8").disabled = false;
        document.getElementById("newinputproduct9").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct9").disabled = true;
      } else if (inputCount === 10) {
        setProductIntermediate9("");
        setProductIntermediate10(state.product_smiles[state.row]);
        setName9("");
        setName10(state.product_name);
        setChebi9("");
        setChebi10(state.product_chebi[state.row]);
        document.getElementById("newinputchebi9").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi9").disabled = false;
        document.getElementById("newinputchebi10").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi10").disabled = true;
        document.getElementById("newinputname9").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname9").disabled = false;
        document.getElementById("newinputname10").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname10").disabled = true;
        document.getElementById("newinputproduct9").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct9").disabled = false;
        document.getElementById("newinputproduct10").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct10").disabled = true;
      } else if (inputCount === 11) {
        setProductIntermediate10("");
        setProductIntermediate11(state.product_smiles[state.row]);
        setName10("");
        setName11(state.product_name);
        setChebi10("");
        setChebi11(state.product_chebi[state.row]);
        document.getElementById("newinputchebi10").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi10").disabled = false;
        document.getElementById("newinputchebi11").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi11").disabled = true;
        document.getElementById("newinputname10").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname10").disabled = false;
        document.getElementById("newinputname11").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname11").disabled = true;
        document.getElementById("newinputproduct10").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct10").disabled = false;
        document.getElementById("newinputproduct11").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct11").disabled = true;
      } else if (inputCount === 12) {
        setProductIntermediate11("");
        setProductIntermediate12(state.product_smiles[state.row]);
        setName11("");
        setName12(state.product_name);
        setChebi11("");
        setChebi12(state.product_chebi[state.row]);
        document.getElementById("newinputchebi11").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi11").disabled = false;
        document.getElementById("newinputchebi12").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi12").disabled = true;
        document.getElementById("newinputname11").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname11").disabled = false;
        document.getElementById("newinputname12").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname12").disabled = true;
        document.getElementById("newinputproduct11").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct11").disabled = false;
        document.getElementById("newinputproduct12").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct12").disabled = true;
      } else if (inputCount === 13) {
        setProductIntermediate12("");
        setProductIntermediate13(state.product_smiles[state.row]);
        setName12("");
        setName13(state.product_name);
        setChebi12("");
        setChebi13(state.product_chebi[state.row]);
        document.getElementById("newinputchebi12").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi12").disabled = false;
        document.getElementById("newinputchebi13").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi13").disabled = true;
        document.getElementById("newinputname12").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname12").disabled = false;
        document.getElementById("newinputname13").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname13").disabled = true;
        document.getElementById("newinputproduct12").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct12").disabled = false;
        document.getElementById("newinputproduct13").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct13").disabled = true;
      } else if (inputCount === 14) {
        setProductIntermediate13("");
        setProductIntermediate14(state.product_smiles[state.row]);
        setName13("");
        setName14(state.product_name);
        setChebi13("");
        setChebi14(state.product_chebi[state.row]);
        document.getElementById("newinputchebi13").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi13").disabled = false;
        document.getElementById("newinputchebi14").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi14").disabled = true;
        document.getElementById("newinputname13").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname13").disabled = false;
        document.getElementById("newinputname14").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname14").disabled = true;
        document.getElementById("newinputproduct13").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct13").disabled = false;
        document.getElementById("newinputproduct14").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct14").disabled = true;
      } else if (inputCount === 15) {
        setProductIntermediate14("");
        setProductIntermediate15(state.product_smiles[state.row]);
        setName14("");
        setName15(state.product_name);
        setChebi14("");
        setChebi15(state.product_chebi[state.row]);
        document.getElementById("newinputchebi14").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi14").disabled = false;
        document.getElementById("newinputchebi15").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi15").disabled = true;
        document.getElementById("newinputname14").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname14").disabled = false;
        document.getElementById("newinputname15").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname15").disabled = true;
        document.getElementById("newinputproduct14").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct14").disabled = false;
        document.getElementById("newinputproduct15").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct15").disabled = true;
      } else if (inputCount === 16) {
        setProductIntermediate15("");
        setProductIntermediate16(state.product_smiles[state.row]);
        setName15("");
        setName16(state.product_name);
        setChebi15("");
        setChebi16(state.product_chebi[state.row]);
        document.getElementById("newinputchebi15").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi15").disabled = false;
        document.getElementById("newinputchebi16").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi16").disabled = true;
        document.getElementById("newinputname15").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname15").disabled = false;
        document.getElementById("newinputname16").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname16").disabled = true;
        document.getElementById("newinputproduct15").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct15").disabled = false;
        document.getElementById("newinputproduct16").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct16").disabled = true;
      } else if (inputCount === 17) {
        setProductIntermediate16("");
        setProductIntermediate17(state.product_smiles[state.row]);
        setName16("");
        setName17(state.product_name);
        setChebi16("");
        setChebi17(state.product_chebi[state.row]);
        document.getElementById("newinputchebi16").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi16").disabled = false;
        document.getElementById("newinputchebi17").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi17").disabled = true;
        document.getElementById("newinputname16").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname16").disabled = false;
        document.getElementById("newinputname17").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname17").disabled = true;
        document.getElementById("newinputproduct16").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct16").disabled = false;
        document.getElementById("newinputproduct17").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct17").disabled = true;
      } else if (inputCount === 18) {
        setProductIntermediate17("");
        setProductIntermediate18(state.product_smiles[state.row]);
        setName17("");
        setName18(state.product_name);
        setChebi17("");
        setChebi18(state.product_chebi[state.row]);
        document.getElementById("newinputchebi17").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi17").disabled = false;
        document.getElementById("newinputchebi18").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi18").disabled = true;
        document.getElementById("newinputname17").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname17").disabled = false;
        document.getElementById("newinputname18").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname18").disabled = true;
        document.getElementById("newinputproduct17").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct17").disabled = false;
        document.getElementById("newinputproduct18").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct18").disabled = true;
      } else if (inputCount === 19) {
        setProductIntermediate18("");
        setProductIntermediate19(state.product_smiles[state.row]);
        setName18("");
        setName19(state.product_name);
        setChebi18("");
        setChebi19(state.product_chebi[state.row]);
        document.getElementById("newinputchebi18").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi18").disabled = false;
        document.getElementById("newinputchebi19").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi19").disabled = true;
        document.getElementById("newinputname18").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname18").disabled = false;
        document.getElementById("newinputname19").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname19").disabled = true;
        document.getElementById("newinputproduct18").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct18").disabled = false;
        document.getElementById("newinputproduct19").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct19").disabled = true;
      } else if (inputCount === 20) {
        setProductIntermediate19("");
        setProductIntermediate20(state.product_smiles[state.row]);
        setName19("");
        setName20(state.product_name);
        setChebi19("");
        setChebi20(state.product_chebi[state.row]);
        document.getElementById("newinputchebi19").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi19").disabled = false;
        document.getElementById("newinputchebi20").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi20").disabled = true;
        document.getElementById("newinputname19").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname19").disabled = false;
        document.getElementById("newinputname20").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname20").disabled = true;
        document.getElementById("newinputproduct19").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct19").disabled = false;
        document.getElementById("newinputproduct20").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct20").disabled = true;
      } else if (inputCount === 21) {
        setProductIntermediate20("");
        setProductIntermediate21(state.product_smiles[state.row]);
        setName20("");
        setName21(state.product_name);
        setChebi20("");
        setChebi21(state.product_chebi[state.row]);
        document.getElementById("newinputchebi20").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi20").disabled = false;
        document.getElementById("newinputchebi21").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi21").disabled = true;
        document.getElementById("newinputname20").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname20").disabled = false;
        document.getElementById("newinputname21").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname21").disabled = true;
        document.getElementById("newinputproduct20").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct20").disabled = false;
        document.getElementById("newinputproduct21").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct21").disabled = true;
      } else if (inputCount === 22) {
        setProductIntermediate21("");
        setProductIntermediate22(state.product_smiles[state.row]);
        setName21("");
        setName22(state.product_name);
        setChebi22("");
        setChebi22(state.product_chebi[state.row]);
        document.getElementById("newinputchebi21").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi21").disabled = false;
        document.getElementById("newinputchebi22").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi22").disabled = true;
        document.getElementById("newinputname21").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname21").disabled = false;
        document.getElementById("newinputname22").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname22").disabled = true;
        document.getElementById("newinputproduct21").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct21").disabled = false;
        document.getElementById("newinputproduct22").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct22").disabled = true;
      } else if (inputCount === 23) {
        setProductIntermediate22("");
        setProductIntermediate23(state.product_smiles[state.row]);
        setName22("");
        setName23(state.product_name);
        setChebi22("");
        setChebi23(state.product_chebi[state.row]);
        document.getElementById("newinputchebi22").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi22").disabled = false;
        document.getElementById("newinputchebi23").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi23").disabled = true;
        document.getElementById("newinputname22").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname22").disabled = false;
        document.getElementById("newinputname23").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname23").disabled = true;
        document.getElementById("newinputproduct22").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct22").disabled = false;
        document.getElementById("newinputproduct23").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct23").disabled = true;
      } else if (inputCount === 24) {
        setProductIntermediate23("");
        setProductIntermediate24(state.product_smiles[state.row]);
        setName23("");
        setName24(state.product_name);
        setChebi23("");
        setChebi24(state.product_chebi[state.row]);
        document.getElementById("newinputchebi23").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi23").disabled = false;
        document.getElementById("newinputchebi24").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi24").disabled = true;
        document.getElementById("newinputname23").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname23").disabled = false;
        document.getElementById("newinputname24").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname24").disabled = true;
        document.getElementById("newinputproduct23").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct23").disabled = false;
        document.getElementById("newinputproduct24").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct24").disabled = true;
      } else if (inputCount === 25) {
        setProductIntermediate24("");
        setProductIntermediate25(state.product_smiles[state.row]);
        setName24("");
        setName25(state.product_name);
        setChebi24("");
        setChebi25(state.product_chebi[state.row]);
        document.getElementById("newinputchebi24").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi24").disabled = false;
        document.getElementById("newinputchebi25").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi25").disabled = true;
        document.getElementById("newinputname24").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname24").disabled = false;
        document.getElementById("newinputname25").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname25").disabled = true;
        document.getElementById("newinputproduct24").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct24").disabled = false;
        document.getElementById("newinputproduct25").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct25").disabled = true;
      } else if (inputCount === 26) {
        setProductIntermediate25("");
        setProductIntermediate26(state.product_smiles[state.row]);
        setName25("");
        setName26(state.product_name);
        setChebi25("");
        setChebi26(state.product_chebi[state.row]);
        document.getElementById("newinputchebi25").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi25").disabled = false;
        document.getElementById("newinputchebi26").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi26").disabled = true;
        document.getElementById("newinputname25").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname25").disabled = false;
        document.getElementById("newinputname26").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname26").disabled = true;
        document.getElementById("newinputproduct25").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct25").disabled = false;
        document.getElementById("newinputproduct26").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct26").disabled = true;
      } else if (inputCount === 27) {
        setProductIntermediate26("");
        setProductIntermediate27(state.product_smiles[state.row]);
        setName26("");
        setName27(state.product_name);
        setChebi26("");
        setChebi27(state.product_chebi[state.row]);
        document.getElementById("newinputchebi26").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi26").disabled = false;
        document.getElementById("newinputchebi27").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi27").disabled = true;
        document.getElementById("newinputname26").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname26").disabled = false;
        document.getElementById("newinputname27").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname27").disabled = true;
        document.getElementById("newinputproduct26").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct26").disabled = false;
        document.getElementById("newinputproduct27").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct27").disabled = true;
      } else if (inputCount === 28) {
        setProductIntermediate27("");
        setProductIntermediate28(state.product_smiles[state.row]);
        setName27("");
        setName28(state.product_name);
        setChebi27("");
        setChebi28(state.product_chebi[state.row]);
        document.getElementById("newinputchebi27").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi27").disabled = false;
        document.getElementById("newinputchebi28").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi28").disabled = true;
        document.getElementById("newinputname27").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname27").disabled = false;
        document.getElementById("newinputname28").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname28").disabled = true;
        document.getElementById("newinputproduct27").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct27").disabled = false;
        document.getElementById("newinputproduct28").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct28").disabled = true;
      } else if (inputCount === 29) {
        setProductIntermediate28("");
        setProductIntermediate29(state.product_smiles[state.row]);
        setName28("");
        setName29(state.product_name);
        setChebi28("");
        setChebi29(state.product_chebi[state.row]);
        document.getElementById("newinputchebi28").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi28").disabled = false;
        document.getElementById("newinputchebi29").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi29").disabled = true;
        document.getElementById("newinputname28").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname28").disabled = false;
        document.getElementById("newinputname29").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname29").disabled = true;
        document.getElementById("newinputproduct28").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct28").disabled = false;
        document.getElementById("newinputproduct29").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct29").disabled = true;
      } else if (inputCount === 30) {
        setProductIntermediate29("");
        setProductIntermediate30(state.product_smiles[state.row]);
        setName29("");
        setName30(state.product_name);
        setChebi29("");
        setChebi30(state.product_chebi[state.row]);
        document.getElementById("newinputchebi29").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi29").disabled = false;
        document.getElementById("newinputchebi30").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi30").disabled = true;
        document.getElementById("newinputname29").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname29").disabled = false;
        document.getElementById("newinputname30").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname30").disabled = true;
        document.getElementById("newinputproduct29").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct29").disabled = false;
        document.getElementById("newinputproduct30").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct30").disabled = true;
      } else if (inputCount === 31) {
        setProductIntermediate30("");
        setProductIntermediate31(state.product_smiles[state.row]);
        setName30("");
        setName31(state.product_name);
        setChebi30("");
        setChebi31(state.product_chebi[state.row]);
        document.getElementById("newinputchebi30").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi30").disabled = false;
        document.getElementById("newinputchebi31").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi31").disabled = true;
        document.getElementById("newinputname30").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname30").disabled = false;
        document.getElementById("newinputname31").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname31").disabled = true;
        document.getElementById("newinputproduct30").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct30").disabled = false;
        document.getElementById("newinputproduct31").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct31").disabled = true;
      } else if (inputCount === 32) {
        setProductIntermediate31("");
        setProductIntermediate32(state.product_smiles[state.row]);
        setName31("");
        setName32(state.product_name);
        setChebi31("");
        setChebi32(state.product_chebi[state.row]);
        document.getElementById("newinputchebi31").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi31").disabled = false;
        document.getElementById("newinputchebi32").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi32").disabled = true;
        document.getElementById("newinputname31").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname31").disabled = false;
        document.getElementById("newinputname32").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname32").disabled = true;
        document.getElementById("newinputproduct31").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct31").disabled = false;
        document.getElementById("newinputproduct32").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct32").disabled = true;
      } else if (inputCount === 33) {
        setProductIntermediate32("");
        setProductIntermediate33(state.product_smiles[state.row]);
        setName32("");
        setName33(state.product_name);
        setChebi32("");
        setChebi33(state.product_chebi[state.row]);
        document.getElementById("newinputchebi32").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi32").disabled = false;
        document.getElementById("newinputchebi33").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi33").disabled = true;
        document.getElementById("newinputname32").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname32").disabled = false;
        document.getElementById("newinputname33").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname33").disabled = true;
        document.getElementById("newinputproduct32").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct32").disabled = false;
        document.getElementById("newinputproduct33").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct33").disabled = true;
      } else if (inputCount === 34) {
        setProductIntermediate33("");
        setProductIntermediate34(state.product_smiles[state.row]);
        setName33("");
        setName34(state.product_name);
        setChebi33("");
        setChebi34(state.product_chebi[state.row]);
        document.getElementById("newinputchebi33").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi33").disabled = false;
        document.getElementById("newinputchebi34").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi34").disabled = true;
        document.getElementById("newinputname33").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname33").disabled = false;
        document.getElementById("newinputname34").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname34").disabled = true;
        document.getElementById("newinputproduct33").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct33").disabled = false;
        document.getElementById("newinputproduct34").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct34").disabled = true;
      } else if (inputCount === 35) {
        setProductIntermediate34("");
        setProductIntermediate35(state.product_smiles[state.row]);
        setName34("");
        setName35(state.product_name);
        setChebi34("");
        setChebi35(state.product_chebi[state.row]);
        document.getElementById("newinputchebi34").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi34").disabled = false;
        document.getElementById("newinputchebi35").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi35").disabled = true;
        document.getElementById("newinputname34").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname34").disabled = false;
        document.getElementById("newinputname35").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname35").disabled = true;
        document.getElementById("newinputproduct34").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct34").disabled = false;
        document.getElementById("newinputproduct35").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct35").disabled = true;
      } else if (inputCount === 36) {
        setProductIntermediate35("");
        setProductIntermediate36(state.product_smiles[state.row]);
        setName35("");
        setName36(state.product_name);
        setChebi35("");
        setChebi36(state.product_chebi[state.row]);
        document.getElementById("newinputchebi35").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi35").disabled = false;
        document.getElementById("newinputchebi36").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi36").disabled = true;
        document.getElementById("newinputname35").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname35").disabled = false;
        document.getElementById("newinputname36").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname36").disabled = true;
        document.getElementById("newinputproduct35").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct35").disabled = false;
        document.getElementById("newinputproduct36").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct36").disabled = true;
      } else if (inputCount === 37) {
        setProductIntermediate36("");
        setProductIntermediate37(state.product_smiles[state.row]);
        setName36("");
        setName37(state.product_name);
        setChebi36("");
        setChebi37(state.product_chebi[state.row]);
        document.getElementById("newinputchebi36").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi36").disabled = false;
        document.getElementById("newinputchebi37").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi37").disabled = true;
        document.getElementById("newinputname36").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname36").disabled = false;
        document.getElementById("newinputname37").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname37").disabled = true;
        document.getElementById("newinputproduct36").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct36").disabled = false;
        document.getElementById("newinputproduct37").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct37").disabled = true;
      } else if (inputCount === 38) {
        setProductIntermediate37("");
        setProductIntermediate38(state.product_smiles[state.row]);
        setName37("");
        setName38(state.product_name);
        setChebi37("");
        setChebi38(state.product_chebi[state.row]);
        document.getElementById("newinputchebi37").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi37").disabled = false;
        document.getElementById("newinputchebi38").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi38").disabled = true;
        document.getElementById("newinputname37").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname37").disabled = false;
        document.getElementById("newinputname38").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname38").disabled = true;
        document.getElementById("newinputproduct37").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct37").disabled = false;
        document.getElementById("newinputproduct38").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct38").disabled = true;
      } else if (inputCount === 39) {
        setProductIntermediate38("");
        setProductIntermediate39(state.product_smiles[state.row]);
        setName38("");
        setName39(state.product_name);
        setChebi38("");
        setChebi39(state.product_chebi[state.row]);
        document.getElementById("newinputchebi38").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi38").disabled = false;
        document.getElementById("newinputchebi39").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi39").disabled = true;
        document.getElementById("newinputname38").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname38").disabled = false;
        document.getElementById("newinputname39").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname39").disabled = true;
        document.getElementById("newinputproduct38").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct38").disabled = false;
        document.getElementById("newinputproduct39").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct39").disabled = true;
      } else if (inputCount === 40) {
        setProductIntermediate39("");
        setProductIntermediate40(state.product_smiles[state.row]);
        setName39("");
        setName40(state.product_name);
        setChebi39("");
        setChebi40(state.product_chebi[state.row]);
        document.getElementById("newinputchebi39").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi39").disabled = false;
        document.getElementById("newinputchebi40").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi40").disabled = true;
        document.getElementById("newinputname39").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname39").disabled = false;
        document.getElementById("newinputname40").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname40").disabled = true;
        document.getElementById("newinputproduct39").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct39").disabled = false;
        document.getElementById("newinputproduct40").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct40").disabled = true;
      } else if (inputCount === 41) {
        setProductIntermediate40("");
        setProductIntermediate41(state.product_smiles[state.row]);
        setName40("");
        setName41(state.product_name);
        setChebi40("");
        setChebi41(state.product_chebi[state.row]);
        document.getElementById("newinputchebi40").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi40").disabled = false;
        document.getElementById("newinputchebi41").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi41").disabled = true;
        document.getElementById("newinputname40").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname40").disabled = false;
        document.getElementById("newinputname41").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname41").disabled = true;
        document.getElementById("newinputproduct40").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct40").disabled = false;
        document.getElementById("newinputproduct41").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct41").disabled = true;
      } else if (inputCount === 42) {
        setProductIntermediate41("");
        setProductIntermediate42(state.product_smiles[state.row]);
        setName41("");
        setName42(state.product_name);
        setChebi41("");
        setChebi42(state.product_chebi[state.row]);
        document.getElementById("newinputchebi41").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi41").disabled = false;
        document.getElementById("newinputchebi42").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi42").disabled = true;
        document.getElementById("newinputname41").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname41").disabled = false;
        document.getElementById("newinputname42").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname42").disabled = true;
        document.getElementById("newinputproduct41").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct41").disabled = false;
        document.getElementById("newinputproduct42").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct42").disabled = true;
      } else if (inputCount === 43) {
        setProductIntermediate42("");
        setProductIntermediate43(state.product_smiles[state.row]);
        setName42("");
        setName43(state.product_name);
        setChebi42("");
        setChebi43(state.product_chebi[state.row]);
        document.getElementById("newinputchebi42").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi42").disabled = false;
        document.getElementById("newinputchebi43").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi43").disabled = true;
        document.getElementById("newinputname42").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname42").disabled = false;
        document.getElementById("newinputname43").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname43").disabled = true;
        document.getElementById("newinputproduct42").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct42").disabled = false;
        document.getElementById("newinputproduct43").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct43").disabled = true;
      } else if (inputCount === 44) {
        setProductIntermediate43("");
        setProductIntermediate44(state.product_smiles[state.row]);
        setName43("");
        setName44(state.product_name);
        setChebi43("");
        setChebi44(state.product_chebi[state.row]);
        document.getElementById("newinputchebi43").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi43").disabled = false;
        document.getElementById("newinputchebi44").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi44").disabled = true;
        document.getElementById("newinputname43").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname43").disabled = false;
        document.getElementById("newinputname44").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname44").disabled = true;
        document.getElementById("newinputproduct43").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct43").disabled = false;
        document.getElementById("newinputproduct44").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct44").disabled = true;
      } else if (inputCount === 45) {
        setProductIntermediate44("");
        setProductIntermediate45(state.product_smiles[state.row]);
        setName44("");
        setName45(state.product_name);
        setChebi44("");
        setChebi45(state.product_chebi[state.row]);
        document.getElementById("newinputchebi44").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi44").disabled = false;
        document.getElementById("newinputchebi45").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi45").disabled = true;
        document.getElementById("newinputname44").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname44").disabled = false;
        document.getElementById("newinputname45").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname45").disabled = true;
        document.getElementById("newinputproduct44").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct44").disabled = false;
        document.getElementById("newinputproduct45").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct45").disabled = true;
      } else if (inputCount === 46) {
        setProductIntermediate45("");
        setProductIntermediate46(state.product_smiles[state.row]);
        setName45("");
        setName46(state.product_name);
        setChebi45("");
        setChebi46(state.product_chebi[state.row]);
        document.getElementById("newinputchebi45").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi45").disabled = false;
        document.getElementById("newinputchebi46").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi46").disabled = true;
        document.getElementById("newinputname45").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname45").disabled = false;
        document.getElementById("newinputname46").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname46").disabled = true;
        document.getElementById("newinputproduct45").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct45").disabled = false;
        document.getElementById("newinputproduct46").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct46").disabled = true;
      } else if (inputCount === 47) {
        setProductIntermediate46("");
        setProductIntermediate47(state.product_smiles[state.row]);
        setName46("");
        setName47(state.product_name);
        setChebi46("");
        setChebi47(state.product_chebi[state.row]);
        document.getElementById("newinputchebi46").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi46").disabled = false;
        document.getElementById("newinputchebi47").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi47").disabled = true;
        document.getElementById("newinputname46").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname46").disabled = false;
        document.getElementById("newinputname47").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname47").disabled = true;
        document.getElementById("newinputproduct46").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct46").disabled = false;
        document.getElementById("newinputproduct47").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct47").disabled = true;
      } else if (inputCount === 48) {
        setProductIntermediate47("");
        setProductIntermediate48(state.product_smiles[state.row]);
        setName47("");
        setName48(state.product_name);
        setChebi47("");
        setChebi48(state.product_chebi[state.row]);
        document.getElementById("newinputchebi47").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi47").disabled = false;
        document.getElementById("newinputchebi48").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi48").disabled = true;
        document.getElementById("newinputname47").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname47").disabled = false;
        document.getElementById("newinputname48").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname48").disabled = true;
        document.getElementById("newinputproduct47").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct47").disabled = false;
        document.getElementById("newinputproduct48").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct48").disabled = true;
      } else if (inputCount === 49) {
        setProductIntermediate48("");
        setProductIntermediate49(state.product_smiles[state.row]);
        setName48("");
        setName49(state.product_name);
        setChebi48("");
        setChebi49(state.product_chebi[state.row]);
        document.getElementById("newinputchebi48").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi48").disabled = false;
        document.getElementById("newinputchebi49").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi49").disabled = true;
        document.getElementById("newinputname48").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname48").disabled = false;
        document.getElementById("newinputname49").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname49").disabled = true;
        document.getElementById("newinputproduct48").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct48").disabled = false;
        document.getElementById("newinputproduct49").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct49").disabled = true;
      } else if (inputCount === 50) {
        setProductIntermediate49("");
        setProductIntermediate50(state.product_smiles[state.row]);
        setName49("");
        setName50(state.product_name);
        setChebi49("");
        setChebi50(state.product_chebi[state.row]);
        document.getElementById("newinputchebi49").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi49").disabled = false;
        document.getElementById("newinputchebi50").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi50").disabled = true;
        document.getElementById("newinputname49").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname49").disabled = false;
        document.getElementById("newinputname50").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname50").disabled = true;
        document.getElementById("newinputproduct49").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct49").disabled = false;
        document.getElementById("newinputproduct50").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct50").disabled = true;
      }
    }
  }

  function deleteInput(event) {
    event.preventDefault();
    if (inputCount >= 3) {
      const heading = document.getElementById(`heading${inputCount}`);
      const substrateInput = document.getElementById(
        `newinputsubstrate${inputCount}`
      );
      const productInput = document.getElementById(
        `newinputproduct${inputCount}`
      );
      const name = document.getElementById(`newinputname${inputCount}`);
      const chebi = document.getElementById(`newinputchebi${inputCount}`);
      const typeInput = document.getElementById(`newinputtype${inputCount}`);
      const labelSubstrate = document.getElementById(
        `labelsubstrate${inputCount}`
      );
      const labelProduct = document.getElementById(`labelproduct${inputCount}`);
      const labelType = document.getElementById(`labeltype${inputCount}`);
      const labelName = document.getElementById(`labelname${inputCount}`);
      const labelChebi = document.getElementById(`labelchebi${inputCount}`);

      heading.style.display = "none";
      substrateInput.style.display = "none";
      productInput.style.display = "none";
      name.style.display = "none";
      chebi.style.display = "none";
      typeInput.style.display = "none";
      labelSubstrate.style.display = "none";
      labelProduct.style.display = "none";
      labelType.style.display = "none";
      labelName.style.display = "none";
      labelChebi.style.display = "none";

      if (inputCount === 3) {
        setProductIntermediate2(state.product_smiles[state.row]);
        setProductIntermediate3("");
        setSubstrateIntermediate3("");
        setName2(state.product_name);
        setName3("");
        setChebi2(state.product_chebi[state.row]);
        setChebi3("");
        document.getElementById("newinputchebi3").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi3").disabled = false;
        document.getElementById("newinputchebi2").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi2").disabled = true;
        document.getElementById("newinputname3").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname3").disabled = false;
        document.getElementById("newinputname2").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname2").disabled = true;
        document.getElementById("newinputproduct3").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct3").disabled = false;
        document.getElementById("newinputproduct2").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct2").disabled = true;
      } else if (inputCount === 4) {
        setProductIntermediate3(state.product_smiles[state.row]);
        setProductIntermediate4("");
        setSubstrateIntermediate4("");
        setName3(state.product_name);
        setName4("");
        setChebi3(state.product_chebi[state.row]);
        setChebi4("");
        document.getElementById("newinputchebi4").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi4").disabled = false;
        document.getElementById("newinputchebi3").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi3").disabled = true;
        document.getElementById("newinputname4").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname4").disabled = false;
        document.getElementById("newinputname3").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname3").disabled = true;
        document.getElementById("newinputproduct4").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct4").disabled = false;
        document.getElementById("newinputproduct3").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct3").disabled = true;
      } else if (inputCount === 5) {
        setProductIntermediate4(state.product_smiles[state.row]);
        setProductIntermediate5("");
        setSubstrateIntermediate5("");
        setName4(state.product_name);
        setName5("");
        setChebi4(state.product_chebi[state.row]);
        setChebi5("");
        document.getElementById("newinputchebi5").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi5").disabled = false;
        document.getElementById("newinputchebi4").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi4").disabled = true;
        document.getElementById("newinputname5").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname5").disabled = false;
        document.getElementById("newinputname4").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname4").disabled = true;
        document.getElementById("newinputproduct5").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct5").disabled = false;
        document.getElementById("newinputproduct4").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct4").disabled = true;
      } else if (inputCount === 6) {
        setProductIntermediate5(state.product_smiles[state.row]);
        setProductIntermediate6("");
        setSubstrateIntermediate6("");
        setName5(state.product_name);
        setName6("");
        setChebi5(state.product_chebi[state.row]);
        setChebi6("");
        document.getElementById("newinputchebi6").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi6").disabled = false;
        document.getElementById("newinputchebi5").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi5").disabled = true;
        document.getElementById("newinputname6").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname6").disabled = false;
        document.getElementById("newinputname5").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname5").disabled = true;
        document.getElementById("newinputproduct6").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct6").disabled = false;
        document.getElementById("newinputproduct5").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct5").disabled = true;
      } else if (inputCount === 7) {
        setProductIntermediate6(state.product_smiles[state.row]);
        setProductIntermediate7("");
        setSubstrateIntermediate7("");
        setName6(state.product_name);
        setName7("");
        setChebi6(state.product_chebi[state.row]);
        setChebi7("");
        document.getElementById("newinputchebi7").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi7").disabled = false;
        document.getElementById("newinputchebi6").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi6").disabled = true;
        document.getElementById("newinputname7").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname7").disabled = false;
        document.getElementById("newinputname6").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname6").disabled = true;
        document.getElementById("newinputproduct7").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct7").disabled = false;
        document.getElementById("newinputproduct6").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct6").disabled = true;
      } else if (inputCount === 8) {
        setProductIntermediate7(state.product_smiles[state.row]);
        setProductIntermediate8("");
        setSubstrateIntermediate8("");
        setName7(state.product_name);
        setName8("");
        setChebi7(state.product_chebi[state.row]);
        setChebi8("");
        document.getElementById("newinputchebi8").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi8").disabled = false;
        document.getElementById("newinputchebi7").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi7").disabled = true;
        document.getElementById("newinputname8").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname8").disabled = false;
        document.getElementById("newinputname7").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname7").disabled = true;
        document.getElementById("newinputproduct8").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct8").disabled = false;
        document.getElementById("newinputproduct7").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct7").disabled = true;
      } else if (inputCount === 9) {
        setProductIntermediate8(state.product_smiles[state.row]);
        setProductIntermediate9("");
        setSubstrateIntermediate9("");
        setName8(state.product_name);
        setName9("");
        setChebi8(state.product_chebi[state.row]);
        setChebi9("");
        document.getElementById("newinputchebi9").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi9").disabled = false;
        document.getElementById("newinputchebi8").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi8").disabled = true;
        document.getElementById("newinputname9").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname9").disabled = false;
        document.getElementById("newinputname8").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname8").disabled = true;
        document.getElementById("newinputproduct9").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct9").disabled = false;
        document.getElementById("newinputproduct8").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct8").disabled = true;
      } else if (inputCount === 10) {
        setProductIntermediate9(state.product_smiles[state.row]);
        setProductIntermediate10("");
        setSubstrateIntermediate10("");
        setName9(state.product_name);
        setName10("");
        setChebi9(state.product_chebi[state.row]);
        setChebi10("");
        document.getElementById("newinputchebi10").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi10").disabled = false;
        document.getElementById("newinputchebi9").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi9").disabled = true;
        document.getElementById("newinputname10").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname10").disabled = false;
        document.getElementById("newinputname9").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname9").disabled = true;
        document.getElementById("newinputproduct10").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct10").disabled = false;
        document.getElementById("newinputproduct9").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct9").disabled = true;
      } else if (inputCount === 11) {
        setProductIntermediate10(state.product_smiles[state.row]);
        setProductIntermediate11("");
        setSubstrateIntermediate11("");
        setName10(state.product_name);
        setName11("");
        setChebi10(state.product_chebi[state.row]);
        setChebi11("");
        document.getElementById("newinputchebi11").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi11").disabled = false;
        document.getElementById("newinputchebi10").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi10").disabled = true;
        document.getElementById("newinputname11").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname11").disabled = false;
        document.getElementById("newinputname10").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname10").disabled = true;
        document.getElementById("newinputproduct11").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct11").disabled = false;
        document.getElementById("newinputproduct10").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct10").disabled = true;
      } else if (inputCount === 12) {
        setProductIntermediate11(state.product_smiles[state.row]);
        setProductIntermediate12("");
        setSubstrateIntermediate12("");
        setName11(state.product_name);
        setName12("");
        setChebi11(state.product_chebi[state.row]);
        setChebi12("");
        document.getElementById("newinputchebi12").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi12").disabled = false;
        document.getElementById("newinputchebi11").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi11").disabled = true;
        document.getElementById("newinputname12").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname12").disabled = false;
        document.getElementById("newinputname11").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname11").disabled = true;
        document.getElementById("newinputproduct12").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct12").disabled = false;
        document.getElementById("newinputproduct11").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct11").disabled = true;
      } else if (inputCount === 13) {
        setProductIntermediate12(state.product_smiles[state.row]);
        setProductIntermediate13("");
        setSubstrateIntermediate13("");
        setName12(state.product_name);
        setName13("");
        setChebi12(state.product_chebi[state.row]);
        setChebi13("");
        document.getElementById("newinputchebi13").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi13").disabled = false;
        document.getElementById("newinputchebi12").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi12").disabled = true;
        document.getElementById("newinputname13").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname13").disabled = false;
        document.getElementById("newinputname12").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname12").disabled = true;
        document.getElementById("newinputproduct13").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct13").disabled = false;
        document.getElementById("newinputproduct12").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct12").disabled = true;
      } else if (inputCount === 14) {
        setProductIntermediate13(state.product_smiles[state.row]);
        setProductIntermediate14("");
        setSubstrateIntermediate14("");
        setName13(state.product_name);
        setName14("");
        setChebi13(state.product_chebi[state.row]);
        setChebi14("");
        document.getElementById("newinputchebi14").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi14").disabled = false;
        document.getElementById("newinputchebi13").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi13").disabled = true;
        document.getElementById("newinputname14").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname14").disabled = false;
        document.getElementById("newinputname13").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname13").disabled = true;
        document.getElementById("newinputproduct14").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct14").disabled = false;
        document.getElementById("newinputproduct13").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct13").disabled = true;
      } else if (inputCount === 15) {
        setProductIntermediate14(state.product_smiles[state.row]);
        setProductIntermediate15("");
        setSubstrateIntermediate15("");
        setName14(state.product_name);
        setName15("");
        setChebi14(state.product_chebi[state.row]);
        setChebi15("");
        document.getElementById("newinputchebi15").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi15").disabled = false;
        document.getElementById("newinputchebi14").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi14").disabled = true;
        document.getElementById("newinputname15").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname15").disabled = false;
        document.getElementById("newinputname14").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname14").disabled = true;
        document.getElementById("newinputproduct15").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct15").disabled = false;
        document.getElementById("newinputproduct14").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct14").disabled = true;
      } else if (inputCount === 16) {
        setProductIntermediate15(state.product_smiles[state.row]);
        setProductIntermediate16("");
        setSubstrateIntermediate16("");
        setName15(state.product_name);
        setName16("");
        setChebi15(state.product_chebi[state.row]);
        setChebi16("");
        document.getElementById("newinputchebi16").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi16").disabled = false;
        document.getElementById("newinputchebi15").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi15").disabled = true;
        document.getElementById("newinputname16").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname16").disabled = false;
        document.getElementById("newinputname15").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname15").disabled = true;
        document.getElementById("newinputproduct16").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct16").disabled = false;
        document.getElementById("newinputproduct15").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct15").disabled = true;
      } else if (inputCount === 17) {
        setProductIntermediate16(state.product_smiles[state.row]);
        setProductIntermediate17("");
        setSubstrateIntermediate17("");
        setName16(state.product_name);
        setName17("");
        setChebi16(state.product_chebi[state.row]);
        setChebi17("");
        document.getElementById("newinputchebi17").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi17").disabled = false;
        document.getElementById("newinputchebi16").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi16").disabled = true;
        document.getElementById("newinputname17").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname17").disabled = false;
        document.getElementById("newinputname16").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname16").disabled = true;
        document.getElementById("newinputproduct17").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct17").disabled = false;
        document.getElementById("newinputproduct16").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct16").disabled = true;
      } else if (inputCount === 18) {
        setProductIntermediate17(state.product_smiles[state.row]);
        setProductIntermediate18("");
        setSubstrateIntermediate18("");
        setName17(state.product_name);
        setName18("");
        setChebi17(state.product_chebi[state.row]);
        setChebi18("");
        document.getElementById("newinputchebi18").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi18").disabled = false;
        document.getElementById("newinputchebi17").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi17").disabled = true;
        document.getElementById("newinputname18").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname18").disabled = false;
        document.getElementById("newinputname17").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname17").disabled = true;
        document.getElementById("newinputproduct18").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct18").disabled = false;
        document.getElementById("newinputproduct17").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct17").disabled = true;
      } else if (inputCount === 19) {
        setProductIntermediate18(state.product_smiles[state.row]);
        setProductIntermediate19("");
        setSubstrateIntermediate19("");
        setName18(state.product_name);
        setName19("");
        setChebi18(state.product_chebi[state.row]);
        setChebi19("");
        document.getElementById("newinputchebi19").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi19").disabled = false;
        document.getElementById("newinputchebi18").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi18").disabled = true;
        document.getElementById("newinputname19").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname19").disabled = false;
        document.getElementById("newinputname18").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname18").disabled = true;
        document.getElementById("newinputproduct19").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct19").disabled = false;
        document.getElementById("newinputproduct18").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct18").disabled = true;
      } else if (inputCount === 20) {
        setProductIntermediate19(state.product_smiles[state.row]);
        setProductIntermediate20("");
        setSubstrateIntermediate20("");
        setName19(state.product_name);
        setName20("");
        setChebi19(state.product_chebi[state.row]);
        setChebi20("");
        document.getElementById("newinputchebi20").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi20").disabled = false;
        document.getElementById("newinputchebi19").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi19").disabled = true;
        document.getElementById("newinputname20").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname20").disabled = false;
        document.getElementById("newinputname19").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname19").disabled = true;
        document.getElementById("newinputproduct20").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct20").disabled = false;
        document.getElementById("newinputproduct19").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct19").disabled = true;
      } else if (inputCount === 21) {
        setProductIntermediate20(state.product_smiles[state.row]);
        setProductIntermediate21("");
        setSubstrateIntermediate21("");
        setName20(state.product_name);
        setName21("");
        setChebi20(state.product_chebi[state.row]);
        setChebi21("");
        document.getElementById("newinputchebi21").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi21").disabled = false;
        document.getElementById("newinputchebi20").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi20").disabled = true;
        document.getElementById("newinputname21").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname21").disabled = false;
        document.getElementById("newinputname20").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname20").disabled = true;
        document.getElementById("newinputproduct21").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct21").disabled = false;
        document.getElementById("newinputproduct20").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct20").disabled = true;
      } else if (inputCount === 22) {
        setProductIntermediate21(state.product_smiles[state.row]);
        setProductIntermediate22("");
        setSubstrateIntermediate22("");
        setName21(state.product_name);
        setName22("");
        setChebi21(state.product_chebi[state.row]);
        setChebi22("");
        document.getElementById("newinputchebi22").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi22").disabled = false;
        document.getElementById("newinputchebi21").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi21").disabled = true;
        document.getElementById("newinputname22").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname22").disabled = false;
        document.getElementById("newinputname21").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname21").disabled = true;
        document.getElementById("newinputproduct22").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct22").disabled = false;
        document.getElementById("newinputproduct21").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct21").disabled = true;
      } else if (inputCount === 23) {
        setProductIntermediate22(state.product_smiles[state.row]);
        setProductIntermediate23("");
        setSubstrateIntermediate23("");
        setName22(state.product_name);
        setName23("");
        setChebi22(state.product_chebi[state.row]);
        setChebi23("");
        document.getElementById("newinputchebi23").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi23").disabled = false;
        document.getElementById("newinputchebi22").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi22").disabled = true;
        document.getElementById("newinputname23").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname23").disabled = false;
        document.getElementById("newinputname22").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname22").disabled = true;
        document.getElementById("newinputproduct23").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct23").disabled = false;
        document.getElementById("newinputproduct22").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct22").disabled = true;
      } else if (inputCount === 24) {
        setProductIntermediate23(state.product_smiles[state.row]);
        setProductIntermediate24("");
        setSubstrateIntermediate24("");
        setName23(state.product_name);
        setName24("");
        setChebi23(state.product_chebi[state.row]);
        setChebi24("");
        document.getElementById("newinputchebi24").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi24").disabled = false;
        document.getElementById("newinputchebi23").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi23").disabled = true;
        document.getElementById("newinputname24").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname24").disabled = false;
        document.getElementById("newinputname23").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname23").disabled = true;
        document.getElementById("newinputproduct24").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct24").disabled = false;
        document.getElementById("newinputproduct23").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct23").disabled = true;
      } else if (inputCount === 25) {
        setProductIntermediate24(state.product_smiles[state.row]);
        setProductIntermediate25("");
        setSubstrateIntermediate25("");
        setName24(state.product_name);
        setName25("");
        setChebi24(state.product_chebi[state.row]);
        setChebi25("");
        document.getElementById("newinputchebi25").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi25").disabled = false;
        document.getElementById("newinputchebi24").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi24").disabled = true;
        document.getElementById("newinputname25").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname25").disabled = false;
        document.getElementById("newinputname24").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname24").disabled = true;
        document.getElementById("newinputproduct25").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct25").disabled = false;
        document.getElementById("newinputproduct24").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct24").disabled = true;
      } else if (inputCount === 26) {
        setProductIntermediate25(state.product_smiles[state.row]);
        setProductIntermediate26("");
        setSubstrateIntermediate26("");
        setName25(state.product_name);
        setName26("");
        setChebi25(state.product_chebi[state.row]);
        setChebi26("");
        document.getElementById("newinputchebi26").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi26").disabled = false;
        document.getElementById("newinputchebi25").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi25").disabled = true;
        document.getElementById("newinputname26").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname26").disabled = false;
        document.getElementById("newinputname25").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname25").disabled = true;
        document.getElementById("newinputproduct26").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct26").disabled = false;
        document.getElementById("newinputproduct25").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct25").disabled = true;
      } else if (inputCount === 27) {
        setProductIntermediate26(state.product_smiles[state.row]);
        setProductIntermediate27("");
        setSubstrateIntermediate27("");
        setName26(state.product_name);
        setName27("");
        setChebi26(state.product_chebi[state.row]);
        setChebi27("");
        document.getElementById("newinputchebi27").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi27").disabled = false;
        document.getElementById("newinputchebi26").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi26").disabled = true;
        document.getElementById("newinputname27").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname27").disabled = false;
        document.getElementById("newinputname26").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname26").disabled = true;
        document.getElementById("newinputproduct27").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct27").disabled = false;
        document.getElementById("newinputproduct26").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct26").disabled = true;
      } else if (inputCount === 28) {
        setProductIntermediate27(state.product_smiles[state.row]);
        setProductIntermediate28("");
        setSubstrateIntermediate28("");
        setName27(state.product_name);
        setName28("");
        setChebi27(state.product_chebi[state.row]);
        setChebi28("");
        document.getElementById("newinputchebi28").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi28").disabled = false;
        document.getElementById("newinputchebi27").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi27").disabled = true;
        document.getElementById("newinputname28").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname28").disabled = false;
        document.getElementById("newinputname27").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname27").disabled = true;
        document.getElementById("newinputproduct28").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct28").disabled = false;
        document.getElementById("newinputproduct27").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct27").disabled = true;
      } else if (inputCount === 29) {
        setProductIntermediate28(state.product_smiles[state.row]);
        setProductIntermediate29("");
        setSubstrateIntermediate29("");
        setName28(state.product_name);
        setName29("");
        setChebi28(state.product_chebi[state.row]);
        setChebi29("");
        document.getElementById("newinputchebi29").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi29").disabled = false;
        document.getElementById("newinputchebi28").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi28").disabled = true;
        document.getElementById("newinputname29").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname29").disabled = false;
        document.getElementById("newinputname28").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname28").disabled = true;
        document.getElementById("newinputproduct29").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct29").disabled = false;
        document.getElementById("newinputproduct28").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct28").disabled = true;
      } else if (inputCount === 30) {
        setProductIntermediate29(state.product_smiles[state.row]);
        setProductIntermediate30("");
        setSubstrateIntermediate30("");
        setName29(state.product_name);
        setName30("");
        setChebi29(state.product_chebi[state.row]);
        setChebi30("");
        document.getElementById("newinputchebi30").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi30").disabled = false;
        document.getElementById("newinputchebi29").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi29").disabled = true;
        document.getElementById("newinputname30").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname30").disabled = false;
        document.getElementById("newinputname29").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname29").disabled = true;
        document.getElementById("newinputproduct30").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct30").disabled = false;
        document.getElementById("newinputproduct29").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct29").disabled = true;
      } else if (inputCount === 31) {
        setProductIntermediate30(state.product_smiles[state.row]);
        setProductIntermediate31("");
        setSubstrateIntermediate31("");
        setName30(state.product_name);
        setName31("");
        setChebi30(state.product_chebi[state.row]);
        setChebi31("");
        document.getElementById("newinputchebi31").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi31").disabled = false;
        document.getElementById("newinputchebi30").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi30").disabled = true;
        document.getElementById("newinputname31").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname31").disabled = false;
        document.getElementById("newinputname30").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname30").disabled = true;
        document.getElementById("newinputproduct31").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct31").disabled = false;
        document.getElementById("newinputproduct30").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct30").disabled = true;
      } else if (inputCount === 32) {
        setProductIntermediate31(state.product_smiles[state.row]);
        setProductIntermediate32("");
        setSubstrateIntermediate32("");
        setName31(state.product_name);
        setName32("");
        setChebi31(state.product_chebi[state.row]);
        setChebi32("");
        document.getElementById("newinputchebi32").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi32").disabled = false;
        document.getElementById("newinputchebi31").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi31").disabled = true;
        document.getElementById("newinputname32").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname32").disabled = false;
        document.getElementById("newinputname31").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname31").disabled = true;
        document.getElementById("newinputproduct32").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct32").disabled = false;
        document.getElementById("newinputproduct31").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct31").disabled = true;
      } else if (inputCount === 33) {
        setProductIntermediate32(state.product_smiles[state.row]);
        setProductIntermediate33("");
        setSubstrateIntermediate33("");
        setName32(state.product_name);
        setName33("");
        setChebi32(state.product_chebi[state.row]);
        setChebi33("");
        document.getElementById("newinputchebi33").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi33").disabled = false;
        document.getElementById("newinputchebi32").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi32").disabled = true;
        document.getElementById("newinputname33").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname33").disabled = false;
        document.getElementById("newinputname32").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname32").disabled = true;
        document.getElementById("newinputproduct33").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct33").disabled = false;
        document.getElementById("newinputproduct32").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct32").disabled = true;
      } else if (inputCount === 34) {
        setProductIntermediate33(state.product_smiles[state.row]);
        setProductIntermediate34("");
        setSubstrateIntermediate34("");
        setName33(state.product_name);
        setName34("");
        setChebi33(state.product_chebi[state.row]);
        setChebi34("");
        document.getElementById("newinputchebi34").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi34").disabled = false;
        document.getElementById("newinputchebi33").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi33").disabled = true;
        document.getElementById("newinputname34").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname34").disabled = false;
        document.getElementById("newinputname33").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname33").disabled = true;
        document.getElementById("newinputproduct34").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct34").disabled = false;
        document.getElementById("newinputproduct33").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct33").disabled = true;
      } else if (inputCount === 35) {
        setProductIntermediate34(state.product_smiles[state.row]);
        setProductIntermediate35("");
        setSubstrateIntermediate35("");
        setName34(state.product_name);
        setName35("");
        setChebi34(state.product_chebi[state.row]);
        setChebi35("");
        document.getElementById("newinputchebi35").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi35").disabled = false;
        document.getElementById("newinputchebi34").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi34").disabled = true;
        document.getElementById("newinputname35").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname35").disabled = false;
        document.getElementById("newinputname34").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname34").disabled = true;
        document.getElementById("newinputproduct35").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct35").disabled = false;
        document.getElementById("newinputproduct34").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct34").disabled = true;
      } else if (inputCount === 36) {
        setProductIntermediate35(state.product_smiles[state.row]);
        setProductIntermediate36("");
        setSubstrateIntermediate36("");
        setName35(state.product_name);
        setName36("");
        setChebi35(state.product_chebi[state.row]);
        setChebi36("");
        document.getElementById("newinputchebi36").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi36").disabled = false;
        document.getElementById("newinputchebi35").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi35").disabled = true;
        document.getElementById("newinputname36").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname36").disabled = false;
        document.getElementById("newinputname35").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname35").disabled = true;
        document.getElementById("newinputproduct36").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct36").disabled = false;
        document.getElementById("newinputproduct35").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct35").disabled = true;
      } else if (inputCount === 37) {
        setProductIntermediate36(state.product_smiles[state.row]);
        setProductIntermediate37("");
        setSubstrateIntermediate37("");
        setName36(state.product_name);
        setName37("");
        setChebi36(state.product_chebi[state.row]);
        setChebi37("");
        document.getElementById("newinputchebi37").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi37").disabled = false;
        document.getElementById("newinputchebi36").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi36").disabled = true;
        document.getElementById("newinputname37").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname37").disabled = false;
        document.getElementById("newinputname36").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname36").disabled = true;
        document.getElementById("newinputproduct37").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct37").disabled = false;
        document.getElementById("newinputproduct36").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct36").disabled = true;
      } else if (inputCount === 38) {
        setProductIntermediate37(state.product_smiles[state.row]);
        setProductIntermediate38("");
        setSubstrateIntermediate38("");
        setName37(state.product_name);
        setName38("");
        setChebi37(state.product_chebi[state.row]);
        setChebi38("");
        document.getElementById("newinputchebi38").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi38").disabled = false;
        document.getElementById("newinputchebi37").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi37").disabled = true;
        document.getElementById("newinputname38").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname38").disabled = false;
        document.getElementById("newinputname37").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname37").disabled = true;
        document.getElementById("newinputproduct38").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct38").disabled = false;
        document.getElementById("newinputproduct37").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct37").disabled = true;
      } else if (inputCount === 39) {
        setProductIntermediate38(state.product_smiles[state.row]);
        setProductIntermediate39("");
        setSubstrateIntermediate39("");
        setName38(state.product_name);
        setName39("");
        setChebi38(state.product_chebi[state.row]);
        setChebi39("");
        document.getElementById("newinputchebi39").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi39").disabled = false;
        document.getElementById("newinputchebi38").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi38").disabled = true;
        document.getElementById("newinputname39").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname39").disabled = false;
        document.getElementById("newinputname38").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname38").disabled = true;
        document.getElementById("newinputproduct39").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct39").disabled = false;
        document.getElementById("newinputproduct38").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct38").disabled = true;
      } else if (inputCount === 40) {
        setProductIntermediate39(state.product_smiles[state.row]);
        setProductIntermediate40("");
        setSubstrateIntermediate40("");
        setName39(state.product_name);
        setName40("");
        setChebi39(state.product_chebi[state.row]);
        setChebi40("");
        document.getElementById("newinputchebi40").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi40").disabled = false;
        document.getElementById("newinputchebi39").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi39").disabled = true;
        document.getElementById("newinputname40").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname40").disabled = false;
        document.getElementById("newinputname39").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname39").disabled = true;
        document.getElementById("newinputproduct40").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct40").disabled = false;
        document.getElementById("newinputproduct39").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct39").disabled = true;
      } else if (inputCount === 41) {
        setProductIntermediate40(state.product_smiles[state.row]);
        setProductIntermediate41("");
        setSubstrateIntermediate41("");
        setName40(state.product_name);
        setName41("");
        setChebi40(state.product_chebi[state.row]);
        setChebi41("");
        document.getElementById("newinputchebi41").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi41").disabled = false;
        document.getElementById("newinputchebi40").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi40").disabled = true;
        document.getElementById("newinputname41").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname41").disabled = false;
        document.getElementById("newinputname40").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname40").disabled = true;
        document.getElementById("newinputproduct41").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct41").disabled = false;
        document.getElementById("newinputproduct40").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct40").disabled = true;
      } else if (inputCount === 42) {
        setProductIntermediate41(state.product_smiles[state.row]);
        setProductIntermediate42("");
        setSubstrateIntermediate42("");
        setName41(state.product_name);
        setName42("");
        setChebi41(state.product_chebi[state.row]);
        setChebi42("");
        document.getElementById("newinputchebi42").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi42").disabled = false;
        document.getElementById("newinputchebi41").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi41").disabled = true;
        document.getElementById("newinputname42").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname42").disabled = false;
        document.getElementById("newinputname41").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname41").disabled = true;
        document.getElementById("newinputproduct42").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct42").disabled = false;
        document.getElementById("newinputproduct41").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct41").disabled = true;
      } else if (inputCount === 43) {
        setProductIntermediate42(state.product_smiles[state.row]);
        setProductIntermediate43("");
        setSubstrateIntermediate43("");
        setName42(state.product_name);
        setName43("");
        setChebi42(state.product_chebi[state.row]);
        setChebi43("");
        document.getElementById("newinputchebi43").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi43").disabled = false;
        document.getElementById("newinputchebi42").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi42").disabled = true;
        document.getElementById("newinputname43").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname43").disabled = false;
        document.getElementById("newinputname42").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname42").disabled = true;
        document.getElementById("newinputproduct43").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct43").disabled = false;
        document.getElementById("newinputproduct42").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct42").disabled = true;
      } else if (inputCount === 44) {
        setProductIntermediate43(state.product_smiles[state.row]);
        setProductIntermediate44("");
        setSubstrateIntermediate44("");
        setName43(state.product_name);
        setName44("");
        setChebi43(state.product_chebi[state.row]);
        setChebi44("");
        document.getElementById("newinputchebi44").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi44").disabled = false;
        document.getElementById("newinputchebi43").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi43").disabled = true;
        document.getElementById("newinputname44").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname44").disabled = false;
        document.getElementById("newinputname43").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname43").disabled = true;
        document.getElementById("newinputproduct44").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct44").disabled = false;
        document.getElementById("newinputproduct43").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct43").disabled = true;
      } else if (inputCount === 45) {
        setProductIntermediate44(state.product_smiles[state.row]);
        setProductIntermediate45("");
        setSubstrateIntermediate45("");
        setName44(state.product_name);
        setName45("");
        setChebi44(state.product_chebi[state.row]);
        setChebi45("");
        document.getElementById("newinputchebi45").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi45").disabled = false;
        document.getElementById("newinputchebi4").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi44").disabled = true;
        document.getElementById("newinputname45").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname45").disabled = false;
        document.getElementById("newinputname44").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname44").disabled = true;
        document.getElementById("newinputproduct45").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct45").disabled = false;
        document.getElementById("newinputproduct44").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct44").disabled = true;
      } else if (inputCount === 46) {
        setProductIntermediate45(state.product_smiles[state.row]);
        setProductIntermediate46("");
        setSubstrateIntermediate46("");
        setName45(state.product_name);
        setName46("");
        setChebi45(state.product_chebi[state.row]);
        setChebi46("");
        document.getElementById("newinputchebi46").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi46").disabled = false;
        document.getElementById("newinputchebi45").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi45").disabled = true;
        document.getElementById("newinputname46").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname46").disabled = false;
        document.getElementById("newinputname45").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname45").disabled = true;
        document.getElementById("newinputproduct46").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct46").disabled = false;
        document.getElementById("newinputproduct45").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct45").disabled = true;
      } else if (inputCount === 47) {
        setProductIntermediate46(state.product_smiles[state.row]);
        setProductIntermediate47("");
        setSubstrateIntermediate47("");
        setName46(state.product_name);
        setName47("");
        setChebi46(state.product_chebi[state.row]);
        setChebi47("");
        document.getElementById("newinputchebi47").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi47").disabled = false;
        document.getElementById("newinputchebi46").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi46").disabled = true;
        document.getElementById("newinputname47").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname47").disabled = false;
        document.getElementById("newinputname46").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname46").disabled = true;
        document.getElementById("newinputproduct47").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct47").disabled = false;
        document.getElementById("newinputproduct46").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct46").disabled = true;
      } else if (inputCount === 48) {
        setProductIntermediate47(state.product_smiles[state.row]);
        setProductIntermediate48("");
        setSubstrateIntermediate48("");
        setName47(state.product_name);
        setName48("");
        setChebi47(state.product_chebi[state.row]);
        setChebi48("");
        document.getElementById("newinputchebi48").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi48").disabled = false;
        document.getElementById("newinputchebi47").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi47").disabled = true;
        document.getElementById("newinputname48").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname48").disabled = false;
        document.getElementById("newinputname47").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname47").disabled = true;
        document.getElementById("newinputproduct48").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct48").disabled = false;
        document.getElementById("newinputproduct47").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct47").disabled = true;
      } else if (inputCount === 49) {
        setProductIntermediate48(state.product_smiles[state.row]);
        setProductIntermediate49("");
        setSubstrateIntermediate49("");
        setName48(state.product_name);
        setName49("");
        setChebi48(state.product_chebi[state.row]);
        setChebi49("");
        document.getElementById("newinputchebi49").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi49").disabled = false;
        document.getElementById("newinputchebi48").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi48").disabled = true;
        document.getElementById("newinputname49").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname49").disabled = false;
        document.getElementById("newinputname48").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname48").disabled = true;
        document.getElementById("newinputproduct49").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct49").disabled = false;
        document.getElementById("newinputproduct48").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct48").disabled = true;
      } else if (inputCount === 50) {
        setProductIntermediate49(state.product_smiles[state.row]);
        setProductIntermediate50("");
        setSubstrateIntermediate50("");
        setName49(state.product_name);
        setName50("");
        setChebi49(state.product_chebi[state.row]);
        setChebi50("");
        document.getElementById("newinputchebi50").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputchebi50").disabled = false;
        document.getElementById("newinputchebi49").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputchebi49").disabled = true;
        document.getElementById("newinputname50").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputname50").disabled = false;
        document.getElementById("newinputname49").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputname49").disabled = true;
        document.getElementById("newinputproduct50").style.backgroundColor =
          "rgb(245,245,245)";
        document.getElementById("newinputproduct50").disabled = false;
        document.getElementById("newinputproduct49").style.backgroundColor =
          "rgb(220,220,220)";
        document.getElementById("newinputproduct49").disabled = true;
      }
      setInputCount((inputCount = inputCount - 1));
    }
  }

  async function clickProceed() {
    document.getElementById("footer").style.display = "block";
    document.getElementById("entrycontent").style.display = "block";
    await document
      .querySelector("#entrycontent")
      .scrollIntoView({ behavior: "smooth" });
    setTimeout(() => {
      document.getElementById("SearchTitle").style.display = "none";
    }, 550);
  }

  async function submit(event) {
    event.preventDefault();
    document.getElementById("newinputsubstrate2").style.borderColor = "black";
    document.getElementById("newinputsubstrate2").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct1").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct1").style.borderColor = "black";
    document.getElementById("newinputsubstrate3").style.borderColor = "black";
    document.getElementById("newinputsubstrate3").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct2").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct2").style.borderColor = "black";
    document.getElementById("newinputsubstrate4").style.borderColor = "black";
    document.getElementById("newinputsubstrate4").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct3").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct3").style.borderColor = "black";
    document.getElementById("newinputsubstrate5").style.borderColor = "black";
    document.getElementById("newinputsubstrate5").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct4").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct4").style.borderColor = "black";
    document.getElementById("newinputsubstrate6").style.borderColor = "black";
    document.getElementById("newinputsubstrate6").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct5").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct5").style.borderColor = "black";
    document.getElementById("newinputsubstrate7").style.borderColor = "black";
    document.getElementById("newinputsubstrate7").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct6").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct6").style.borderColor = "black";
    document.getElementById("newinputsubstrate8").style.borderColor = "black";
    document.getElementById("newinputsubstrate8").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct7").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct7").style.borderColor = "black";
    document.getElementById("newinputsubstrate9").style.borderColor = "black";
    document.getElementById("newinputsubstrate9").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct8").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct8").style.borderColor = "black";
    document.getElementById("newinputsubstrate10").style.borderColor = "black";
    document.getElementById("newinputsubstrate10").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct9").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct9").style.borderColor = "black";
    document.getElementById("newinputsubstrate11").style.borderColor = "black";
    document.getElementById("newinputsubstrate11").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct10").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct10").style.borderColor = "black";
    document.getElementById("newinputsubstrate12").style.borderColor = "black";
    document.getElementById("newinputsubstrate12").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct11").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct11").style.borderColor = "black";
    document.getElementById("newinputsubstrate13").style.borderColor = "black";
    document.getElementById("newinputsubstrate13").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct12").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct12").style.borderColor = "black";
    document.getElementById("newinputsubstrate14").style.borderColor = "black";
    document.getElementById("newinputsubstrate14").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct13").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct13").style.borderColor = "black";
    document.getElementById("newinputsubstrate15").style.borderColor = "black";
    document.getElementById("newinputsubstrate15").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct14").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct14").style.borderColor = "black";
    document.getElementById("newinputsubstrate16").style.borderColor = "black";
    document.getElementById("newinputsubstrate16").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct15").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct15").style.borderColor = "black";
    document.getElementById("newinputsubstrate17").style.borderColor = "black";
    document.getElementById("newinputsubstrate17").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct16").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct16").style.borderColor = "black";
    document.getElementById("newinputsubstrate18").style.borderColor = "black";
    document.getElementById("newinputsubstrate18").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct17").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct17").style.borderColor = "black";
    document.getElementById("newinputsubstrate19").style.borderColor = "black";
    document.getElementById("newinputsubstrate19").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct18").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct18").style.borderColor = "black";
    document.getElementById("newinputsubstrate20").style.borderColor = "black";
    document.getElementById("newinputsubstrate20").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct19").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct19").style.borderColor = "black";
    document.getElementById("newinputsubstrate21").style.borderColor = "black";
    document.getElementById("newinputsubstrate21").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct20").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct20").style.borderColor = "black";
    document.getElementById("newinputsubstrate22").style.borderColor = "black";
    document.getElementById("newinputsubstrate22").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct21").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct21").style.borderColor = "black";
    document.getElementById("newinputsubstrate23").style.borderColor = "black";
    document.getElementById("newinputsubstrate23").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct22").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct22").style.borderColor = "black";
    document.getElementById("newinputsubstrate24").style.borderColor = "black";
    document.getElementById("newinputsubstrate24").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct23").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct23").style.borderColor = "black";
    document.getElementById("newinputsubstrate25").style.borderColor = "black";
    document.getElementById("newinputsubstrate25").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct24").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct24").style.borderColor = "black";
    document.getElementById("newinputsubstrate26").style.borderColor = "black";
    document.getElementById("newinputsubstrate26").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct25").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct25").style.borderColor = "black";
    document.getElementById("newinputsubstrate27").style.borderColor = "black";
    document.getElementById("newinputsubstrate27").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct26").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct26").style.borderColor = "black";
    document.getElementById("newinputsubstrate28").style.borderColor = "black";
    document.getElementById("newinputsubstrate28").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct27").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct27").style.borderColor = "black";
    document.getElementById("newinputsubstrate29").style.borderColor = "black";
    document.getElementById("newinputsubstrate29").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct28").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct28").style.borderColor = "black";
    document.getElementById("newinputsubstrate30").style.borderColor = "black";
    document.getElementById("newinputsubstrate30").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct29").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct29").style.borderColor = "black";
    document.getElementById("newinputsubstrate31").style.borderColor = "black";
    document.getElementById("newinputsubstrate31").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct30").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct30").style.borderColor = "black";
    document.getElementById("newinputsubstrate32").style.borderColor = "black";
    document.getElementById("newinputsubstrate32").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct31").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct31").style.borderColor = "black";
    document.getElementById("newinputsubstrate33").style.borderColor = "black";
    document.getElementById("newinputsubstrate33").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct32").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct32").style.borderColor = "black";
    document.getElementById("newinputsubstrate34").style.borderColor = "black";
    document.getElementById("newinputsubstrate34").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct33").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct33").style.borderColor = "black";
    document.getElementById("newinputsubstrate35").style.borderColor = "black";
    document.getElementById("newinputsubstrate35").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct34").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct34").style.borderColor = "black";
    document.getElementById("newinputsubstrate36").style.borderColor = "black";
    document.getElementById("newinputsubstrate36").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct35").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct35").style.borderColor = "black";
    document.getElementById("newinputsubstrate37").style.borderColor = "black";
    document.getElementById("newinputsubstrate37").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct36").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct36").style.borderColor = "black";
    document.getElementById("newinputsubstrate38").style.borderColor = "black";
    document.getElementById("newinputsubstrate38").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct37").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct37").style.borderColor = "black";
    document.getElementById("newinputsubstrate39").style.borderColor = "black";
    document.getElementById("newinputsubstrate39").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct38").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct38").style.borderColor = "black";
    document.getElementById("newinputsubstrate40").style.borderColor = "black";
    document.getElementById("newinputsubstrate40").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct39").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct39").style.borderColor = "black";
    document.getElementById("newinputsubstrate41").style.borderColor = "black";
    document.getElementById("newinputsubstrate41").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct40").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct40").style.borderColor = "black";
    document.getElementById("newinputsubstrate42").style.borderColor = "black";
    document.getElementById("newinputsubstrate42").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct41").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct41").style.borderColor = "black";
    document.getElementById("newinputsubstrate43").style.borderColor = "black";
    document.getElementById("newinputsubstrate43").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct42").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct42").style.borderColor = "black";
    document.getElementById("newinputsubstrate44").style.borderColor = "black";
    document.getElementById("newinputsubstrate44").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct43").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct43").style.borderColor = "black";
    document.getElementById("newinputsubstrate45").style.borderColor = "black";
    document.getElementById("newinputsubstrate45").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct44").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct44").style.borderColor = "black";
    document.getElementById("newinputsubstrate46").style.borderColor = "black";
    document.getElementById("newinputsubstrate46").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct45").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct45").style.borderColor = "black";
    document.getElementById("newinputsubstrate47").style.borderColor = "black";
    document.getElementById("newinputsubstrate47").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct46").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct46").style.borderColor = "black";
    document.getElementById("newinputsubstrate48").style.borderColor = "black";
    document.getElementById("newinputsubstrate48").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct47").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct47").style.borderColor = "black";
    document.getElementById("newinputsubstrate49").style.borderColor = "black";
    document.getElementById("newinputsubstrate49").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct48").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct48").style.borderColor = "black";
    document.getElementById("newinputsubstrate50").style.borderColor = "black";
    document.getElementById("newinputsubstrate50").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct49").style.backgroundColor =
      "rgb(245,245,245)";
    document.getElementById("newinputproduct49").style.borderColor = "black";

    if (
      substrateIntermediate2 !== productIntermediate1 &&
      substrateIntermediate2 !== "" &&
      productIntermediate1 !== ""
    ) {
      document.getElementById("newinputsubstrate2").style.borderColor = "red";
      document.getElementById("newinputsubstrate2").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct1").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct1").style.borderColor = "red";
    } else if (
      substrateIntermediate3 !== productIntermediate2 &&
      substrateIntermediate3 !== "" &&
      productIntermediate2 !== ""
    ) {
      document.getElementById("newinputsubstrate3").style.borderColor = "red";
      document.getElementById("newinputsubstrate3").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct2").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct2").style.borderColor = "red";
    } else if (
      substrateIntermediate4 !== productIntermediate3 &&
      substrateIntermediate4 !== "" &&
      productIntermediate3 !== ""
    ) {
      document.getElementById("newinputsubstrate4").style.borderColor = "red";
      document.getElementById("newinputsubstrate4").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct3").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct3").style.borderColor = "red";
    } else if (
      substrateIntermediate5 !== productIntermediate4 &&
      substrateIntermediate5 !== "" &&
      productIntermediate4 !== ""
    ) {
      document.getElementById("newinputsubstrate5").style.borderColor = "red";
      document.getElementById("newinputsubstrate5").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct4").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct4").style.borderColor = "red";
    } else if (
      substrateIntermediate6 !== productIntermediate5 &&
      substrateIntermediate6 !== "" &&
      productIntermediate5 !== ""
    ) {
      document.getElementById("newinputsubstrate6").style.borderColor = "red";
      document.getElementById("newinputsubstrate6").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct5").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct5").style.borderColor = "red";
    } else if (
      substrateIntermediate7 !== productIntermediate6 &&
      substrateIntermediate7 !== "" &&
      productIntermediate6 !== ""
    ) {
      document.getElementById("newinputsubstrate7").style.borderColor = "red";
      document.getElementById("newinputsubstrate7").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct6").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct6").style.borderColor = "red";
    } else if (
      substrateIntermediate8 !== productIntermediate7 &&
      substrateIntermediate8 !== "" &&
      productIntermediate7 !== ""
    ) {
      document.getElementById("newinputsubstrate8").style.borderColor = "red";
      document.getElementById("newinputsubstrate8").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct7").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct7").style.borderColor = "red";
    } else if (
      substrateIntermediate9 !== productIntermediate8 &&
      substrateIntermediate9 !== "" &&
      productIntermediate8 !== ""
    ) {
      document.getElementById("newinputsubstrate9").style.borderColor = "red";
      document.getElementById("newinputsubstrate9").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct8").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct8").style.borderColor = "red";
    } else if (
      substrateIntermediate10 !== productIntermediate9 &&
      substrateIntermediate10 !== "" &&
      productIntermediate9 !== ""
    ) {
      document.getElementById("newinputsubstrate10").style.borderColor = "red";
      document.getElementById("newinputsubstrate10").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct9").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct9").style.borderColor = "red";
    } else if (
      substrateIntermediate11 !== productIntermediate10 &&
      substrateIntermediate11 !== "" &&
      productIntermediate10 !== ""
    ) {
      document.getElementById("newinputsubstrate11").style.borderColor = "red";
      document.getElementById("newinputsubstrate11").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct10").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct10").style.borderColor = "red";
    } else if (
      substrateIntermediate12 !== productIntermediate11 &&
      substrateIntermediate12 !== "" &&
      productIntermediate11 !== ""
    ) {
      document.getElementById("newinputsubstrate12").style.borderColor = "red";
      document.getElementById("newinputsubstrate12").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct11").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct11").style.borderColor = "red";
    } else if (
      substrateIntermediate13 !== productIntermediate12 &&
      substrateIntermediate13 !== "" &&
      productIntermediate12 !== ""
    ) {
      document.getElementById("newinputsubstrate13").style.borderColor = "red";
      document.getElementById("newinputsubstrate13").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct12").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct12").style.borderColor = "red";
    } else if (
      substrateIntermediate14 !== productIntermediate13 &&
      substrateIntermediate14 !== "" &&
      productIntermediate13 !== ""
    ) {
      document.getElementById("newinputsubstrate14").style.borderColor = "red";
      document.getElementById("newinputsubstrate14").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct13").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct13").style.borderColor = "red";
    } else if (
      substrateIntermediate15 !== productIntermediate14 &&
      substrateIntermediate15 !== "" &&
      productIntermediate14 !== ""
    ) {
      document.getElementById("newinputsubstrate15").style.borderColor = "red";
      document.getElementById("newinputsubstrate15").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct14").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct14").style.borderColor = "red";
    } else if (
      substrateIntermediate16 !== productIntermediate15 &&
      substrateIntermediate16 !== "" &&
      productIntermediate15 !== ""
    ) {
      document.getElementById("newinputsubstrate16").style.borderColor = "red";
      document.getElementById("newinputsubstrate16").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct15").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct15").style.borderColor = "red";
    } else if (
      substrateIntermediate17 !== productIntermediate16 &&
      substrateIntermediate17 !== "" &&
      productIntermediate16 !== ""
    ) {
      document.getElementById("newinputsubstrate17").style.borderColor = "red";
      document.getElementById("newinputsubstrate17").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct16").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct16").style.borderColor = "red";
    } else if (
      substrateIntermediate18 !== productIntermediate17 &&
      substrateIntermediate18 !== "" &&
      productIntermediate17 !== ""
    ) {
      document.getElementById("newinputsubstrate18").style.borderColor = "red";
      document.getElementById("newinputsubstrate18").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct17").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct17").style.borderColor = "red";
    } else if (
      substrateIntermediate19 !== productIntermediate18 &&
      substrateIntermediate19 !== "" &&
      productIntermediate18 !== ""
    ) {
      document.getElementById("newinputsubstrate19").style.borderColor = "red";
      document.getElementById("newinputsubstrate19").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct18").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct18").style.borderColor = "red";
    } else if (
      substrateIntermediate20 !== productIntermediate19 &&
      substrateIntermediate20 !== "" &&
      productIntermediate19 !== ""
    ) {
      document.getElementById("newinputsubstrate20").style.borderColor = "red";
      document.getElementById("newinputsubstrate20").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct19").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct19").style.borderColor = "red";
    } else if (
      substrateIntermediate21 !== productIntermediate20 &&
      substrateIntermediate21 !== "" &&
      productIntermediate20 !== ""
    ) {
      document.getElementById("newinputsubstrate21").style.borderColor = "red";
      document.getElementById("newinputsubstrate21").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct20").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct20").style.borderColor = "red";
    } else if (
      substrateIntermediate22 !== productIntermediate21 &&
      substrateIntermediate22 !== "" &&
      productIntermediate21 !== ""
    ) {
      document.getElementById("newinputsubstrate22").style.borderColor = "red";
      document.getElementById("newinputsubstrate22").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct21").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct21").style.borderColor = "red";
    } else if (
      substrateIntermediate23 !== productIntermediate22 &&
      substrateIntermediate23 !== "" &&
      productIntermediate22 !== ""
    ) {
      document.getElementById("newinputsubstrate23").style.borderColor = "red";
      document.getElementById("newinputsubstrate23").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct22").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct22").style.borderColor = "red";
    } else if (
      substrateIntermediate24 !== productIntermediate23 &&
      substrateIntermediate24 !== "" &&
      productIntermediate23 !== ""
    ) {
      document.getElementById("newinputsubstrate24").style.borderColor = "red";
      document.getElementById("newinputsubstrate24").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct23").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct23").style.borderColor = "red";
    } else if (
      substrateIntermediate25 !== productIntermediate24 &&
      substrateIntermediate25 !== "" &&
      productIntermediate24 !== ""
    ) {
      document.getElementById("newinputsubstrate25").style.borderColor = "red";
      document.getElementById("newinputsubstrate25").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct24").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct24").style.borderColor = "red";
    } else if (
      substrateIntermediate26 !== productIntermediate25 &&
      substrateIntermediate26 !== "" &&
      productIntermediate25 !== ""
    ) {
      document.getElementById("newinputsubstrate26").style.borderColor = "red";
      document.getElementById("newinputsubstrate26").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct25").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct25").style.borderColor = "red";
    } else if (
      substrateIntermediate27 !== productIntermediate26 &&
      substrateIntermediate27 !== "" &&
      productIntermediate26 !== ""
    ) {
      document.getElementById("newinputsubstrate27").style.borderColor = "red";
      document.getElementById("newinputsubstrate27").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct26").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct26").style.borderColor = "red";
    } else if (
      substrateIntermediate28 !== productIntermediate27 &&
      substrateIntermediate28 !== "" &&
      productIntermediate27 !== ""
    ) {
      document.getElementById("newinputsubstrate28").style.borderColor = "red";
      document.getElementById("newinputsubstrate28").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct27").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct27").style.borderColor = "red";
    } else if (
      substrateIntermediate29 !== productIntermediate28 &&
      substrateIntermediate29 !== "" &&
      productIntermediate28 !== ""
    ) {
      document.getElementById("newinputsubstrate29").style.borderColor = "red";
      document.getElementById("newinputsubstrate29").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct28").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct28").style.borderColor = "red";
    } else if (
      substrateIntermediate30 !== productIntermediate29 &&
      substrateIntermediate30 !== "" &&
      productIntermediate29 !== ""
    ) {
      document.getElementById("newinputsubstrate30").style.borderColor = "red";
      document.getElementById("newinputsubstrate30").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct29").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct29").style.borderColor = "red";
    } else if (
      substrateIntermediate31 !== productIntermediate30 &&
      substrateIntermediate31 !== "" &&
      productIntermediate30 !== ""
    ) {
      document.getElementById("newinputsubstrate31").style.borderColor = "red";
      document.getElementById("newinputsubstrate31").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct30").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct30").style.borderColor = "red";
    } else if (
      substrateIntermediate32 !== productIntermediate31 &&
      substrateIntermediate32 !== "" &&
      productIntermediate31 !== ""
    ) {
      document.getElementById("newinputsubstrate32").style.borderColor = "red";
      document.getElementById("newinputsubstrate32").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct31").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct31").style.borderColor = "red";
    } else if (
      substrateIntermediate33 !== productIntermediate32 &&
      substrateIntermediate33 !== "" &&
      productIntermediate32 !== ""
    ) {
      document.getElementById("newinputsubstrate33").style.borderColor = "red";
      document.getElementById("newinputsubstrate33").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct32").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct32").style.borderColor = "red";
    } else if (
      substrateIntermediate34 !== productIntermediate33 &&
      substrateIntermediate34 !== "" &&
      productIntermediate33 !== ""
    ) {
      document.getElementById("newinputsubstrate34").style.borderColor = "red";
      document.getElementById("newinputsubstrate34").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct33").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct33").style.borderColor = "red";
    } else if (
      substrateIntermediate35 !== productIntermediate34 &&
      substrateIntermediate35 !== "" &&
      productIntermediate34 !== ""
    ) {
      document.getElementById("newinputsubstrate35").style.borderColor = "red";
      document.getElementById("newinputsubstrate35").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct34").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct34").style.borderColor = "red";
    } else if (
      substrateIntermediate36 !== productIntermediate35 &&
      substrateIntermediate36 !== "" &&
      productIntermediate35 !== ""
    ) {
      document.getElementById("newinputsubstrate36").style.borderColor = "red";
      document.getElementById("newinputsubstrate36").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct35").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct35").style.borderColor = "red";
    } else if (
      substrateIntermediate37 !== productIntermediate36 &&
      substrateIntermediate37 !== "" &&
      productIntermediate36 !== ""
    ) {
      document.getElementById("newinputsubstrate37").style.borderColor = "red";
      document.getElementById("newinputsubstrate37").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct36").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct36").style.borderColor = "red";
    } else if (
      substrateIntermediate38 !== productIntermediate37 &&
      substrateIntermediate38 !== "" &&
      productIntermediate37 !== ""
    ) {
      document.getElementById("newinputsubstrate38").style.borderColor = "red";
      document.getElementById("newinputsubstrate38").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct37").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct37").style.borderColor = "red";
    } else if (
      substrateIntermediate39 !== productIntermediate38 &&
      substrateIntermediate39 !== "" &&
      productIntermediate38 !== ""
    ) {
      document.getElementById("newinputsubstrate39").style.borderColor = "red";
      document.getElementById("newinputsubstrate39").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct38").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct38").style.borderColor = "red";
    } else if (
      substrateIntermediate40 !== productIntermediate39 &&
      substrateIntermediate40 !== "" &&
      productIntermediate39 !== ""
    ) {
      document.getElementById("newinputsubstrate40").style.borderColor = "red";
      document.getElementById("newinputsubstrate40").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct39").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct39").style.borderColor = "red";
    } else if (
      substrateIntermediate41 !== productIntermediate40 &&
      substrateIntermediate41 !== "" &&
      productIntermediate40 !== ""
    ) {
      document.getElementById("newinputsubstrate41").style.borderColor = "red";
      document.getElementById("newinputsubstrate41").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct40").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct40").style.borderColor = "red";
    } else if (
      substrateIntermediate42 !== productIntermediate41 &&
      substrateIntermediate42 !== "" &&
      productIntermediate41 !== ""
    ) {
      document.getElementById("newinputsubstrate42").style.borderColor = "red";
      document.getElementById("newinputsubstrate42").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct41").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct41").style.borderColor = "red";
    } else if (
      substrateIntermediate43 !== productIntermediate42 &&
      substrateIntermediate43 !== "" &&
      productIntermediate42 !== ""
    ) {
      document.getElementById("newinputsubstrate43").style.borderColor = "red";
      document.getElementById("newinputsubstrate43").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct42").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct42").style.borderColor = "red";
    } else if (
      substrateIntermediate44 !== productIntermediate43 &&
      substrateIntermediate44 !== "" &&
      productIntermediate43 !== ""
    ) {
      document.getElementById("newinputsubstrate44").style.borderColor = "red";
      document.getElementById("newinputsubstrate44").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct43").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct43").style.borderColor = "red";
    } else if (
      substrateIntermediate45 !== productIntermediate44 &&
      substrateIntermediate45 !== "" &&
      productIntermediate44 !== ""
    ) {
      document.getElementById("newinputsubstrate45").style.borderColor = "red";
      document.getElementById("newinputsubstrate45").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct44").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct44").style.borderColor = "red";
    } else if (
      substrateIntermediate46 !== productIntermediate45 &&
      substrateIntermediate46 !== "" &&
      productIntermediate45 !== ""
    ) {
      document.getElementById("newinputsubstrate46").style.borderColor = "red";
      document.getElementById("newinputsubstrate46").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct45").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct45").style.borderColor = "red";
    } else if (
      substrateIntermediate47 !== productIntermediate46 &&
      substrateIntermediate47 !== "" &&
      productIntermediate46 !== ""
    ) {
      document.getElementById("newinputsubstrate47").style.borderColor = "red";
      document.getElementById("newinputsubstrate47").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct46").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct46").style.borderColor = "red";
    } else if (
      substrateIntermediate48 !== productIntermediate47 &&
      substrateIntermediate48 !== "" &&
      productIntermediate47 !== ""
    ) {
      document.getElementById("newinputsubstrate48").style.borderColor = "red";
      document.getElementById("newinputsubstrate48").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct47").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct47").style.borderColor = "red";
    } else if (
      substrateIntermediate49 !== productIntermediate48 &&
      substrateIntermediate49 !== "" &&
      productIntermediate48 !== ""
    ) {
      document.getElementById("newinputsubstrate49").style.borderColor = "red";
      document.getElementById("newinputsubstrate49").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct48").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct48").style.borderColor = "red";
    } else if (
      substrateIntermediate50 !== productIntermediate49 &&
      substrateIntermediate50 !== "" &&
      productIntermediate49 !== ""
    ) {
      document.getElementById("newinputsubstrate50").style.borderColor = "red";
      document.getElementById("newinputsubstrate50").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct49").style.backgroundColor =
        "pink";
      document.getElementById("newinputproduct49").style.borderColor = "red";
    } else {
      try {

      
      const username = auth.username
      const response = await axiosPrivate.post("/mechanismentry", {
        RE_pair:state.RE_pair,
        username,
        suggestion_id:state.suggestion_id,
        substrateIntermediate1:encoding.convert(substrateIntermediate1,"Latin_1"),
        productIntermediate1:encoding.convert(productIntermediate1,"Latin_1"),
        name1,
        chebi1,
        type1,
        substrateIntermediate2:encoding.convert(substrateIntermediate2,"Latin_1"),
        productIntermediate2:encoding.convert(productIntermediate2,"Latin_1"),
        name2,
        chebi2,
        type2,
        substrateIntermediate3:encoding.convert(substrateIntermediate3,"Latin_1"),
        productIntermediate3:encoding.convert(productIntermediate3,"Latin_1"),
        name3,
        chebi3,
        type3,
        substrateIntermediate4:encoding.convert(substrateIntermediate4,"Latin_1"),
        productIntermediate4:encoding.convert(productIntermediate4,"Latin_1"),
        name4,
        chebi4,
        type4,
        substrateIntermediate5:encoding.convert(substrateIntermediate5,"Latin_1"),
        productIntermediate5:encoding.convert(productIntermediate5,"Latin_1"),
        name5,
        chebi5,
        type5,
        substrateIntermediate6:encoding.convert(substrateIntermediate6,"Latin_1"),
        productIntermediate6:encoding.convert(productIntermediate6,"Latin_1"),
        name6,
        chebi6,
        type6,
        substrateIntermediate7:encoding.convert(substrateIntermediate7,"Latin_1"),
        productIntermediate7:encoding.convert(productIntermediate7,"Latin_1"),
        name7,
        chebi7,
        type7,
        substrateIntermediate8:encoding.convert(substrateIntermediate8,"Latin_1"),
        productIntermediate8:encoding.convert(productIntermediate8,"Latin_1"),
        name8,
        chebi8,
        type8,
        substrateIntermediate9:encoding.convert(substrateIntermediate9,"Latin_1"),
        productIntermediate9:encoding.convert(productIntermediate9,"Latin_1"),
        name9,
        chebi9,
        type9,
        substrateIntermediate10:encoding.convert(substrateIntermediate10,"Latin_1"),
        productIntermediate10:encoding.convert(productIntermediate10,"Latin_1"),
        name10,
        chebi10,
        type10,
        substrateIntermediate11:encoding.convert(substrateIntermediate11,"Latin_1"),
        productIntermediate11:encoding.convert(productIntermediate11,"Latin_1"),
        name11,
        chebi11,
        type11,
        substrateIntermediate12:encoding.convert(substrateIntermediate12,"Latin_1"),
        productIntermediate12:encoding.convert(productIntermediate12,"Latin_1"),
        name12,
        chebi12,
        type12,
        substrateIntermediate13:encoding.convert(substrateIntermediate13,"Latin_1"),
        productIntermediate13:encoding.convert(productIntermediate13,"Latin_1"),
        name13,
        chebi13,
        type13,
        substrateIntermediate14:encoding.convert(substrateIntermediate14,"Latin_1"),
        productIntermediate14:encoding.convert(productIntermediate14,"Latin_1"),
        name14,
        chebi14,
        type14,
        substrateIntermediate15:encoding.convert(substrateIntermediate15,"Latin_1"),
        productIntermediate15:encoding.convert(productIntermediate15,"Latin_1"),
        name15,
        chebi15,
        type15,
        substrateIntermediate16:encoding.convert(substrateIntermediate16,"Latin_1"),
        productIntermediate16:encoding.convert(productIntermediate16,"Latin_1"),
        name16,
        chebi16,
        type16,
        substrateIntermediate17:encoding.convert(substrateIntermediate17,"Latin_1"),
        productIntermediate17:encoding.convert(productIntermediate17,"Latin_1"),
        name17,
        chebi17,
        type17,
        substrateIntermediate18:encoding.convert(substrateIntermediate18,"Latin_1"),
        productIntermediate18:encoding.convert(productIntermediate18,"Latin_1"),
        name18,
        chebi18,
        type18,
        substrateIntermediate19:encoding.convert(substrateIntermediate19,"Latin_1"),
        productIntermediate19:encoding.convert(productIntermediate19,"Latin_1"),
        name19,
        chebi19,
        type19,
        substrateIntermediate20:encoding.convert(substrateIntermediate20,"Latin_1"),
        productIntermediate20:encoding.convert(productIntermediate20,"Latin_1"),
        name20,
        chebi20,
        type20,
        substrateIntermediate21:encoding.convert(substrateIntermediate21,"Latin_1"),
        productIntermediate21:encoding.convert(productIntermediate21,"Latin_1"),
        name21,
        chebi21,
        type21,
        substrateIntermediate22:encoding.convert(substrateIntermediate22,"Latin_1"),
        productIntermediate22:encoding.convert(productIntermediate22,"Latin_1"),
        name22,
        chebi22,
        type22,
        substrateIntermediate23:encoding.convert(substrateIntermediate23,"Latin_1"),
        productIntermediate23:encoding.convert(productIntermediate23,"Latin_1"),
        name23,
        chebi23,
        type23,
        substrateIntermediate24:encoding.convert(substrateIntermediate24,"Latin_1"),
        productIntermediate24:encoding.convert(productIntermediate24,"Latin_1"),
        name24,
        chebi24,
        type24,
        substrateIntermediate25:encoding.convert(substrateIntermediate25,"Latin_1"),
        productIntermediate25:encoding.convert(productIntermediate25,"Latin_1"),
        name25,
        chebi25,
        type25,
        substrateIntermediate26:encoding.convert(substrateIntermediate26,"Latin_1"),
        productIntermediate26:encoding.convert(productIntermediate26,"Latin_1"),
        name26,
        chebi26,
        type26,
        substrateIntermediate27:encoding.convert(substrateIntermediate27,"Latin_1"),
        productIntermediate27:encoding.convert(productIntermediate27,"Latin_1"),
        name27,
        chebi27,
        type27,
        substrateIntermediate28:encoding.convert(substrateIntermediate28,"Latin_1"),
        productIntermediate28:encoding.convert(productIntermediate28,"Latin_1"),
        name28,
        chebi28,
        type28,
        substrateIntermediate29:encoding.convert(substrateIntermediate29,"Latin_1"),
        productIntermediate29:encoding.convert(productIntermediate29,"Latin_1"),
        name29,
        chebi29,
        type29,
        substrateIntermediate30:encoding.convert(substrateIntermediate30,"Latin_1"),
        productIntermediate30:encoding.convert(productIntermediate30,"Latin_1"),
        name30,
        chebi30,
        type30,
        substrateIntermediate31:encoding.convert(substrateIntermediate31,"Latin_1"),
        productIntermediate31:encoding.convert(productIntermediate31,"Latin_1"),
        name31,
        chebi31,
        type31,
        substrateIntermediate32:encoding.convert(substrateIntermediate32,"Latin_1"),
        productIntermediate32:encoding.convert(productIntermediate32,"Latin_1"),
        name32,
        chebi32,
        type32,
        substrateIntermediate33:encoding.convert(substrateIntermediate33,"Latin_1"),
        productIntermediate33:encoding.convert(productIntermediate33,"Latin_1"),
        name33,
        chebi33,
        type33,
        substrateIntermediate34:encoding.convert(substrateIntermediate34,"Latin_1"),
        productIntermediate34:encoding.convert(productIntermediate34,"Latin_1"),
        name34,
        chebi34,
        type34,
        substrateIntermediate35:encoding.convert(substrateIntermediate35,"Latin_1"),
        productIntermediate35:encoding.convert(productIntermediate35,"Latin_1"),
        name35,
        chebi35,
        type35,
        substrateIntermediate36:encoding.convert(substrateIntermediate36,"Latin_1"),
        productIntermediate36:encoding.convert(productIntermediate36,"Latin_1"),
        name36,
        chebi36,
        type36,
        substrateIntermediate37:encoding.convert(substrateIntermediate37,"Latin_1"),
        productIntermediate37:encoding.convert(productIntermediate37,"Latin_1"),
        name37,
        chebi37,
        type37,
        substrateIntermediate38:encoding.convert(substrateIntermediate38,"Latin_1"),
        productIntermediate38:encoding.convert(productIntermediate38,"Latin_1"),
        name38,
        chebi38,
        type38,
        substrateIntermediate39:encoding.convert(substrateIntermediate39,"Latin_1"),
        productIntermediate39:encoding.convert(productIntermediate39,"Latin_1"),
        name39,
        chebi39,
        type39,
        substrateIntermediate40:encoding.convert(substrateIntermediate40,"Latin_1"),
        productIntermediate40:encoding.convert(productIntermediate40,"Latin_1"),
        name40,
        chebi40,
        type40,
        substrateIntermediate41:encoding.convert(substrateIntermediate41,"Latin_1"),
        productIntermediate41:encoding.convert(productIntermediate41,"Latin_1"),
        name41,
        chebi41,
        type41,
        substrateIntermediate42:encoding.convert(substrateIntermediate42,"Latin_1"),
        productIntermediate42:encoding.convert(productIntermediate42,"Latin_1"),
        name42,
        chebi42,
        type42,
        substrateIntermediate43:encoding.convert(substrateIntermediate43,"Latin_1"),
        productIntermediate43:encoding.convert(productIntermediate43,"Latin_1"),
        name43,
        chebi43,
        type43,
        substrateIntermediate44:encoding.convert(substrateIntermediate44,"Latin_1"),
        productIntermediate44:encoding.convert(productIntermediate44,"Latin_1"),
        name44,
        chebi44,
        type44,
        substrateIntermediate45:encoding.convert(substrateIntermediate45,"Latin_1"),
        productIntermediate45:encoding.convert(productIntermediate45,"Latin_1"),
        name45,
        chebi45,
        type45,
        substrateIntermediate46:encoding.convert(substrateIntermediate46,"Latin_1"),
        productIntermediate46:encoding.convert(productIntermediate46,"Latin_1"),
        name46,
        chebi46,
        type46,
        substrateIntermediate47:encoding.convert(substrateIntermediate47,"Latin_1"),
        productIntermediate47:encoding.convert(productIntermediate47,"Latin_1"),
        name47,
        chebi47,
        type47,
        substrateIntermediate48:encoding.convert(substrateIntermediate48,"Latin_1"),
        productIntermediate48:encoding.convert(productIntermediate48,"Latin_1"),
        name48,
        chebi48,
        type48,
        substrateIntermediate49:encoding.convert(substrateIntermediate49,"Latin_1"),
        productIntermediate49:encoding.convert(productIntermediate49,"Latin_1"),
        name49,
        chebi49,
        type49,
        substrateIntermediate50:encoding.convert(substrateIntermediate50,"Latin_1"),
        productIntermediate50:encoding.convert(productIntermediate50,"Latin_1"),
        name50,
        chebi50,
        type50,
        evidence,
        link,
        doi,
        pmid
      });
      if (!response.data.duplication) {
        const elements = document.querySelectorAll("#body > *:not(#overlap)");
        elements.forEach((element) => {
          element.style.filter = "blur(3px)";
        });
        document.getElementById("overlap").style.display = "block";
        document.getElementById("submitConfirmation").style.display = "block";
      } else {
        const elements = document.querySelectorAll("#body > *:not(#overlap)");
        elements.forEach((element) => {
          element.style.filter = "blur(3px)";
        });
        document.getElementById("overlap").style.display = "block";
        document.getElementById("duplication").style.display = "block";
      }
    } catch (err) {
      throw err
    }
    }
  }

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css"
          rel="stylesheet"
        />
      </head>
      <body id="body">
        <HomeNavbar/>
        <div
          id="SearchTitle"
          style={{ display: "block" }}
          onClick={clickProceed}
        >
          <div id="SearchInstructions" align="left" onClick={clickProceed}>
            <h1>Make a new mechanism entry</h1>
          </div>
          <div id="SearchInstructions" align="left" onClick={clickProceed}>
            <FontAwesomeIcon icon={faDatabase} /> One reaction mechanism can
            have as many mechanism steps as you need. <br />
            <FontAwesomeIcon icon={faDatabase} /> Each mechanism step consists
            of one reactant and product. <br />
            <FontAwesomeIcon icon={faDatabase} /> Mechanism steps are added by
            clicking on + icon and removed by clicking on - icon. <br />
            <FontAwesomeIcon icon={faDatabase} /> Steps have to be submitted in
            the correct order. All fields are required. <br />
          </div>
          <div id="SearchInstructions" align="left" onClick={clickProceed}>
            <h4>Click anywhere to proceed.</h4>
          </div>
        </div>
        <div
          class="row"
          id="entrycontent"
          style={{
            display: "none",
            paddingTop: "165px",
            paddingBottom: "115px",
          }}
        >
          <div class="col-lg-12" align="center">
            <div class="row">
              <div class="col-lg-12">
                <ul id="buttonUl">
                  <li>
                    <button id="addInput" onClick={addInput}>
                      <FontAwesomeIcon icon={faPlus} color="rgb(150,150,150)" />
                    </button>
                  </li>
                  <li>
                    <button id="deleteInput" onClick={deleteInput}>
                      <FontAwesomeIcon
                        icon={faMinus}
                        color="rgb(150,150,150)"
                      />
                    </button>
                  </li>
                </ul>
                <form id="MechanismInputs" align="left">
                  <h5 id="heading1" align="left">
                    Step 1:
                  </h5>
                  <label id="labelsubstrate1" for="newinputsubstrate1">
                    Substrate SMILES:
                  </label>
                  <textarea
                    id="newinputsubstrate1"
                    value={substrateIntermediate1}
                    onChange={(event) => {
                      setSubstrateIntermediate1(event.target.value);
                    }}
                    disabled={true}
                    style={{ backgroundColor: "rgb(220,220,220)" }}
                  ></textarea>
                  <label id="labelproduct1" for="newinputproduct1">
                    Product SMILES:
                  </label>
                  <textarea
                    id="newinputproduct1"
                    value={productIntermediate1}
                    onChange={(event) => {
                      setProductIntermediate1(event.target.value);
                      setSubstrateIntermediate2(event.target.value);
                    }}
                  ></textarea>
                  <label id="labelname1" for="newinputname1">
                    Product name:
                  </label>
                  <input
                    id="newinputname1"
                    value={name1}
                    onChange={(event) => {
                      setName1(event.target.value);
                    }}
                  ></input>
                  <label id="labelchebi1" for="newinputchebi1">
                    Product ChEBI:
                  </label>
                  <input
                    id="newinputchebi1"
                    value={chebi1}
                    onChange={(event) => {
                      setChebi1(event.target.value);
                    }}
                  ></input>
                  <label id="labeltype1" for="newinputtype1">
                    Step type:
                  </label>
                  <input
                    id="newinputtype1"
                    value={type1}
                    onChange={(event) => {
                      setType1(event.target.value);
                    }}
                  ></input>

                  <h5 id="heading2" align="left">
                    Step 2:
                  </h5>
                  <label id="labelsubstrate2" for="newinputsubstrate2">
                    Substrate SMILES:
                  </label>
                  <textarea
                    id="newinputsubstrate2"
                    value={substrateIntermediate2}
                    onChange={(event) => {
                      setSubstrateIntermediate2(event.target.value);
                      setProductIntermediate1(event.target.value);
                    }}
                  ></textarea>
                  <label id="labelproduct2" for="newinputproduct2">
                    Product SMILES:
                  </label>
                  <textarea
                    id="newinputproduct2"
                    value={productIntermediate2}
                    onChange={(event) => {
                      setProductIntermediate2(event.target.value);
                      setSubstrateIntermediate3(event.target.value);
                    }}
                    disabled={true}
                    style={{ backgroundColor: "rgb(220,220,220)" }}
                  ></textarea>
                  <label id="labelname2" for="newinputname2">
                    Product name:
                  </label>
                  <input
                    id="newinputname2"
                    value={name2}
                    onChange={(event) => {
                      setName2(event.target.value);
                    }}
                    disabled={true}
                    style={{ backgroundColor: "rgb(220,220,220)" }}
                  ></input>
                  <label id="labelchebi2" for="newinputchebi2">
                    Product ChEBI:
                  </label>
                  <input
                    id="newinputchebi2"
                    value={chebi2}
                    onChange={(event) => {
                      setChebi2(event.target.value);
                    }}
                    disabled={true}
                    style={{ backgroundColor: "rgb(220,220,220)" }}
                  ></input>
                  <label id="labeltype2" for="newinputtype2">
                    Step type:
                  </label>
                  <input
                    id="newinputtype2"
                    value={type2}
                    onChange={(event) => {
                      setType2(event.target.value);
                    }}
                  ></input>

                  <h5 id="heading3" align="left" style={{ display: "none" }}>
                    Step 3:
                  </h5>
                  <label
                    id="labelsubstrate3"
                    for="newinputsubstrate3"
                    style={{ display: "none" }}
                  >
                    Substrate SMILES:
                  </label>
                  <textarea
                    id="newinputsubstrate3"
                    style={{ display: "none" }}
                    value={substrateIntermediate3}
                    onChange={(event) => {
                      setSubstrateIntermediate3(event.target.value);
                      setProductIntermediate2(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelproduct3"
                    for="newinputproduct3"
                    style={{ display: "none" }}
                  >
                    Product SMILES:
                  </label>
                  <textarea
                    id="newinputproduct3"
                    style={{ display: "none" }}
                    value={productIntermediate3}
                    onChange={(event) => {
                      setProductIntermediate3(event.target.value);
                      setSubstrateIntermediate4(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelname3"
                    for="newinputname3"
                    style={{ display: "none" }}
                  >
                    Product name:
                  </label>
                  <input
                    id="newinputname3"
                    value={name3}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setName3(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labelchebi3"
                    for="newinputchebi3"
                    style={{ display: "none" }}
                  >
                    Product ChEBI:
                  </label>
                  <input
                    id="newinputchebi3"
                    value={chebi3}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setChebi3(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labeltype3"
                    for="newinputtype3"
                    style={{ display: "none" }}
                  >
                    Step type:
                  </label>
                  <input
                    id="newinputtype3"
                    style={{ display: "none" }}
                    value={type3}
                    onChange={(event) => {
                      setType3(event.target.value);
                    }}
                  ></input>

                  <h5 id="heading4" align="left" style={{ display: "none" }}>
                    Step 4:
                  </h5>
                  <label
                    id="labelsubstrate4"
                    for="newinputsubstrate4"
                    style={{ display: "none" }}
                  >
                    Substrate SMILES:
                  </label>
                  <textarea
                    id="newinputsubstrate4"
                    style={{ display: "none" }}
                    value={substrateIntermediate4}
                    onChange={(event) => {
                      setSubstrateIntermediate4(event.target.value);
                      setProductIntermediate3(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelproduct4"
                    for="newinputproduct4"
                    style={{ display: "none" }}
                  >
                    Product SMILES:
                  </label>
                  <textarea
                    id="newinputproduct4"
                    style={{ display: "none" }}
                    value={productIntermediate4}
                    onChange={(event) => {
                      setProductIntermediate4(event.target.value);
                      setSubstrateIntermediate5(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelname4"
                    for="newinputname4"
                    style={{ display: "none" }}
                  >
                    Product name:
                  </label>
                  <input
                    id="newinputname4"
                    value={name4}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setName4(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labelchebi4"
                    for="newinputchebi4"
                    style={{ display: "none" }}
                  >
                    Product ChEBI:
                  </label>
                  <input
                    id="newinputchebi4"
                    value={chebi4}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setChebi4(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labeltype4"
                    for="newinputtype4"
                    style={{ display: "none" }}
                  >
                    Step type:
                  </label>
                  <input
                    id="newinputtype4"
                    style={{ display: "none" }}
                    value={type4}
                    onChange={(event) => {
                      setType4(event.target.value);
                    }}
                  ></input>

                  <h5 id="heading5" align="left" style={{ display: "none" }}>
                    Step 5:
                  </h5>
                  <label
                    id="labelsubstrate5"
                    for="newinputsubstrate5"
                    style={{ display: "none" }}
                  >
                    Substrate SMILES:
                  </label>
                  <textarea
                    id="newinputsubstrate5"
                    style={{ display: "none" }}
                    value={substrateIntermediate5}
                    onChange={(event) => {
                      setSubstrateIntermediate5(event.target.value);
                      setProductIntermediate4(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelproduct5"
                    for="newinputproduct5"
                    style={{ display: "none" }}
                  >
                    Product SMILES:
                  </label>
                  <textarea
                    id="newinputproduct5"
                    style={{ display: "none" }}
                    value={productIntermediate5}
                    onChange={(event) => {
                      setProductIntermediate5(event.target.value);
                      setSubstrateIntermediate6(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelname5"
                    for="newinputname5"
                    style={{ display: "none" }}
                  >
                    Product name:
                  </label>
                  <input
                    id="newinputname5"
                    value={name5}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setName5(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labelchebi5"
                    for="newinputchebi5"
                    style={{ display: "none" }}
                  >
                    Product ChEBI:
                  </label>
                  <input
                    id="newinputchebi5"
                    value={chebi5}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setChebi5(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labeltype5"
                    for="newinputtype5"
                    style={{ display: "none" }}
                  >
                    Step type:
                  </label>
                  <input
                    id="newinputtype5"
                    style={{ display: "none" }}
                    value={type5}
                    onChange={(event) => {
                      setType5(event.target.value);
                    }}
                  ></input>

                  <h5 id="heading6" align="left" style={{ display: "none" }}>
                    Step 6:
                  </h5>
                  <label
                    id="labelsubstrate6"
                    for="newinputsubstrate6"
                    style={{ display: "none" }}
                  >
                    Substrate SMILES:
                  </label>
                  <textarea
                    id="newinputsubstrate6"
                    style={{ display: "none" }}
                    value={substrateIntermediate6}
                    onChange={(event) => {
                      setSubstrateIntermediate6(event.target.value);
                      setProductIntermediate5(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelproduct6"
                    for="newinputproduct6"
                    style={{ display: "none" }}
                  >
                    Product SMILES:
                  </label>
                  <textarea
                    id="newinputproduct6"
                    style={{ display: "none" }}
                    value={productIntermediate6}
                    onChange={(event) => {
                      setProductIntermediate6(event.target.value);
                      setSubstrateIntermediate7(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelname6"
                    for="newinputname6"
                    style={{ display: "none" }}
                  >
                    Product name:
                  </label>
                  <input
                    id="newinputname6"
                    value={name6}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setName6(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labelchebi6"
                    for="newinputchebi6"
                    style={{ display: "none" }}
                  >
                    Product ChEBI:
                  </label>
                  <input
                    id="newinputchebi6"
                    value={chebi6}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setChebi6(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labeltype6"
                    for="newinputtype6"
                    style={{ display: "none" }}
                  >
                    Step type:
                  </label>
                  <input
                    id="newinputtype6"
                    style={{ display: "none" }}
                    value={type6}
                    onChange={(event) => {
                      setType6(event.target.value);
                    }}
                  ></input>

                  <h5 id="heading7" align="left" style={{ display: "none" }}>
                    Step 7:
                  </h5>
                  <label
                    id="labelsubstrate7"
                    for="newinputsubstrate7"
                    style={{ display: "none" }}
                  >
                    Substrate SMILES:
                  </label>
                  <textarea
                    id="newinputsubstrate7"
                    style={{ display: "none" }}
                    value={substrateIntermediate7}
                    onChange={(event) => {
                      setSubstrateIntermediate7(event.target.value);
                      setProductIntermediate6(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelproduct7"
                    for="newinputproduct7"
                    style={{ display: "none" }}
                  >
                    Product SMILES:
                  </label>
                  <textarea
                    id="newinputproduct7"
                    style={{ display: "none" }}
                    value={productIntermediate7}
                    onChange={(event) => {
                      setProductIntermediate7(event.target.value);
                      setSubstrateIntermediate8(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelname7"
                    for="newinputname7"
                    style={{ display: "none" }}
                  >
                    Product name:
                  </label>
                  <input
                    id="newinputname7"
                    value={name7}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setName7(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labelchebi7"
                    for="newinputchebi7"
                    style={{ display: "none" }}
                  >
                    Product ChEBI:
                  </label>
                  <input
                    id="newinputchebi7"
                    value={chebi7}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setChebi7(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labeltype7"
                    for="newinputtype7"
                    style={{ display: "none" }}
                  >
                    Step type:
                  </label>
                  <input
                    id="newinputtype7"
                    style={{ display: "none" }}
                    value={type7}
                    onChange={(event) => {
                      setType7(event.target.value);
                    }}
                  ></input>

                  <h5 id="heading8" align="left" style={{ display: "none" }}>
                    Step 8:
                  </h5>
                  <label
                    id="labelsubstrate8"
                    for="newinputsubstrate8"
                    style={{ display: "none" }}
                  >
                    Substrate SMILES:
                  </label>
                  <textarea
                    id="newinputsubstrate8"
                    style={{ display: "none" }}
                    value={substrateIntermediate8}
                    onChange={(event) => {
                      setSubstrateIntermediate8(event.target.value);
                      setProductIntermediate7(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelproduct8"
                    for="newinputproduct8"
                    style={{ display: "none" }}
                  >
                    Product SMILES:
                  </label>
                  <textarea
                    id="newinputproduct8"
                    style={{ display: "none" }}
                    value={productIntermediate8}
                    onChange={(event) => {
                      setProductIntermediate8(event.target.value);
                      setSubstrateIntermediate9(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelname8"
                    for="newinputname8"
                    style={{ display: "none" }}
                  >
                    Product name:
                  </label>
                  <input
                    id="newinputname8"
                    value={name8}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setName8(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labelchebi8"
                    for="newinputchebi8"
                    style={{ display: "none" }}
                  >
                    Product ChEBI:
                  </label>
                  <input
                    id="newinputchebi8"
                    value={chebi8}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setChebi8(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labeltype8"
                    for="newinputtype8"
                    style={{ display: "none" }}
                  >
                    Step type:
                  </label>
                  <input
                    id="newinputtype8"
                    style={{ display: "none" }}
                    value={type8}
                    onChange={(event) => {
                      setType8(event.target.value);
                    }}
                  ></input>

                  <h5 id="heading9" align="left" style={{ display: "none" }}>
                    Step 9:
                  </h5>
                  <label
                    id="labelsubstrate9"
                    for="newinputsubstrate9"
                    style={{ display: "none" }}
                  >
                    Substrate SMILES:
                  </label>
                  <textarea
                    id="newinputsubstrate9"
                    style={{ display: "none" }}
                    value={substrateIntermediate9}
                    onChange={(event) => {
                      setSubstrateIntermediate9(event.target.value);
                      setProductIntermediate8(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelproduct9"
                    for="newinputproduct9"
                    style={{ display: "none" }}
                  >
                    Product SMILES:
                  </label>
                  <textarea
                    id="newinputproduct9"
                    style={{ display: "none" }}
                    value={productIntermediate9}
                    onChange={(event) => {
                      setProductIntermediate9(event.target.value);
                      setSubstrateIntermediate10(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelname9"
                    for="newinputname9"
                    style={{ display: "none" }}
                  >
                    Product name:
                  </label>
                  <input
                    id="newinputname9"
                    value={name9}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setName9(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labelchebi9"
                    for="newinputchebi9"
                    style={{ display: "none" }}
                  >
                    Product ChEBI:
                  </label>
                  <input
                    id="newinputchebi9"
                    value={chebi9}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setChebi9(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labeltype9"
                    for="newinputtype9"
                    style={{ display: "none" }}
                  >
                    Step type:
                  </label>
                  <input
                    id="newinputtype9"
                    style={{ display: "none" }}
                    value={type9}
                    onChange={(event) => {
                      setType9(event.target.value);
                    }}
                  ></input>

                  <h5 id="heading10" align="left" style={{ display: "none" }}>
                    Step 10:
                  </h5>
                  <label
                    id="labelsubstrate10"
                    for="newinputsubstrate10"
                    style={{ display: "none" }}
                  >
                    Substrate SMILES:
                  </label>
                  <textarea
                    id="newinputsubstrate10"
                    style={{ display: "none" }}
                    value={substrateIntermediate10}
                    onChange={(event) => {
                      setSubstrateIntermediate10(event.target.value);
                      setProductIntermediate9(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelproduct10"
                    for="newinputproduct10"
                    style={{ display: "none" }}
                  >
                    Product SMILES:
                  </label>
                  <textarea
                    id="newinputproduct10"
                    style={{ display: "none" }}
                    value={productIntermediate10}
                    onChange={(event) => {
                      setProductIntermediate10(event.target.value);
                      setSubstrateIntermediate11(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelname10"
                    for="newinputname10"
                    style={{ display: "none" }}
                  >
                    Product name:
                  </label>
                  <input
                    id="newinputname10"
                    value={name10}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setName10(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labelchebi10"
                    for="newinputchebi10"
                    style={{ display: "none" }}
                  >
                    Product ChEBI:
                  </label>
                  <input
                    id="newinputchebi10"
                    value={chebi10}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setChebi10(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labeltype10"
                    for="newinputtype10"
                    style={{ display: "none" }}
                  >
                    Step type:
                  </label>
                  <input
                    id="newinputtype10"
                    style={{ display: "none" }}
                    value={type10}
                    onChange={(event) => {
                      setType10(event.target.value);
                    }}
                  ></input>

                  <h5 id="heading11" align="left" style={{ display: "none" }}>
                    Step 11:
                  </h5>
                  <label
                    id="labelsubstrate11"
                    for="newinputsubstrate11"
                    style={{ display: "none" }}
                  >
                    Substrate SMILES:
                  </label>
                  <textarea
                    id="newinputsubstrate11"
                    style={{ display: "none" }}
                    value={substrateIntermediate11}
                    onChange={(event) => {
                      setSubstrateIntermediate11(event.target.value);
                      setProductIntermediate10(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelproduct11"
                    for="newinputproduct11"
                    style={{ display: "none" }}
                  >
                    Product SMILES:
                  </label>
                  <textarea
                    id="newinputproduct11"
                    style={{ display: "none" }}
                    value={productIntermediate11}
                    onChange={(event) => {
                      setProductIntermediate11(event.target.value);
                      setSubstrateIntermediate12(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelname11"
                    for="newinputname11"
                    style={{ display: "none" }}
                  >
                    Product name:
                  </label>
                  <input
                    id="newinputname11"
                    value={name11}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setName11(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labelchebi11"
                    for="newinputchebi11"
                    style={{ display: "none" }}
                  >
                    Product ChEBI:
                  </label>
                  <input
                    id="newinputchebi11"
                    value={chebi11}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setChebi11(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labeltype11"
                    for="newinputtype11"
                    style={{ display: "none" }}
                  >
                    Step type:
                  </label>
                  <input
                    id="newinputtype11"
                    style={{ display: "none" }}
                    value={type11}
                    onChange={(event) => {
                      setType11(event.target.value);
                    }}
                  ></input>

                  <h5 id="heading12" align="left" style={{ display: "none" }}>
                    Step 12:
                  </h5>
                  <label
                    id="labelsubstrate12"
                    for="newinputsubstrate12"
                    style={{ display: "none" }}
                  >
                    Substrate SMILES:
                  </label>
                  <textarea
                    id="newinputsubstrate12"
                    style={{ display: "none" }}
                    value={substrateIntermediate12}
                    onChange={(event) => {
                      setSubstrateIntermediate12(event.target.value);
                      setProductIntermediate11(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelproduct12"
                    for="newinputproduct12"
                    style={{ display: "none" }}
                  >
                    Product SMILES:
                  </label>
                  <textarea
                    id="newinputproduct12"
                    style={{ display: "none" }}
                    value={productIntermediate12}
                    onChange={(event) => {
                      setProductIntermediate12(event.target.value);
                      setSubstrateIntermediate13(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelname12"
                    for="newinputname12"
                    style={{ display: "none" }}
                  >
                    Product name:
                  </label>
                  <input
                    id="newinputname12"
                    value={name12}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setName12(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labelchebi12"
                    for="newinputchebi12"
                    style={{ display: "none" }}
                  >
                    Product ChEBI:
                  </label>
                  <input
                    id="newinputchebi12"
                    value={chebi12}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setChebi12(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labeltype12"
                    for="newinputtype12"
                    style={{ display: "none" }}
                  >
                    Step type:
                  </label>
                  <input
                    id="newinputtype12"
                    style={{ display: "none" }}
                    value={type12}
                    onChange={(event) => {
                      setType12(event.target.value);
                    }}
                  ></input>

                  <h5 id="heading13" align="left" style={{ display: "none" }}>
                    Step 13:
                  </h5>
                  <label
                    id="labelsubstrate13"
                    for="newinputsubstrate13"
                    style={{ display: "none" }}
                  >
                    Substrate SMILES:
                  </label>
                  <textarea
                    id="newinputsubstrate13"
                    style={{ display: "none" }}
                    value={substrateIntermediate13}
                    onChange={(event) => {
                      setSubstrateIntermediate13(event.target.value);
                      setProductIntermediate12(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelproduct13"
                    for="newinputproduct13"
                    style={{ display: "none" }}
                  >
                    Product SMILES:
                  </label>
                  <textarea
                    id="newinputproduct13"
                    style={{ display: "none" }}
                    value={productIntermediate13}
                    onChange={(event) => {
                      setProductIntermediate13(event.target.value);
                      setSubstrateIntermediate14(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelname13"
                    for="newinputname13"
                    style={{ display: "none" }}
                  >
                    Product name:
                  </label>
                  <input
                    id="newinputname13"
                    value={name13}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setName13(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labelchebi13"
                    for="newinputchebi13"
                    style={{ display: "none" }}
                  >
                    Product ChEBI:
                  </label>
                  <input
                    id="newinputchebi13"
                    value={chebi13}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setChebi13(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labeltype13"
                    for="newinputtype13"
                    style={{ display: "none" }}
                  >
                    Step type:
                  </label>
                  <input
                    id="newinputtype13"
                    style={{ display: "none" }}
                    value={type13}
                    onChange={(event) => {
                      setType13(event.target.value);
                    }}
                  ></input>

                  <h5 id="heading14" align="left" style={{ display: "none" }}>
                    Step 14:
                  </h5>
                  <label
                    id="labelsubstrate14"
                    for="newinputsubstrate14"
                    style={{ display: "none" }}
                  >
                    Substrate SMILES:
                  </label>
                  <textarea
                    id="newinputsubstrate14"
                    style={{ display: "none" }}
                    value={substrateIntermediate14}
                    onChange={(event) => {
                      setSubstrateIntermediate14(event.target.value);
                      setProductIntermediate13(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelproduct14"
                    for="newinputproduct14"
                    style={{ display: "none" }}
                  >
                    Product SMILES:
                  </label>
                  <textarea
                    id="newinputproduct14"
                    style={{ display: "none" }}
                    value={productIntermediate14}
                    onChange={(event) => {
                      setProductIntermediate14(event.target.value);
                      setSubstrateIntermediate15(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelname14"
                    for="newinputname14"
                    style={{ display: "none" }}
                  >
                    Product name:
                  </label>
                  <input
                    id="newinputname14"
                    value={name14}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setName14(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labelchebi14"
                    for="newinputchebi14"
                    style={{ display: "none" }}
                  >
                    Product ChEBI:
                  </label>
                  <input
                    id="newinputchebi14"
                    value={chebi14}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setChebi14(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labeltype14"
                    for="newinputtype14"
                    style={{ display: "none" }}
                  >
                    Step type:
                  </label>
                  <input
                    id="newinputtype14"
                    style={{ display: "none" }}
                    value={type14}
                    onChange={(event) => {
                      setType14(event.target.value);
                    }}
                  ></input>

                  <h5 id="heading15" align="left" style={{ display: "none" }}>
                    Step 15:
                  </h5>
                  <label
                    id="labelsubstrate15"
                    for="newinputsubstrate15"
                    style={{ display: "none" }}
                  >
                    Substrate SMILES:
                  </label>
                  <textarea
                    id="newinputsubstrate15"
                    style={{ display: "none" }}
                    value={substrateIntermediate15}
                    onChange={(event) => {
                      setSubstrateIntermediate15(event.target.value);
                      setProductIntermediate14(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelproduct15"
                    for="newinputproduct15"
                    style={{ display: "none" }}
                  >
                    Product SMILES:
                  </label>
                  <textarea
                    id="newinputproduct15"
                    style={{ display: "none" }}
                    value={productIntermediate15}
                    onChange={(event) => {
                      setProductIntermediate15(event.target.value);
                      setSubstrateIntermediate16(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelname15"
                    for="newinputname15"
                    style={{ display: "none" }}
                  >
                    Product name:
                  </label>
                  <input
                    id="newinputname15"
                    value={name15}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setName15(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labelchebi15"
                    for="newinputchebi15"
                    style={{ display: "none" }}
                  >
                    Product ChEBI:
                  </label>
                  <input
                    id="newinputchebi15"
                    value={chebi15}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setChebi15(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labeltype15"
                    for="newinputtype15"
                    style={{ display: "none" }}
                  >
                    Step type:
                  </label>
                  <input
                    id="newinputtype15"
                    style={{ display: "none" }}
                    value={type15}
                    onChange={(event) => {
                      setType15(event.target.value);
                    }}
                  ></input>

                  <h5 id="heading16" align="left" style={{ display: "none" }}>
                    Step 16:
                  </h5>
                  <label
                    id="labelsubstrate16"
                    for="newinputsubstrate16"
                    style={{ display: "none" }}
                  >
                    Substrate SMILES:
                  </label>
                  <textarea
                    id="newinputsubstrate16"
                    style={{ display: "none" }}
                    value={substrateIntermediate16}
                    onChange={(event) => {
                      setSubstrateIntermediate16(event.target.value);
                      setProductIntermediate15(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelproduct16"
                    for="newinputproduct16"
                    style={{ display: "none" }}
                  >
                    Product SMILES:
                  </label>
                  <textarea
                    id="newinputproduct16"
                    style={{ display: "none" }}
                    value={productIntermediate16}
                    onChange={(event) => {
                      setProductIntermediate16(event.target.value);
                      setSubstrateIntermediate17(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelname16"
                    for="newinputname16"
                    style={{ display: "none" }}
                  >
                    Product name:
                  </label>
                  <input
                    id="newinputname16"
                    value={name16}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setName16(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labelchebi16"
                    for="newinputchebi16"
                    style={{ display: "none" }}
                  >
                    Product ChEBI:
                  </label>
                  <input
                    id="newinputchebi16"
                    value={chebi16}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setChebi16(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labeltype16"
                    for="newinputtype16"
                    style={{ display: "none" }}
                  >
                    Step type:
                  </label>
                  <input
                    id="newinputtype16"
                    style={{ display: "none" }}
                    value={type16}
                    onChange={(event) => {
                      setType16(event.target.value);
                    }}
                  ></input>

                  <h5 id="heading17" align="left" style={{ display: "none" }}>
                    Step 17:
                  </h5>
                  <label
                    id="labelsubstrate17"
                    for="newinputsubstrate17"
                    style={{ display: "none" }}
                  >
                    Substrate SMILES:
                  </label>
                  <textarea
                    id="newinputsubstrate17"
                    style={{ display: "none" }}
                    value={substrateIntermediate17}
                    onChange={(event) => {
                      setSubstrateIntermediate17(event.target.value);
                      setProductIntermediate16(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelproduct17"
                    for="newinputproduct17"
                    style={{ display: "none" }}
                  >
                    Product SMILES:
                  </label>
                  <textarea
                    id="newinputproduct17"
                    style={{ display: "none" }}
                    value={productIntermediate17}
                    onChange={(event) => {
                      setProductIntermediate17(event.target.value);
                      setSubstrateIntermediate18(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelname17"
                    for="newinputname17"
                    style={{ display: "none" }}
                  >
                    Product name:
                  </label>
                  <input
                    id="newinputname17"
                    value={name17}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setName17(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labelchebi17"
                    for="newinputchebi17"
                    style={{ display: "none" }}
                  >
                    Product ChEBI:
                  </label>
                  <input
                    id="newinputchebi17"
                    value={chebi17}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setChebi17(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labeltype17"
                    for="newinputtype17"
                    style={{ display: "none" }}
                  >
                    Step type:
                  </label>
                  <input
                    id="newinputtype17"
                    style={{ display: "none" }}
                    value={type17}
                    onChange={(event) => {
                      setType17(event.target.value);
                    }}
                  ></input>

                  <h5 id="heading18" align="left" style={{ display: "none" }}>
                    Step 18:
                  </h5>
                  <label
                    id="labelsubstrate18"
                    for="newinputsubstrate18"
                    style={{ display: "none" }}
                  >
                    Substrate SMILES:
                  </label>
                  <textarea
                    id="newinputsubstrate18"
                    style={{ display: "none" }}
                    value={substrateIntermediate18}
                    onChange={(event) => {
                      setSubstrateIntermediate18(event.target.value);
                      setProductIntermediate17(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelproduct18"
                    for="newinputproduct18"
                    style={{ display: "none" }}
                  >
                    Product SMILES:
                  </label>
                  <textarea
                    id="newinputproduct18"
                    style={{ display: "none" }}
                    value={productIntermediate18}
                    onChange={(event) => {
                      setProductIntermediate18(event.target.value);
                      setSubstrateIntermediate19(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelname18"
                    for="newinputname18"
                    style={{ display: "none" }}
                  >
                    Product name:
                  </label>
                  <input
                    id="newinputname18"
                    value={name18}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setName18(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labelchebi18"
                    for="newinputchebi18"
                    style={{ display: "none" }}
                  >
                    Product ChEBI:
                  </label>
                  <input
                    id="newinputchebi18"
                    value={chebi18}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setChebi18(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labeltype18"
                    for="newinputtype18"
                    style={{ display: "none" }}
                  >
                    Step type:
                  </label>
                  <input
                    id="newinputtype18"
                    style={{ display: "none" }}
                    value={type18}
                    onChange={(event) => {
                      setType18(event.target.value);
                    }}
                  ></input>

                  <h5 id="heading19" align="left" style={{ display: "none" }}>
                    Step 19:
                  </h5>
                  <label
                    id="labelsubstrate19"
                    for="newinputsubstrate19"
                    style={{ display: "none" }}
                  >
                    Substrate SMILES:
                  </label>
                  <textarea
                    id="newinputsubstrate19"
                    style={{ display: "none" }}
                    value={substrateIntermediate19}
                    onChange={(event) => {
                      setSubstrateIntermediate19(event.target.value);
                      setProductIntermediate18(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelproduct19"
                    for="newinputproduct19"
                    style={{ display: "none" }}
                  >
                    Product SMILES:
                  </label>
                  <textarea
                    id="newinputproduct19"
                    style={{ display: "none" }}
                    value={productIntermediate19}
                    onChange={(event) => {
                      setProductIntermediate19(event.target.value);
                      setSubstrateIntermediate20(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelname19"
                    for="newinputname19"
                    style={{ display: "none" }}
                  >
                    Product name:
                  </label>
                  <input
                    id="newinputname19"
                    value={name19}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setName19(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labelchebi19"
                    for="newinputchebi19"
                    style={{ display: "none" }}
                  >
                    Product ChEBI:
                  </label>
                  <input
                    id="newinputchebi19"
                    value={chebi19}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setChebi19(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labeltype19"
                    for="newinputtype19"
                    style={{ display: "none" }}
                  >
                    Step type:
                  </label>
                  <input
                    id="newinputtype19"
                    style={{ display: "none" }}
                    value={type19}
                    onChange={(event) => {
                      setType19(event.target.value);
                    }}
                  ></input>

                  <h5 id="heading20" align="left" style={{ display: "none" }}>
                    Step 20:
                  </h5>
                  <label
                    id="labelsubstrate20"
                    for="newinputsubstrate20"
                    style={{ display: "none" }}
                  >
                    Substrate SMILES:
                  </label>
                  <textarea
                    id="newinputsubstrate20"
                    style={{ display: "none" }}
                    value={substrateIntermediate20}
                    onChange={(event) => {
                      setSubstrateIntermediate20(event.target.value);
                      setProductIntermediate19(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelproduct20"
                    for="newinputproduct20"
                    style={{ display: "none" }}
                  >
                    Product SMILES:
                  </label>
                  <textarea
                    id="newinputproduct20"
                    style={{ display: "none" }}
                    value={productIntermediate20}
                    onChange={(event) => {
                      setProductIntermediate20(event.target.value);
                      setSubstrateIntermediate21(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelname20"
                    for="newinputname20"
                    style={{ display: "none" }}
                  >
                    Product name:
                  </label>
                  <input
                    id="newinputname20"
                    value={name20}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setName20(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labelchebi20"
                    for="newinputchebi20"
                    style={{ display: "none" }}
                  >
                    Product ChEBI:
                  </label>
                  <input
                    id="newinputchebi20"
                    value={chebi20}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setChebi20(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labeltype20"
                    for="newinputtype20"
                    style={{ display: "none" }}
                  >
                    Step type:
                  </label>
                  <input
                    id="newinputtype20"
                    style={{ display: "none" }}
                    value={type20}
                    onChange={(event) => {
                      setType20(event.target.value);
                    }}
                  ></input>

                  <h5 id="heading21" align="left" style={{ display: "none" }}>
                    Step 21:
                  </h5>
                  <label
                    id="labelsubstrate21"
                    for="newinputsubstrate21"
                    style={{ display: "none" }}
                  >
                    Substrate SMILES:
                  </label>
                  <textarea
                    id="newinputsubstrate21"
                    style={{ display: "none" }}
                    value={substrateIntermediate21}
                    onChange={(event) => {
                      setSubstrateIntermediate21(event.target.value);
                      setProductIntermediate20(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelproduct21"
                    for="newinputproduct21"
                    style={{ display: "none" }}
                  >
                    Product SMILES:
                  </label>
                  <textarea
                    id="newinputproduct21"
                    style={{ display: "none" }}
                    value={productIntermediate21}
                    onChange={(event) => {
                      setProductIntermediate21(event.target.value);
                      setSubstrateIntermediate22(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelname21"
                    for="newinputname21"
                    style={{ display: "none" }}
                  >
                    Product name:
                  </label>
                  <input
                    id="newinputname21"
                    value={name21}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setName21(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labelchebi21"
                    for="newinputchebi21"
                    style={{ display: "none" }}
                  >
                    Product ChEBI:
                  </label>
                  <input
                    id="newinputchebi21"
                    value={chebi21}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setChebi21(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labeltype21"
                    for="newinputtype21"
                    style={{ display: "none" }}
                  >
                    Step type:
                  </label>
                  <input
                    id="newinputtype21"
                    style={{ display: "none" }}
                    value={type21}
                    onChange={(event) => {
                      setType21(event.target.value);
                    }}
                  ></input>

                  <h5 id="heading22" align="left" style={{ display: "none" }}>
                    Step 22:
                  </h5>
                  <label
                    id="labelsubstrate22"
                    for="newinputsubstrate22"
                    style={{ display: "none" }}
                  >
                    Substrate SMILES:
                  </label>
                  <textarea
                    id="newinputsubstrate22"
                    style={{ display: "none" }}
                    value={substrateIntermediate22}
                    onChange={(event) => {
                      setSubstrateIntermediate22(event.target.value);
                      setProductIntermediate21(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelproduct22"
                    for="newinputproduct22"
                    style={{ display: "none" }}
                  >
                    Product SMILES:
                  </label>
                  <textarea
                    id="newinputproduct22"
                    style={{ display: "none" }}
                    value={productIntermediate22}
                    onChange={(event) => {
                      setProductIntermediate22(event.target.value);
                      setSubstrateIntermediate23(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelname22"
                    for="newinputname22"
                    style={{ display: "none" }}
                  >
                    Product name:
                  </label>
                  <input
                    id="newinputname22"
                    value={name22}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setName22(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labelchebi22"
                    for="newinputchebi22"
                    style={{ display: "none" }}
                  >
                    Product ChEBI:
                  </label>
                  <input
                    id="newinputchebi22"
                    value={chebi22}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setChebi22(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labeltype22"
                    for="newinputtype22"
                    style={{ display: "none" }}
                  >
                    Step type:
                  </label>
                  <input
                    id="newinputtype22"
                    style={{ display: "none" }}
                    value={type22}
                    onChange={(event) => {
                      setType22(event.target.value);
                    }}
                  ></input>

                  <h5 id="heading23" align="left" style={{ display: "none" }}>
                    Step 23:
                  </h5>
                  <label
                    id="labelsubstrate23"
                    for="newinputsubstrate23"
                    style={{ display: "none" }}
                  >
                    Substrate SMILES:
                  </label>
                  <textarea
                    id="newinputsubstrate23"
                    style={{ display: "none" }}
                    value={substrateIntermediate23}
                    onChange={(event) => {
                      setSubstrateIntermediate23(event.target.value);
                      setProductIntermediate22(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelproduct23"
                    for="newinputproduct23"
                    style={{ display: "none" }}
                  >
                    Product SMILES:
                  </label>
                  <textarea
                    id="newinputproduct23"
                    style={{ display: "none" }}
                    value={productIntermediate23}
                    onChange={(event) => {
                      setProductIntermediate23(event.target.value);
                      setSubstrateIntermediate24(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelname23"
                    for="newinputname23"
                    style={{ display: "none" }}
                  >
                    Product name:
                  </label>
                  <input
                    id="newinputname23"
                    value={name23}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setName23(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labelchebi23"
                    for="newinputchebi23"
                    style={{ display: "none" }}
                  >
                    Product ChEBI:
                  </label>
                  <input
                    id="newinputchebi23"
                    value={chebi23}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setChebi23(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labeltype23"
                    for="newinputtype23"
                    style={{ display: "none" }}
                  >
                    Step type:
                  </label>
                  <input
                    id="newinputtype23"
                    style={{ display: "none" }}
                    value={type23}
                    onChange={(event) => {
                      setType23(event.target.value);
                    }}
                  ></input>

                  <h5 id="heading24" align="left" style={{ display: "none" }}>
                    Step 24:
                  </h5>
                  <label
                    id="labelsubstrate24"
                    for="newinputsubstrate24"
                    style={{ display: "none" }}
                  >
                    Substrate SMILES:
                  </label>
                  <textarea
                    id="newinputsubstrate24"
                    style={{ display: "none" }}
                    value={substrateIntermediate24}
                    onChange={(event) => {
                      setSubstrateIntermediate24(event.target.value);
                      setProductIntermediate23(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelproduct24"
                    for="newinputproduct24"
                    style={{ display: "none" }}
                  >
                    Product SMILES:
                  </label>
                  <textarea
                    id="newinputproduct24"
                    style={{ display: "none" }}
                    value={productIntermediate24}
                    onChange={(event) => {
                      setProductIntermediate24(event.target.value);
                      setSubstrateIntermediate25(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelname24"
                    for="newinputname24"
                    style={{ display: "none" }}
                  >
                    Product name:
                  </label>
                  <input
                    id="newinputname24"
                    value={name24}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setName24(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labelchebi24"
                    for="newinputchebi24"
                    style={{ display: "none" }}
                  >
                    Product ChEBI:
                  </label>
                  <input
                    id="newinputchebi24"
                    value={chebi24}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setChebi24(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labeltype24"
                    for="newinputtype24"
                    style={{ display: "none" }}
                  >
                    Step type:
                  </label>
                  <input
                    id="newinputtype24"
                    style={{ display: "none" }}
                    value={type24}
                    onChange={(event) => {
                      setType24(event.target.value);
                    }}
                  ></input>

                  <h5 id="heading25" align="left" style={{ display: "none" }}>
                    Step 25:
                  </h5>
                  <label
                    id="labelsubstrate25"
                    for="newinputsubstrate25"
                    style={{ display: "none" }}
                  >
                    Substrate SMILES:
                  </label>
                  <textarea
                    id="newinputsubstrate25"
                    style={{ display: "none" }}
                    value={substrateIntermediate25}
                    onChange={(event) => {
                      setSubstrateIntermediate25(event.target.value);
                      setProductIntermediate24(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelproduct25"
                    for="newinputproduct25"
                    style={{ display: "none" }}
                  >
                    Product SMILES:
                  </label>
                  <textarea
                    id="newinputproduct25"
                    style={{ display: "none" }}
                    value={productIntermediate25}
                    onChange={(event) => {
                      setProductIntermediate25(event.target.value);
                      setSubstrateIntermediate26(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelname25"
                    for="newinputname25"
                    style={{ display: "none" }}
                  >
                    Product name:
                  </label>
                  <input
                    id="newinputname25"
                    value={name25}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setName25(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labelchebi25"
                    for="newinputchebi25"
                    style={{ display: "none" }}
                  >
                    Product ChEBI:
                  </label>
                  <input
                    id="newinputchebi25"
                    value={chebi25}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setChebi25(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labeltype25"
                    for="newinputtype25"
                    style={{ display: "none" }}
                  >
                    Step type:
                  </label>
                  <input
                    id="newinputtype25"
                    style={{ display: "none" }}
                    value={type25}
                    onChange={(event) => {
                      setType25(event.target.value);
                    }}
                  ></input>

                  <h5 id="heading26" align="left" style={{ display: "none" }}>
                    Step 26:
                  </h5>
                  <label
                    id="labelsubstrate26"
                    for="newinputsubstrate26"
                    style={{ display: "none" }}
                  >
                    Substrate SMILES:
                  </label>
                  <textarea
                    id="newinputsubstrate26"
                    style={{ display: "none" }}
                    value={substrateIntermediate26}
                    onChange={(event) => {
                      setSubstrateIntermediate26(event.target.value);
                      setProductIntermediate25(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelproduct26"
                    for="newinputproduct26"
                    style={{ display: "none" }}
                  >
                    Product SMILES:
                  </label>
                  <textarea
                    id="newinputproduct26"
                    style={{ display: "none" }}
                    value={productIntermediate26}
                    onChange={(event) => {
                      setProductIntermediate26(event.target.value);
                      setSubstrateIntermediate27(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelname26"
                    for="newinputname26"
                    style={{ display: "none" }}
                  >
                    Product name:
                  </label>
                  <input
                    id="newinputname26"
                    value={name26}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setName26(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labelchebi26"
                    for="newinputchebi26"
                    style={{ display: "none" }}
                  >
                    Product ChEBI:
                  </label>
                  <input
                    id="newinputchebi26"
                    value={chebi26}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setChebi26(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labeltype26"
                    for="newinputtype26"
                    style={{ display: "none" }}
                  >
                    Step type:
                  </label>
                  <input
                    id="newinputtype26"
                    style={{ display: "none" }}
                    value={type26}
                    onChange={(event) => {
                      setType26(event.target.value);
                    }}
                  ></input>

                  <h5 id="heading27" align="left" style={{ display: "none" }}>
                    Step 27:
                  </h5>
                  <label
                    id="labelsubstrate27"
                    for="newinputsubstrate27"
                    style={{ display: "none" }}
                  >
                    Substrate SMILES:
                  </label>
                  <textarea
                    id="newinputsubstrate27"
                    style={{ display: "none" }}
                    value={substrateIntermediate27}
                    onChange={(event) => {
                      setSubstrateIntermediate27(event.target.value);
                      setProductIntermediate26(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelproduct27"
                    for="newinputproduct27"
                    style={{ display: "none" }}
                  >
                    Product SMILES:
                  </label>
                  <textarea
                    id="newinputproduct27"
                    style={{ display: "none" }}
                    value={productIntermediate27}
                    onChange={(event) => {
                      setProductIntermediate27(event.target.value);
                      setSubstrateIntermediate28(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelname27"
                    for="newinputname27"
                    style={{ display: "none" }}
                  >
                    Product name:
                  </label>
                  <input
                    id="newinputname27"
                    value={name27}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setName27(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labelchebi27"
                    for="newinputchebi27"
                    style={{ display: "none" }}
                  >
                    Product ChEBI:
                  </label>
                  <input
                    id="newinputchebi27"
                    value={chebi27}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setChebi27(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labeltype27"
                    for="newinputtype27"
                    style={{ display: "none" }}
                  >
                    Step type:
                  </label>
                  <input
                    id="newinputtype27"
                    style={{ display: "none" }}
                    value={type27}
                    onChange={(event) => {
                      setType27(event.target.value);
                    }}
                  ></input>

                  <h5 id="heading28" align="left" style={{ display: "none" }}>
                    Step 28:
                  </h5>
                  <label
                    id="labelsubstrate28"
                    for="newinputsubstrate28"
                    style={{ display: "none" }}
                  >
                    Substrate SMILES:
                  </label>
                  <textarea
                    id="newinputsubstrate28"
                    style={{ display: "none" }}
                    value={substrateIntermediate28}
                    onChange={(event) => {
                      setSubstrateIntermediate28(event.target.value);
                      setProductIntermediate27(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelproduct28"
                    for="newinputproduct28"
                    style={{ display: "none" }}
                  >
                    Product SMILES:
                  </label>
                  <textarea
                    id="newinputproduct28"
                    style={{ display: "none" }}
                    value={productIntermediate28}
                    onChange={(event) => {
                      setProductIntermediate28(event.target.value);
                      setSubstrateIntermediate29(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelname28"
                    for="newinputname28"
                    style={{ display: "none" }}
                  >
                    Product name:
                  </label>
                  <input
                    id="newinputname28"
                    value={name28}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setName28(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labelchebi28"
                    for="newinputchebi28"
                    style={{ display: "none" }}
                  >
                    Product ChEBI:
                  </label>
                  <input
                    id="newinputchebi28"
                    value={chebi28}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setChebi28(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labeltype28"
                    for="newinputtype28"
                    style={{ display: "none" }}
                  >
                    Step type:
                  </label>
                  <input
                    id="newinputtype28"
                    style={{ display: "none" }}
                    value={type28}
                    onChange={(event) => {
                      setType28(event.target.value);
                    }}
                  ></input>

                  <h5 id="heading29" align="left" style={{ display: "none" }}>
                    Step 29:
                  </h5>
                  <label
                    id="labelsubstrate29"
                    for="newinputsubstrate29"
                    style={{ display: "none" }}
                  >
                    Substrate SMILES:
                  </label>
                  <textarea
                    id="newinputsubstrate29"
                    style={{ display: "none" }}
                    value={substrateIntermediate29}
                    onChange={(event) => {
                      setSubstrateIntermediate29(event.target.value);
                      setProductIntermediate28(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelproduct29"
                    for="newinputproduct29"
                    style={{ display: "none" }}
                  >
                    Product SMILES:
                  </label>
                  <textarea
                    id="newinputproduct29"
                    style={{ display: "none" }}
                    value={productIntermediate29}
                    onChange={(event) => {
                      setProductIntermediate29(event.target.value);
                      setSubstrateIntermediate30(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelname29"
                    for="newinputname29"
                    style={{ display: "none" }}
                  >
                    Product name:
                  </label>
                  <input
                    id="newinputname29"
                    value={name29}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setName29(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labelchebi29"
                    for="newinputchebi29"
                    style={{ display: "none" }}
                  >
                    Product ChEBI:
                  </label>
                  <input
                    id="newinputchebi29"
                    value={chebi29}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setChebi29(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labeltype29"
                    for="newinputtype29"
                    style={{ display: "none" }}
                  >
                    Step type:
                  </label>
                  <input
                    id="newinputtype29"
                    style={{ display: "none" }}
                    value={type29}
                    onChange={(event) => {
                      setType29(event.target.value);
                    }}
                  ></input>

                  <h5 id="heading30" align="left" style={{ display: "none" }}>
                    Step 30:
                  </h5>
                  <label
                    id="labelsubstrate30"
                    for="newinputsubstrate30"
                    style={{ display: "none" }}
                  >
                    Substrate SMILES:
                  </label>
                  <textarea
                    id="newinputsubstrate30"
                    style={{ display: "none" }}
                    value={substrateIntermediate30}
                    onChange={(event) => {
                      setSubstrateIntermediate30(event.target.value);
                      setProductIntermediate29(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelproduct30"
                    for="newinputproduct30"
                    style={{ display: "none" }}
                  >
                    Product SMILES:
                  </label>
                  <textarea
                    id="newinputproduct30"
                    style={{ display: "none" }}
                    value={productIntermediate30}
                    onChange={(event) => {
                      setProductIntermediate30(event.target.value);
                      setSubstrateIntermediate31(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelname30"
                    for="newinputname30"
                    style={{ display: "none" }}
                  >
                    Product name:
                  </label>
                  <input
                    id="newinputname30"
                    value={name30}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setName30(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labelchebi30"
                    for="newinputchebi30"
                    style={{ display: "none" }}
                  >
                    Product ChEBI:
                  </label>
                  <input
                    id="newinputchebi30"
                    value={chebi30}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setChebi30(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labeltype30"
                    for="newinputtype30"
                    style={{ display: "none" }}
                  >
                    Step type:
                  </label>
                  <input
                    id="newinputtype30"
                    style={{ display: "none" }}
                    value={type30}
                    onChange={(event) => {
                      setType30(event.target.value);
                    }}
                  ></input>

                  <h5 id="heading31" align="left" style={{ display: "none" }}>
                    Step 31:
                  </h5>
                  <label
                    id="labelsubstrate31"
                    for="newinputsubstrate31"
                    style={{ display: "none" }}
                  >
                    Substrate SMILES:
                  </label>
                  <textarea
                    id="newinputsubstrate31"
                    style={{ display: "none" }}
                    value={substrateIntermediate31}
                    onChange={(event) => {
                      setSubstrateIntermediate31(event.target.value);
                      setProductIntermediate30(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelproduct31"
                    for="newinputproduct31"
                    style={{ display: "none" }}
                  >
                    Product SMILES:
                  </label>
                  <textarea
                    id="newinputproduct31"
                    style={{ display: "none" }}
                    value={productIntermediate31}
                    onChange={(event) => {
                      setProductIntermediate31(event.target.value);
                      setSubstrateIntermediate32(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelname31"
                    for="newinputname31"
                    style={{ display: "none" }}
                  >
                    Product name:
                  </label>
                  <input
                    id="newinputname31"
                    value={name31}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setName31(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labelchebi31"
                    for="newinputchebi31"
                    style={{ display: "none" }}
                  >
                    Product ChEBI:
                  </label>
                  <input
                    id="newinputchebi31"
                    value={chebi31}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setChebi31(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labeltype31"
                    for="newinputtype31"
                    style={{ display: "none" }}
                  >
                    Step type:
                  </label>
                  <input
                    id="newinputtype31"
                    style={{ display: "none" }}
                    value={type31}
                    onChange={(event) => {
                      setType31(event.target.value);
                    }}
                  ></input>

                  <h5 id="heading32" align="left" style={{ display: "none" }}>
                    Step 32:
                  </h5>
                  <label
                    id="labelsubstrate32"
                    for="newinputsubstrate32"
                    style={{ display: "none" }}
                  >
                    Substrate SMILES:
                  </label>
                  <textarea
                    id="newinputsubstrate32"
                    style={{ display: "none" }}
                    value={substrateIntermediate32}
                    onChange={(event) => {
                      setSubstrateIntermediate32(event.target.value);
                      setProductIntermediate31(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelproduct32"
                    for="newinputproduct32"
                    style={{ display: "none" }}
                  >
                    Product SMILES:
                  </label>
                  <textarea
                    id="newinputproduct32"
                    style={{ display: "none" }}
                    value={productIntermediate32}
                    onChange={(event) => {
                      setProductIntermediate32(event.target.value);
                      setSubstrateIntermediate33(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelname32"
                    for="newinputname32"
                    style={{ display: "none" }}
                  >
                    Product name:
                  </label>
                  <input
                    id="newinputname32"
                    value={name32}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setName32(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labelchebi32"
                    for="newinputchebi32"
                    style={{ display: "none" }}
                  >
                    Product ChEBI:
                  </label>
                  <input
                    id="newinputchebi32"
                    value={chebi32}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setChebi32(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labeltype32"
                    for="newinputtype32"
                    style={{ display: "none" }}
                  >
                    Step type:
                  </label>
                  <input
                    id="newinputtype32"
                    style={{ display: "none" }}
                    value={type32}
                    onChange={(event) => {
                      setType32(event.target.value);
                    }}
                  ></input>

                  <h5 id="heading33" align="left" style={{ display: "none" }}>
                    Step 33:
                  </h5>
                  <label
                    id="labelsubstrate33"
                    for="newinputsubstrate33"
                    style={{ display: "none" }}
                  >
                    Substrate SMILES:
                  </label>
                  <textarea
                    id="newinputsubstrate33"
                    style={{ display: "none" }}
                    value={substrateIntermediate33}
                    onChange={(event) => {
                      setSubstrateIntermediate33(event.target.value);
                      setProductIntermediate32(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelproduct33"
                    for="newinputproduct33"
                    style={{ display: "none" }}
                  >
                    Product SMILES:
                  </label>
                  <textarea
                    id="newinputproduct33"
                    style={{ display: "none" }}
                    value={productIntermediate33}
                    onChange={(event) => {
                      setProductIntermediate33(event.target.value);
                      setSubstrateIntermediate34(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelname33"
                    for="newinputname33"
                    style={{ display: "none" }}
                  >
                    Product name:
                  </label>
                  <input
                    id="newinputname33"
                    value={name33}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setName33(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labelchebi33"
                    for="newinputchebi33"
                    style={{ display: "none" }}
                  >
                    Product ChEBI:
                  </label>
                  <input
                    id="newinputchebi33"
                    value={chebi33}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setChebi33(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labeltype33"
                    for="newinputtype33"
                    style={{ display: "none" }}
                  >
                    Step type:
                  </label>
                  <input
                    id="newinputtype33"
                    style={{ display: "none" }}
                    value={type33}
                    onChange={(event) => {
                      setType33(event.target.value);
                    }}
                  ></input>

                  <h5 id="heading34" align="left" style={{ display: "none" }}>
                    Step 34:
                  </h5>
                  <label
                    id="labelsubstrate34"
                    for="newinputsubstrate34"
                    style={{ display: "none" }}
                  >
                    Substrate SMILES:
                  </label>
                  <textarea
                    id="newinputsubstrate34"
                    style={{ display: "none" }}
                    value={substrateIntermediate34}
                    onChange={(event) => {
                      setSubstrateIntermediate34(event.target.value);
                      setProductIntermediate33(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelproduct34"
                    for="newinputproduct34"
                    style={{ display: "none" }}
                  >
                    Product SMILES:
                  </label>
                  <textarea
                    id="newinputproduct34"
                    style={{ display: "none" }}
                    value={productIntermediate34}
                    onChange={(event) => {
                      setProductIntermediate34(event.target.value);
                      setSubstrateIntermediate35(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelname34"
                    for="newinputname34"
                    style={{ display: "none" }}
                  >
                    Product name:
                  </label>
                  <input
                    id="newinputname34"
                    value={name34}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setName34(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labelchebi34"
                    for="newinputchebi34"
                    style={{ display: "none" }}
                  >
                    Product ChEBI:
                  </label>
                  <input
                    id="newinputchebi34"
                    value={chebi34}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setChebi34(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labeltype34"
                    for="newinputtype34"
                    style={{ display: "none" }}
                  >
                    Step type:
                  </label>
                  <input
                    id="newinputtype34"
                    style={{ display: "none" }}
                    value={type34}
                    onChange={(event) => {
                      setType34(event.target.value);
                    }}
                  ></input>

                  <h5 id="heading35" align="left" style={{ display: "none" }}>
                    Step 35:
                  </h5>
                  <label
                    id="labelsubstrate35"
                    for="newinputsubstrate35"
                    style={{ display: "none" }}
                  >
                    Substrate SMILES:
                  </label>
                  <textarea
                    id="newinputsubstrate35"
                    style={{ display: "none" }}
                    value={substrateIntermediate35}
                    onChange={(event) => {
                      setSubstrateIntermediate35(event.target.value);
                      setProductIntermediate34(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelproduct35"
                    for="newinputproduct35"
                    style={{ display: "none" }}
                  >
                    Product SMILES:
                  </label>
                  <textarea
                    id="newinputproduct35"
                    style={{ display: "none" }}
                    value={productIntermediate35}
                    onChange={(event) => {
                      setProductIntermediate35(event.target.value);
                      setSubstrateIntermediate36(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelname35"
                    for="newinputname35"
                    style={{ display: "none" }}
                  >
                    Product name:
                  </label>
                  <input
                    id="newinputname35"
                    value={name35}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setName35(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labelchebi35"
                    for="newinputchebi35"
                    style={{ display: "none" }}
                  >
                    Product ChEBI:
                  </label>
                  <input
                    id="newinputchebi35"
                    value={chebi35}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setChebi35(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labeltype35"
                    for="newinputtype35"
                    style={{ display: "none" }}
                  >
                    Step type:
                  </label>
                  <input
                    id="newinputtype35"
                    style={{ display: "none" }}
                    value={type35}
                    onChange={(event) => {
                      setType35(event.target.value);
                    }}
                  ></input>

                  <h5 id="heading36" align="left" style={{ display: "none" }}>
                    Step 36:
                  </h5>
                  <label
                    id="labelsubstrate36"
                    for="newinputsubstrate36"
                    style={{ display: "none" }}
                  >
                    Substrate SMILES:
                  </label>
                  <textarea
                    id="newinputsubstrate36"
                    style={{ display: "none" }}
                    value={substrateIntermediate36}
                    onChange={(event) => {
                      setSubstrateIntermediate36(event.target.value);
                      setProductIntermediate35(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelproduct36"
                    for="newinputproduct36"
                    style={{ display: "none" }}
                  >
                    Product SMILES:
                  </label>
                  <textarea
                    id="newinputproduct36"
                    style={{ display: "none" }}
                    value={productIntermediate36}
                    onChange={(event) => {
                      setProductIntermediate36(event.target.value);
                      setSubstrateIntermediate37(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelname36"
                    for="newinputname36"
                    style={{ display: "none" }}
                  >
                    Product name:
                  </label>
                  <input
                    id="newinputname36"
                    value={name36}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setName36(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labelchebi36"
                    for="newinputchebi36"
                    style={{ display: "none" }}
                  >
                    Product ChEBI:
                  </label>
                  <input
                    id="newinputchebi36"
                    value={chebi36}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setChebi36(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labeltype36"
                    for="newinputtype36"
                    style={{ display: "none" }}
                  >
                    Step type:
                  </label>
                  <input
                    id="newinputtype36"
                    style={{ display: "none" }}
                    value={type36}
                    onChange={(event) => {
                      setType36(event.target.value);
                    }}
                  ></input>

                  <h5 id="heading37" align="left" style={{ display: "none" }}>
                    Step 37:
                  </h5>
                  <label
                    id="labelsubstrate37"
                    for="newinputsubstrate37"
                    style={{ display: "none" }}
                  >
                    Substrate SMILES:
                  </label>
                  <textarea
                    id="newinputsubstrate37"
                    style={{ display: "none" }}
                    value={substrateIntermediate37}
                    onChange={(event) => {
                      setSubstrateIntermediate37(event.target.value);
                      setProductIntermediate36(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelproduct37"
                    for="newinputproduct37"
                    style={{ display: "none" }}
                  >
                    Product SMILES:
                  </label>
                  <textarea
                    id="newinputproduct37"
                    style={{ display: "none" }}
                    value={productIntermediate37}
                    onChange={(event) => {
                      setProductIntermediate37(event.target.value);
                      setSubstrateIntermediate38(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelname37"
                    for="newinputname37"
                    style={{ display: "none" }}
                  >
                    Product name:
                  </label>
                  <input
                    id="newinputname37"
                    value={name37}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setName37(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labelchebi37"
                    for="newinputchebi37"
                    style={{ display: "none" }}
                  >
                    Product ChEBI:
                  </label>
                  <input
                    id="newinputchebi37"
                    value={chebi37}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setChebi37(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labeltype37"
                    for="newinputtype37"
                    style={{ display: "none" }}
                  >
                    Step type:
                  </label>
                  <input
                    id="newinputtype37"
                    style={{ display: "none" }}
                    value={type37}
                    onChange={(event) => {
                      setType37(event.target.value);
                    }}
                  ></input>

                  <h5 id="heading38" align="left" style={{ display: "none" }}>
                    Step 38:
                  </h5>
                  <label
                    id="labelsubstrate38"
                    for="newinputsubstrate38"
                    style={{ display: "none" }}
                  >
                    Substrate SMILES:
                  </label>
                  <textarea
                    id="newinputsubstrate38"
                    style={{ display: "none" }}
                    value={substrateIntermediate38}
                    onChange={(event) => {
                      setSubstrateIntermediate38(event.target.value);
                      setProductIntermediate37(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelproduct38"
                    for="newinputproduct38"
                    style={{ display: "none" }}
                  >
                    Product SMILES:
                  </label>
                  <textarea
                    id="newinputproduct38"
                    style={{ display: "none" }}
                    value={productIntermediate38}
                    onChange={(event) => {
                      setProductIntermediate38(event.target.value);
                      setSubstrateIntermediate39(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelname38"
                    for="newinputname38"
                    style={{ display: "none" }}
                  >
                    Product name:
                  </label>
                  <input
                    id="newinputname38"
                    value={name38}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setName38(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labelchebi38"
                    for="newinputchebi38"
                    style={{ display: "none" }}
                  >
                    Product ChEBI:
                  </label>
                  <input
                    id="newinputchebi38"
                    value={chebi38}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setChebi38(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labeltype38"
                    for="newinputtype38"
                    style={{ display: "none" }}
                  >
                    Step type:
                  </label>
                  <input
                    id="newinputtype38"
                    style={{ display: "none" }}
                    value={type38}
                    onChange={(event) => {
                      setType38(event.target.value);
                    }}
                  ></input>

                  <h5 id="heading39" align="left" style={{ display: "none" }}>
                    Step 39:
                  </h5>
                  <label
                    id="labelsubstrate39"
                    for="newinputsubstrate39"
                    style={{ display: "none" }}
                  >
                    Substrate SMILES:
                  </label>
                  <textarea
                    id="newinputsubstrate39"
                    style={{ display: "none" }}
                    value={substrateIntermediate39}
                    onChange={(event) => {
                      setSubstrateIntermediate39(event.target.value);
                      setProductIntermediate38(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelproduct39"
                    for="newinputproduct39"
                    style={{ display: "none" }}
                  >
                    Product SMILES:
                  </label>
                  <textarea
                    id="newinputproduct39"
                    style={{ display: "none" }}
                    value={productIntermediate39}
                    onChange={(event) => {
                      setProductIntermediate39(event.target.value);
                      setSubstrateIntermediate40(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelname39"
                    for="newinputname39"
                    style={{ display: "none" }}
                  >
                    Product name:
                  </label>
                  <input
                    id="newinputname39"
                    value={name39}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setName39(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labelchebi39"
                    for="newinputchebi39"
                    style={{ display: "none" }}
                  >
                    Product ChEBI:
                  </label>
                  <input
                    id="newinputchebi39"
                    value={chebi39}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setChebi39(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labeltype39"
                    for="newinputtype39"
                    style={{ display: "none" }}
                  >
                    Step type:
                  </label>
                  <input
                    id="newinputtype39"
                    style={{ display: "none" }}
                    value={type39}
                    onChange={(event) => {
                      setType39(event.target.value);
                    }}
                  ></input>

                  <h5 id="heading40" align="left" style={{ display: "none" }}>
                    Step 40:
                  </h5>
                  <label
                    id="labelsubstrate40"
                    for="newinputsubstrate40"
                    style={{ display: "none" }}
                  >
                    Substrate SMILES:
                  </label>
                  <textarea
                    id="newinputsubstrate40"
                    style={{ display: "none" }}
                    value={substrateIntermediate40}
                    onChange={(event) => {
                      setSubstrateIntermediate40(event.target.value);
                      setProductIntermediate39(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelproduct40"
                    for="newinputproduct40"
                    style={{ display: "none" }}
                  >
                    Product SMILES:
                  </label>
                  <textarea
                    id="newinputproduct40"
                    style={{ display: "none" }}
                    value={productIntermediate40}
                    onChange={(event) => {
                      setProductIntermediate40(event.target.value);
                      setSubstrateIntermediate41(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelname40"
                    for="newinputname40"
                    style={{ display: "none" }}
                  >
                    Product name:
                  </label>
                  <input
                    id="newinputname40"
                    value={name40}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setName40(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labelchebi40"
                    for="newinputchebi40"
                    style={{ display: "none" }}
                  >
                    Product ChEBI:
                  </label>
                  <input
                    id="newinputchebi40"
                    value={chebi40}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setChebi40(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labeltype40"
                    for="newinputtype40"
                    style={{ display: "none" }}
                  >
                    Step type:
                  </label>
                  <input
                    id="newinputtype40"
                    style={{ display: "none" }}
                    value={type40}
                    onChange={(event) => {
                      setType40(event.target.value);
                    }}
                  ></input>

                  <h5 id="heading41" align="left" style={{ display: "none" }}>
                    Step 41:
                  </h5>
                  <label
                    id="labelsubstrate41"
                    for="newinputsubstrate41"
                    style={{ display: "none" }}
                  >
                    Substrate SMILES:
                  </label>
                  <textarea
                    id="newinputsubstrate41"
                    style={{ display: "none" }}
                    value={substrateIntermediate41}
                    onChange={(event) => {
                      setSubstrateIntermediate41(event.target.value);
                      setProductIntermediate40(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelproduct41"
                    for="newinputproduct41"
                    style={{ display: "none" }}
                  >
                    Product SMILES:
                  </label>
                  <textarea
                    id="newinputproduct41"
                    style={{ display: "none" }}
                    value={productIntermediate41}
                    onChange={(event) => {
                      setProductIntermediate41(event.target.value);
                      setSubstrateIntermediate42(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelname41"
                    for="newinputname41"
                    style={{ display: "none" }}
                  >
                    Product name:
                  </label>
                  <input
                    id="newinputname41"
                    value={name41}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setName41(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labelchebi41"
                    for="newinputchebi41"
                    style={{ display: "none" }}
                  >
                    Product ChEBI:
                  </label>
                  <input
                    id="newinputchebi41"
                    value={chebi41}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setChebi41(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labeltype41"
                    for="newinputtype41"
                    style={{ display: "none" }}
                  >
                    Step type:
                  </label>
                  <input
                    id="newinputtype41"
                    style={{ display: "none" }}
                    value={type41}
                    onChange={(event) => {
                      setType41(event.target.value);
                    }}
                  ></input>

                  <h5 id="heading42" align="left" style={{ display: "none" }}>
                    Step 42:
                  </h5>
                  <label
                    id="labelsubstrate42"
                    for="newinputsubstrate42"
                    style={{ display: "none" }}
                  >
                    Substrate SMILES:
                  </label>
                  <textarea
                    id="newinputsubstrate42"
                    style={{ display: "none" }}
                    value={substrateIntermediate42}
                    onChange={(event) => {
                      setSubstrateIntermediate42(event.target.value);
                      setProductIntermediate41(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelproduct42"
                    for="newinputproduct42"
                    style={{ display: "none" }}
                  >
                    Product SMILES:
                  </label>
                  <textarea
                    id="newinputproduct42"
                    style={{ display: "none" }}
                    value={productIntermediate42}
                    onChange={(event) => {
                      setProductIntermediate42(event.target.value);
                      setSubstrateIntermediate43(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelname42"
                    for="newinputname42"
                    style={{ display: "none" }}
                  >
                    Product name:
                  </label>
                  <input
                    id="newinputname42"
                    value={name42}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setName42(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labelchebi42"
                    for="newinputchebi42"
                    style={{ display: "none" }}
                  >
                    Product ChEBI:
                  </label>
                  <input
                    id="newinputchebi42"
                    value={chebi42}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setChebi42(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labeltype42"
                    for="newinputtype42"
                    style={{ display: "none" }}
                  >
                    Step type:
                  </label>
                  <input
                    id="newinputtype42"
                    style={{ display: "none" }}
                    value={type42}
                    onChange={(event) => {
                      setType42(event.target.value);
                    }}
                  ></input>

                  <h5 id="heading43" align="left" style={{ display: "none" }}>
                    Step 43:
                  </h5>
                  <label
                    id="labelsubstrate43"
                    for="newinputsubstrate43"
                    style={{ display: "none" }}
                  >
                    Substrate SMILES:
                  </label>
                  <textarea
                    id="newinputsubstrate43"
                    style={{ display: "none" }}
                    value={substrateIntermediate43}
                    onChange={(event) => {
                      setSubstrateIntermediate43(event.target.value);
                      setProductIntermediate42(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelproduct43"
                    for="newinputproduct43"
                    style={{ display: "none" }}
                  >
                    Product SMILES:
                  </label>
                  <textarea
                    id="newinputproduct43"
                    style={{ display: "none" }}
                    value={productIntermediate43}
                    onChange={(event) => {
                      setProductIntermediate43(event.target.value);
                      setSubstrateIntermediate44(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelname43"
                    for="newinputname43"
                    style={{ display: "none" }}
                  >
                    Product name:
                  </label>
                  <input
                    id="newinputname43"
                    value={name43}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setName43(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labelchebi43"
                    for="newinputchebi43"
                    style={{ display: "none" }}
                  >
                    Product ChEBI:
                  </label>
                  <input
                    id="newinputchebi43"
                    value={chebi43}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setChebi43(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labeltype43"
                    for="newinputtype43"
                    style={{ display: "none" }}
                  >
                    Step type:
                  </label>
                  <input
                    id="newinputtype43"
                    style={{ display: "none" }}
                    value={type43}
                    onChange={(event) => {
                      setType43(event.target.value);
                    }}
                  ></input>

                  <h5 id="heading44" align="left" style={{ display: "none" }}>
                    Step 44:
                  </h5>
                  <label
                    id="labelsubstrate44"
                    for="newinputsubstrate44"
                    style={{ display: "none" }}
                  >
                    Substrate SMILES:
                  </label>
                  <textarea
                    id="newinputsubstrate44"
                    style={{ display: "none" }}
                    value={substrateIntermediate44}
                    onChange={(event) => {
                      setSubstrateIntermediate44(event.target.value);
                      setProductIntermediate43(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelproduct44"
                    for="newinputproduct44"
                    style={{ display: "none" }}
                  >
                    Product SMILES:
                  </label>
                  <textarea
                    id="newinputproduct44"
                    style={{ display: "none" }}
                    value={productIntermediate44}
                    onChange={(event) => {
                      setProductIntermediate44(event.target.value);
                      setSubstrateIntermediate45(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelname44"
                    for="newinputname44"
                    style={{ display: "none" }}
                  >
                    Product name:
                  </label>
                  <input
                    id="newinputname44"
                    value={name44}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setName44(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labelchebi44"
                    for="newinputchebi44"
                    style={{ display: "none" }}
                  >
                    Product ChEBI:
                  </label>
                  <input
                    id="newinputchebi44"
                    value={chebi44}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setChebi44(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labeltype44"
                    for="newinputtype44"
                    style={{ display: "none" }}
                  >
                    Step type:
                  </label>
                  <input
                    id="newinputtype44"
                    style={{ display: "none" }}
                    value={type44}
                    onChange={(event) => {
                      setType44(event.target.value);
                    }}
                  ></input>

                  <h5 id="heading45" align="left" style={{ display: "none" }}>
                    Step 45:
                  </h5>
                  <label
                    id="labelsubstrate45"
                    for="newinputsubstrate45"
                    style={{ display: "none" }}
                  >
                    Substrate SMILES:
                  </label>
                  <textarea
                    id="newinputsubstrate45"
                    style={{ display: "none" }}
                    value={substrateIntermediate45}
                    onChange={(event) => {
                      setSubstrateIntermediate45(event.target.value);
                      setProductIntermediate44(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelproduct45"
                    for="newinputproduct45"
                    style={{ display: "none" }}
                  >
                    Product SMILES:
                  </label>
                  <textarea
                    id="newinputproduct45"
                    style={{ display: "none" }}
                    value={productIntermediate45}
                    onChange={(event) => {
                      setProductIntermediate45(event.target.value);
                      setSubstrateIntermediate46(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelname45"
                    for="newinputname45"
                    style={{ display: "none" }}
                  >
                    Product name:
                  </label>
                  <input
                    id="newinputname45"
                    value={name45}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setName45(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labelchebi45"
                    for="newinputchebi45"
                    style={{ display: "none" }}
                  >
                    Product ChEBI:
                  </label>
                  <input
                    id="newinputchebi45"
                    value={chebi45}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setChebi45(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labeltype45"
                    for="newinputtype45"
                    style={{ display: "none" }}
                  >
                    Step type:
                  </label>
                  <input
                    id="newinputtype45"
                    style={{ display: "none" }}
                    value={type45}
                    onChange={(event) => {
                      setType45(event.target.value);
                    }}
                  ></input>

                  <h5 id="heading46" align="left" style={{ display: "none" }}>
                    Step 46:
                  </h5>
                  <label
                    id="labelsubstrate46"
                    for="newinputsubstrate46"
                    style={{ display: "none" }}
                  >
                    Substrate SMILES:
                  </label>
                  <textarea
                    id="newinputsubstrate46"
                    style={{ display: "none" }}
                    value={substrateIntermediate46}
                    onChange={(event) => {
                      setSubstrateIntermediate46(event.target.value);
                      setProductIntermediate45(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelproduct46"
                    for="newinputproduct46"
                    style={{ display: "none" }}
                  >
                    Product SMILES:
                  </label>
                  <textarea
                    id="newinputproduct46"
                    style={{ display: "none" }}
                    value={productIntermediate46}
                    onChange={(event) => {
                      setProductIntermediate46(event.target.value);
                      setSubstrateIntermediate47(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelname46"
                    for="newinputname46"
                    style={{ display: "none" }}
                  >
                    Product name:
                  </label>
                  <input
                    id="newinputname46"
                    value={name46}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setName46(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labelchebi46"
                    for="newinputchebi46"
                    style={{ display: "none" }}
                  >
                    Product ChEBI:
                  </label>
                  <input
                    id="newinputchebi46"
                    value={chebi46}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setChebi46(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labeltype46"
                    for="newinputtype46"
                    style={{ display: "none" }}
                  >
                    Step type:
                  </label>
                  <input
                    id="newinputtype46"
                    style={{ display: "none" }}
                    value={type46}
                    onChange={(event) => {
                      setType46(event.target.value);
                    }}
                  ></input>

                  <h5 id="heading47" align="left" style={{ display: "none" }}>
                    Step 47:
                  </h5>
                  <label
                    id="labelsubstrate47"
                    for="newinputsubstrate47"
                    style={{ display: "none" }}
                  >
                    Substrate SMILES:
                  </label>
                  <textarea
                    id="newinputsubstrate47"
                    style={{ display: "none" }}
                    value={substrateIntermediate47}
                    onChange={(event) => {
                      setSubstrateIntermediate47(event.target.value);
                      setProductIntermediate46(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelproduct47"
                    for="newinputproduct47"
                    style={{ display: "none" }}
                  >
                    Product SMILES:
                  </label>
                  <textarea
                    id="newinputproduct47"
                    style={{ display: "none" }}
                    value={productIntermediate47}
                    onChange={(event) => {
                      setProductIntermediate47(event.target.value);
                      setSubstrateIntermediate48(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelname47"
                    for="newinputname47"
                    style={{ display: "none" }}
                  >
                    Product name:
                  </label>
                  <input
                    id="newinputname47"
                    value={name47}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setName47(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labelchebi47"
                    for="newinputchebi47"
                    style={{ display: "none" }}
                  >
                    Product ChEBI:
                  </label>
                  <input
                    id="newinputchebi47"
                    value={chebi47}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setChebi47(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labeltype47"
                    for="newinputtype47"
                    style={{ display: "none" }}
                  >
                    Step type:
                  </label>
                  <input
                    id="newinputtype47"
                    style={{ display: "none" }}
                    value={type47}
                    onChange={(event) => {
                      setType47(event.target.value);
                    }}
                  ></input>

                  <h5 id="heading48" align="left" style={{ display: "none" }}>
                    Step 48:
                  </h5>
                  <label
                    id="labelsubstrate48"
                    for="newinputsubstrate48"
                    style={{ display: "none" }}
                  >
                    Substrate SMILES:
                  </label>
                  <textarea
                    id="newinputsubstrate48"
                    style={{ display: "none" }}
                    value={substrateIntermediate48}
                    onChange={(event) => {
                      setSubstrateIntermediate48(event.target.value);
                      setProductIntermediate47(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelproduct48"
                    for="newinputproduct48"
                    style={{ display: "none" }}
                  >
                    Product SMILES:
                  </label>
                  <textarea
                    id="newinputproduct48"
                    style={{ display: "none" }}
                    value={productIntermediate48}
                    onChange={(event) => {
                      setProductIntermediate48(event.target.value);
                      setSubstrateIntermediate49(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelname48"
                    for="newinputname48"
                    style={{ display: "none" }}
                  >
                    Product name:
                  </label>
                  <input
                    id="newinputname48"
                    value={name48}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setName48(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labelchebi48"
                    for="newinputchebi48"
                    style={{ display: "none" }}
                  >
                    Product ChEBI:
                  </label>
                  <input
                    id="newinputchebi48"
                    value={chebi48}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setChebi48(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labeltype48"
                    for="newinputtype48"
                    style={{ display: "none" }}
                  >
                    Step type:
                  </label>
                  <input
                    id="newinputtype48"
                    style={{ display: "none" }}
                    value={type48}
                    onChange={(event) => {
                      setType48(event.target.value);
                    }}
                  ></input>

                  <h5 id="heading49" align="left" style={{ display: "none" }}>
                    Step 49:
                  </h5>
                  <label
                    id="labelsubstrate49"
                    for="newinputsubstrate49"
                    style={{ display: "none" }}
                  >
                    Substrate SMILES:
                  </label>
                  <textarea
                    id="newinputsubstrate49"
                    style={{ display: "none" }}
                    value={substrateIntermediate49}
                    onChange={(event) => {
                      setSubstrateIntermediate49(event.target.value);
                      setProductIntermediate48(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelproduct49"
                    for="newinputproduct49"
                    style={{ display: "none" }}
                  >
                    Product SMILES:
                  </label>
                  <textarea
                    id="newinputproduct49"
                    style={{ display: "none" }}
                    value={productIntermediate49}
                    onChange={(event) => {
                      setProductIntermediate49(event.target.value);
                      setSubstrateIntermediate50(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelname49"
                    for="newinputname49"
                    style={{ display: "none" }}
                  >
                    Product name:
                  </label>
                  <input
                    id="newinputname49"
                    value={name49}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setName49(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labelchebi49"
                    for="newinputchebi49"
                    style={{ display: "none" }}
                  >
                    Product ChEBI:
                  </label>
                  <input
                    id="newinputchebi49"
                    value={chebi49}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setChebi49(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labeltype49"
                    for="newinputtype49"
                    style={{ display: "none" }}
                  >
                    Step type:
                  </label>
                  <input
                    id="newinputtype49"
                    style={{ display: "none" }}
                    value={type49}
                    onChange={(event) => {
                      setType49(event.target.value);
                    }}
                  ></input>

                  <h5 id="heading50" align="left" style={{ display: "none" }}>
                    Step 50:
                  </h5>
                  <label
                    id="labelsubstrate50"
                    for="newinputsubstrate50"
                    style={{ display: "none" }}
                  >
                    Substrate SMILES:
                  </label>
                  <textarea
                    id="newinputsubstrate50"
                    style={{ display: "none" }}
                    value={substrateIntermediate50}
                    onChange={(event) => {
                      setSubstrateIntermediate50(event.target.value);
                      setProductIntermediate49(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelproduct50"
                    for="newinputproduct50"
                    style={{ display: "none" }}
                  >
                    Product SMILES:
                  </label>
                  <textarea
                    id="newinputproduct50"
                    style={{ display: "none" }}
                    value={productIntermediate50}
                    onChange={(event) => {
                      setProductIntermediate50(event.target.value);
                    }}
                  ></textarea>
                  <label
                    id="labelname50"
                    for="newinputname50"
                    style={{ display: "none" }}
                  >
                    Product name:
                  </label>
                  <input
                    id="newinputname50"
                    value={name50}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setName50(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labelchebi50"
                    for="newinputchebi50"
                    style={{ display: "none" }}
                  >
                    Product ChEBI:
                  </label>
                  <input
                    id="newinputchebi50"
                    value={chebi50}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setChebi50(event.target.value);
                    }}
                  ></input>
                  <label
                    id="labeltype50"
                    for="newinputtype50"
                    style={{ display: "none" }}
                  >
                    Step type:
                  </label>
                  <input
                    id="newinputtype50"
                    style={{ display: "none" }}
                    value={type50}
                    onChange={(event) => {
                      setType50(event.target.value);
                    }}
                  ></input>
                  <h5 align="left">Publication:</h5>
                  <label for="pubLink">Link:</label>
                  <textarea
                    id="pubLink"
                    value={link}
                    onChange={(event) => {
                      setLink(event.target.value);
                    }}
                  ></textarea>
                  <label for="pubDoi">DOI:</label>
                  <input
                    id="pubDoi"
                    value={doi}
                    onChange={(event) => {
                      setDoi(event.target.value);
                    }}
                  ></input>
                  <label for="pubPmid">PMID:</label>
                  <input
                    id="pubPmid"
                    value={pmid}
                    onChange={(event) => {
                      setPmid(event.target.value);
                    }}
                  ></input>
                  <h5 align="left">Evidence:</h5>
                  <label for="evidence">Mechanism evidence:</label>
                  <input
                    id="evidence"
                    value={evidence}
                    onChange={(event) => {
                      setEvidence(event.target.value);
                    }}
                  ></input>
                  <button
                    id="submitButton"
                    onClick={submit}
                    disabled={
                      !substrateIntermediate1 || !productIntermediate1 || !type1
                        ? true
                        : !substrateIntermediate2 ||
                          !productIntermediate2 ||
                          !type2
                        ? true
                        : document.getElementById("newinputsubstrate3")?.style
                            .display === "none" &&
                          document.getElementById("newinputproduct3")?.style
                            .display === "none" &&
                          document.getElementById("newinputtype3")?.style
                            .display === "none"
                        ? false
                        : !substrateIntermediate3 ||
                          !productIntermediate3 ||
                          !type3
                        ? true
                        : document.getElementById("newinputsubstrate4")?.style
                            .display === "none" &&
                          document.getElementById("newinputproduct4")?.style
                            .display === "none" &&
                          document.getElementById("newinputtype4")?.style
                            .display === "none"
                        ? false
                        : !substrateIntermediate4 ||
                          !productIntermediate4 ||
                          !type4
                        ? true
                        : document.getElementById("newinputsubstrate5")?.style
                            .display === "none" &&
                          document.getElementById("newinputproduct5")?.style
                            .display === "none" &&
                          document.getElementById("newinputtype5")?.style
                            .display === "none"
                        ? false
                        : !substrateIntermediate5 ||
                          !productIntermediate5 ||
                          !type5
                        ? true
                        : document.getElementById("newinputsubstrate6")?.style
                            .display === "none" &&
                          document.getElementById("newinputproduct6")?.style
                            .display === "none" &&
                          document.getElementById("newinputtype6")?.style
                            .display === "none"
                        ? false
                        : !substrateIntermediate6 ||
                          !productIntermediate6 ||
                          !type6
                        ? true
                        : document.getElementById("newinputsubstrate7")?.style
                            .display === "none" &&
                          document.getElementById("newinputproduct7")?.style
                            .display === "none" &&
                          document.getElementById("newinputtype7")?.style
                            .display === "none"
                        ? false
                        : !substrateIntermediate7 ||
                          !productIntermediate7 ||
                          !type7
                        ? true
                        : document.getElementById("newinputsubstrate8")?.style
                            .display === "none" &&
                          document.getElementById("newinputproduct8")?.style
                            .display === "none" &&
                          document.getElementById("newinputtype8")?.style
                            .display === "none"
                        ? false
                        : !substrateIntermediate8 ||
                          !productIntermediate8 ||
                          !type8
                        ? true
                        : document.getElementById("newinputsubstrate9")?.style
                            .display === "none" &&
                          document.getElementById("newinputproduct9")?.style
                            .display === "none" &&
                          document.getElementById("newinputtype9")?.style
                            .display === "none"
                        ? false
                        : !substrateIntermediate9 ||
                          !productIntermediate9 ||
                          !type9
                        ? true
                        : document.getElementById("newinputsubstrate10")?.style
                            .display === "none" &&
                          document.getElementById("newinputproduct10")?.style
                            .display === "none" &&
                          document.getElementById("newinputtype10")?.style
                            .display === "none"
                        ? false
                        : !substrateIntermediate10 ||
                          !productIntermediate10 ||
                          !type10
                        ? true
                        : document.getElementById("newinputsubstrate11")?.style
                            .display === "none" &&
                          document.getElementById("newinputproduct11")?.style
                            .display === "none" &&
                          document.getElementById("newinputtype11")?.style
                            .display === "none"
                        ? false
                        : !substrateIntermediate11 ||
                          !productIntermediate11 ||
                          !type11
                        ? true
                        : document.getElementById("newinputsubstrate12")?.style
                            .display === "none" &&
                          document.getElementById("newinputproduct12")?.style
                            .display === "none" &&
                          document.getElementById("newinputtype12")?.style
                            .display === "none"
                        ? false
                        : !substrateIntermediate12 ||
                          !productIntermediate12 ||
                          !type12
                        ? true
                        : document.getElementById("newinputsubstrate13")?.style
                            .display === "none" &&
                          document.getElementById("newinputproduct13")?.style
                            .display === "none" &&
                          document.getElementById("newinputtype13")?.style
                            .display === "none"
                        ? false
                        : !substrateIntermediate13 ||
                          !productIntermediate13 ||
                          !type13
                        ? true
                        : document.getElementById("newinputsubstrate14")?.style
                            .display === "none" &&
                          document.getElementById("newinputproduct14")?.style
                            .display === "none" &&
                          document.getElementById("newinputtype14")?.style
                            .display === "none"
                        ? false
                        : !substrateIntermediate14 ||
                          !productIntermediate14 ||
                          !type14
                        ? true
                        : document.getElementById("newinputsubstrate15")?.style
                            .display === "none" &&
                          document.getElementById("newinputproduct15")?.style
                            .display === "none" &&
                          document.getElementById("newinputtype15")?.style
                            .display === "none"
                        ? false
                        : !substrateIntermediate15 ||
                          !productIntermediate15 ||
                          !type15
                        ? true
                        : document.getElementById("newinputsubstrate16")?.style
                            .display === "none" &&
                          document.getElementById("newinputproduct16")?.style
                            .display === "none" &&
                          document.getElementById("newinputtype16")?.style
                            .display === "none"
                        ? false
                        : !substrateIntermediate16 ||
                          !productIntermediate16 ||
                          !type16
                        ? true
                        : document.getElementById("newinputsubstrate17")?.style
                            .display === "none" &&
                          document.getElementById("newinputproduct17")?.style
                            .display === "none" &&
                          document.getElementById("newinputtype17")?.style
                            .display === "none"
                        ? false
                        : !substrateIntermediate17 ||
                          !productIntermediate17 ||
                          !type17
                        ? true
                        : document.getElementById("newinputsubstrate18")?.style
                            .display === "none" &&
                          document.getElementById("newinputproduct18")?.style
                            .display === "none" &&
                          document.getElementById("newinputtype18")?.style
                            .display === "none"
                        ? false
                        : !substrateIntermediate18 ||
                          !productIntermediate18 ||
                          !type18
                        ? true
                        : document.getElementById("newinputsubstrate19")?.style
                            .display === "none" &&
                          document.getElementById("newinputproduct19")?.style
                            .display === "none" &&
                          document.getElementById("newinputtype19")?.style
                            .display === "none"
                        ? false
                        : !substrateIntermediate19 ||
                          !productIntermediate19 ||
                          !type19
                        ? true
                        : document.getElementById("newinputsubstrate20")?.style
                            .display === "none" &&
                          document.getElementById("newinputproduct20")?.style
                            .display === "none" &&
                          document.getElementById("newinputtype20")?.style
                            .display === "none"
                        ? false
                        : !substrateIntermediate20 ||
                          !productIntermediate20 ||
                          !type20
                        ? true
                        : document.getElementById("newinputsubstrate21")?.style
                            .display === "none" &&
                          document.getElementById("newinputproduct21")?.style
                            .display === "none" &&
                          document.getElementById("newinputtype21")?.style
                            .display === "none"
                        ? false
                        : !substrateIntermediate21 ||
                          !productIntermediate21 ||
                          !type21
                        ? true
                        : document.getElementById("newinputsubstrate22")?.style
                            .display === "none" &&
                          document.getElementById("newinputproduct22")?.style
                            .display === "none" &&
                          document.getElementById("newinputtype22")?.style
                            .display === "none"
                        ? false
                        : !substrateIntermediate22 ||
                          !productIntermediate22 ||
                          !type22
                        ? true
                        : document.getElementById("newinputsubstrate23")?.style
                            .display === "none" &&
                          document.getElementById("newinputproduct23")?.style
                            .display === "none" &&
                          document.getElementById("newinputtype23")?.style
                            .display === "none"
                        ? false
                        : !substrateIntermediate23 ||
                          !productIntermediate23 ||
                          !type23
                        ? true
                        : document.getElementById("newinputsubstrate24")?.style
                            .display === "none" &&
                          document.getElementById("newinputproduct24")?.style
                            .display === "none" &&
                          document.getElementById("newinputtype24")?.style
                            .display === "none"
                        ? false
                        : !substrateIntermediate24 ||
                          !productIntermediate24 ||
                          !type24
                        ? true
                        : document.getElementById("newinputsubstrate25")?.style
                            .display === "none" &&
                          document.getElementById("newinputproduct25")?.style
                            .display === "none" &&
                          document.getElementById("newinputtype25")?.style
                            .display === "none"
                        ? false
                        : !substrateIntermediate25 ||
                          !productIntermediate25 ||
                          !type25
                        ? true
                        : document.getElementById("newinputsubstrate26")?.style
                            .display === "none" &&
                          document.getElementById("newinputproduct26")?.style
                            .display === "none" &&
                          document.getElementById("newinputtype26")?.style
                            .display === "none"
                        ? false
                        : !substrateIntermediate26 ||
                          !productIntermediate26 ||
                          !type26
                        ? true
                        : document.getElementById("newinputsubstrate27")?.style
                            .display === "none" &&
                          document.getElementById("newinputproduct27")?.style
                            .display === "none" &&
                          document.getElementById("newinputtype27")?.style
                            .display === "none"
                        ? false
                        : !substrateIntermediate27 ||
                          !productIntermediate27 ||
                          !type27
                        ? true
                        : document.getElementById("newinputsubstrate28")?.style
                            .display === "none" &&
                          document.getElementById("newinputproduct28")?.style
                            .display === "none" &&
                          document.getElementById("newinputtype28")?.style
                            .display === "none"
                        ? false
                        : !substrateIntermediate28 ||
                          !productIntermediate28 ||
                          !type28
                        ? true
                        : document.getElementById("newinputsubstrate29")?.style
                            .display === "none" &&
                          document.getElementById("newinputproduct29")?.style
                            .display === "none" &&
                          document.getElementById("newinputtype29")?.style
                            .display === "none"
                        ? false
                        : !substrateIntermediate29 ||
                          !productIntermediate29 ||
                          !type29
                        ? true
                        : document.getElementById("newinputsubstrate30")?.style
                            .display === "none" &&
                          document.getElementById("newinputproduct30")?.style
                            .display === "none" &&
                          document.getElementById("newinputtype30")?.style
                            .display === "none"
                        ? false
                        : !substrateIntermediate30 ||
                          !productIntermediate30 ||
                          !type30
                        ? true
                        : document.getElementById("newinputsubstrate31")?.style
                            .display === "none" &&
                          document.getElementById("newinputproduct31")?.style
                            .display === "none" &&
                          document.getElementById("newinputtype31")?.style
                            .display === "none"
                        ? false
                        : !substrateIntermediate31 ||
                          !productIntermediate31 ||
                          !type31
                        ? true
                        : document.getElementById("newinputsubstrate32")?.style
                            .display === "none" &&
                          document.getElementById("newinputproduct32")?.style
                            .display === "none" &&
                          document.getElementById("newinputtype32")?.style
                            .display === "none"
                        ? false
                        : !substrateIntermediate32 ||
                          !productIntermediate32 ||
                          !type32
                        ? true
                        : document.getElementById("newinputsubstrate33")?.style
                            .display === "none" &&
                          document.getElementById("newinputproduct33")?.style
                            .display === "none" &&
                          document.getElementById("newinputtype33")?.style
                            .display === "none"
                        ? false
                        : !substrateIntermediate33 ||
                          !productIntermediate33 ||
                          !type33
                        ? true
                        : document.getElementById("newinputsubstrate34")?.style
                            .display === "none" &&
                          document.getElementById("newinputproduct34")?.style
                            .display === "none" &&
                          document.getElementById("newinputtype34")?.style
                            .display === "none"
                        ? false
                        : !substrateIntermediate34 ||
                          !productIntermediate34 ||
                          !type34
                        ? true
                        : document.getElementById("newinputsubstrate35")?.style
                            .display === "none" &&
                          document.getElementById("newinputproduct35")?.style
                            .display === "none" &&
                          document.getElementById("newinputtype35")?.style
                            .display === "none"
                        ? false
                        : !substrateIntermediate35 ||
                          !productIntermediate35 ||
                          !type35
                        ? true
                        : document.getElementById("newinputsubstrate36")?.style
                            .display === "none" &&
                          document.getElementById("newinputproduct36")?.style
                            .display === "none" &&
                          document.getElementById("newinputtype36")?.style
                            .display === "none"
                        ? false
                        : !substrateIntermediate36 ||
                          !productIntermediate36 ||
                          !type36
                        ? true
                        : document.getElementById("newinputsubstrate37")?.style
                            .display === "none" &&
                          document.getElementById("newinputproduct37")?.style
                            .display === "none" &&
                          document.getElementById("newinputtype37")?.style
                            .display === "none"
                        ? false
                        : !substrateIntermediate37 ||
                          !productIntermediate37 ||
                          !type37
                        ? true
                        : document.getElementById("newinputsubstrate38")?.style
                            .display === "none" &&
                          document.getElementById("newinputproduct38")?.style
                            .display === "none" &&
                          document.getElementById("newinputtype38")?.style
                            .display === "none"
                        ? false
                        : !substrateIntermediate38 ||
                          !productIntermediate38 ||
                          !type38
                        ? true
                        : document.getElementById("newinputsubstrate39")?.style
                            .display === "none" &&
                          document.getElementById("newinputproduct39")?.style
                            .display === "none" &&
                          document.getElementById("newinputtype39")?.style
                            .display === "none"
                        ? false
                        : !substrateIntermediate39 ||
                          !productIntermediate39 ||
                          !type39
                        ? true
                        : document.getElementById("newinputsubstrate40")?.style
                            .display === "none" &&
                          document.getElementById("newinputproduct40")?.style
                            .display === "none" &&
                          document.getElementById("newinputtype40")?.style
                            .display === "none"
                        ? false
                        : !substrateIntermediate40 ||
                          !productIntermediate40 ||
                          !type40
                        ? true
                        : document.getElementById("newinputsubstrate41")?.style
                            .display === "none" &&
                          document.getElementById("newinputproduct41")?.style
                            .display === "none" &&
                          document.getElementById("newinputtype41")?.style
                            .display === "none"
                        ? false
                        : !substrateIntermediate41 ||
                          !productIntermediate41 ||
                          !type41
                        ? true
                        : document.getElementById("newinputsubstrate42")?.style
                            .display === "none" &&
                          document.getElementById("newinputproduct42")?.style
                            .display === "none" &&
                          document.getElementById("newinputtype42")?.style
                            .display === "none"
                        ? false
                        : !substrateIntermediate42 ||
                          !productIntermediate42 ||
                          !type42
                        ? true
                        : document.getElementById("newinputsubstrate43")?.style
                            .display === "none" &&
                          document.getElementById("newinputproduct43")?.style
                            .display === "none" &&
                          document.getElementById("newinputtype43")?.style
                            .display === "none"
                        ? false
                        : !substrateIntermediate43 ||
                          !productIntermediate43 ||
                          !type43
                        ? true
                        : document.getElementById("newinputsubstrate44")?.style
                            .display === "none" &&
                          document.getElementById("newinputproduct44")?.style
                            .display === "none" &&
                          document.getElementById("newinputtype44")?.style
                            .display === "none"
                        ? false
                        : !substrateIntermediate44 ||
                          !productIntermediate44 ||
                          !type44
                        ? true
                        : document.getElementById("newinputsubstrate45")?.style
                            .display === "none" &&
                          document.getElementById("newinputproduct45")?.style
                            .display === "none" &&
                          document.getElementById("newinputtype45")?.style
                            .display === "none"
                        ? false
                        : !substrateIntermediate45 ||
                          !productIntermediate45 ||
                          !type45
                        ? true
                        : document.getElementById("newinputsubstrate46")?.style
                            .display === "none" &&
                          document.getElementById("newinputproduct46")?.style
                            .display === "none" &&
                          document.getElementById("newinputtype46")?.style
                            .display === "none"
                        ? false
                        : !substrateIntermediate46 ||
                          !productIntermediate46 ||
                          !type46
                        ? true
                        : document.getElementById("newinputsubstrate47")?.style
                            .display === "none" &&
                          document.getElementById("newinputproduct47")?.style
                            .display === "none" &&
                          document.getElementById("newinputtype47")?.style
                            .display === "none"
                        ? false
                        : !substrateIntermediate47 ||
                          !productIntermediate47 ||
                          !type47
                        ? true
                        : document.getElementById("newinputsubstrate48")?.style
                            .display === "none" &&
                          document.getElementById("newinputproduct48")?.style
                            .display === "none" &&
                          document.getElementById("newinputtype48")?.style
                            .display === "none"
                        ? false
                        : !substrateIntermediate48 ||
                          !productIntermediate48 ||
                          !type48
                        ? true
                        : document.getElementById("newinputsubstrate49")?.style
                            .display === "none" &&
                          document.getElementById("newinputproduct49")?.style
                            .display === "none" &&
                          document.getElementById("newinputtype49")?.style
                            .display === "none"
                        ? false
                        : !substrateIntermediate49 ||
                          !productIntermediate49 ||
                          !type49
                        ? true
                        : document.getElementById("newinputsubstrate50")?.style
                            .display === "none" &&
                          document.getElementById("newinputproduct50")?.style
                            .display === "none" &&
                          document.getElementById("newinputtype50")?.style
                            .display === "none"
                        ? false
                        : !substrateIntermediate50 ||
                          !productIntermediate50 ||
                          !type50
                        ? true
                        : false
                    }
                    style={{
                      opacity:
                        !substrateIntermediate1 ||
                        !productIntermediate1 ||
                        !type1
                          ? "0.5"
                          : !substrateIntermediate2 ||
                            !productIntermediate2 ||
                            !type2
                          ? "0.5"
                          : document.getElementById("newinputsubstrate3")?.style
                              .display === "none" &&
                            document.getElementById("newinputproduct3")?.style
                              .display === "none" &&
                            document.getElementById("newinputtype3")?.style
                              .display === "none"
                          ? "1"
                          : !substrateIntermediate3 ||
                            !productIntermediate3 ||
                            !type3
                          ? "0.5"
                          : document.getElementById("newinputsubstrate4")?.style
                              .display === "none" &&
                            document.getElementById("newinputproduct4")?.style
                              .display === "none" &&
                            document.getElementById("newinputtype4")?.style
                              .display === "none"
                          ? "1"
                          : !substrateIntermediate4 ||
                            !productIntermediate4 ||
                            !type4
                          ? "0.5"
                          : document.getElementById("newinputsubstrate5")?.style
                              .display === "none" &&
                            document.getElementById("newinputproduct5")?.style
                              .display === "none" &&
                            document.getElementById("newinputtype5")?.style
                              .display === "none"
                          ? "1"
                          : !substrateIntermediate5 ||
                            !productIntermediate5 ||
                            !type5
                          ? "0.5"
                          : document.getElementById("newinputsubstrate6")?.style
                              .display === "none" &&
                            document.getElementById("newinputproduct6")?.style
                              .display === "none" &&
                            document.getElementById("newinputtype6")?.style
                              .display === "none"
                          ? "1"
                          : !substrateIntermediate6 ||
                            !productIntermediate6 ||
                            !type6
                          ? "0.5"
                          : document.getElementById("newinputsubstrate7")?.style
                              .display === "none" &&
                            document.getElementById("newinputproduct7")?.style
                              .display === "none" &&
                            document.getElementById("newinputtype7")?.style
                              .display === "none"
                          ? "1"
                          : !substrateIntermediate7 ||
                            !productIntermediate7 ||
                            !type7
                          ? "0.5"
                          : document.getElementById("newinputsubstrate8")?.style
                              .display === "none" &&
                            document.getElementById("newinputproduct8")?.style
                              .display === "none" &&
                            document.getElementById("newinputtype8")?.style
                              .display === "none"
                          ? "1"
                          : !substrateIntermediate8 ||
                            !productIntermediate8 ||
                            !type8
                          ? "0.5"
                          : document.getElementById("newinputsubstrate9")?.style
                              .display === "none" &&
                            document.getElementById("newinputproduct9")?.style
                              .display === "none" &&
                            document.getElementById("newinputtype9")?.style
                              .display === "none"
                          ? "1"
                          : !substrateIntermediate9 ||
                            !productIntermediate9 ||
                            !type9
                          ? "0.5"
                          : document.getElementById("newinputsubstrate10")
                              ?.style.display === "none" &&
                            document.getElementById("newinputproduct10")?.style
                              .display === "none" &&
                            document.getElementById("newinputtype10")?.style
                              .display === "none"
                          ? "1"
                          : !substrateIntermediate10 ||
                            !productIntermediate10 ||
                            !type10
                          ? "0.5"
                          : document.getElementById("newinputsubstrate11")
                              ?.style.display === "none" &&
                            document.getElementById("newinputproduct11")?.style
                              .display === "none" &&
                            document.getElementById("newinputtype11")?.style
                              .display === "none"
                          ? "1"
                          : !substrateIntermediate11 ||
                            !productIntermediate11 ||
                            !type11
                          ? "0.5"
                          : document.getElementById("newinputsubstrate12")
                              ?.style.display === "none" &&
                            document.getElementById("newinputproduct12")?.style
                              .display === "none" &&
                            document.getElementById("newinputtype12")?.style
                              .display === "none"
                          ? "1"
                          : !substrateIntermediate12 ||
                            !productIntermediate12 ||
                            !type12
                          ? "0.5"
                          : document.getElementById("newinputsubstrate13")
                              ?.style.display === "none" &&
                            document.getElementById("newinputproduct13")?.style
                              .display === "none" &&
                            document.getElementById("newinputtype13")?.style
                              .display === "none"
                          ? "1"
                          : !substrateIntermediate13 ||
                            !productIntermediate13 ||
                            !type13
                          ? "0.5"
                          : document.getElementById("newinputsubstrate14")
                              ?.style.display === "none" &&
                            document.getElementById("newinputproduct14")?.style
                              .display === "none" &&
                            document.getElementById("newinputtype14")?.style
                              .display === "none"
                          ? "1"
                          : !substrateIntermediate14 ||
                            !productIntermediate14 ||
                            !type14
                          ? "0.5"
                          : document.getElementById("newinputsubstrate15")
                              ?.style.display === "none" &&
                            document.getElementById("newinputproduct15")?.style
                              .display === "none" &&
                            document.getElementById("newinputtype15")?.style
                              .display === "none"
                          ? "1"
                          : !substrateIntermediate15 ||
                            !productIntermediate15 ||
                            !type15
                          ? "0.5"
                          : document.getElementById("newinputsubstrate16")
                              ?.style.display === "none" &&
                            document.getElementById("newinputproduct16")?.style
                              .display === "none" &&
                            document.getElementById("newinputtype16")?.style
                              .display === "none"
                          ? "1"
                          : !substrateIntermediate16 ||
                            !productIntermediate16 ||
                            !type16
                          ? "0.5"
                          : document.getElementById("newinputsubstrate17")
                              ?.style.display === "none" &&
                            document.getElementById("newinputproduct17")?.style
                              .display === "none" &&
                            document.getElementById("newinputtype17")?.style
                              .display === "none"
                          ? "1"
                          : !substrateIntermediate17 ||
                            !productIntermediate17 ||
                            !type17
                          ? "0.5"
                          : document.getElementById("newinputsubstrate18")
                              ?.style.display === "none" &&
                            document.getElementById("newinputproduct18")?.style
                              .display === "none" &&
                            document.getElementById("newinputtype18")?.style
                              .display === "none"
                          ? "1"
                          : !substrateIntermediate18 ||
                            !productIntermediate18 ||
                            !type18
                          ? "0.5"
                          : document.getElementById("newinputsubstrate19")
                              ?.style.display === "none" &&
                            document.getElementById("newinputproduct19")?.style
                              .display === "none" &&
                            document.getElementById("newinputtype19")?.style
                              .display === "none"
                          ? "1"
                          : !substrateIntermediate19 ||
                            !productIntermediate19 ||
                            !type19
                          ? "0.5"
                          : document.getElementById("newinputsubstrate20")
                              ?.style.display === "none" &&
                            document.getElementById("newinputproduct20")?.style
                              .display === "none" &&
                            document.getElementById("newinputtype20")?.style
                              .display === "none"
                          ? "1"
                          : !substrateIntermediate20 ||
                            !productIntermediate20 ||
                            !type20
                          ? "0.5"
                          : document.getElementById("newinputsubstrate21")
                              ?.style.display === "none" &&
                            document.getElementById("newinputproduct21")?.style
                              .display === "none" &&
                            document.getElementById("newinputtype21")?.style
                              .display === "none"
                          ? "1"
                          : !substrateIntermediate21 ||
                            !productIntermediate21 ||
                            !type21
                          ? "0.5"
                          : document.getElementById("newinputsubstrate22")
                              ?.style.display === "none" &&
                            document.getElementById("newinputproduct22")?.style
                              .display === "none" &&
                            document.getElementById("newinputtype22")?.style
                              .display === "none"
                          ? "1"
                          : !substrateIntermediate22 ||
                            !productIntermediate22 ||
                            !type22
                          ? "0.5"
                          : document.getElementById("newinputsubstrate23")
                              ?.style.display === "none" &&
                            document.getElementById("newinputproduct23")?.style
                              .display === "none" &&
                            document.getElementById("newinputtype23")?.style
                              .display === "none"
                          ? "1"
                          : !substrateIntermediate23 ||
                            !productIntermediate23 ||
                            !type23
                          ? "0.5"
                          : document.getElementById("newinputsubstrate24")
                              ?.style.display === "none" &&
                            document.getElementById("newinputproduct24")?.style
                              .display === "none" &&
                            document.getElementById("newinputtype24")?.style
                              .display === "none"
                          ? "1"
                          : !substrateIntermediate24 ||
                            !productIntermediate24 ||
                            !type24
                          ? "0.5"
                          : document.getElementById("newinputsubstrate25")
                              ?.style.display === "none" &&
                            document.getElementById("newinputproduct25")?.style
                              .display === "none" &&
                            document.getElementById("newinputtype25")?.style
                              .display === "none"
                          ? "1"
                          : !substrateIntermediate25 ||
                            !productIntermediate25 ||
                            !type25
                          ? "0.5"
                          : document.getElementById("newinputsubstrate26")
                              ?.style.display === "none" &&
                            document.getElementById("newinputproduct26")?.style
                              .display === "none" &&
                            document.getElementById("newinputtype26")?.style
                              .display === "none"
                          ? "1"
                          : !substrateIntermediate26 ||
                            !productIntermediate26 ||
                            !type26
                          ? "0.5"
                          : document.getElementById("newinputsubstrate27")
                              ?.style.display === "none" &&
                            document.getElementById("newinputproduct27")?.style
                              .display === "none" &&
                            document.getElementById("newinputtype27")?.style
                              .display === "none"
                          ? "1"
                          : !substrateIntermediate27 ||
                            !productIntermediate27 ||
                            !type27
                          ? "0.5"
                          : document.getElementById("newinputsubstrate28")
                              ?.style.display === "none" &&
                            document.getElementById("newinputproduct28")?.style
                              .display === "none" &&
                            document.getElementById("newinputtype28")?.style
                              .display === "none"
                          ? "1"
                          : !substrateIntermediate28 ||
                            !productIntermediate28 ||
                            !type28
                          ? "0.5"
                          : document.getElementById("newinputsubstrate29")
                              ?.style.display === "none" &&
                            document.getElementById("newinputproduct29")?.style
                              .display === "none" &&
                            document.getElementById("newinputtype29")?.style
                              .display === "none"
                          ? "1"
                          : !substrateIntermediate29 ||
                            !productIntermediate29 ||
                            !type29
                          ? "0.5"
                          : document.getElementById("newinputsubstrate30")
                              ?.style.display === "none" &&
                            document.getElementById("newinputproduct30")?.style
                              .display === "none" &&
                            document.getElementById("newinputtype30")?.style
                              .display === "none"
                          ? "1"
                          : !substrateIntermediate30 ||
                            !productIntermediate30 ||
                            !type30
                          ? "0.5"
                          : document.getElementById("newinputsubstrate31")
                              ?.style.display === "none" &&
                            document.getElementById("newinputproduct31")?.style
                              .display === "none" &&
                            document.getElementById("newinputtype31")?.style
                              .display === "none"
                          ? "1"
                          : !substrateIntermediate31 ||
                            !productIntermediate31 ||
                            !type31
                          ? "0.5"
                          : document.getElementById("newinputsubstrate32")
                              ?.style.display === "none" &&
                            document.getElementById("newinputproduct32")?.style
                              .display === "none" &&
                            document.getElementById("newinputtype32")?.style
                              .display === "none"
                          ? "1"
                          : !substrateIntermediate32 ||
                            !productIntermediate32 ||
                            !type32
                          ? "0.5"
                          : document.getElementById("newinputsubstrate33")
                              ?.style.display === "none" &&
                            document.getElementById("newinputproduct33")?.style
                              .display === "none" &&
                            document.getElementById("newinputtype33")?.style
                              .display === "none"
                          ? "1"
                          : !substrateIntermediate33 ||
                            !productIntermediate33 ||
                            !type33
                          ? "0.5"
                          : document.getElementById("newinputsubstrate34")
                              ?.style.display === "none" &&
                            document.getElementById("newinputproduct34")?.style
                              .display === "none" &&
                            document.getElementById("newinputtype34")?.style
                              .display === "none"
                          ? "1"
                          : !substrateIntermediate34 ||
                            !productIntermediate34 ||
                            !type34
                          ? "0.5"
                          : document.getElementById("newinputsubstrate35")
                              ?.style.display === "none" &&
                            document.getElementById("newinputproduct35")?.style
                              .display === "none" &&
                            document.getElementById("newinputtype35")?.style
                              .display === "none"
                          ? "1"
                          : !substrateIntermediate35 ||
                            !productIntermediate35 ||
                            !type35
                          ? "0.5"
                          : document.getElementById("newinputsubstrate36")
                              ?.style.display === "none" &&
                            document.getElementById("newinputproduct36")?.style
                              .display === "none" &&
                            document.getElementById("newinputtype36")?.style
                              .display === "none"
                          ? "1"
                          : !substrateIntermediate36 ||
                            !productIntermediate36 ||
                            !type36
                          ? "0.5"
                          : document.getElementById("newinputsubstrate37")
                              ?.style.display === "none" &&
                            document.getElementById("newinputproduct37")?.style
                              .display === "none" &&
                            document.getElementById("newinputtype37")?.style
                              .display === "none"
                          ? "1"
                          : !substrateIntermediate37 ||
                            !productIntermediate37 ||
                            !type37
                          ? "0.5"
                          : document.getElementById("newinputsubstrate38")
                              ?.style.display === "none" &&
                            document.getElementById("newinputproduct38")?.style
                              .display === "none" &&
                            document.getElementById("newinputtype38")?.style
                              .display === "none"
                          ? "1"
                          : !substrateIntermediate38 ||
                            !productIntermediate38 ||
                            !type38
                          ? "0.5"
                          : document.getElementById("newinputsubstrate39")
                              ?.style.display === "none" &&
                            document.getElementById("newinputproduct39")?.style
                              .display === "none" &&
                            document.getElementById("newinputtype39")?.style
                              .display === "none"
                          ? "1"
                          : !substrateIntermediate39 ||
                            !productIntermediate39 ||
                            !type39
                          ? "0.5"
                          : document.getElementById("newinputsubstrate40")
                              ?.style.display === "none" &&
                            document.getElementById("newinputproduct40")?.style
                              .display === "none" &&
                            document.getElementById("newinputtype40")?.style
                              .display === "none"
                          ? "1"
                          : !substrateIntermediate40 ||
                            !productIntermediate40 ||
                            !type40
                          ? "0.5"
                          : document.getElementById("newinputsubstrate41")
                              ?.style.display === "none" &&
                            document.getElementById("newinputproduct41")?.style
                              .display === "none" &&
                            document.getElementById("newinputtype41")?.style
                              .display === "none"
                          ? "1"
                          : !substrateIntermediate41 ||
                            !productIntermediate41 ||
                            !type41
                          ? "0.5"
                          : document.getElementById("newinputsubstrate42")
                              ?.style.display === "none" &&
                            document.getElementById("newinputproduct42")?.style
                              .display === "none" &&
                            document.getElementById("newinputtype42")?.style
                              .display === "none"
                          ? "1"
                          : !substrateIntermediate42 ||
                            !productIntermediate42 ||
                            !type42
                          ? "0.5"
                          : document.getElementById("newinputsubstrate43")
                              ?.style.display === "none" &&
                            document.getElementById("newinputproduct43")?.style
                              .display === "none" &&
                            document.getElementById("newinputtype43")?.style
                              .display === "none"
                          ? "1"
                          : !substrateIntermediate43 ||
                            !productIntermediate43 ||
                            !type43
                          ? "0.5"
                          : document.getElementById("newinputsubstrate44")
                              ?.style.display === "none" &&
                            document.getElementById("newinputproduct44")?.style
                              .display === "none" &&
                            document.getElementById("newinputtype44")?.style
                              .display === "none"
                          ? "1"
                          : !substrateIntermediate44 ||
                            !productIntermediate44 ||
                            !type44
                          ? "0.5"
                          : document.getElementById("newinputsubstrate45")
                              ?.style.display === "none" &&
                            document.getElementById("newinputproduct45")?.style
                              .display === "none" &&
                            document.getElementById("newinputtype45")?.style
                              .display === "none"
                          ? "1"
                          : !substrateIntermediate45 ||
                            !productIntermediate45 ||
                            !type45
                          ? "0.5"
                          : document.getElementById("newinputsubstrate46")
                              ?.style.display === "none" &&
                            document.getElementById("newinputproduct46")?.style
                              .display === "none" &&
                            document.getElementById("newinputtype46")?.style
                              .display === "none"
                          ? "1"
                          : !substrateIntermediate46 ||
                            !productIntermediate46 ||
                            !type46
                          ? "0.5"
                          : document.getElementById("newinputsubstrate47")
                              ?.style.display === "none" &&
                            document.getElementById("newinputproduct47")?.style
                              .display === "none" &&
                            document.getElementById("newinputtype47")?.style
                              .display === "none"
                          ? "1"
                          : !substrateIntermediate47 ||
                            !productIntermediate47 ||
                            !type47
                          ? "0.5"
                          : document.getElementById("newinputsubstrate48")
                              ?.style.display === "none" &&
                            document.getElementById("newinputproduct48")?.style
                              .display === "none" &&
                            document.getElementById("newinputtype48")?.style
                              .display === "none"
                          ? "1"
                          : !substrateIntermediate48 ||
                            !productIntermediate48 ||
                            !type48
                          ? "0.5"
                          : document.getElementById("newinputsubstrate49")
                              ?.style.display === "none" &&
                            document.getElementById("newinputproduct49")?.style
                              .display === "none" &&
                            document.getElementById("newinputtype49")?.style
                              .display === "none"
                          ? "1"
                          : !substrateIntermediate49 ||
                            !productIntermediate49 ||
                            !type49
                          ? "0.5"
                          : document.getElementById("newinputsubstrate50")
                              ?.style.display === "none" &&
                            document.getElementById("newinputproduct50")?.style
                              .display === "none" &&
                            document.getElementById("newinputtype50")?.style
                              .display === "none"
                          ? "1"
                          : !substrateIntermediate50 ||
                            !productIntermediate50 ||
                            !type50
                          ? "0.5"
                          : "1",
                    }}
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div id="footer" style={{ display: "none" }}>
          <Footer />
        </div>
        <div id="overlap" style={{ display: "none" }}>
          <div id="submitConfirmation" align="left">
            <ul>
              <li>
                <FontAwesomeIcon icon={faCheck} style={{ color: "green" }} />{" "}
                Your entry has been successfully submitted to the database.
              </li>
              <li>
                <button id="ok" onClick={submitOK}>
                  OK
                </button>
              </li>
            </ul>
          </div>
          <div id="duplication" align="left">
            <ul>
              <li>
                <FontAwesomeIcon
                  icon={faExclamationCircle}
                  style={{ color: "red" }}
                />{" "}
                Your entry contained one or more duplicate values.<br></br>
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ color: "green" }}
                />{" "}
                Duplicates were not inserted but your entry remains valid.
              </li>
              <li>
                <button id="ok" onClick={submitOK}>
                  OK
                </button>
              </li>
            </ul>
          </div>
          </div>
      </body>
    </html>
  );
}

export default NewMechanism;
