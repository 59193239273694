import React from "react";
import axios from "../api/axios.js";
import { useState, useEffect } from "react";
import "./signUp.css";
import {
  faCheck,
  faTimes,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HomeNavbar from "../HomeNavbar.js";

const USER_REGEX = /^[a-zA-Z][a-zA-Z0-9-_]{3,23}$/;
const PASSWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,24}$/;
const EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

export default function SignUp() {
  const [username, setUsername] = useState("");
  const [validUsername, setValidUsername] = useState(false);
  const [usernameFocus, setUsernameFocus] = useState(false);
  const [password, setPassword] = useState("");
  const [validPassword, setValidPassword] = useState(false);
  const [passwordFocus, setPasswordFocus] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [validConfirmPassword, setValidConfirmPassword] = useState(false);
  const [confirmPasswordFocus, setConfirmPasswordFocus] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [title, setTitle] = useState("");
  const [affiliation, setAffiliation] = useState("");
  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");

  useEffect(() => {
    setValidUsername(USER_REGEX.test(username));
  }, [username]);

  useEffect(() => {
    setValidPassword(PASSWD_REGEX.test(password));
    setValidConfirmPassword(password === confirmPassword);
  }, [password, confirmPassword]);

  useEffect(() => {
    setErrMsg("");
  }, [username, password, confirmPassword]);

  useEffect(() => {
    setValidEmail(EMAIL_REGEX.test(email));
  }, [email]);

  async function onSubmit(event) {
    event.preventDefault();
    try {
      await axios
        .post("/signup", {
          username,
          password,
          confirmPassword,
          firstName,
          lastName,
          title,
          affiliation,
          email,
          phoneNumber,
        })
        .then((res) => {
          if (res.status === 200)
            return window.location.replace("http://localhost:3000/login");
        });
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 409) {
        setErrMsg("Username Taken");
      } else {
        setErrMsg("Registration Failed");
      }
    }
  }

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css"
          rel="stylesheet"
        />
      </head>
      <body>
        <HomeNavbar/>
        <div className="SignUpBackground" style={{position:"relative"}}>
          <div className="SignUpAlignment">
            <div className="SignUpBox">
              <div class="row">
                <div class="col-lg-12" align="center">
                  <p
                    id="errorMessage"
                    style={errMsg ? { display: "block" } : { display: "none" }}
                    aria-live="assertive"
                  >
                    {errMsg}
                  </p>
                  <h1>Register</h1>
                </div>
              </div>

              <div className="SignUpFormDiv">
                <form size="400px" className="SignUpForm" onSubmit={onSubmit}>
                  <div class="row">
                    <div class="col-lg-6" align="left" id="UserDiv">
                      <label htmlFor="username" id="UserLabel">
                        Username:{" "}
                        <span
                          style={
                            validUsername
                              ? { display: "block" }
                              : { display: "none" }
                          }
                        >
                          <FontAwesomeIcon icon={faCheck} id="tick" />
                        </span>
                        <span
                          style={
                            validUsername || !username
                              ? { display: "none" }
                              : { display: "block" }
                          }
                        >
                          <FontAwesomeIcon icon={faTimes} id="cross" />
                        </span>
                      </label>
                      <input
                        id="username"
                        type="text"
                        autoComplete="off"
                        required
                        aria-invalid={validUsername ? "false" : "true"}
                        aria-describedby="uidnote"
                        onFocus={() => setUsernameFocus(true)}
                        onBlur={() => setUsernameFocus(false)}
                        onChange={async (event) => {
                          setUsername(event.target.value);
                        }}
                        value={username}
                      />
                      <p
                        id="uidnote"
                        style={
                          usernameFocus && username && !validUsername
                            ? { display: "block" }
                            : { display: "none" }
                        }
                      >
                        <FontAwesomeIcon icon={faInfoCircle} id="info" />
                        4 to 24 characters.
                        <br />
                        Must start with a letter.
                        <br />
                        Only letters, numbers, underscores, hyphens allowed.
                      </p>
                    </div>
                    <div class="col-lg-6" align="left" id="TitleDiv">
                      <label htmlFor="title" id="TitleLabel">
                        Title:
                      </label>
                      <input
                        id="title"
                        type="text"
                        required
                        onChange={async (event) => {
                          setTitle(event.target.value);
                        }}
                        value={title}
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6" align="left" id="PasswdDiv">
                      <label htmlFor="password" id="PasswdLabel">
                        Password:{" "}
                        <span
                          style={
                            validPassword
                              ? { display: "block" }
                              : { display: "none" }
                          }
                        >
                          <FontAwesomeIcon icon={faCheck} id="tick" />
                        </span>
                        <span
                          style={
                            validPassword || !password
                              ? { display: "none" }
                              : { display: "block" }
                          }
                        >
                          <FontAwesomeIcon icon={faTimes} id="cross" />
                        </span>
                      </label>
                      <input
                        id="password"
                        type="password"
                        required
                        aria-invalid={validPassword ? "false" : "true"}
                        aria-describedby="passwdnote"
                        onFocus={() => setPasswordFocus(true)}
                        onBlur={() => setPasswordFocus(false)}
                        onChange={async (event) => {
                          setPassword(event.target.value);
                        }}
                        value={password}
                      />
                      <p
                        id="passwdnote"
                        style={
                          passwordFocus && !validPassword
                            ? { display: "block" }
                            : { display: "none" }
                        }
                      >
                        <FontAwesomeIcon icon={faInfoCircle} id="info" />
                        8 to 24 characters.
                        <br />
                        Must include uppercase and lowercase letters and a
                        number.
                      </p>
                    </div>
                    <div class="col-lg-6" align="left" id="AffiliationDiv">
                      <label htmlFor="affiliation" id="AffiliationLabel">
                        Affiliation:
                      </label>
                      <input
                        id="affiliation"
                        type="text"
                        required
                        onChange={async (event) => {
                          setAffiliation(event.target.value);
                        }}
                        value={affiliation}
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6" align="left" id="ConfirmPasswdDiv">
                      <label htmlFor="confirmpassword" id="ConfirmPasswdLabel">
                        Confirm password:{" "}
                        <span
                          style={
                            validConfirmPassword && password
                              ? { display: "block" }
                              : { display: "none" }
                          }
                        >
                          <FontAwesomeIcon icon={faCheck} id="tick" />
                        </span>
                        <span
                          style={
                            validConfirmPassword || !confirmPassword
                              ? { display: "none" }
                              : { display: "block" }
                          }
                        >
                          <FontAwesomeIcon icon={faTimes} id="cross" />
                        </span>
                      </label>
                      <input
                        id="confirmpassword"
                        type="password"
                        required
                        aria-invalid={validConfirmPassword ? "false" : "true"}
                        aria-describedby="confirmnote"
                        onFocus={() => setConfirmPasswordFocus(true)}
                        onBlur={() => setConfirmPasswordFocus(false)}
                        onChange={async (event) => {
                          setConfirmPassword(event.target.value);
                        }}
                        value={confirmPassword}
                      />
                      <p
                        id="confirmnote"
                        style={
                          confirmPasswordFocus && !validConfirmPassword
                            ? { display: "block" }
                            : { display: "none" }
                        }
                      >
                        <FontAwesomeIcon icon={faInfoCircle} id="info" />
                        Must match the first password input field.
                      </p>
                    </div>
                    <div class="col-lg-6" align="left" id="EmailDiv">
                      <label htmlFor="email" id="EmailLabel">
                        E-mail:{" "}
                        <span
                          style={
                            validEmail
                              ? { display: "block" }
                              : { display: "none" }
                          }
                        >
                          <FontAwesomeIcon icon={faCheck} id="tick" />
                        </span>
                        <span
                          style={
                            validEmail || !email
                              ? { display: "none" }
                              : { display: "block" }
                          }
                        >
                          <FontAwesomeIcon icon={faTimes} id="cross" />
                        </span>
                      </label>
                      <input
                        id="email"
                        type="text"
                        required
                        autoComplete="off"
                        aria-invalid={validEmail ? "false" : "true"}
                        aria-describedby="emailnote"
                        onFocus={() => setEmailFocus(true)}
                        onBlur={() => setEmailFocus(false)}
                        onChange={async (event) => {
                          setEmail(event.target.value);
                        }}
                        value={email}
                      />
                      <p
                        id="emailnote"
                        style={
                          emailFocus && email && !validEmail
                            ? { display: "block" }
                            : { display: "none" }
                        }
                      >
                        <FontAwesomeIcon icon={faInfoCircle} id="info" />
                        Enter valid e-mail adress.
                      </p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6" align="left" id="FirstNameDiv">
                      <label htmlFor="firstname" id="FirstNameLabel">
                        First name:
                      </label>
                      <input
                        id="firstname"
                        type="text"
                        required
                        onChange={async (event) => {
                          setFirstName(event.target.value);
                        }}
                        value={firstName}
                      />
                    </div>
                    <div class="col-lg-6" align="left" id="PhoneNumberDiv">
                      <label htmlFor="phonenumber" id="PhoneNumberLabel">
                        Phone number:
                      </label>
                      <input
                        id="phonenumber"
                        type="text"
                        required
                        onChange={async (event) => {
                          setPhoneNumber(event.target.value);
                        }}
                        value={phoneNumber}
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6" align="left" id="LastNameDiv">
                      <label htmlFor="lastname" id="LastNameLabel">
                        Last name:
                      </label>
                      <input
                        id="lastname"
                        type="text"
                        required
                        onChange={async (event) => {
                          setLastName(event.target.value);
                        }}
                        value={lastName}
                      />
                    </div>
                    <div class="col-lg-6" align="left" id="ButtonDiv">
                      <button
                        className="SignUpButton"
                        disabled={true}
                        style={{opacity:0.5}}
                        // disabled={
                        //   !validUsername ||
                        //   !validPassword ||
                        //   !validConfirmPassword ||
                        //   !firstName ||
                        //   !lastName ||
                        //   !title ||
                        //   !affiliation ||
                        //   !validEmail ||
                        //   !phoneNumber
                        //     ? true
                        //     : false
                        // }
                        // style={{
                        //   opacity:
                        //     !validUsername ||
                        //     !validPassword ||
                        //     !validConfirmPassword ||
                        //     !firstName ||
                        //     !lastName ||
                        //     !title ||
                        //     !affiliation ||
                        //     !validEmail ||
                        //     !phoneNumber
                        //       ? "0.5"
                        //       : "1",
                        // }}
                      >
                        Not available
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </body>
    </html>
  );
}
